/*doc
---
title: contact
name: contact
category: components
---
```html_example
<div class="c-contact">
  <a class="c-contact-tel c-contact__contact__tel" href="tel:0120002718">
    <div class="c-contact-tel__top">
      <i class="c-icon c-icon-tel c-contact-tel__icon"></i>
      <span>0120-002718</span>
    </div>
    <p class="c-contact-tel__desc">
      営業時間：<span class="c-contact-tel__desc--bold">9:00〜18:00</span>
    </p>
  </a>
  <div class="c-contact__desc">
    営業時間9:00〜18:00まで
  </div>
  <a class="c-link c-link--button c-contact__web" href="#">
    <span class="c-contact__link">WEBでお問い合わせ</span>
  </a>
</div>
```
*/

.c-contact {
  margin-bottom: 8px;
  text-align: center;
}

.c-contact__tel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-contact__desc {
  $fs-md: 14px;
  line-height: $line-height5;
  color: $honne-gray-700;
}

.c-contact-tel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  margin-bottom: 16px;
  color: $honne-white;
  background-color: color-lighten($honne-blue, 4%);
  border-radius: $radius-size-medium;
  box-shadow: $shadow-size-x-small;
}

.c-contact-tel:visited {
  color: $honne-white;
}

.c-contact-tel__top {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-4xl;
  font-weight: bold;
}

.c-contact-tel__icon {
  margin-right: 8px;
  background-color: $honne-white;
  background-image: initial;
  mask-image: url("~images/user_inline/icon/tel.svg");
  mask-size: 100% 100%;
}

.c-contact-tel__desc {
  font-size: $fs-xs;
}

.c-contact-tel__desc--bold {
  font-weight: bold;
}

.c-contact__web {
  font-weight: bold;
  background-color: $honne-white;
  border: 2px solid $honne-blue;
}
