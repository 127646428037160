@charset "utf-8";

.p-term-box:not(:last-child) {
  margin-bottom: 24px;
}

.p-term__title {
  padding: 24px 24px 0;
}

.p-term__subtitle {
  margin-bottom: 16px;
}

.p-term__text {
  margin-bottom: 24px;
}
