@charset "utf-8";

/*doc
---
title: list-group
name: list-group
category: components2
---
```html_example
<div class="c-list-group">
  <div class="c-list-group__item">
    <div class="c-list-group__head">タイトル</div>
    <div class="c-list-group__content">
      コンテント
    </div>
  </div>
  <div class="c-list-group__item">
    <div class="c-list-group__head">タイトル</div>
    <div class="c-list-group__content">
      コンテント
    </div>
  </div>
  <div class="c-list-group__item">
    <div class="c-list-group__head">タイトル</div>
    <div class="c-list-group__content">
      コンテント
    </div>
  </div>
</div>
```
*/

.c-list-group {
  border-top: 1px solid $honne-gray-300;
  border-bottom: 1px solid $honne-gray-300;
}

.c-list-group__item {
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid $honne-gray-300;
}

.c-list-group__item:last-child {
  border-bottom: none;
}

.c-list-group__head {
  width: 120px;
  min-width: 120px;
  font-size: $fs-md;
  font-weight: bold;
}

.c-list-group__content {
  margin-left: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-list-group__head {
    width: 200px;
    min-width: 200px;
    font-size: $fs-lg;
  }

  .c-list-group__content {
    margin-left: 24px;
  }
}
