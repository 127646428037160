.p-search-top {
  display: none;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-top {
    display: flex;
    width: 100%;
    padding: 12px;
    margin-bottom: 12px;
    color: $honne-blue-lighten;
    background-color: $honne-white;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 5%);
  }
}
