.p-search-desc {
  margin-bottom: 12px;
  background-color: $honne-white;
}

.p-search-desc__main {
  position: relative;
}

.p-search-desc__inner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 32px 24px 20px;
  color: $honne-white;
  background: linear-gradient(rgba($honne-black, 0), rgba($honne-black, 0.8));
}

.p-search-desc__img {
  width: 100%;
  height: 200px;
  vertical-align: bottom;
  object-fit: cover;
}

.p-search-desc__h1 {
  margin-bottom: 6px;
  text-shadow: rgba($honne-black, 0.6) 0 0 32px;

  & a {
    color: $honne-white;
  }
}

.p-search-desc__page {
  margin-left: 4px;
  font-size: $fs-lg;
}

.p-search-desc__summary {
  font-size: $fs-2xs;
  line-height: $lh-large;

  & a {
    color: $honne-white;
  }
}

.p-search-desc__title {
  padding: 12px 24px;
}

.p-search-desc__desc {
  padding: 0 24px;
  margin-bottom: 12px;
  font-size: $fs-sm;
  color: $honne-gray-500;

  & a {
    color: $honne-gray-500;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-search-desc__img {
    height: 200px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-desc {
    padding: 24px;
    overflow: hidden;
    border-radius: 8px;
  }

  .p-search-desc__title {
    padding: 0;
  }

  .p-search-desc__inner {
    padding: 24px;
    background: linear-gradient(rgba($honne-black, 0), rgba($honne-black, 0.7));
  }

  .p-search-desc__img {
    height: 260px;
  }

  .p-search-desc__h1 {
    font-family: $font-family-700bold;
    font-size: $font-size-big1;
    font-weight: $font-weight-700bold;
  }

  .p-search-desc__page {
    font-size: $fs-3xl;
  }

  .p-search-desc__summary {
    margin-bottom: 0;
    font-size: $fs-sm;
  }

  .p-search-desc__desc {
    padding: 0;
    margin-top: 8px;
    margin-bottom: 0;
  }
}
