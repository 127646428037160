@charset "utf-8";

.p-news-main {
  padding-top: 0;
}

.p-new__side_question .c-side-qa-request {
  background-color: $honne-yellow-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-main {
    padding-top: 24px;
  }
}
