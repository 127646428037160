@charset "utf-8";

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special-card {
    width: 1080px;
    padding-bottom: 1px;
    margin: -80px auto 0;
    background-color: $honne-white;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba($honne-gray-600, 0.1);
  }
  .p-special-subcard {
    width: 1080px;
    padding-bottom: 1px;
    margin: 0 auto;
    background-color: $honne-white;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba($honne-gray-600, 0.1);
  }
}
