@charset "utf-8";

.p-brochures-check {
  padding: 12px;
  background-color: $honne-yellow-white;

  .c-form__errors {
    margin-top: 16px;
    margin-bottom: 0;
  }
}

.p-brochures-check__inner {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: $honne-white;
  border-radius: 12px;
}

.p-brochures-check__text {
  font-weight: bold;
}

.p-brochures-check__button {
  width: 160px;
  padding: 12px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochures-check {
    padding: 16px 0;
  }

  .p-brochures-check__inner {
    padding: 16px 32px;
  }

  .p-brochures-check__text {
    font-size: $fs-lg;
  }
}
