.p-whitepaper-complete__container {
  padding: 24px;
  background-color: $honne-white;
}

.p-whitepaper-complete__title {
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-x-large;
  color: $honne-gray-700;
  letter-spacing: 0.5px;
}

.p-whitepaper-complete__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.p-whitepaper-complete__header_image {
  width: 122px;
  height: 130px;
  margin-bottom: 16px;
}

.p-whitepaper-complete__desc {
  margin-top: 12px;
  font-size: $fs-md;
  line-height: $line-height-px4;
  color: $honne-gray-700;
  letter-spacing: 0.5px;
}

.p-whitepaper-complete__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 48px;
  margin: 24px 0 45px 0;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $line-height-px4;
  color: $honne-blue;
  text-align: center;
  letter-spacing: 1px;
  background: $honne-yellow-white;
  border-radius: 8px;
}

@include min-screen($breakpoint-pc-min) {
  .p-whitepaper-complete__container {
    background-color: $honne-yellow-white;
  }

  .p-whitepaper-complete__inner {
    background: $honne-white;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(206, 206, 206, 0.25);
  }
  .p-whitepaper-complete__header_image {
    width: 135px;
    height: 144px;
    margin-top: 40px;
  }
  .p-whitepaper-complete__desc {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
