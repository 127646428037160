@charset "utf-8";

/*doc
---
title: keywords
name: keywords
category: components
---
```html_example
<ul class="c-keywords">
  <li class="c-keywords__item">
    <a class="c-keywords__link" href="#">人工呼吸器</a>
  </li>
  <li class="c-keywords__item">
    <a class="c-keywords__link" href="#">空室あり</a>
  </li>
  <li class="c-keywords__item">
    <a class="c-keywords__link c-keywords__link--white" href="#">
      <span class="c-keywords__link__text">千葉県</span>
    </a>
  </li>
</ul>
```
*/

.c-keywords {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-top: 16px;
  margin-right: -16px;
}

.c-keywords__item {
  margin-right: 18px;
  margin-bottom: 8px;
}

.c-keywords__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 52px;
  padding: 0 8px;
  font-size: $fs-xs;
  font-weight: bold;
  line-height: $line-height-px4;
  color: $honne-blue;
  text-align: center;
  background-color: $honne-yellow-white;
  border-radius: 4px;
}

.c-keywords__link:visited {
  color: $honne-blue;
}

.c-keywords__link:after {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 22px;
  height: 22px;
  content: "";
  background: linear-gradient(
    to top right,
    transparent 40%,
    $honne-yellow-white 40%
  );
  border-radius: 4px;
  transform: translate(34%, -50%) rotate(45deg);
}

.c-keywords__link__text {
  display: inline-block;
  margin-left: 5px;
}

.c-keywords__link--white {
  color: $honne-blue;
  background-color: $honne-white;
}

.c-keywords__link--white:visited {
  color: $honne-blue;
}

.c-keywords__link--white::after {
  background: linear-gradient(to top right, transparent 40%, $honne-white 40%);
}
