@charset "utf-8";

.p-footer-search {
  margin-top: 16px;
}

.p-footer-search__block + .p-footer-search__block {
  margin-top: 16px;
}

.p-footer-search__title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: $fs-xs;
  font-weight: bold;
}

.p-footer-search__icon {
  margin-right: 4px;
}

.p-footer-search__links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.p-footer-search__link {
  padding-right: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: $fs-xs;
  color: $honne-gray-600;
  border-right: 1px solid $honne-gray-300;

  &:visited {
    color: $honne-gray-600;
  }

  &:last-child {
    border-right: none;
  }
}
