@charset "utf-8";

/* 769px ~ 990px */
@include screen($breakpoint-pc-small-min, 990px) {
  .c-ownership-show-block {
    margin: 32px 0;
  }
}

/* 990px ~ */
@include min-screen(990px) {
  .c-ownership-show-block {
    margin-bottom: 32px;
  }
}
