@charset "utf-8";

.o-modal-frame {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $modal-frame-zindex;
  display: none;
  backface-visibility: hidden;
}
