.p-ownership-summary__block {
  margin-bottom: 0;
}

.p-ownership-summary__title {
  margin-bottom: 8px;
  font-size: $fs-2xl;
}

.p-ownership-summary__note {
  margin-top: 8px;
  font-size: $fs-sm;
  color: $honne-gray-500;
}

.p-ownership-summary__message {
  margin-bottom: 24px;
  line-height: $lh-large;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-ownership-summary__title {
    text-align: center;
  }

  .p-ownership-summary__button {
    display: block;
    max-width: 400px;
    margin: 0 auto;
  }
}
