@charset "utf-8";

.p-jigyosho-event__head {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: $honne-blue-bright;
}

.p-jigyosho-event__honne {
  width: 44px;
  height: 44px;
  margin-right: 12px;
  background-image: url("~images/honne_144x144.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.p-jigyosho-event__icon {
  position: absolute;
  top: 0;
  right: 24px;
  display: block;
  width: 46px;
  height: 56px;
  background: url("~images/jigyosho/campaign.png") no-repeat;
}

.p-jigyosho-event__title {
  font-size: $fs-2xl;
}
