@charset "utf-8";

/*doc
---
title: brochure-footer
name: brochure-footer
category: components
---
```html_example
<div class="c-brochure-footer" style="position: static;">
  <div class="c-brochure-footer__top">
    <p class="c-brochure-footer__title">チェックした施設10件を一括で</p>
  </div>
  <div class="c-brochure-footer__main">
    main content
  </div>
</div>
```
*/

.c-brochure-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $brochure-footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px $sp-page-margin;
  background-color: rgba($honne-white, 0.9);
}

.c-brochure-footer__top {
  min-width: 95px;
}

.c-brochure-footer__title {
  font-size: $fs-md;
  font-weight: bold;
  letter-spacing: $letter-spacing-px2;
}

.c-brochure-footer__count {
  color: $honne-red-bright;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .c-brochure-footer {
    padding: 12px 32px;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-brochure-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .c-brochure-footer__top {
    margin-bottom: 0;
  }

  .c-brochure-footer__title {
    font-size: $fs-2xl;
    letter-spacing: $letter-spacing-px4;
  }

  .c-brochure-footer__main {
    min-width: 410px;
    margin-left: 24px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-brochure-footer {
    width: 800px;
    padding: 12px 24px;
    margin-left: calc(50vw - 540px);
  }

  .c-brochure-footer--full {
    width: 1080px;
  }
}
