@charset "utf-8";

.c-footer-banner {
  padding-bottom: 24px;
}

.c-footer-banner__item {
  display: block;
  max-width: 400px;
  margin: 16px auto 0;

  &:first-child {
    margin-bottom: 12px;
  }
}

.c-footer-banner__image {
  width: 100%;
  height: auto;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-footer-banner {
    width: 100%;
    max-width: 1080px;
    padding-bottom: 32px;
    margin: 0 auto;
  }

  .c-footer-banner__item {
    max-width: 100%;
  }
}
