@charset "utf-8";

.u-d-none {
  display: none !important;
}

.u-hidden-pc {
  @include min-screen($breakpoint-pc-small-min) {
    display: none !important;
  }
}

.u-hidden-not-pc {
  @include max-screen($breakpoint-tablet-max) {
    display: none !important;
  }
}

.u-hidden-pc-max {
  @include min-screen($breakpoint-pc-max) {
    display: none !important;
  }
}

.u-hidden-not-pc-max {
  @include max-screen($breakpoint-pc-max) {
    display: none !important;
  }
}

.u-hidden-pc-min {
  @include min-screen($breakpoint-pc-min) {
    display: none !important;
  }
}

.u-hidden-not-pc-min {
  @include max-screen($breakpoint-pc-small-max) {
    display: none !important;
  }
}

.u-hidden-sp {
  @include max-screen($breakpoint-sp) {
    display: none !important;
  }
}

.u-hidden-not-sp {
  @include min-screen($breakpoint-tablet-min) {
    display: none !important;
  }
}

.u-hidden-tablet {
  @include screen($breakpoint-tablet-min, $breakpoint-tablet-max) {
    display: none !important;
  }
}
