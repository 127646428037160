@charset "utf-8";

.p-special3-solution {
  padding: 52px 0 40px;
}

.p-special3-solution__list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 24px;
}

.p-special3-solution__list__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 28px;
  background-color: $honne-blue-bright;
  border-radius: 12px;
}

.p-special3-solution__subtitle {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  letter-spacing: $letter-spacing-px2;

  br {
    display: none;
  }
}

$bgs: bg01, bg02, bg03;

@each $bg in $bgs {
  .p-special3-solution__image--#{$bg} {
    position: relative;
    width: 60px;
    height: 60px;
    margin: -30px 0 20px 0;
    background-image: url("~images/lp/special3/#{$bg}.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.p-special3-solution__text {
  font-size: $fs-sm;
  color: $honne-gray-600;
  letter-spacing: $letter-spacing-px2;

  span {
    font-weight: bold;
    color: $honne-gray-700;
  }
}

/* 768px ~ */
@include min-screen($breakpoint-tablet-max) {
  .p-special3-solution__list {
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    width: calc(100vw - 48px);
    padding: 0;
    margin: 0 auto 28px;
  }

  .p-special3-solution__list__item {
    width: calc((100% - 32px) / 3);
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special3-solution {
    padding: 72px 0 0;
  }

  .p-special3-solution__list {
    width: 952px;
  }

  .p-special3-solution__list__item {
    width: 300px;
  }

  .p-special3-solution__subtitle {
    margin-bottom: 13px;
    font-size: $fs-xl;
  }

  @each $bg in $bgs {
    .p-special3-solution__image--#{$bg} {
      width: 100px;
      height: 100px;
      margin: -24px 0 20px 0;
    }
  }

  .p-special3-solution__text {
    font-size: $fs-md;
  }
}
