@charset "utf-8";

/* 検索条件 詳細条件 */
.p-search-slide {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $jigyosho-search-slide;
  display: none;
}

.p-search-slide__frame {
  height: 100%;
}

.p-search-slide__inner {
  height: 100%;
  background-color: $honne-white;
}

.p-search-slide__head {
  display: flex;
  align-items: center;
  border-bottom: solid 1px $honne-gray-200;
}

.p-search-slide__head__title {
  flex: 1;
  padding: 16px 0 16px 24px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-search-slide__head__close {
  width: 32px;
  height: 32px;
  padding-top: 48px;
  padding-right: 66px;
  margin-left: auto;
  cursor: pointer;
  background: url("~images/icon/close.svg") no-repeat center;
  background-size: 16px 16px;
}

.p-search-slide__content {
  height: calc(100% - 146px);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  @include scrollbar-none;
}

.p-search-slide__footer {
  display: flex;
  padding: 16px;
  font-weight: bold;
  border-top: solid 1px $honne-gray-200;
}

.p-search-slide__footer__left {
  display: flex;
  flex-direction: column;
  min-width: 90px;
  padding: 4px 0;
  margin-right: 8px;
}

.p-search-slide__footer__text {
  font-size: $fs-xs;
}

.p-search-slide__footer__count {
  font-size: $fs-2xl;
  color: $honne-red-bright;
}

.p-search-slide__footer__button {
  padding: 16px;
  font-size: $fs-xl;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-slide {
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .p-search-slide__frame {
    display: flex;
    align-items: center;
  }

  .p-search-slide__inner {
    width: 90%;
    max-width: 960px;
    height: auto;
    margin: 0 auto;
    border-radius: 8px;
  }

  .p-search-slide__content {
    height: auto;
    min-height: 50vh;
    max-height: calc(100vh - 220px);
    padding: 24px;
    overflow-y: scroll;
  }

  .p-search-slide__footer {
    padding: 8px 24px;
  }

  .p-search-cond__footer__button {
    max-width: 250px;
    margin: 0 auto;
  }
}
