@charset "utf-8";

.c-breadcrumb {
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  padding: 12px 0;
  background-color: $honne-white;
  border-bottom: 1px solid $honne-gray-300;
  li {
    &:before {
      padding: 0 6px;
      content: url("~images/user_inline/arrow_pankuzu.svg");
    }
    &:first-child {
      padding-right: 4px;
      padding-left: 16px;
      font-weight: bold;
      &:before {
        padding: 0;
        content: none;
      }
    }
  }
}

.c-breadcrumb__home {
  margin-bottom: 2px;
}

.c-breadcrumb__list {
  max-width: 1080px;
  margin: 0 auto;
  overflow-x: auto;
  @include scrollbar-none;
}

.c-breadcrumb__item {
  box-sizing: border-box;
  display: table-cell;
  font-size: $fs-xs;
  font-weight: bold;
  line-height: $line-height6;
  white-space: nowrap;
  vertical-align: middle;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-breadcrumb {
    li {
      &:first-child {
        padding-left: 32px;
      }
    }
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-breadcrumb {
    li {
      &:first-child {
        padding-left: 0;
      }
    }
  }
}
