@charset "utf-8";

.p-search-tag__head {
  padding: 12px 24px;
  font-size: $fs-lg;
  font-weight: bold;
  border-bottom: solid 1px $honne-gray-200;
}

.p-search-tag__item {
  padding: 16px 16px 16px 24px;
  border-bottom: solid 1px $honne-gray-200;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-tag {
    padding: 0 24px;
  }

  .p-search-tag__group {
    display: flex;
    padding: 16px 0;
    border-bottom: solid 1px $honne-gray-200;

    &:last-child {
      border-bottom: none;
    }
  }

  .p-search-tag__head {
    min-width: 200px;
    padding: 0;
    margin-right: 12px;
    border-bottom: none;
  }

  .p-search-tag__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: -16px;
  }

  .p-search-tag__item {
    width: calc((100% - 16px) / 3);
    padding: 0;
    margin-right: 8px;
    margin-bottom: 16px;
    border-bottom: none;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
