/* stylelint-disable no-descending-specificity*/

.p-lp-online-flow__balloon {
  position: relative;
  width: 230px;
  padding: 8px 16px;
  margin: 0 auto 24px;
  font-size: $fs-lg;
  font-weight: bold;
  color: color-lighten($honne-blue, 4%);
  text-align: center;
  background: $honne-white;
  border: solid 3px color-lighten($honne-blue, 4%);
  border-radius: 100px;

  &::before {
    position: absolute;
    bottom: -19px;
    left: 50%;
    z-index: $z-index-2;
    margin-left: -15px;
    content: "";
    border: 10px solid transparent;
    border-top: 10px solid $honne-white;
  }

  &::after {
    position: absolute;
    bottom: -25px;
    left: 50%;
    z-index: $z-index-1;
    margin-left: -17px;
    content: "";
    border: 12px solid transparent;
    border-top: 12px solid color-lighten($honne-blue, 4%);
  }
}

.p-lp-online-flow {
  margin-bottom: 24px;
}

.p-lp-online-flow__title {
  margin-bottom: 16px;
  font-size: $fs-xl;
  text-align: center;
}

.p-lp-online-flow-list__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px 24px;
  background-color: color-lighten($honne-gray-100, 1%);
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:first-child {
    .p-lp-online-flow-list__image {
      width: 120px;
    }
  }

  &:nth-child(2) {
    .p-lp-online-flow-list__image {
      width: 180px;
    }
  }

  &:last-child {
    .p-lp-online-flow-list__image {
      width: 150px;
    }
  }
}

.p-lp-online-flow-list__number {
  order: 1;
}

.p-lp-online-flow-list__step {
  font-size: $fs-xs;
  color: $honne-gray-500;
}

.p-lp-online-flow-list__count {
  font-size: $fs-3xl;
  color: color-lighten($honne-blue, 4%);
}

.p-lp-online-flow-list__image {
  order: 2;
  margin: 0 auto 16px;

  img {
    width: 100%;
  }
}

.p-lp-online-flow-list__title {
  margin-bottom: 8px;
  font-size: $fs-xl;
  text-align: center;
}

.p-lp-online-flow-list__text {
  line-height: $line-height9;
  opacity: 0.8;
}

@include max-screen($breakpoint-tablet-max) {
  .p-lp-online-flow__movie {
    margin-bottom: 16px;
    text-align: center;

    iframe {
      width: calc(100vw - 96px);
      height: calc(((100vw - 96px) / 3) * 1.5);
    }
  }
}

@include min-screen($breakpoint-pc-small-min) {
  .p-lp-online-flow__movie {
    margin-bottom: 32px;
    text-align: center;

    iframe {
      width: 500px;
      height: 270px;
    }
  }

  .p-lp-online-flow-list__item {
    flex-direction: row;
    padding: 32px;
    border-radius: 8px;
  }

  .p-lp-online-flow-list__number {
    order: initial;
    width: 45px;
    margin-right: 16px;
  }

  .p-lp-online-flow-list__title {
    font-size: $fs-xl;
    text-align: left;
  }

  .p-lp-online-flow-list__text {
    width: calc(100vw - 375px);
    margin-right: 48px;
  }

  .p-lp-online-flow-list__image {
    order: initial;
    width: 170px;
    margin: 0;
  }
}

@include min-screen($breakpoint-pc-max) {
  .p-lp-online-flow {
    margin-bottom: 24px;
  }

  .p-lp-online-flow__balloon {
    width: 280px;
  }

  .p-lp-online-flow__title {
    margin-bottom: 32px;
    font-size: $fs-4xl;
  }

  .p-lp-online-flow-list__number {
    width: 45px;
    margin-right: 16px;
  }

  .p-lp-online-flow-list__text {
    width: 645px;
  }
}
