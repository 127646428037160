@charset "utf-8";

.p-special3-staff {
  padding: 0;
}

.p-special3-staff__head {
  margin-bottom: 16px;
  font-size: $fs-2xl;
  font-weight: bold;
  text-align: center;
}

.p-special3-staff__head2 {
  margin-bottom: 24px;
  font-size: $fs-lg;
  font-weight: bold;
  text-align: center;
}

.p-special3-staff__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.p-special3-staff__block {
  padding: 24px;
  border: 1px solid $honne-gray-300;
  border-radius: 8px;
}

.p-special3-staff__top {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.p-special3-staff__img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.p-special3-staff__name {
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-special3-staff__desc {
  font-size: $fs-xs;
  line-height: $lh-x-large;
  color: $honne-gray-600;
}

.p-special3-staff__text {
  color: $honne-gray-600;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special3-staff__head {
    margin-bottom: 16px;
    font-size: $fs-5xl;
  }

  .p-special3-staff__head2 {
    font-size: $fs-2xl;

    br {
      display: none;
    }
  }

  .p-special3-staff__list {
    flex-direction: row;
    gap: 24px;
  }

  .p-special3-staff__block {
    flex: 1;
  }
}
