@charset "utf-8";

// 費用概要
.p-jg-price-box {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 16px;
  background-color: $honne-yellow-bright;
  border-radius: 8px;
}

.p-jg-price-box__line {
  display: flex;
  align-items: center;
}

.p-jg-price-box__line + .p-jg-price-box__line {
  margin-top: 8px;
}

.p-jg-price-box__content {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: 8px;
  line-height: $line-height4;
}

.p-jg-price-box__content--s2 {
  font-size: $fs-xl;
  font-weight: bold;
  color: $honne-red-bright;
}

.p-jg-price-box__group {
  display: flex;
  flex-direction: column;
}

.p-jg-price-box__content--small {
  align-items: center;
  font-size: $fs-sm;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jg-price-box {
    justify-content: center;
    padding: 16px 24px;
    margin-bottom: 0;
  }

  .p-jg-price-box__line + .p-jg-price-box__line {
    margin-top: 12px;
    margin-left: 0;
  }

  .p-jg-price-box__group {
    flex-direction: row;
  }
}
