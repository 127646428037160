@charset "utf-8";

.p-regist-enterprise-mainvisual {
  width: 100%;
  min-width: 320px;
  padding: 40px 24px;
  overflow: hidden;
  background: $honne-blue-bright;
}

.p-regist-enterprise-mainvisual__inner {
  margin-bottom: 24px;
}

.p-regist-enterprise-mainvisual__title {
  margin-bottom: 16px;
  font-size: $fs-2xl;
  color: $honne-gray-600;
  letter-spacing: $letter-spacing-px4;
}

.p-regist-enterprise-mainvisual__title--blue {
  font-size: $font-size-big1;
  color: $honne-blue;
}

.p-regist-enterprise-mainvisual__title--yellow {
  font-size: $font-size-big1;
  color: $honne-yellow;
}

.p-regist-enterprise-mainvisual__text {
  margin-bottom: 16px;
  font-size: $fs-md;
  line-height: $line-height-px2;
}

.p-regist-enterprise-mainvisual__text-br {
  display: none;
}

.p-regist-enterprise-mainvisual__banner-image-sp {
  display: block;
  width: 100%;
}

.p-regist-enterprise-mainvisual__banner-image-pc {
  display: none;
}

.p-regist-enterprise-mainvisual__button-link {
  width: 100%;
  padding: 23px;
  margin: 0 auto;
  font-size: $fs-xl;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-enterprise-mainvisual {
    padding: 64px 32px;
  }

  .p-regist-enterprise-mainvisual__inner {
    position: relative;
    max-width: 1023px;
    padding: 0;
    margin: 0 auto 24px;
  }

  .p-regist-enterprise-mainvisual__title {
    font-size: $fs-5xl;
  }

  .p-regist-enterprise-mainvisual__title--blue {
    font-size: $font-size-big4;
  }

  .p-regist-enterprise-mainvisual__title--yellow {
    font-size: $font-size-big4;
  }

  .p-regist-enterprise-mainvisual__text {
    max-width: 57%;
    padding-right: 16px;
    margin-bottom: 0;
    font-size: $fs-lg;
    line-height: $line-height-px4;
  }

  .p-regist-enterprise-mainvisual__text-br {
    display: block;
  }

  .p-regist-enterprise-mainvisual__banner {
    position: absolute;
    top: 40px;
    right: 16px;
    width: 418px;
    max-width: 41%;
  }

  .p-regist-enterprise-mainvisual__banner-image-sp {
    display: none;
  }

  .p-regist-enterprise-mainvisual__banner-image-pc {
    display: block;
    width: 100%;
  }

  .p-regist-enterprise-mainvisual__button {
    max-width: 1023px;
    margin: 0 auto;
    background: none;
  }

  .p-regist-enterprise-mainvisual__button-link {
    max-width: 304px;
    margin: 0;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-regist-enterprise-mainvisual {
    padding: 64px 0;
  }
}
