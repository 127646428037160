@charset "utf-8";

.p-ownership-detail {
  padding: 0;
  margin-top: 0;
}

.p-ownership-detail__note {
  padding-top: 16px;
}

.p-ownership-detail__note__desc {
  margin-bottom: 16px;
  color: $honne-gray-600;
}

.p-ownership-detail__note__ownership {
  padding: 16px;
  background-color: $honne-yellow-bright;
  border-radius: $radius-size-medium;
}

.p-ownership-detail__note__ownership__head {
  width: 85%;
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-ownership-detail__note__ownership__desc {
  margin-bottom: 16px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-ownership-detail {
    padding: 0 32px;
    margin-top: 20px;
  }
}
