@charset "utf-8";

.p-br-c__title {
  margin-top: 12px;
}

.p-br-c__descript {
  font-size: $fs-sm;
  color: $honne-black;
}

strong + .p-br-c__descript {
  margin-top: 4px;
}

.p-br-c-radio {
  margin-top: 4px;
  margin-right: 16px;
}
