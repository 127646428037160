@charset "utf-8";

/*doc
---
title: special-card
name: special-card
category: components
---
```html_example
<div style="width:341px;">
  <a class="c-special-card" href="#">
    <img class="c-special-card__img" src="http://placehold.it/341x160">
    <h3 class="c-special-card__title">入居費用0円の施設特集</h3>
    <p class="c-special-card__desc">入居時に入居一時金という初期費用が発生する場合があります。ここでは初期費用無しの施設をご紹介します。</p>
  </a>
</div>
```
*/

.c-special-card {
  background-color: $honne-white;
}

.c-special-card__img {
  width: 234px;
  height: 124px;
  margin-bottom: 12px;
  object-fit: cover;
  border-radius: 8px;
}

.c-special-card__title {
  margin-bottom: 4px;
  font-size: $fs-lg;
  color: $honne-gray-700;
  letter-spacing: $ls-small;
}

.c-special-card__desc {
  font-size: $fs-sm;
  line-height: $lh-large;
  color: $honne-gray-500;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-special-card__img {
    width: 100%;
    height: initial;
    max-height: 160px;
  }

  .c-special-card__desc {
    font-size: $fs-md;
  }
}
