@charset "utf-8";

/*doc
---
title: search-button
name: search-button
category: components
---
```html_example
<a class="c-search-button" href="#">
  <i class="c-icon-search-white c-icon--xx-large c-search-button__icon"></i>
  <div class="c-search-button__text">
    老人ホーム・介護<br>施設を検索する
  </div>
</a>
```
*/

// guide,QA記事内で使用
%c-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  color: $honne-white;
  text-align: center;
  background: $honne-blue;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.c-search-button {
  @extend %c-search-button;
}

.c-search-button:visited {
  @extend %c-search-button;
}

.c-search-button__icon {
  display: inline-block;
  margin-right: 16px;
}

.c-search-button__text {
  display: inline-block;
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: $line-height-px3;
  white-space: nowrap;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-search-button {
    width: calc(50% - 8px);
  }
}
