@charset "utf-8";

.p-review-top {
  background-color: $honne-white;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-review-top {
    padding: 24px;
  }

  .p-review-top__inner {
    display: flex;
    width: 100%;
    max-width: $breakpoint-pc-max;
    margin: 0 auto;
  }
}
