@charset "utf-8";

// LPは特別な変数を利用してるため、一旦ここに変数定義する
// 今後はLPに関連するCSSは変数のLint Checkを外していく
// /* stylelint-disable sh-waqar/declaration-use-variable */

$font-size-big1: 28px;
$font-size-big2: 30px;
$font-size-big3: 35px;
$font-size-big4: 40px;
$font-size-big5: 48px;
$font-size-big6: 56px;
$font-size-big7: 72px;
$font-size-big8: 32px;
$font-size-big9: 46px;
