@charset "utf-8";

.p-news-bottom:last-child {
  margin-bottom: 16px;
}

.p-news-bottom__balloon {
  position: relative;
  width: calc(100vw - 48px);
  max-width: 1080px;
  padding: 12px 0;
  margin: 24px auto;
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-blue-lighten;
  text-align: center;
  background-color: $honne-white;
  border-radius: 100px;

  &:after {
    position: absolute;
    bottom: -24px;
    left: 50%;
    content: "";
    border: 15px solid transparent;
    border-top: 15px solid $honne-white;
    transform: translateX(-50%);
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-bottom {
    width: calc(100vw - 64px);
    margin: 0 auto;
    box-shadow: $shadow-size-small;

    &:last-child {
      margin-bottom: 64px;
    }
  }

  .p-news-bottom__balloon {
    width: calc(100vw - 64px);
    font-size: $fs-lg;
    background-color: $honne-yellow-white;

    &:after {
      border-top: 15px solid $honne-yellow-white;
    }

    br {
      display: none;
    }
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-news-bottom {
    max-width: 1080px;
    border-radius: 12px;
    box-shadow: $shadow-size-small;
  }
}
