@charset "utf-8";

.p-jigyosho-appeal__message {
  font-size: $fs-md;
  line-height: $lh-large;

  h3 {
    margin-bottom: 4px;
    font-size: $fs-lg;
    font-weight: bold;
    line-height: $lh-small;
    color: $honne-gray-700;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-appeal__message {
    font-size: $fs-2md;

    h3 {
      font-size: $fs-xl;
    }
  }
}
