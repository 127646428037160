@charset "utf-8";

.p-sign-pre {
  padding: 40px 0;
}

.p-sign-pre__h1 {
  margin-bottom: 32px;
  font-size: $fs-2xl;
  font-weight: bold;
}

.p-sign-pre__inner {
  padding: 40px 16px;
  text-align: center;
  background-color: $honne-white;
  border-radius: $radius-size-medium;
  box-shadow: $shadow-size-medium;
}

.p-sign-pre__done {
  display: block;
  width: 120px;
  height: 120px;
  margin: 20px auto 40px;
  background-image: url("~images/signup_done.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.p-sign-pre__mail {
  display: block;
  width: 120px;
  height: 90px;
  margin: 20px auto 40px;
  background-image: url("~images/signup_mail.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.p-sign-pre__title {
  margin-bottom: 12px;
  font-size: $fs-4xl;
  font-weight: bold;
  color: $honne-blue;
}

.p-sign-pre__text {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-600;
}

.p-sign-pre__desc {
  font-size: $fs-xs;
  color: $honne-gray-600;
}

.p-sign-pre__block {
  padding: 16px;
  background-color: $honne-gray-100;
  border-radius: $radius-size-medium;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-sign-pre {
    max-width: 640px;
    margin: 0 auto;
  }

  .p-sign-pre__inner {
    padding: 40px;
  }

  .p-sign-pre__text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
