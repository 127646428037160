@charset "utf-8";

.p-cashback-target {
  background: $honne-yellow-white;
}

.p-cashback-target__inner {
  width: 100%;
  padding: 48px 0;
}

.p-cashback-targe__title {
  margin-bottom: 32px;
}

.p-cashback-target__column {
  margin-bottom: 24px;
}

.p-cashback-target__box {
  padding: 24px;
  background: $honne-white;
  border-radius: 8px;
}

.p-cashback-target__box + .p-cashback-target__box {
  margin-top: 16px;
}

.p-cashback-target__box-title {
  margin-bottom: 24px;
  font-size: $fs-md;
  font-weight: normal;
  line-height: $line-height-large;
  text-align: center;
}

.p-cashback-target__title-blue {
  font-weight: bold;
  color: $honne-blue;
}

.p-cashback-target__icon {
  display: block;
  width: 70px;
  margin: 0 auto 24px;
}

.p-cashback-target__box-text {
  font-size: $fs-md;
  line-height: $line-height-large;
  text-align: center;
}

.p-cashback-target__text-yellow {
  font-weight: bold;
  color: $honne-yellow;
}

.p-cashback-target__bottom-box-title {
  margin-bottom: 10px;
  font-size: $fs-lg;
  line-height: $line-height-px3;
}

.p-cashback-target__bottom-box-text {
  margin-bottom: 15px;
  font-size: $fs-md;
  line-height: $line-height-px2;
}

.p-cashback-target__image {
  display: block;
  width: 100%;
}

.p-cashback-target__note {
  font-size: $fs-xs;
  color: $honne-gray-600;
}

.p-cashback-target__bottom-box {
  margin-bottom: 16px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cashback-target__inner {
    width: 100%;
    padding: 72px 0;
  }

  .p-cashback-target__column {
    display: flex;
    justify-content: space-between;
  }

  .p-cashback-target__box {
    padding: 24px 33px 30px;
  }

  .p-cashback-target__column > .p-cashback-target__box {
    width: calc(50% - 12px);
  }

  .p-cashback-target__box + .p-cashback-target__box {
    margin-top: 0;
  }

  .p-cashback-target__box-title {
    margin-bottom: 24px;
    font-size: $fs-lg;
  }

  .p-cashback-target__icon {
    display: block;
    width: 90px;
    margin: 0 auto 24px;
  }

  .p-cashback-target__box-text {
    font-size: $fs-lg;
  }

  .p-cashback-target__bottom-box {
    display: flex;
    padding: 32px;
    margin-bottom: 16px;
  }

  .p-cashback-target__bottom-text-wrap {
    order: 2;
    max-width: 415px;
  }

  .p-cashback-target__image {
    order: 1;
    width: 250px;
    margin-right: 25px;
  }

  .p-cashback-target__note {
    font-size: $fs-xs;
  }
}
