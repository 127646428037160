@charset "utf-8";

.p-cash-top-visual__h1 {
  margin-bottom: 4px;
}

.p-cash-top-visual__text {
  @include typo-sm-xl-md;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cash-top-visual__text {
    @include typo-2md-xl-md;
  }
}
