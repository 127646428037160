@charset "utf-8";

.c-jigyosho-access {
  display: flex;
  flex-wrap: wrap;
}

.c-jigyosho-access__item {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.c-jigyosho-access__state {
  position: relative;
  padding-right: 14px;

  &:after {
    position: absolute;
    right: 0.2em;
    width: 8px;
    height: 8px;
    content: "";
    background-image: url("~images/user_inline/icon/arrow-right.svg");
    background-size: 8px 8px;
  }
}

.c-jigyosho-access__city {
  padding-right: 10px;
}

.c-jigyosho-access__station {
  margin-left: 2px;
}

.c-jigyosho-access__link {
  display: flex;
  align-items: center;
  color: $honne-gray-500;
  text-decoration: underline;

  &:visited {
    color: $honne-gray-500;
  }
}

.c-jigyosho-access__icon {
  margin-right: 2px;
  margin-bottom: 2px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-jigyosho-access__item {
    font-size: $fs-md;
  }
}
