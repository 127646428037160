@charset "utf-8";

/* 検索条件パーツ checkbox radioなど */
.p-search-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-search-option__main {
  width: calc(100% - 32px);
}

.p-search-option__name {
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-blue;
}

.p-search-option__number {
  margin-left: 4px;
  font-size: $fs-sm;
  color: $honne-gray-700;
}

.p-search-option__link {
  width: 14px;
  min-width: 28px;
  height: 14px;
  background-image: url("~images/user_inline/icon/arrow-right-gray.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}

.p-search-option__pc {
  display: none;
}

.p-search-option__link__name {
  display: none;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-option {
    & .c-checkbox__mimic-wrap {
      margin-right: 8px;
    }
  }

  .p-search-option__main {
    width: auto;
  }

  .p-search-option__name {
    color: $honne-blue;
  }

  .p-search-option__link {
    display: none;
  }

  .p-search-option__pc {
    display: block;
  }

  .p-search-option__sp {
    display: none;
  }
}
