@charset "utf-8";

.p-consultant-worry {
  position: relative;
  z-index: $z-index-2;
}

.p-consultant-worry__inner {
  width: 100%;
  margin-bottom: 8px;
}

.p-consultant-worry__title {
  font-size: $fs-2xl;
  text-align: center;
  letter-spacing: $ls-medium;
}

.p-consultant-worry__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
}

.p-consultant-worry__item {
  width: calc(50vw - 32px);
  padding: 5px 0 16px;
  margin-bottom: 16px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.p-consultant-worry__item-icon {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto 3px;
}

.p-consultant-worry__item-text {
  font-size: $fs-md;
  line-height: $lh-large;
  text-align: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-consultant-worry__items {
    justify-content: center;
  }

  .p-consultant-worry__title {
    font-size: $fs-5xl;
    letter-spacing: $ls-large;
  }

  .p-consultant-worry__inner {
    margin-bottom: 48px;
  }

  .p-consultant-worry__item {
    width: calc(25% - 24px);
    max-width: 252px;
    padding: 24px 16px;
    margin: 0 12px;
  }

  .p-consultant-worry__item-icon {
    width: 120px;
    height: 120px;
    margin-bottom: 8px;
  }

  .p-consultant-worry__item-text {
    font-size: $fs-xl;
    line-height: $lh-medium;
  }
}
