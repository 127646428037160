@charset "utf-8";

.c-cards {
  margin-right: -24px;
  margin-left: -24px;
}

.c-card {
  display: block;
  background-color: $honne-white;
  box-shadow: $shadow-size-medium;
}

.c-card + .c-card {
  margin-top: 16px;
}

.c-card__top {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid $honne-gray-300;
}

.c-card__title {
  margin-bottom: 0;
  font-size: $fs-xl;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: $honne-blue;
}

.c-card__image {
  position: relative;
  height: 172px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-card__image-1 {
  position: relative;
  height: 172px;
}

.c-card__main__right {
  padding: 12px 16px 0;
}

.c-card__main____covid-19-visit {
  margin-bottom: 8px;
  font-weight: bold;
  color: $honne-red-bright;
}

.c-card__main____covid-19-visit__date {
  display: inline-block;
  margin-left: 8px;
  font-size: $fs-xs;
  font-weight: normal;
  color: $honne-gray-600;
}

.c-card__main__labels {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 8px;
}

.c-card__desc {
  display: -webkit-box;
  margin: 4px 0 8px;
  overflow: hidden;
  font-size: $fs-sm;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.c-card__bottom {
  padding: 8px 16px 0;
}

.c-card__footer {
  display: flex;
  gap: 8px;
  padding: 8px 16px 12px;
}

.c-card__footer__link__wrapper {
  width: 100%;
}

.c-card__footer__link {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  margin: 0 auto;
  font-weight: bold;
  color: $honne-gray-700;
  background: $honne-yellow-white;
  border-radius: 8px;

  &:visited {
    color: $honne-gray-700;
  }
}

.c-card__footer__link--blue {
  color: $honne-white;
  background: $honne-blue-lighten;

  &:visited {
    color: $honne-white;
  }
}

.c-card__footer__icon {
  width: 16px;
  height: 16px;
  color: $honne-white;

  &:visited {
    color: $honne-white;
  }
}

.c-card__pr {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  margin-right: 8px;
  font-weight: bold;
  color: $honne-yellow;
  background: $honne-white;
  border: 1px solid $honne-yellow;
  border-radius: $radius-size-small;
}

// min-width: 769px
@include min-screen($breakpoint-pc-small-min) {
  .c-cards {
    margin-right: 0;
    margin-left: 0;
  }

  .c-card {
    padding: 0;
    margin: 0;
    border-radius: $radius-size-medium;
    box-shadow: $shadow-size-x-medium;
  }

  .c-card + .c-card {
    margin-top: 24px;
  }

  .c-card__top {
    padding: 12px 24px;
  }

  .c-card__main {
    display: flex;
    align-items: flex-start;
    padding: 16px 24px 0;
    margin-bottom: 0;
  }

  .c-card__main__right {
    width: calc(100% - 320px - 24px);
    padding: 0;
  }

  .c-card__main__right--full {
    width: 100% !important;
  }

  .c-card__title {
    font-family: $font-family-700bold;
    font-size: $fs-4xl;
  }

  .c-card__image {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 320px;
    min-width: 320px;
    height: auto;
    margin-right: 24px;
    border-radius: 8px;
  }

  .c-card__image-1 {
    width: 320px;
    height: 240px;
  }

  .c-card__image-2,
  .c-card__image-3 {
    width: 158px;
    height: 118px;
    margin-top: 4px;
  }

  .c-card__bottom {
    padding: 12px 24px 0;
  }

  .c-card__footer {
    padding: 12px 24px;
  }

  .c-card__pr {
    font-size: $fs-2md;
  }

  .c-card__main__labels {
    margin-bottom: 12px;
  }

  .c-card__footer__link {
    padding: 16px 0;
  }
}

/* 900px ~ 1080px */
@include screen($breakpoint-pc-min, $breakpoint-pc-max) {
  .c-card__image {
    width: 280px;
    min-width: 280px;
  }

  .c-card__image-1 {
    width: 280px;
    height: 210px;
  }

  .c-card__image-2,
  .c-card__image-3 {
    width: 138px;
    height: 100px;
  }

  .c-card__main__right {
    width: calc(100% - 280px - 24px);
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-card {
    margin-right: 0;
    margin-left: 0;
  }
}
