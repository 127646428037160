@charset "utf-8";

.c-header {
  border-bottom: 1px solid $honne-gray-300;
}

.c-header--top {
  position: absolute;
  top: 12px;
  left: 50%;
  z-index: $z-index-1;
  width: calc(100vw - 32px);
  max-width: 1300px;
  overflow: hidden;
  background-color: $honne-white;
  border-radius: 40px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);
}

.c-header__inner {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: calc(100vw - 32px);
  max-width: 1300px;
  padding: 8px 16px;
  margin: 0 auto;
}

.c-header__back {
  margin-right: 8px;
}

.c-header__back__text {
  display: -webkit-box;
  width: 84px;
  overflow: hidden;
  font-size: $fs-2xs;
  line-height: initial;
  color: $honne-gray-700;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.c-header__title.c-header-js__title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: initial;
  transform: translate(-50%, -50%);
}

.c-header--normal,
.c-header--simple {
  background-color: $honne-white;

  .c-header__inner {
    padding: 12px 0;
  }
}

.c-header__title {
  display: flex;
  width: calc(100% - 195px);
}

.c-header__title__image {
  width: 100%;
  max-width: 130px;
  height: 100%;
}

.c-header__right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.c-header__history {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 58px;
  font-size: $fs-2xs;
  font-weight: bold;
}

.c-header__history__count {
  position: absolute;
  top: -2px;
  left: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-3xs;
  font-weight: bold;
  color: $honne-blue;
  background-color: $honne-white;
  border-radius: 50%;
}

.c-header__favorite__count {
  position: absolute;
  top: -2px;
  left: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-3xs;
  font-weight: bold;
  color: $honne-blue;
  background-color: $honne-white;
  border-radius: 50%;
}

.c-header__menu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.c-header__menu__image {
  width: 18px;
  height: 14px;
}

.c-header__menu__text {
  display: none;
}

.c-header__tel {
  text-align: center;
}

.c-header__tel__icon {
  width: 12px;
}

.c-header__simple__tel__title {
  font-size: $fs-3xs;
  font-weight: bold;
  line-height: $line-height2;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing0;
  white-space: nowrap;
  transform: scale(0.8);
}

.c-header__simple__tel__line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}

.c-header__simple__tel__number {
  margin-left: 4px;
  font-size: $fs-md;
  font-weight: $font-weight-800bold;
  line-height: $line-height2;
  color: $honne-gray-800;
  letter-spacing: $letter-spacing1;
}

.c-header__user {
  @include icon(20px, 20px);
  background-image: url("~images/user_inline/icon/user.svg");

  &.badge {
    position: relative;

    &:after {
      position: absolute;
      top: -1px;
      right: -2px;
      display: block;
      width: 8px;
      height: 8px;
      content: "";
      background-color: $honne-red-bright;
      border-radius: 50%;
    }
  }
}

/* 481px ~  */
@include min-screen($breakpoint-tablet-min) {
  .c-header__title {
    display: flex;
    width: calc(100% - 312px);
  }

  .c-header__history {
    margin-right: 4px;
  }

  .c-header__back__text {
    width: 130px;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-header__title {
    width: 40%;
  }

  .c-header__title.c-header-js__title {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
  }

  .c-header__history {
    min-width: 56px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-header--top {
    top: 16px;

    .c-header__inner {
      padding: 12px 24px;
    }
  }

  .c-header--normal,
  .c-header--simple {
    top: 16px;

    .c-header__inner {
      padding: 16px 4px;
    }
  }

  .c-header__title {
    width: 190px;
  }

  .c-header__title__image {
    max-width: 180px;
  }

  .c-header__tel {
    margin-right: 8px;
  }

  .c-header__tel__icon {
    width: 15px;
  }

  .c-header__tel__inner {
    display: flex;
    flex-direction: column;
  }

  .c-header__tel__title {
    margin-top: 6px;
    font-size: $fs-2xs;
    font-weight: normal;
    color: $honne-gray-700;
    letter-spacing: $letter-spacing-px2;
    transform: none;
  }

  .c-header__tel__line {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .c-header__tel__number {
    margin-left: 4px;
    font-size: $fs-2xl;
    font-weight: $font-weight-800bold;
    color: $honne-gray-800;
    letter-spacing: $letter-spacing3;
  }

  .c-header__history__count {
    top: 0;
    left: 40px;
  }

  .c-header__favorite__count {
    top: 0;
    left: 40px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-header__menu {
    display: none;
  }

  .c-header__tel {
    margin-right: 0;
  }

  .c-header__simple__tel__line {
    margin-bottom: 0;
  }

  .c-header--normal {
    .c-hover-menu__content {
      top: 120px;
      width: 100%;
      border-top: 1px solid $honne-gray-100;
      box-shadow: 0 80px 30px 0 rgba(0, 0, 0, 0.05);
    }
  }

  .c-header__user {
    width: 30px;
    height: 30px;
    margin-right: 16px;
    margin-left: 8px;

    &.badge:after {
      top: 0;
      right: 2px;
    }
  }
}
