@charset "utf-8";

.p-jigyosho-article__content {
  margin-top: -8px;

  h3 {
    padding-left: 0;
    margin: 24px 0 8px;
    font-size: $fs-lg;
    line-height: $lh-large;

    &:before {
      position: static;
      display: inline;
      margin-right: 4px;
      content: "ーー";
      background: $honne-white;
    }
  }

  %p-jigyosho-article-icon {
    position: relative;
    padding-left: 36px;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      width: 30px;
      content: "";
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 0;
    }
  }

  h3.recommend {
    @extend %p-jigyosho-article-icon;

    &:before {
      background-image: url("~images/user_inline/article/recommend.svg");
    }
  }

  h3.point {
    @extend %p-jigyosho-article-icon;

    &:before {
      background-image: url("~images/user_inline/article/point.svg");
    }
  }

  h3.meal {
    @extend %p-jigyosho-article-icon;

    &:before {
      background-image: url("~images/user_inline/article/meal.svg");
    }
  }

  p {
    margin-bottom: 4px;
    font-size: $fs-md;
  }

  & img {
    border-radius: 8px;
  }

  .desc {
    margin-bottom: 0;
  }
}

.p-jigyosho-article__top {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background-color: $honne-yellow;
}

.p-jigyosho-article__title {
  margin: 0 8px;
  font-size: $fs-xl;
  font-weight: bold;
}

.p-jigyosho-article__inner {
  padding: 16px 24px 24px;
}

.p-jigyosho-article__date {
  display: inline-block;
  padding: 6px 8px;
  font-size: $fs-xs;
  font-weight: bold;
  background: rgba(242, 184, 48, 0.2);
  border-radius: 4px;
}

.p-jigyosho-article__msg {
  padding: 16px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.p-jigyosho-article__mhead {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-jigyosho-article__logo {
  margin-right: 8px;
}

.p-jigyosho-article__text {
  line-height: $lh-large;
}

.p-jigyosho-article__mimg {
  margin-top: 16px;
}

.p-jigyosho-article__img {
  width: 100%;
  height: auto;
  margin-bottom: 4px;
  object-fit: contain;
  border-radius: 8px;
}

.p-jigyosho-article__name {
  font-size: $fs-xs;
}

.p-jigyosho-article__oname {
  font-size: $fs-xs;
  text-align: right;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-article__top {
    padding: 12px 24px;
  }

  .p-jigyosho-article__title {
    font-size: $fs-3xl;
  }

  .p-jigyosho-article__msg {
    padding: 16px 24px;
  }

  .p-jigyosho-article__text {
    margin-bottom: 0;
  }

  .p-jigyosho-article__mhead {
    margin-bottom: 12px;
    font-size: $fs-xl;
  }

  .p-jigyosho-article__mmain {
    display: flex;
    flex-direction: row-reverse;
  }

  .p-jigyosho-article__mimg {
    min-width: 240px;
    max-width: 240px;
    margin-top: 0;
    margin-right: 16px;
  }

  .p-jigyosho-article__name {
    font-size: $fs-xs;
  }
}
