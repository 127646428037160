@charset "utf-8";

.c-header-guide {
  display: flex;
}

.c-header-guide__group {
  width: 25%;
}

.c-header-guide__title {
  margin-bottom: 16px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-base;
  color: $honne-gray-600;
  letter-spacing: $letter-spacing-px7;
}

.c-header-guide__link {
  margin-bottom: 12px;
  font-size: $fs-2md;
}

.c-header-guide__link + .c-header-guide__link {
  margin-top: 12px;
}
