@charset "utf-8";

.p-guide-article-summary {
  position: relative;
  padding: 48px 24px 24px;
  margin: 69px 0 40px;
  background: $honne-yellow-dark;
  border: 2px solid $honne-yellow;
  border-radius: 8px;
}

.p-guide-article-summary__title {
  position: absolute;
  top: -2px;
  left: -2px;
  padding: 3px 12px;
  font-size: $fs-2md;
  line-height: $line-height-large;
  color: $honne-white;
  background: $honne-yellow;
  border-radius: 8px 0 8px 0;
}

.p-guide-article-summary__list-item {
  position: relative;
  padding-left: 32px;
  font-weight: bold;
}

.p-guide-article-summary__list-item:before,
.p-guide-article-summary__list-item:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 3px;
  content: "";
  background: $honne-red-bright;
  border-radius: 4px;
}

.p-guide-article-summary__list-item:before {
  top: 12px;
  left: -1px;
  width: 9px;
  transform: rotate(40deg);
}

.p-guide-article-summary__list-item:after {
  top: 9px;
  left: 2px;
  width: 16px;
  transform: rotate(-50deg);
}

.p-guide-article-summary__list-item + .p-guide-article-summary__list-item {
  margin-top: 12px;
}
