@charset "utf-8";

.p-review__link {
  padding: 24px 0;
  margin-bottom: 16px;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-review__link {
    margin-bottom: 24px;
  }
}
