@charset "UTF-8";

.p-my-resign__head {
  display: flex;
  padding: 12px 0;
  font-size: $fs-xl;
  font-weight: bold;
  background-color: $honne-white;
}

.p-my-resign__text {
  margin-bottom: 16px;
  line-height: $lh-medium;
}

.p-my-resign__reasons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-my-resign__head {
    padding: 0 0 12px 0;
    font-size: $fs-2xl;
    border-radius: 8px;
  }
}
