@charset "utf-8";

/*doc
---
title: side-sns
name: side-sns
category: components
---
```html_example
<div class="c-side-sns" style="width: 256px;">
  <p class="c-side-sns__copy">公式SNSアカウントも更新中！</p>
  <p class="c-side-sns__title">介護に役立つ選りすぐりの<br>最新ニュースをお届けします！</p>
  <a class="c-button c-button--twitter c-button--large c-side-sns__button" href="#">Twitterをフォロー</a>
  <a class="c-button c-button--facebook c-button--large" href="#">Facebookをフォロー</a>
</div>
```
*/

.c-side-sns {
  padding: 16px;
  font-weight: bold;
  text-align: center;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.c-side-sns__copy {
  margin-bottom: 4px;
  color: $honne-blue-lighten;
}

.c-side-sns__title {
  margin-bottom: 12px;
  font-size: $fs-2md;
  color: $honne-gray-700;
}

.c-side-sns__button {
  margin-bottom: 12px;
}
