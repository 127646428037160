@charset "utf-8";

.p-theme-detail__pager {
  margin-top: 24px;
}

.p-theme-detail__bottom {
  margin-top: 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-theme-detail__top {
    padding-top: 24px;
  }
}
