@charset "utf-8";

.p-consultant-staff {
  margin-bottom: 24px;

  & .p-top-staff {
    margin-top: 0;
  }

  & .p-top-staff__title {
    text-align: center;
  }
}

.p-consultant-staff__inner {
  width: 100%;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-consultant-staff {
    margin-bottom: 40px;
  }

  .p-consultant-staff__inner {
    margin-bottom: 32px;
  }
}
