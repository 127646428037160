@charset "utf-8";

.p-brochures-date__time {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-brochures-date__date {
  margin-bottom: 8px;
}

.p-brochures-date__hour {
  width: calc(50vw - 52px);
}

.p-brochures-date__split {
  margin: 0 4px;
}

.p-brochures-date__full {
  margin-top: 8px;
}

.p-brochures-date__end {
  margin-left: 4px;
  font-size: $fs-2xs;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochures-date {
    display: flex;
  }

  .p-brochures-date__date {
    width: 200px;
    margin-right: 8px;
    margin-bottom: 0;
  }

  .p-brochures-date__hour {
    width: 160px;

    & .hour {
      margin-right: 8px;
    }
  }

  .p-brochures-date__full {
    margin-top: 0;
    margin-left: 12px;
  }

  .p-brochures-date__end {
    margin-left: 8px;
    font-size: $fs-xs;

    br {
      display: none;
    }
  }
}
