@charset "utf-8";

/*doc
---
title: question
name: question
category: components
---
```html_example
<a class="c-question" href="#">
  <h2 class="c-question__title">
    <span class="c-question__title__q">Q</span>
    認知症の母が施設入居を嫌がる場合の対応方法を教えてください
  </h2>
  <div class="c-question__main">
    <p class="c-question__text">認知症の母の在宅介護をしているのですが、そろそろ限界を感じていて、グループホームなどの施設にお世話を任せたいなと思っています。しかし、いざ施設への入居となると嫌がるのではないかと不安です。そこで質問です！ 施設入居を嫌がる母への対応について教えてください。また認知症でも入居できる施設の種類について教えてください！</p>
    <time class="c-question__date">2020年12月10日</time>
    <div class="c-question__category">
      <ul class="o-line o-line--small">
        <li class="o-line__item">
          <span class="c-link--tag">生活保護</span>
        </li>
        <li class="o-line__item">
          <span class="c-link--tag">介護施設</span>
        </li>
      </ul>
    </div>
    <div class="c-question-editor">
      <span class="c-question-editor__a">A</span>
      <img class="c-question-editor__photo" alt="平栗 潤一" src="http://placehold.it/36x36">
      <div class="c-question-editor__box">
        <p class="c-question-editor__name">平栗 潤一<span>(回答者)</span></p>
        <p class="c-question-editor__profile">一般社団法人 日本介護協会 理事長</p>
      </div>
    </div>
  </div>
</a>

```
*/

.c-question {
  display: block;
  padding: 24px 0;
  border-bottom: 1px solid $honne-gray-300;
}

.c-question__title {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $lh-small;
  color: $honne-gray-700;
}

.c-question__title__q {
  padding: 6px 10px;
  margin-right: 12px;
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: initial;
  color: $honne-white;
  background-color: $honne-blue-lighten;
  border-radius: 12px 12px 0 12px;
}

.c-question__title:hover {
  opacity: 1;
}

.c-question__main {
  padding-left: 50px;
}

.c-question__text {
  display: -webkit-box;
  margin-top: 8px;
  overflow: hidden;
  font-size: $fs-md;
  line-height: $line-height-px2;
  color: $honne-gray-600;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

.c-question__date {
  font-size: $fs-sm;
  color: $honne-gray-600;
}

.c-question__category {
  margin: 8px 0;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-question__title {
    align-items: center;
    font-size: $fs-xl;
    line-height: $line-height10;
  }

  .c-question__title__q {
    padding: 6px 10px;
    margin-right: 12px;
    font-size: $fs-3xl;
    border-radius: 12px 12px 0 12px;
  }

  .c-question__main {
    display: flex;
    flex-direction: column;
    padding-left: 51px;
  }

  .c-question__text {
    margin: 0 0 8px;
    -webkit-line-clamp: 1;
  }

  .c-question__category {
    width: calc(100% - 114px);
  }
}
