@charset "utf-8";

.p-cashback__button {
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin: 0 auto;
  background-color: $honne-red-bright;
  border-radius: 8px;
}

.p-cashback__button__text {
  font-size: $fs-md;
  color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cashback__button__text {
    font-size: $fs-lg;
  }
}
