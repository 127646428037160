@charset "utf-8";

/*doc
---
title: search-box
name: search-box
category: components
---
```html_example
<div class="c-search-box">
  <label class="c-text-field">
    <input type="text" name="q" id="q" value="" placeholder="都道府県や施設名、運営会社など">
  </label>
  <button class="c-search-box__button">
    <i class="c-search-box__icon"></i>
  </button>
</div>
```
*/

.c-search-box {
  position: relative;
  background-color: $honne-white;
  border-radius: $radius-size-medium;
}

.c-search-box__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  line-height: $line-height-px5;
  background-color: $honne-blue-lighten;
  border: 0;
  border-radius: 8px;
}

.c-search-box__button:after {
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-white;
  content: "施設を探す";
}

.c-search-box__icon {
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background: url("~images/user_inline/icon/icon-search-white.svg") no-repeat
    center center;
  background-size: contain;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-search-box {
    height: initial;
  }

  .c-search-box__button {
    position: absolute;
    top: 0;
    right: 0;
    width: 156px;
    height: 100%;
    line-height: $line-height-px6;
    text-align: center;
    cursor: pointer;
    border-radius: 0 8px 8px 0;
  }

  .c-search-box__button:hover {
    opacity: 0.5;
  }

  .c-search-box__button:after {
    margin-left: 8px;
  }

  .c-search-box__icon {
    width: 14px;
    height: 14px;
    margin-right: 0;
  }
}
