@charset "utf-8";

.p-theme-detail-search__box {
  display: flex;
  flex-direction: column-reverse;
  margin: 16px 0;
}

.p-theme-detail-search__head {
  font-size: $fs-lg;
}

.p-theme-detail-search__head--red {
  color: $honne-red-bright;
}

.p-theme-detail-search__state {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.p-theme-detail-search__title {
  min-width: 64px;
  font-size: $fs-sm;
  font-weight: bold;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-theme-detail-search {
    padding: 0;
  }

  .p-theme-detail-search__box {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .p-theme-detail-search__head {
    font-size: $fs-xl;
  }

  .p-theme-detail-search__state {
    margin-bottom: 0;

    & .c-selectbox {
      width: 314px;
    }
  }
}
