@charset "utf-8";

.p-theme-top {
  margin-bottom: 16px;
}

.p-theme-top__h1 {
  margin-bottom: 8px;
}

.p-theme-top__desc {
  line-height: $line-height3;
  letter-spacing: 0.03em;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-theme-top__h1 {
    margin-bottom: 16px;
  }
}
