@charset "utf-8";

.p-jigyosho-sample-payments__subtitle {
  padding: 8px 16px;
  margin-bottom: 24px;
  font-size: $fs-2md;
  background-color: $honne-yellow-white;
  border-radius: 4px;
}

.p-jigyosho-sample-payments__date {
  display: inline-block;
  margin-left: 4px;
  font-size: $fs-xs;
  color: $honne-gray-600;
}

.p-jigyosho-sample-payments__doughnut {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

.p-jigyosho-sample-payments__total-amount {
  margin-bottom: 4px;
  font-size: $fs-4xl * 1.15;
  text-align: center;
}

.p-jigyosho-sample-payments__list {
  display: flex;
  flex-wrap: wrap;
}

.p-jigyosho-sample-payments__detail {
  &:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 4px;
  }
}

.p-jigyosho-sample-payments__box {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-jigyosho-sample-payments__doughnut {
    flex-direction: row;
    margin-bottom: 24px;
  }

  .p-jigyosho-sample-payments__list {
    display: block;
  }

  .p-jigyosho-sample-payments__detail {
    margin-right: 0;
  }

  .p-jigyosho-sample-payments__total-amount {
    text-align: left;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-sample-payments__doughnut {
    padding: 0 40px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-sample-payments__doughnut {
    padding: 0;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-sample-payments__doughnut {
    padding: 0 64px;
  }
}
