@charset "utf-8";

.p-brochures-visit__block + .p-brochures-visit__block {
  margin-top: 12px;
}

.p-brochures-visit__title {
  margin-bottom: 8px;
  font-size: $fs-xs;
  font-weight: bold;
}

.p-brochures-visit__date {
  margin-bottom: 8px;
}

.p-brochures-visit__line {
  display: flex;
  gap: 8px;
  align-items: center;
}

.p-brochures-visit__time {
  flex: 1;
}

.p-brochures-visit__error {
  margin-bottom: 8px;
}

.p-brochures-visit__br {
  & br {
    display: none;
  }
}

.p-brochures-visit__hint {
  margin: 16px 0;
  font-size: $fs-3xs;
  color: $honne-gray-500;
}

.p-brochures-visit__form-title {
  margin-bottom: 8px;
  font-size: $fs-md;
  font-weight: bold;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochures-visit {
    align-items: baseline;
  }

  .p-brochures-visit__main {
    & .c-form__field__head {
      min-width: 100px;
    }
  }

  .p-brochures-visit__block {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .p-brochures-visit__date {
    width: 200px;
    margin-bottom: 0;
  }

  .p-brochures-visit__line {
    flex: 1;
  }

  .p-brochures-visit__error {
    margin-top: 0;
  }

  .p-brochures-visit__br {
    & br {
      display: block;
    }
  }

  .p-brochures-visit__hint {
    margin: 12px 0;
    font-size: $fs-sm;
  }

  .p-brochures-visit__form-title {
    margin-bottom: 0;
  }
}
