@charset "utf-8";

/*doc
---
title: side-list
name: side-list
category: components
---
```html_example
<div class="c-side-list c-side-list--blue" style="width:256px;">
  <h3 class="c-side-list__title">介護まるわかりガイド</h3>
  <ul class="c-side-list__list">
    <li class="c-side-list__item js-drop-down">
      <div class="c-side-list__item__parent js-drop-down-trigger">
        <span class="c-side-list__link c-side-list__link--category">介護のほんねの使い方</span>
        <i class="c-area__list__icon c-dropdown-menu__icon js-drop-down-icon"></i>
      </div>
      <div class="js-drop-down-target">
        <a class="c-side-list__link c-side-list__link__child" href="#">介護のほんねの使い方</a>
        <a class="c-side-list__link c-side-list__link__child" href="#">問い合わせから施設見学までの6ステップ</a>
        <a class="c-side-list__link c-side-list__link__child" href="#">全国の施設を検索する方法</a>
      </div>
    </li>
    <li class="c-side-list__item js-drop-down">
      <div class="c-side-list__item__parent js-drop-down-trigger">
        <span class="c-side-list__link c-side-list__link--category">老人ホームの種類</span>
        <i class="c-area__list__icon c-dropdown-menu__icon js-drop-down-icon c-dropdown-menu__icon--up"></i>
      </div>
      <div class="js-drop-down-target js-drop-down-pc-open">
        <a class="c-side-list__link c-side-list__link__child" href="#">介護施設の種類</a>
        <a class="c-side-list__link c-side-list__link__child" href="#">有料老人ホームとは</a>
        <a class="c-side-list__link c-side-list__link__child" href="#">介護付き有料老人ホームとは</a>
      </div>
    </li>
  </ul>
</div>
```
*/

.c-side-list {
  background-color: $honne-white;
}

.c-side-list:first-child {
  margin-top: 0;
}

.c-side-list--blue {
  border-top: 8px solid $honne-blue;
}

.c-side-list__title {
  padding: 10px 12px;
  font-size: $fs-lg;
  font-weight: bold;
}

.c-side-list__list {
  font-size: $fs-lg;
  font-weight: bold;
  border-top: 1px solid $honne-gray-300;
}

.c-side-list__item {
  border-bottom: 1px solid $honne-gray-300;
}

.c-side-list__item__parent {
  position: relative;
  cursor: pointer;
}

.c-side-list__link {
  position: relative;
  display: block;
}

.c-side-list__link--category {
  display: inline-block;
  padding: 12px 12px;
  font-size: $fs-md;
  font-weight: bold;
  line-height: $line-height-px2;
  color: $honne-gray-700;
}

.c-side-list__link--category:visited {
  color: $honne-gray-700;
}

.c-side-list__link__child {
  position: relative;
  display: block;
  padding: 12px 16px 12px 32px;
  font-size: $fs-md;
  font-weight: bold;
  border-top: 1px solid $honne-gray-300;
}

.c-side-list__link--latest {
  min-height: 50px;
  padding: 10px 12px 3px 12px;
  font-size: $fs-md;
  font-weight: bold;
  line-height: $line-height-px2;
  color: $honne-blue;
}

.c-side-list__link--latest-date {
  padding: 0 10px 5px 0;
  font-size: $fs-xs;
  font-weight: normal;
  color: $honne-gray-500;
  text-align: right;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-side-list__link--category {
    padding: 12px 16px;
    font-size: $fs-lg;
    line-height: $line-height-large;
  }
}
