@charset "utf-8";

.c-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $modal-frame-zindex;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.95);
}

.c-overlay--gray {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $modal-frame-zindex;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
}
