@charset "utf-8";

.c-guide-top {
  display: flex;
  align-items: center;
  height: 220px;
  background-image: url("~images/guide_main_sp.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.c-guide-top__title-wrap {
  padding: 24px;
}

.c-guide-top__title {
  margin-bottom: 8px;
  font-size: $fs-4xl;
  letter-spacing: $letter-spacing-px4;
}

.c-guide-top__sub-title {
  font-weight: bold;
  letter-spacing: $letter-spacing-px4;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-guide-top {
    height: 400px;
    margin-bottom: 32px;
    background-image: url("~images/guide_main_pc.jpg");
  }

  .c-guide-top__title {
    margin-bottom: 16px;
    font-size: $font-size-big3;
  }

  .c-guide-top__title-wrap {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
  }

  .c-guide-top__sub-title {
    font-size: $fs-3xl;
    line-height: $line-height10;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-guide-top__title-wrap {
    padding: 0;
  }
}
