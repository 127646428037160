@charset "utf-8";

.c-slide-nav {
  position: fixed;
  top: 0;
  z-index: $slide-menu-zindex;
  display: none;
  width: 100%;
  height: 100%;
}

.c-slide-nav-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

.c-slide-nav__background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($honne-black, 0.5);
}

.c-slide-nav__container {
  position: relative;
  right: -100%;
  width: 280px;
  height: 100%;
  background-color: $honne-white;
  box-shadow: $shadow-size-medium;

  .c-area__list {
    margin: 0;
  }

  .c-area__list__link {
    margin-left: 16px;
  }

  .c-slide-nav__drop-down {
    &:last-child {
      .c-area__list__link {
        border-bottom: 0;
      }
    }
  }

  .c-area__list__child__link {
    padding-left: 24px;
    margin-left: 24px;
  }

  .c-area__list__child-item {
    &:last-child {
      .c-area__list__child__link {
        border-bottom: none;
      }
    }
  }
}

.c-slide-nav__container__wrapper {
  position: relative;
  height: 100%;
}

.c-slide-nav__container__main {
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.c-slide-nav__container__close {
  position: absolute;
  top: 0;
  left: -50px;
  display: none;
  width: 40px;
  height: 40px;
}

.c-slide-nav__container__close_icon {
  position: relative;
  top: 20px;
  left: 10px;
  width: 14px;
  height: 14px;
}

.c-slide-nav__drop-down-icon.c-dropdown-menu__icon--up {
  transform: translateY(-30%) rotate(-135deg);
}

.c-slide-nav__contact {
  padding: 16px;
}

.c-slide-nav__text {
  margin-top: 16px;
  font-size: $fs-md;
  font-weight: bold;
  text-align: center;
}
