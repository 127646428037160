@charset "utf-8";

/*doc
---
title: article-list
name: article-list
category: components
---

```html_example
<ul class="c-article-list">
  <li class="c-article-list__item">
    <h2 class="c-article-list__title">
      <a class="c-article-list__link" href="#">
        SOMPOケア株式会社
      </a>
    </h2>
    <p class="c-article-list__text c-article-list__text--clamp-4">SOMPOケア株式会社は、全国400施設を超える有料老人ホーム・サービス付高齢者向け住宅を展開するほか、グループホームの運営、居宅サービス事業を行っています。2018年7月にSOMPOケア株式会社とSOMPOケアネクスト株式会社が合併しました。グループ内には、食事開発や提供、福祉用具の販売・レンタルなど、生活支援...</p>
    <a class="c-article-list__more" href="#">
      詳しくみる<span class="c-icon-arrow-right-blue c-article-list__icon"></span>
    </a>
  </li>
  <li class="c-article-list__item">
    <h2 class="c-article-list__title">
      <a class="c-article-list__link" href="#">
        株式会社ニチイ学館
      </a>
    </h2>
    <p class="c-article-list__text c-article-list__text--clamp-4">1968年創業の株式会社ニチイ学館は、『医療・介護・教育』の3つを柱に、高齢社会の明日を支える生活支援企業です。一人一人のライフスタイルに合わせた、便利で高品質なサービスを提供を通し、誰もが安心して生活できる地域社会の実現を目指しています。介護の分野では、在宅系・居宅系サービスをはじめ、介護事業を幅広くカバーし、...</p>
    <a class="c-article-list__more" href="#">
      詳しくみる<span class="c-icon-arrow-right-blue c-article-list__icon"></span>
    </a>
  </li>
</ul>
```
*/

.c-article-list {
  margin-top: 24px;
}

.c-article-list__item {
  padding-bottom: 10px;
  margin-bottom: 13px;
  border-bottom: 1px solid $honne-gray-300;
}

.c-article-list__item:last-child {
  margin-bottom: 0;
}

.c-article-list__item:after {
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
  content: ".";
  opacity: 0;
}

.c-article-list__link {
  display: table;
  margin-bottom: 9px;
  font-size: $fs-lg;
  line-height: $line-height-small;
}

.c-article-list__text {
  display: -webkit-box;
  margin-bottom: 9px;
  overflow: hidden;
  font-size: $fs-sm;
  line-height: $line-height-px1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.c-article-list__text--clamp-4 {
  -webkit-line-clamp: 4;
}

.c-article-list__more {
  float: right;
  font-size: $fs-md;
  font-weight: bold;
}

.c-article-list__icon {
  position: relative;
  top: 4px;
  float: right;
  width: 8px;
  height: 13px;
  margin-left: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-article-list__item {
    padding-bottom: 16px;
    margin-bottom: 20px;
  }
  .c-article-list__link {
    margin-bottom: 16px;
  }
  .c-article-list__text {
    margin-bottom: 6px;
  }
  .c-article-list__text--clamp-4 {
    -webkit-line-clamp: 3;
  }
}
