@charset "utf-8";

/*doc
---
title: text
name: text
category: components
---
```html_example
<div class="c-text--line" style="margin-bottom:16px;">テキスト</div>
<div class="c-text--marker">テキスト</div>
```
*/

.c-text-normal {
  @include typo-md-xl-md;
}

.c-text-small {
  @include typo-sm-xl-md;
}

.c-text--gray {
  color: $honne-gray-500;
}

.c-text--blue {
  color: $honne-blue;
}

.c-text--bold {
  font-weight: bold;
}

.c-text--line {
  position: relative;
}

.c-text--line:before {
  position: absolute;
  bottom: -4px;
  left: 0;
  display: block;
  width: 100%;
  height: 3px;
  content: "";
  background: $honne-yellow;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-text-normal {
    line-height: $lh-x-large;
  }

  .c-text--marker {
    display: inline;
    padding-bottom: 2px;
    font-weight: bold;
    background: linear-gradient(to top, #fbeac0 40%, transparent 41%);
  }
}
