@charset "utf-8";

/*doc
---
title: area
name: area
category: components
---

```html_example
<div class="c-area">
  <h2 class="c-area__title">
    エリアから老人ホーム・介護施設を探す
  </h2>
  <ul class="c-area__list" style="display: block;">
    <li class="js-drop-down">
      <div class="c-area__list__link js-drop-down-trigger">
        <span class="c-area__list__text">北海道・東北</span>
        <span class="c-area__list__num">[15020]</span>
        <i class="c-area__list__icon c-dropdown-menu__icon js-drop-down-icon"></i>
      </div>
      <div class="js-drop-down-target" style="display: none;">
        <ul class="c-area__list__child">
          <li class="c-area__list__child-item">
            <a class="c-area__list__child__link" href="/hokkaido">
              <span class="c-area__list__text">北海道</span>
              <span class="c-area__list__num">[5192]</span>
            </a></li>
          <li class="c-area__list__child-item">
            <a class="c-area__list__child__link" href="/aomori">
              <span class="c-area__list__text">青森県</span>
              <span class="c-area__list__num">[1715]</span>
            </a></li>
        </ul>
      </div>
    </li>
    <li class="js-drop-down">
      <div class="c-area__list__link js-drop-down-trigger">
        <span class="c-area__list__text">関東</span>
        <span class="c-area__list__num">[29024]</span>
        <i class="c-area__list__icon c-dropdown-menu__icon js-drop-down-icon"></i>
      </div>
      <div class="js-drop-down-target" style="display: none;">
        <ul class="c-area__list__child">
          <li class="c-area__list__child-item">
            <a class="c-area__list__child__link" href="/tokyo">
              <span class="c-area__list__text">東京都</span>
              <span class="c-area__list__num">[7364]</span>
            </a></li>
          <li class="c-area__list__child-item">
            <a class="c-area__list__child__link" href="/kanagawa">
              <span class="c-area__list__text">神奈川県</span>
              <span class="c-area__list__num">[6066]</span>
            </a></li>
        </ul>
      </div>
    </li>
  </ul>
  <div class="c-area__map">
    <p>PC用検索マップ</p>
  </div>
</div>
```
*/

.c-area {
  padding-top: 16px;
}

.c-area__title {
  margin-bottom: 16px;
  font-size: $fs-xl;
  line-height: $line-height-large;
  text-align: center;
}

.c-area__map {
  display: none;
}

.c-area__list {
  border-top: 1px solid $honne-gray-300;
}

.c-area__list__link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 13px 16px;
  border-bottom: 1px solid $honne-gray-300;
}

.c-area__list__text {
  font-size: $fs-md;
  font-weight: bold;
  vertical-align: middle;
}

.c-area__list__num {
  padding-left: 6px;
  font-size: $fs-sm;
  font-weight: normal;
  color: $honne-gray-700;
}

.c-area__list__icon.c-dropdown-menu__icon--up {
  transform: translateY(-30%) rotate(-135deg);
}

.c-area__list__child__link {
  position: relative;
  display: block;
  padding: 13px 16px 13px 40px;
  font-size: $fs-md;
  font-weight: bold;
  border-bottom: 1px solid $honne-gray-300;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-area {
    display: block;
    max-width: 1124px;
    padding: 24px 24px 40px;
    margin: 0 auto;
  }

  .c-area__title {
    font-size: $fs-4xl;
    text-align: left;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-area__list {
    display: none;
  }

  .c-area__map {
    display: block;
  }
}
