@charset "utf-8";

.p-regist-tel {
  padding: 24px;
  background: $honne-blue-bright;
}

.p-regist-tel__text {
  margin-bottom: 15px;
  font-size: $fs-lg;
  line-height: $line-height-large;
}

.p-regist-tel__text-br {
  display: none;
}

.p-regist-tel__tel-box {
  display: block;
  padding: 12px 5px;
  color: $honne-gray-700;
  text-align: center;
  background: $honne-white;
  border-radius: 8px;
}

.p-regist-tel__tel-box:visited {
  color: $honne-gray-700;
}

.p-regist-tel__tel-box:hover {
  opacity: 1;
}

.p-regist-tel__tel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-regist-tel__tel-image {
  width: 38px;
  margin-right: 8px;
}

.p-regist-tel__tel-number {
  font-family: "Hiragino Sans", sans-serif;
  font-size: $font-size-big2;
  font-weight: bold;
}

.p-regist-tel__tel-text {
  font-size: $fs-md;
  text-align: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-tel {
    padding: 24px 32px;
  }

  .p-regist-tel__inner {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .p-regist-tel__text {
    width: 50%;
    padding-right: 16px;
    margin-bottom: 0;
    font-size: $fs-xl;
    line-height: $line-height-px3;
  }

  .p-regist-tel__text-br {
    display: block;
  }

  .p-regist-tel__tel-box {
    width: 50%;
    padding: 14px 0;
  }

  .p-regist-tel__tel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-regist-tel__tel-image {
    width: 47px;
    margin-right: 10px;
  }

  .p-regist-tel__tel-number {
    font-size: $font-size-big3;
  }

  .p-regist-tel__tel-text {
    font-size: $fs-lg;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-regist-tel__inner {
    max-width: 1080px;
  }
}

/* ~ 320px */
@include max-screen($breakpoint-sp-min) {
  .p-regist-tel__tel-number {
    font-size: $fs-5xl;
  }
}
