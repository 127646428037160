@charset "utf-8";

.c-jg-content-box {
  padding: 8px 0;
}

.c-jg-content-box__head {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-700;
}

.c-jg-content-box__content {
  width: 100%;
}

.c-jg-content-box__comment {
  margin-top: 8px;
  font-size: $fs-sm;
  color: $honne-gray-500;

  &.right {
    text-align: right;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-jg-content-box {
    display: flex;
  }

  .c-jg-content-box__head {
    width: 160px;
    min-width: 160px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  .c-jg-content-box__content {
    font-size: $fs-lg;
  }
}
