@charset "utf-8";

// 施設からのメッセージ
.c-jg-comment {
  margin-top: 32px;

  &__inner {
    width: 100%;
    padding: 16px;
    background-color: $honne-gray-100;
    border-radius: $radius-size-medium;
  }

  &__inner + &__inner {
    margin-top: 8px;
  }

  &__line {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border: 0;
    border-radius: 100%;
  }

  &__title {
    margin-bottom: 4px;
    margin-left: 8px;
    font-size: $fs-lg;
    font-weight: bold;
  }

  &__text {
    font-size: $fs-sm;
  }
}
