@charset "utf-8";

// 検索結果ページ全般

.p-jys-search__empty {
  padding: 0 24px 24px;
}

.p-jys-search__empty__title {
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-red-bright;
}

.p-jys-search__pager {
  margin-top: 24px;
}

.p-jys-search__block {
  margin-top: 24px;
}

.p-jys-search__side__contact {
  .c-inquiry {
    background-color: $honne-white;
  }
}

.p-jys-search_recommend {
  padding: 24px;
  margin: 24px -24px;
  font-size: $fs-lg;
  font-weight: $font-weight-700bold;
  background-color: $honne-white;
}

.p-jys-search_recommend-icon {
  margin-right: 3px;
}

.p-jys-search__contact {
  margin-top: 16px;
}

@include min-screen($breakpoint-pc-small-min) {
  .p-jys-search_recommend {
    margin-right: 0;
    margin-left: 0;
  }
}

@include min-screen($breakpoint-pc-min) {
  .p-jys-search__empty {
    padding: 0;
  }

  .p-jys-search__empty__title {
    font-size: $fs-xl;
  }
}
