@charset "utf-8";

.c-jigyosho-card {
  min-width: 280px;
  margin: 16px;
  color: $honne-gray-700;
  background-color: $honne-white;
  border-radius: $radius-size-medium;
  box-shadow: $shadow-size-medium;
}

.c-jigyosho-card + .c-jigyosho-card {
  margin: 16px 16px 16px 0;
}

.c-jigyosho-card__img {
  position: relative;
  height: 150px;
  overflow: hidden;
  text-align: center;
  background-color: $honne-gray-100;
  border-radius: 8px 8px 0 0;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.badge {
    position: relative;
  }
}

.c-jigyosho-card__label {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2px 16px;
  font-size: $fs-xs;
  color: $honne-white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 8px 0 0;
}

.c-jigyosho-card__desc {
  padding: 12px 16px;
}

.c-jigyosho-card__title {
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: $font-family-700bold;
  color: $honne-blue;
  text-decoration: underline;
}

.c-jigyosho-card__badge {
  position: absolute;
  top: 0;
  right: 12px;
  width: 52px;
  height: 68px;
  background-size: cover;

  &.rank1 {
    background-image: url("images/ranking/01.png");
  }

  &.rank2 {
    background-image: url("images/ranking/02.png");
  }

  &.rank3 {
    background-image: url("images/ranking/03.png");
  }
}

.c-jigyosho-card__address {
  font-size: $fs-xs;
  color: $honne-gray-700;
}

.c-jigyosho-card__summary {
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  margin-bottom: 8px;
}

.c-jigyosho-card__row {
  display: flex;
  align-items: center;
}

.c-jigyosho-card__row + .c-jigyosho-card__row {
  margin-top: 8px;
}

.c-jigyosho-card__row__head {
  min-width: 54px;
  margin-right: 4px;
  font-size: $fs-xs;
  color: $honne-gray-600;
}

.c-jigyosho-card__row__content {
  display: flex;
  align-items: center;
}

.c-jigyosho-card__vacancy {
  padding: 1px 8px;
  font-weight: bold;
  color: $honne-red-bright;
  background: $honne-yellow-white;
  border: 1px solid $honne-red-bright;
  border-radius: 4px;
}

.c-jigyosho-card__vacancy__text {
  font-size: $fs-lg;
  font-weight: bold;
}

.c-jigyosho-card__date {
  margin-left: 8px;
  font-size: $fs-xs;
  color: $honne-gray-600;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .c-jigyosho-card {
    min-width: 330px;
  }

  .c-jigyosho-card__img {
    height: 176px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-jigyosho-card {
    width: 349px;
  }

  .c-card-list-wrap--cancel-responsive > .c-jigyosho-card {
    width: 330px;
  }
}
