@charset "utf-8";

.p-top-jigyoshos__box {
  padding: 0;
  margin-top: 56px;

  .c-card-list {
    margin: -16px -24px;
  }

  .c-jigyosho-card:first-child {
    margin-left: 24px;
  }

  .c-card-list-wrap {
    padding: 0;
  }
}

.p-top-jigyoshos__title {
  margin-bottom: 16px;
}

.p-top-jigyoshos__card-list {
  margin-bottom: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-jigyoshos {
    padding: 0 32px;
  }

  .p-top-jigyoshos__box {
    .c-card-list {
      margin: -16px -32px;
    }

    .c-jigyosho-card:first-child {
      margin-left: 32px;
    }
  }

  .p-top-jigyoshos__title {
    margin-bottom: 24px;
  }

  .p-top-jigyoshos__card-list {
    margin-top: 8px;
    margin-bottom: 32px;
  }
}

/* 1080 ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-jigyoshos {
    padding: 0;
  }
}
