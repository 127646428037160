@charset "utf-8";

/*doc
---
title: modal
name: modal
category: components
---
```html_example
<div id="wrapper">
  <button class="c-button c-button--primary js-modal-show" data-modal-target-id="sample_modal">クリックしてmodal表示</button>

  <div class="o-modal-frame js-modal-frame">
    <div id="sample_modal" class="c-modal js-modal">
      <div class="c-modal__head">
        <div class="c-modal__title">
          タイトル
        </div>
        <div class="c-modal__closer js-modal-hide"></div>
      </div>
      <div class="c-modal__body js-modal-body">
        本文hogehoge
      </div>
      <div class="c-modal__footer js-modal-footer">
        <div class="c-modal__footer__button">はい</div>
        <div class="c-modal__footer__button js-modal-hide">閉じる</div>
      </div>
    </div>
    <div class="c-overlay js-modal-overlay"></div>
  </div>
</div>
```
*/

.c-modal {
  position: relative;
  z-index: $modal-zindex;
  width: auto;
  margin: 0 12px;
  background-color: $honne-white;
  border-radius: 8px;
  box-shadow: $shadow-size-medium;
}

.c-modal__head {
  display: flex;
  padding: 24px;
  border-bottom: solid 1px $honne-gray-300;
}

.c-modal__title {
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-700;
}

.c-modal__closer {
  width: 16px;
  height: 16px;
  margin-left: auto;
  background: url("~images/user_inline/icon/close.svg") no-repeat center;
}

.c-modal__body {
  height: 100%;
  padding: 24px;
  overflow-y: scroll;
  font-size: $fs-lg;
  border-bottom: solid 1px $honne-gray-300;
  -webkit-overflow-scrolling: touch;
}

.c-modal__footer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 16px 24px;
}

.c-modal__footer__button {
  padding: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-700;
  border: solid 1px $honne-gray-400;
  border-radius: 4px;
}

/* 769px ~ */
@include min-screen($breakpoint-tablet-max) {
  .c-modal {
    margin: 0 32px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-modal {
    max-width: 750px;
    margin: 0 auto;
  }
}
