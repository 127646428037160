@charset "utf-8";

.p-line-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p-line-contact__title {
  margin-bottom: 20px;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $line-height-large;
  text-align: center;
}

.p-line-contact__check {
  margin-bottom: 4px;
}

.p-line-contact__check__text {
  font-size: $fs-sm;
  font-weight: bold;
  color: $honne-gray-700;
}

.p-line-contact__rule {
  margin-bottom: 24px;
  font-size: $fs-2xs;

  a,
  a:visited {
    color: $honne-gray-700;
  }
}

.p-line-contact__error {
  display: none;
  width: 100%;
  margin-top: -8px;
  margin-bottom: 16px;
  font-size: $fs-sm;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-line-contact__title {
    font-size: $font-size-big1;
    line-height: $line-height10;
    letter-spacing: $letter-spacing-px2;
  }

  .p-line-contact__check {
    margin-bottom: 8px;
  }

  .p-line-contact__check__text {
    font-size: $fs-lg;
  }

  .p-line-contact__rule {
    font-size: $fs-sm;
  }

  .p-line-contact__button {
    width: 400px;
    font-size: $fs-2xl;
    line-height: $line-height-px3;
  }

  .p-line-contact__error {
    width: 400px;
  }
}
