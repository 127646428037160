@charset "utf-8";

.p-special-solution {
  position: relative;
  padding: 64px 0 32px;
  margin-bottom: 56px;
  background-color: $honne-blue-lighten;

  &::after {
    position: absolute;
    bottom: -32px;
    display: inline-block;
    content: "";
    border-top: 32px solid $honne-blue-lighten;
    border-right: 50vw solid transparent;
    border-left: 50vw solid transparent;
  }
}

.p-special-solution__catch {
  position: absolute;
  top: 15px;
  right: 0;
  width: 112px;
  height: 106px;
  margin-left: auto;
  background-image: url("~images/lp/special/catch.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.p-special-solution__title {
  margin-bottom: 16px;
  font-size: $fs-2xl;
  color: $honne-white;
  text-align: center;
  letter-spacing: $letter-spacing-px2;
}

.p-special-solution__list {
  padding: 0 24px;
}

.p-special-solution__list__item {
  padding: 24px;
  background-color: $honne-white;
  border-radius: 12px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.p-special-solution__subtitle {
  padding-left: 48px;
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  letter-spacing: $letter-spacing-px2;

  br {
    display: none;
  }
}

$bgs: bg01, bg02, bg03;

@each $bg in $bgs {
  .p-special-solution__subtitle--#{$bg} {
    background-image: url("~images/lp/special/#{$bg}.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 40px;
  }
}

.p-special-solution__text {
  font-size: $fs-sm;
  color: $honne-gray-500;
  letter-spacing: $letter-spacing-px2;

  span {
    font-weight: bold;
    color: $honne-gray-700;
  }
}

/* 768px ~ */
@include min-screen($breakpoint-tablet-max) {
  .p-special-solution__list {
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 48px);
    padding: 0;
    margin: 0 auto;
  }

  .p-special-solution__list__item {
    width: calc((100% - 32px) / 3);

    &:not(:last-child) {
      margin-bottom: initial;
    }
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special-solution {
    padding: 104px 0 64px;
    margin-bottom: 80px;

    &::after {
      bottom: -50px;
      border-top: 50px solid $honne-blue-lighten;
      border-right: 540px solid transparent;
      border-left: 540px solid transparent;
    }
  }
  .p-special-solution__catch {
    top: 60px;
    right: 280px;
  }
  .p-special-solution__title {
    margin-bottom: 24px;
    font-size: $font-size-big1;
    letter-spacing: $letter-spacing-px4;
  }

  .p-special-solution__list {
    width: 952px;
  }

  .p-special-solution__list__item {
    width: 301px;
  }

  .p-special-solution__subtitle {
    font-size: $fs-xl;

    br {
      display: block;
    }
  }

  .p-special-solution__text {
    font-size: $fs-md;
  }
}
