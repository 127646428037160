@charset "utf-8";

.p-jigyosho-top {
  padding-bottom: 16px;
  background-color: $honne-yellow-white;
}

.p-jigyosho-top__covid-19-visit {
  margin-bottom: 16px;
}

.p-jigyosho-top__covid-19-visit__text {
  font-size: $fs-2xs;
  font-weight: bold;
}

.p-jigyosho-top__covid-19-visit__icon {
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-top;
}

.p-jigyosho-top__covid-19-visit__date {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  color: $honne-gray-600;
}

.p-jigyosho-top__inner {
  padding-bottom: 24px;
  background-color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-top {
    padding-bottom: 0;
  }

  .p-jigyosho-top__inner {
    padding-bottom: 0;
  }

  .p-jigyosho-top__covid-19-visit__text {
    font-size: $fs-sm;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-top {
    padding: 24px 32px 16px;
    background-color: $honne-white;
  }

  .p-jigyosho-top__covid-19-visit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .p-jigyosho-top__covid-19-visit__title {
    min-width: 204px;
    margin-right: 24px;
    margin-bottom: 0;
  }

  .p-jigyosho-top__inner {
    display: flex;
    width: 100%;
    max-width: 1015px;
    margin: 0 auto;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-top {
    padding: 24px 0 16px;
  }

  .p-jigyosho-top__inner {
    max-width: 1080px;
  }
}
