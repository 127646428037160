@charset "utf-8";

.c-markup__article {
  .c-mp-consultant {
    margin-top: 32px;
    @include reset-markup();

    .c-inquiry__image {
      width: 129px;
      max-width: none;
      height: 90px;
      margin-right: 32px;
    }
  }

  .c-mp-consultant__text {
    margin-bottom: 12px;
    font-size: $fs-lg;
    font-weight: bold;
  }

  /* 769px ~ */
  @include min-screen($breakpoint-pc-small-min) {
    .c-mp-consultant__text {
      font-size: $fs-xl;
    }
  }
}
