@charset "utf-8";

// こちらの変数は廃止されて、今後削除する予定
$font-size-xxx-small: 10px;
$font-size-xx-small: 11px;
$font-size-x-small: 12px;
$font-size-small: 13px;
$font-size-medium: 14px;
$font-size-x-medium: 15px;
$font-size-large: 16px;
$font-size-x-large: 18px;
$font-size-xx-large: 20px;
$font-size-xxx-large: 22px;
$font-size-xxxx-large: 24px;
$font-size-xxxxx-large: 26px;

$line-height0: 0;
$line-height2: 1;
$line-height1: 1.15;
$line-height4: 1.2;
$line-height6: 1.25;
$line-height5: 1.29;
$line-height8: 1.4;
$line-height-base: 1.5;
$line-height12: 1.6;
$line-height10: 1.7;
$line-height7: 1.75;
$line-height9: 1.8;
$line-height3: 2;
$line-height13: 2.1;
$line-height11: 2.5;
$line-height12: 3;

$line-height-x-small: 18px;
$line-height-small: 20px;
$line-height-medium: 22px;
$line-height-large: 24px;
$line-height-x-large: 26px;
$line-height-xx-large: 32px;
$line-height-px1: 19px;
$line-height-px2: 23px;
$line-height-px3: 30px;
$line-height-px4: 28px;
$line-height-px5: 48px;
$line-height-px6: 56px;

$letter-spacing-small: 0.08em;
$letter-spacing-medium: 0.1em;
$letter-spacing-large: 0.125em;
$letter-spacing0: 0;
$letter-spacing1: 0.04em;
$letter-spacing2: 0.06em;
$letter-spacing3: 0.03em;
$letter-spacing-px1: 0.5px;
$letter-spacing-px2: 1px;
$letter-spacing-px3: 0.8px;
$letter-spacing-px4: 2px;
$letter-spacing-px5: 1.5px;
$letter-spacing-px6: 2.5px;
$letter-spacing-px7: 0.3px;
$letter-spacing-px8: 0.1px;
$letter-spacing-px9: 3px;
$letter-spacing-px10: 4px;
$letter-spacing-px11: 5px;
$letter-spacing-px12: 8px;
$letter-spacing-px13: 7px;
