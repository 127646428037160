.c-line {
  padding: 24px;
  text-align: center;
  background-color: $honne-white;
}

.c-line__title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: bold;
}

.c-line__icon {
  margin-right: 4px;
}

.c-line__text {
  margin-bottom: 12px;
  font-weight: bold;
  color: $line-brand-color;
}

.c-line__button {
  justify-content: center;
  font-size: $fs-2md;
  border-radius: 24px;
}

.c-line__button-icon {
  margin-right: 10px;
}
