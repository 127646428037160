@charset "utf-8";

.p-news-articles {
  margin-bottom: 24px;
  border-bottom: 1px solid $honne-gray-300;
}

.p-news-articles__item {
  margin: 0 16px;
  border-top: 1px solid $honne-gray-300;

  &:first-child {
    border-top: 0;
  }
}

.p-news-articles__inner {
  display: flex;
  padding: 12px 0;
}

.p-news-articles__image {
  width: 75px;
  height: 75px;
  vertical-align: bottom;
  object-fit: cover;
  border-radius: 4px;
}

.p-news-articles__main {
  width: calc(100vw - 32px - 75px - 12px);
  margin-left: 12px;
}

.p-news-articles__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-news-articles__date {
  margin-left: auto;
  font-size: $fs-3xs;
  color: $honne-gray-400;
}

.p-news-articles__title {
  display: -webkit-box;
  margin-top: 12px;
  overflow: hidden;
  font-weight: bold;
  line-height: $lh-medium;
  color: $honne-gray-700;
  -webkit-line-clamp: 4;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.p-news__pager {
  nav {
    background-color: $honne-yellow-white;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-articles {
    display: flex;
    flex-wrap: wrap;
    border: none;
  }

  .p-news-articles__item {
    width: calc(100% / 3 - 16px);
    margin: 0;
    margin-right: 16px;
    margin-bottom: 48px;
    border: none;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .p-news-articles__inner {
    flex-direction: column;
    padding: 0;
  }

  .p-news-articles__main {
    width: 100%;
    margin-top: 16px;
    margin-left: 0;
  }

  .p-news-articles__image {
    width: 100%;
    height: 170px;
    border-radius: 8px;
  }

  .p-news-articles__date {
    font-size: $fs-sm;
  }

  .p-news-articles__title {
    font-size: $fs-lg;
  }

  .p-news__pager {
    margin-bottom: 48px;
  }
}
