@charset "utf-8";

.p-jys-links {
  margin-top: 16px;
}

.p-jys-links__head {
  margin-bottom: 12px;
}

.p-jys-links__main {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.p-jys-links__item {
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: bold;
}

.p-jys-links__link {
  font-size: $fs-md;
  color: $honne-blue;
}

.p-jys-links__number {
  font-size: $fs-2xs;
  color: $honne-gray-600;
}

@include min-screen($breakpoint-pc-min) {
  .p-jys-links__item {
    margin-right: 16px;
  }
}
