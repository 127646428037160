@charset "utf-8";

$tags: 56, 57, 58, 59, 60, 61, 62, 65, 66, 69, 70, 72, 84, 85, 86, 87, 88, 89,
  90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107,
  108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
  127, 128, 129, 130, 131, 132, 134, 136, 137, 138, 140, 141, 142, 144, 145, 146,
  149, 150, 151, 152, 153, 155, 156, 157, 158, 159, 163, 167, 168, 169, 171, 173,
  174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189,
  190, 191, 192, 193, 194, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210,
  211, 212, 213, 214;

@each $tag in $tags {
  .c-tag-#{$tag} {
    background-image: url("~images/tags.svg#tag-#{$tag}");
  }
}

.c-tag-icon {
  background-repeat: no-repeat;
}
