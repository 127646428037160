@charset "utf-8";

.p-questions-editor {
  position: relative;
  margin: 81px 0 32px;
}

.p-questions-editor:before {
  position: absolute;
  top: -40px;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  content: "";
  border-top: 1px dashed $honne-yellow;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-questions-editor {
    margin-bottom: 40px;
  }
}
