@charset "utf-8";

/*doc
---
title: balloon
name: balloon
category: components
---

```html_example
  <p class="c-balloon c-balloon--red c-balloon--top" style="margin-bottom:16px;">上向き</p>
  <div style="background-color: #f8f7f5;margin-bottom: 16px;">
    <p class="c-balloon c-balloon--white c-balloon--left" style="margin-left: 32px;padding: 28px;">左向き</p>
  </div>
```
*/

$balloon-width: 8px;

.c-balloon {
  position: relative;
  padding: 12px 16px;
  font-size: $fs-xs;
  border-radius: 8px;
}

.c-balloon:before {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border: solid transparent;
}

.c-balloon--left:before {
  top: 50%;
  right: 100%;
  margin-top: 12px;
  border-width: $balloon-width;
}

.c-balloon--white {
  color: $honne-gray-700;
  background: $honne-white;
}

.c-balloon--white:before {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: $honne-white;
}

.c-balloon--top:before {
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: $balloon-width;
}

.c-balloon--red {
  color: $honne-red;
  background-color: $honne-red-light;
}

.c-balloon--red:before {
  border-color: rgba(251, 238, 238, 0);
  border-bottom-color: $honne-red-light;
}
