@charset "utf-8";

.p-consultant-mainvisual {
  padding: 0;
  margin-bottom: 188px;
}

.p-consultant-mainvisual__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1080px;
  height: 220px;
  background-image: url("~images/consultant/mainvisual-bg_sp.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.p-consultant-mainvisual__contents {
  width: 100%;
  padding-left: 24px;
}

.p-consultant-mainvisual__title {
  width: 100%;
  margin-bottom: 10px;
  font-size: $font-size-big2;
  line-height: $line-height8;
  color: $honne-blue;
  letter-spacing: $ls-large;
}

.p-consultant-mainvisual__text {
  margin-bottom: 4px;
  font-size: $fs-md;
  font-weight: bold;
  line-height: $lh-large;
}

.p-consultant-mainvisual__tel {
  margin-bottom: 8px;
  text-align: center;
}

.p-consultant-mainvisual__tel-button {
  position: absolute;
  bottom: -84px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: calc(100% - 48px);
  padding: 13px 0 9px;
  color: $honne-gray-700;
  background: $honne-white;
  border: 2px solid $honne-blue;
  border-radius: 8px;
}

.p-consultant-mainvisual__tel-button:visited {
  color: $honne-gray-700;
}

.p-consultant-mainvisual__tel-image {
  width: 30px;
  margin-right: 10px;
}

.p-consultant-mainvisual__tel-number {
  font-family: $font-family-700bold;
  font-size: $fs-3xl;
  font-weight: bold;
}

.p-consultant-mainvisual__tel-button-text {
  display: block;
  width: 100%;
  margin-top: 5px;
  font-size: $fs-3xs;
  font-weight: normal;
}

.p-consultant-mainvisual__apply-button {
  position: absolute;
  bottom: -156px;
  width: calc(100% - 48px);
  height: 56px;
  padding: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-white;
  background: $honne-red-bright;
}

.p-consultant-mainvisual__apply-button:visited {
  color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-consultant-mainvisual {
    margin-bottom: 72px;
  }

  .p-consultant-mainvisual__inner {
    height: 355px;
  }

  .p-consultant-mainvisual__contents {
    width: 100%;
    padding-left: 32px;
  }

  .p-consultant-mainvisual__br {
    display: none;
  }

  .p-consultant-mainvisual__title {
    width: 100%;
    margin-bottom: 8px;
    font-size: $font-size-big5;
    line-height: $lh-medium;
    letter-spacing: $ls-small;
  }

  .p-consultant-mainvisual__text {
    width: 100%;
    font-size: $fs-2xl;
    font-weight: bold;
    letter-spacing: $ls-large;
  }

  .p-consultant-mainvisual__box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .p-consultant-mainvisual__tel {
    display: flex;
    flex-direction: column;
    width: 36%;
    margin-bottom: 0;
    text-align: left;
  }

  .p-consultant-mainvisual__tel-button {
    position: static;
    justify-content: left;
    width: 360px;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: unset;
    box-shadow: none;
  }

  .p-consultant-mainvisual__tel-image {
    width: 40px;
  }

  .p-consultant-mainvisual__tel-number {
    margin-right: 24px;
    font-size: $font-size-big3;
  }

  .p-consultant-mainvisual__tel-button-text {
    display: inline-block;
    width: initial;
    font-size: $fs-lg;
    line-height: $lh-large;
    text-align-last: left;
  }

  .p-consultant-mainvisual__apply-button {
    position: static;
    width: 300px;
    height: 64px;
    margin-top: 0;
    font-size: $fs-2xl;
    background: $honne-red-bright;
    border: none;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-consultant-mainvisual__inner {
    background-image: url("~images/consultant/mainvisual-bg.png");
    background-size: auto 100%;
  }
}
