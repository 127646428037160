@charset "utf-8";

.p-regist-caremanager-card {
  margin-bottom: 32px;
}

.p-regist-caremanager-card__item {
  padding: 24px;
  background: $honne-white;
  border-radius: 24px;
  box-shadow: 0 2px 24px rgba(226, 226, 226, 0.25);
}

.p-regist-caremanager-card__item + .p-regist-caremanager-card__item {
  margin-top: 24px;
}

.p-regist-caremanager-card__image {
  display: block;
  height: 90px;
  margin: 0 auto 32px;
}

.p-regist-caremanager-card__title {
  margin-bottom: 8px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-x-large;
  text-align: center;
}

.p-regist-caremanager-card__text {
  font-size: $fs-md;
  font-weight: normal;
  line-height: $line-height-px2;
  color: $honne-gray-600;
  text-align: justify;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-caremanager-card {
    display: flex;
    justify-content: center;
  }

  .p-regist-caremanager-card__item {
    flex: 1;
    padding: 32px;
  }

  .p-regist-caremanager-card__item + .p-regist-caremanager-card__item {
    margin-top: 0;
    margin-left: 24px;
  }

  .p-regist-caremanager-card__text {
    line-height: $line-height-xx-large;
  }
}
