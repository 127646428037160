@charset "utf-8";

.c-form-group {
  display: flex;
  align-items: center;
}

.c-form-group__label {
  width: 130px;
  font-size: $fs-xs;
  font-weight: bold;
}

.c-form-group__main {
  width: 100%;
  margin-bottom: -8px;
}

.c-form-group__field {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;
    padding: 10px 12px;
    border-radius: 8px;
  }

  ::placeholder {
    color: $honne-gray-400;
  }

  select {
    width: 100%;
    padding: 10px 12px;
    appearance: none;
    background-image: url("~images/user_inline/icon/arrow-down-blue.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) 50%;
    background-size: 10px 10px;
    border: $honne-gray-400 solid 2px;
    border-radius: $radius-size-medium;
  }

  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  select:focus {
    border: $honne-blue 2px solid;
  }
}

.c-form-group__error {
  margin-top: 8px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $honne-red;
}

.c-form-group + .c-form-group {
  margin-top: 16px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-form-group__label {
    font-size: $fs-md;
  }
}
