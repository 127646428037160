@charset "utf-8";

/*doc
---
title: suggest
name: suggest
category: components
---
```html_example
<div class="c-suggest">
  <div class="c-suggest__item">suggest</div>
  <div class="c-suggest__item">suggest</div>
  <div class="c-suggest__item">suggest</div>
</div>
```
*/

.c-suggest {
  background-color: $honne-white;
  border: solid 1px $honne-gray-500;
  border-radius: 8px;
}

.c-suggest__item {
  padding: 8px;
  cursor: pointer;

  &:hover {
    color: $honne-white;
    background-color: $honne-blue;
  }
}

.c-suggest__item + .c-suggest__item {
  border-top: solid 1px $honne-gray-400;
}
