@charset "utf-8";

.p-goika {
  max-width: 1080px;
  margin: 0 auto;
}

.p-goika__inner {
  padding: 24px;
  margin-bottom: 16px;
  background-color: $honne-white;
}

.p-goika__title {
  margin-bottom: 32px;
  line-height: $lh-medium;

  br {
    display: none;
  }
}

.p-goika__logo {
  display: inline-block;
  width: 100%;
  max-width: 500px;
  margin-bottom: 32px;
}

.p-goika__subtitle {
  margin-bottom: 16px;
}

.p-goika__paragraph {
  @include typo-md-xl-md;
  margin-bottom: 8px;

  br {
    display: none;
  }
}

.p-goika__contact {
  padding: 24px 24px 0;
  margin: 24px 0;
  text-align: center;
  background-color: $honne-yellow-white;
  border-radius: 16px;
}

.p-goika__contact__inner {
  margin-bottom: 24px;
}

.p-goika__tel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  font-size: $fs-5xl;
}

.p-goika__tel__time {
  display: inline-block;
  margin-left: 8px;
  font-size: $fs-sm;
}

.p-goika__contact__other {
  padding: 16px 0;
  border-top: 1px solid $honne-gray-300;
}

.p-goika__web {
  margin-top: 8px;
}

.p-goika__button {
  max-width: 400px;
  margin: 12px auto 0;
}

.p-goika__consultant {
  display: none;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-goika__inner {
    padding: 48px;
    margin-bottom: 16px;
    text-align: center;
    border-radius: 16px;
  }

  .p-goika__title {
    br {
      display: block;
    }
  }

  .p-goika__paragraph {
    br {
      display: block;
    }
  }

  .p-goika__contact {
    padding: 40px 40px 0;
    margin: 32px 0;
  }

  .p-goika__tel {
    flex-direction: row;
  }

  .p-goika__tel__box {
    margin-right: 16px;
  }

  .p-goika__search {
    margin-bottom: 24px;
  }

  .p-goika__consultant {
    display: block;
  }
}
