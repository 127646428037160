@charset "utf-8";

/*doc
---
title: article-guide
name: article-guide
category: components
---

```html_example
<ul class="c-article-guide">
  <li class="c-article-guide__item c-article-guide__item--col2">
    <a class="c-article-guide__link" href="#">
      <div class="c-article-guide__image">
        <img alt="介護を始めるにあたって必要な準備と手続きを紹介" src="http://placehold.it/90x100">
      </div>
      <div class="c-article-guide__main">
        <div class="c-article-guide__text">介護を始めるにあたって必要な準備と手続きを紹介</div>
        <div class="c-article-guide__tags">
          <div class="o-line o-line--small">
            <span class="c-label c-label--gray-100 o-line__item">介護施設</span>
            <span class="c-label c-label--gray-100 o-line__item">サービス付き高齢者向け住宅</span>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="c-article-guide__item c-article-guide__item--col2">
    <a class="c-article-guide__link" href="#">
      <div class="c-article-guide__image">
        <img alt="在宅介護と施設介護を徹底比較｜負担・費用・快適さ・安心度など" src="http://placehold.it/90x100">
      </div>
      <div class="c-article-guide__main">
        <div class="c-article-guide__text">在宅介護と施設介護を徹底比較｜負担・費用・快適さ・安心度など</div>
      </div>
    </a>
  </li>
</ul>
```
*/

$article-guide-border: 1px solid $honne-gray-300;

.c-article-guide {
  margin-top: 16px;
}

.c-article-guide__item {
  width: 100%;
  padding: 12px 0;
  border-bottom: $article-guide-border;

  &:first-child {
    border-top: $article-guide-border;
  }
}

.c-article-guide__link {
  display: flex;
}

.c-article-guide__image {
  position: relative;
  width: 72px;
  min-width: 72px;
  height: 72px;
  margin-right: 12px;
  overflow: hidden;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-article-guide__text {
  margin-bottom: 8px;
  font-size: $fs-2md;
  font-weight: bold;
  line-height: $line-height-large;
  word-break: break-word;
}

.c-article-guide__tags {
  margin-bottom: 4px;
}

.c-article-guide__latest-date {
  font-size: $fs-xs;
  font-weight: normal;
  color: $honne-gray-500;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-article-guide {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
  }

  .c-article-guide__item {
    padding: 12px 0;
  }

  .c-article-guide__text {
    font-size: $fs-2md;
    line-height: $line-height-x-large;
    letter-spacing: $letter-spacing-px1;
  }

  .c-article-guide__image {
    margin-right: 16px;
  }

  .c-article-guide__item--col2 {
    width: calc(50% - 8px);
  }

  .c-article-guide__item--col2:nth-child(odd) {
    margin-right: 16px;
  }

  .c-article-guide__item--col2:nth-child(2) {
    border-top: $article-guide-border;
  }
}
