@charset "utf-8";

.p-jigyosho-faq__title {
  padding-bottom: 16px;
}

.p-jigyosho-faq__main {
  padding-bottom: 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-faq__main {
    padding-right: 24px;
    padding-left: 24px;
  }
}
