@charset "utf-8";

// linkの色を上書きする
@mixin link-color($class_name, $color) {
  .#{$class_name} {
    color: $color;
  }

  .#{$class_name}:visited {
    color: $color;
  }

  .#{$class_name}:active {
    color: $color;
  }
}
