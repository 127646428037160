@charset "utf-8";

/*doc
---
title: label-list
name: label-list
category: components
---
```html_example
<div class="c-label-list">
  <span class="c-label c-label--yellow-white c-label-list__item">ラベル</span>
  <span class="c-label c-label--blue c-label-list__item">ラベル</span>
</div>
```
*/

.c-label-list {
  display: flex;
  flex-wrap: wrap;
}

.c-label-list__item {
  margin-right: 4px;
  margin-bottom: 4px;
}
