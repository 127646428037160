@charset "utf-8";

.c-notification {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $notification-zindex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  padding: 0 16px;
  font-size: $fs-xs;
  font-weight: bold;
}

.c-notification--notice {
  color: $honne-white;
  background-color: $honne-blue;
}

.c-notification--alert {
  color: $honne-white;
  background-color: $honne-red-bright;
}

.c-notification--none {
  display: none;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-notification {
    height: 70px;
    font-size: $fs-2md;
  }
}
