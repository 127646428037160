@charset "utf-8";

/*doc
---
title: list
name: list
category: components
---
```html_example
<ul class="c-list">
  <li class="c-list__item">item1</li>
  <li class="c-list__item">item2</li>
  <li class="c-list__item">item3</li>
</ul>
<h2 style="margin-top:24px;">2カラム</h2>
<ul class="c-list c-list--col2">
  <li class="c-list__item">item1</li>
  <li class="c-list__item">item2</li>
  <li class="c-list__item">item3</li>
</ul>
```
*/

.c-list {
  border-top: 1px solid $honne-gray-300;
  border-bottom: 1px solid $honne-gray-300;
}

.c-list__item + .c-list__item {
  border-top: 1px solid $honne-gray-300;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-list--col2 {
    display: flex;
    flex-wrap: wrap;
    border-top: 0;

    .c-list__item {
      width: 50%;

      &:nth-child(2n) {
        padding-left: 16px;
        margin-bottom: -1px;
        border-bottom: 1px solid $honne-gray-300;
      }

      &:first-child {
        border-top: 1px solid $honne-gray-300;
      }
    }
  }
}
