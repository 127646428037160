@charset "utf-8";

.p-cashback-mainvisual__image-pc {
  display: none;
}

.p-cashback-mainvisual__image-sp {
  display: block;
  width: 100%;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cashback-mainvisual {
    display: flex;
    justify-content: center;
    background-image: url("~images/cashback/mainvisual_bg.jpg");
    background-position: center top;
    background-size: cover;
  }

  .p-cashback-mainvisual__image-pc {
    display: block;
    width: 1098px;
    max-width: 100%;
  }

  .p-cashback-mainvisual__image-sp {
    display: none;
  }
}
