@charset "utf-8";

.p-faq-index {
  margin: 24px -16px -24px;
  border-top: 1px solid $honne-gray-300;
}

.p-faq-index__item {
  position: relative;
  border-bottom: 1px solid $honne-gray-300;
}

.p-faq-index__item:before {
  position: absolute;
  top: 24px;
  left: 16px;
  display: block;
  width: 13px;
  height: 8px;
  content: "";
  background: url("~images/icon/arrow-anchor.svg");
}

.p-faq-index__link {
  display: block;
  padding: 16px 16px 16px 35px;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $line-height-large;
}

.p-faq__title {
  padding: 24px 24px 0;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-faq-index {
    margin: 24px 0 0;
    border-top: none;
  }

  .p-faq-index__item {
    border-bottom: none;
  }

  .p-faq-index__item + .p-faq-index__item {
    border-top: 1px solid $honne-gray-300;
  }

  .p-faq-index__item:before {
    top: 20px;
    left: 8px;
  }

  .p-faq-index__link {
    padding: 12px 12px 12px 28px;
  }
}
