@charset "utf-8";

// 医療看護体制
.p-jigyosho-medical__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid $honne-gray-300;
}

.p-jigyosho-medical__item {
  display: flex;
  width: 50%;
  border-top: 1px solid $honne-gray-300;
}

.p-jigyosho-medical__marker {
  display: flex;
  align-items: center;
  padding: 8px;
}

.p-jigyosho-medical__content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  font-size: $fs-sm;
  font-weight: bold;
  line-height: $lh-x-small;
  background-color: $honne-gray-100;
}

.p-jigyosho-medical__footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
}

.p-jigyosho-medical__comment {
  display: flex;
  align-items: center;
  font-size: $fs-sm;
  line-height: $line-height3;

  & + .p-jigyosho-medical__comment {
    margin-left: 8px;
  }
}

.p-jigyosho-medical__comment__icon {
  margin-right: 4px;
  margin-left: auto;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-medical__content {
    font-size: $fs-md;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-medical__footer {
    margin-top: 16px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-medical__item {
    width: 33%;
  }
}
