@charset "utf-8";

/*doc
---
title: map-keywords
name: map-keywords
category: components
---
```html_example
<div class="c-map-keywords__inner">
  <div class="c-map-keywords__body">
    <div class="c-map-keywords__map">
      <div>エリアリスト</div>
    </div>
    <div class="c-map-keywords__keywords">
      <div>
        <p class="c-map-keywords__title">注目のキーワード</p>
        <div>some keyword here</div>
      </div>
    </div>
  </div>
</div>
```
*/

.c-map-keywords {
  background: $honne-white;
}

.c-map-keywords .c-area__list {
  margin-right: -24px;
  margin-left: -24px;
}

.c-map-keywords__inner {
  padding: 40px 0;
}

.c-map-keywords__body {
  margin: 32px 0;
}

.c-map-keywords__keywords {
  margin-bottom: 24px;
}

.c-map-keywords__title {
  font-size: $fs-lg;
}

.c-map-keywords__title--pc {
  display: none;
}

.c-map-keywords__title--area {
  margin-bottom: 16px;
}

.c-map-keywords__more {
  width: 250px;
  margin: 24px auto 0;
}

.c-map-keywords__map {
  margin-bottom: 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-map-keywords__inner {
    padding: 60px 0;
  }

  .c-map-keywords__keywords {
    margin-bottom: 0;
  }

  .c-map-keywords__body {
    margin: 40px 0 56px;
  }

  .c-map-keywords__title--pc {
    display: inline;
  }

  .c-map-keywords__title--area {
    display: none;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-map-keywords__body {
    display: flex;
  }

  .c-map-keywords__keywords {
    max-width: 280px;
  }
}
