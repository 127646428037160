@charset "utf-8";

.p-jys-search-pickup-condition {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.p-jys-search-pickup-condition__head {
  display: inline-block;
  margin-right: 8px;
  font-size: $fs-md;
  font-weight: bold;
}

.p-jys-search-pickup-condition__main {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.p-jys-search-pickup-condition__main_link {
  position: relative;
  padding: 8px 8px 8px 4px;
  margin: 0 6px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $honne-blue;
  background-color: $honne-yellow-white;
  border-radius: 4px;
}

.p-jys-search-pickup-condition__main_link::after {
  position: absolute;
  bottom: 1px;
  left: -7px;
  z-index: $z-index-1;
  width: 0;
  height: 0;
  content: "";
  /* stylelint-disable sh-waqar/declaration-use-variable*/
  border-color: transparent $honne-yellow-white transparent transparent;
  /* stylelint-enable sh-waqar/declaration-use-variable*/
  border-style: solid;
  border-width: 16px 8px 16px 0;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jys-search-pickup-condition {
    display: none;
  }
}
