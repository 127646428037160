@charset "utf-8";

.p-jg-share-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $jigyosho-share-modal;
  display: none;
  background-color: rgba(17, 17, 17, 0.564);
}

.p-jg-share-modal__inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.p-jg-share-modal__main {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 90vw;
  padding: 0 16px;
  background-color: $honne-white;
  border-radius: 20px;
}

.p-jg-share-modal__head {
  padding-top: 24px;
  color: $honne-white;
}

.p-jg-share-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 60px;
}

.p-jg-share-modal__close__icon {
  position: absolute;
  top: 20px;
  left: 40px;
  width: 16px;
  height: 16px;
  margin-left: auto;
  background: url("~images/icon/close-white.svg") no-repeat center;
}

.p-jg-share-modal__main__title {
  padding: 8px;
  font-size: $fs-xl;
  font-weight: bold;
  text-align: center;
}

.p-jg-share-modal__main__description {
  padding: 16px 0;
  word-break: break-all;
}

.p-jg-share-modal__main__footer {
  display: flex;
  justify-content: space-around;
  padding: 8px 16px 16px;
}

.p-jg-share-modal__main__footer__icon__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-black;
  text-align: center;
}

.p-jg-share-modal__main__footer__icon__wrapper:visited {
  color: $honne-black;
}

.p-jg-share-modal__main__footer__icon__wrapper
  + .p-jg-share-modal__main__footer__icon__wrapper {
  margin-left: 16px;
}

.p-jg-share-modal__main__footer__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  margin-bottom: 8px;
  font-size: $font-size-big5;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.p-jg-share-modal__main__footer__line {
  background-image: url("~images/line.png");
}

.p-jg-share-modal__main__footer__copy {
  background-image: url("~images/fontawesome/fa-copy.svg");
}

.p-jg-share-modal__main__footer__mail {
  background-image: url("~images/fontawesome/fa-envelope.svg");
}
