@charset "utf-8";

.p-market-price-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.p-market-price-flex__button {
  min-width: 60px;
}

.p-market-price-flex__link {
  font-size: $fs-xs;
  text-decoration: underline;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-market-price-flex {
    gap: 12px;
  }

  .p-market-price-flex__button {
    width: 87px;
  }

  .p-market-price-flex__link {
    font-size: $fs-md;
  }
}
