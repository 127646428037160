@charset "utf-8";

.p-top-banner {
  padding: 0 24px;
  transition: all 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

.p-top-banner__image {
  width: 100%;
  height: 100%;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-banner {
    width: 1080px;
    padding: 0;
    margin: 0 auto;
  }
}
