@charset "utf-8";

.p-regist-caremanager-about {
  padding: 40px 0 24px;
}

.p-regist-caremanager-about__head {
  margin-bottom: 16px;
}

.p-regist-caremanager-about__head--blue {
  color: $honne-blue;
}

.p-regist-caremanager-about__text {
  margin-bottom: 24px;
  font-size: $fs-2md;
  line-height: $line-height3;
  text-align: justify;
  letter-spacing: $letter-spacing-px2;
}

.p-regist-caremanager-about__h3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8px;
  text-align: center;
}

.p-regist-caremanager-about__list {
  font-weight: bold;
}

.p-regist-caremanager-about__item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & i {
    min-width: 24px;
  }
}

.p-regist-caremanager-about__item--end {
  align-items: end;
}

.p-regist-caremanager-about__item:last-child {
  margin-bottom: 0;
}

.p-regist-caremanager-about__item__text {
  margin-left: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-caremanager-about {
    padding: 72px 0 16px;
  }

  .p-regist-caremanager-about__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
    font-size: $fs-lg;
  }

  .p-regist-caremanager-about__h3 {
    flex-direction: row;
    margin-bottom: 24px;
  }

  .p-regist-caremanager-about__list {
    padding: 32px;
    font-size: $fs-xl;
    background: $honne-yellow-white;
    border-radius: 24px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-regist-caremanager-about__list {
    display: flex;
    flex-wrap: wrap;
  }

  .p-regist-caremanager-about__item {
    width: 50%;
  }
}
