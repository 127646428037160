@charset "utf-8";

/* 検索条件 カテゴリ大 */
.p-search-cond-block {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.p-search-cond-block__head {
  margin: 16px 24px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-search-cond-block__inner {
  padding: 0 24px;
}

.p-search-cond-block__group + .p-search-cond-block__group {
  margin-top: 16px;
}

.p-search-cond-block__title {
  padding: 0 24px;
  margin-bottom: 12px;
  font-weight: bold;
}

.p-search-cond-block__item {
  padding: 12px 24px;
  border-bottom: 1px solid $honne-gray-300;

  &:first-child {
    border-top: 1px solid $honne-gray-300;
  }
}

.p-search-cond-block__button {
  padding: 16px;
  margin: 16px 0;
}

.p-search-cond-block__more {
  display: none;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-cond-block__head {
    margin: 0 0 16px;
  }

  .p-search-cond-block__inner {
    padding: 0;
  }

  .p-search-cond-block__title {
    padding: 0;
    margin-bottom: 8px;
  }

  .p-search-cond-block__item {
    padding: 0;
    margin-bottom: 8px;
    border-bottom: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .p-search-cond-block__button {
    width: 100%;
    margin: 16px 0 0;
  }

  .p-search-cond-block__more {
    display: block;
    text-align: center;
  }
}
