@charset "utf-8";

/*doc
---
title: hero
name: hero
category: components
---

```html_example
<div class="c-hero" style="height:300px;margin-bottom:16px;">
  <img class="c-hero__image" alt="介護のほんね" src="http://localhost:5000/assets/top/mainvisual.jpg">
  <div class="c-hero__inner">
    <p style="color:white;font-size:32px;">テキストなどを置く</p>
  </div>
</div>

<div class="c-hero" style="height:300px;">
  <img class="c-hero__image" alt="介護のほんね" src="http://localhost:5000/assets/top/mainvisual.jpg">
  <div class="c-hero__inner c-hero__inner--center">
    <p style="color:white;font-size:32px;">真ん中寄せ</p>
  </div>
</div>
```
*/

.c-hero {
  position: relative;
  display: block;
  min-width: $breakpoint-sp-min;
}

.c-hero__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

.c-hero__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 24px;
}

.c-hero__inner--center {
  align-items: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-hero {
    height: 660px;
  }

  .c-hero__inner {
    max-width: $breakpoint-pc-max;
    margin: 0 auto;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-hero__inner {
    max-width: $breakpoint-pc-max;
    padding: 80px 0 0;
    margin: 0 auto;
  }
}
