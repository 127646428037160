/*doc
---
title: inquiry
name: inquiry
category: components
---
```html_example
<div class="c-inquiry" style="">
  <div class="c-inquiry__top">
    <img class="c-inquiry__image" src="http://placehold.it/172x120">
    <p class="c-inquiry__title c-inquiry__title--sp">お困りの方はいつでも<br>お気軽にご相談ください</p>
  </div>
  <div class="c-inquiry__main">
    <p class="c-inquiry__title c-inquiry__title--pc">お困りの方はいつでもお気軽にご相談ください</p>
    <p class="c-inquiry__desc">
      施設の探し方や介護に関するご質問など、プロの相談員が施設探しをサポート致します！
    </p>
    <div class="c-inquiry__contact">
      <a class="c-inquiry-tel c-inquiry__contact__tel" href="#">
        <div class="c-inquiry-tel__top">
          <i class="c-icon c-icon-tel c-inquiry-tel__icon"></i>
          <span>0120-002718</span>
        </div>
        <p class="c-inquiry-tel__desc">
          営業時間：<span class="c-inquiry-tel__desc--bold">9:00〜18:00</span>(土日祝除く)
        </p>
      </a>
      <a class="c-link c-link--button c-inquiry__contact__web" href="#">
        <span class="c-link__text c-inquiry__contact__link">WEBでお問い合わせ</span>
      </a>
    </div>
  </div>
</div>
```
*/

.c-inquiry {
  width: 100%;
  padding: 24px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.c-inquiry--white {
  background-color: $honne-white;
}

.c-inquiry__top {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.c-inquiry__image {
  width: 103px;
  height: 72px;
  margin-right: 12px;
  object-fit: cover;
}

.c-inquiry__title {
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $line-height-x-large;
}

.c-inquiry__title--pc {
  display: none;
}

.c-inquiry__desc {
  margin-bottom: 16px;
  font-size: $fs-md;
  line-height: $line-height-large;
}

.c-inquiry__contact__tel {
  margin-bottom: 16px;
  background-color: $honne-white;
}

.c-inquiry__contact__web {
  line-height: $line-height-base;
  background-color: $honne-blue-lighten;
}

.c-inquiry__contact__link {
  color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-inquiry {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }

  .c-inquiry__top {
    margin-bottom: 0;
  }

  .c-inquiry__image {
    width: 136px;
    height: 95px;
    margin-right: 12px;
  }

  .c-inquiry__title {
    margin-bottom: 8px;
    font-size: $fs-xl;
    letter-spacing: $letter-spacing-small;
  }

  .c-inquiry__title--pc {
    display: block;
  }

  .c-inquiry__title--sp {
    display: none;
  }

  .c-inquiry__desc {
    margin-bottom: 12px;
    font-size: $fs-2md;
    letter-spacing: $letter-spacing-small;
  }

  .c-inquiry__contact {
    display: flex;
  }

  .c-inquiry__contact__tel {
    padding: 0;
    margin-top: -6px;
    margin-right: 24px;
    margin-bottom: 0;
    background-color: transparent;
    border: none;
  }

  .c-inquiry__contact__web {
    width: 200px;
  }

  .c-inquiry__contact__link {
    font-size: $fs-lg;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-inquiry {
    max-width: 1080px;
  }

  .c-inquiry__contact__web {
    width: 240px;
  }

  .c-inquiry__image {
    width: 172px;
    height: 120px;
    margin-right: 32px;
  }
}
