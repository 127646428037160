@charset "utf-8";

.p-regist-enterprise-worry {
  position: relative;
  margin-top: 24px;
}

.p-regist-enterprise-worry:before,
.p-regist-enterprise-worry:after {
  position: absolute;
  bottom: -80px;
  display: block;
  width: 50vw;
  content: "";
  border-top: solid 20px $honne-gray-50;
  border-right: solid 25vw transparent;
  border-bottom: solid 20px transparent;
  border-left: solid 25vw transparent;
}

.p-regist-enterprise-worry:before {
  left: -16px;
  border-right: solid 25vw $honne-gray-50;
}

.p-regist-enterprise-worry:after {
  right: -16px;
  border-left: solid 25vw $honne-gray-50;
}

.p-regist-enterprise-worry__item {
  position: relative;
  padding: 16px 17px 16px 54px;
  font-size: $fs-lg;
  line-height: $line-height-px3;
  background: $honne-white;
  border-radius: 8px;
}

.p-regist-enterprise-worry__item + .p-regist-enterprise-worry__item {
  margin-top: 10px;
}

.p-regist-enterprise-worry__icon {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 26px;
}

.p-regist-enterprise-worry__bold {
  font-size: $fs-2xl;
  font-weight: bold;
  color: $honne-blue;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-enterprise-worry {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .p-regist-enterprise-worry:before,
  .p-regist-enterprise-worry:after {
    position: absolute;
    bottom: -90px;
    width: 50vw;
    border-top: solid 40px $honne-gray-50;
    border-right: solid 25vw transparent;
    border-bottom: solid 40px transparent;
    border-left: solid 25vw transparent;
  }

  .p-regist-enterprise-worry:before {
    right: 50%;
    left: auto;
    border-right: solid 25vw $honne-gray-50;
  }

  .p-regist-enterprise-worry:after {
    right: auto;
    left: 50%;
    border-left: solid 25vw $honne-gray-50;
  }

  .p-regist-enterprise-worry__item {
    width: 374px;
    max-width: unset;
    padding: 20px 37px 20px 78px;
    margin: 0 8px;
    white-space: nowrap;
  }

  .p-regist-enterprise-worry__item + .p-regist-enterprise-worry__item {
    margin-top: 0;
  }

  .p-regist-enterprise-worry__icon {
    top: 20px;
    left: 24px;
    width: 34px;
  }
}
