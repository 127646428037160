@charset "utf-8";

.p-top-staff {
  margin-top: 48px;
}

.p-top-staff__items {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  @include scrollbar-none;
}

.p-top-staff__title {
  margin-bottom: 24px;
}
