@charset "utf-8";

.p-jigyosho-short-table {
  width: 100%;
  border-top: solid 1px $honne-gray-300;
}

.p-jigyosho-short-table__head {
  display: block;
  width: 100%;
  padding: 8px;
  font-weight: bold;
  text-align: left;
  background-color: $honne-gray-100;
  border-right: solid 1px $honne-gray-300;
  border-left: solid 1px $honne-gray-300;
}

.p-jigyosho-short-table__col {
  display: block;
  width: 100%;
  padding: 8px;
  border-right: solid 1px $honne-gray-300;
  border-bottom: solid 1px $honne-gray-300;
  border-left: solid 1px $honne-gray-300;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-short-table {
    width: 100%;
    border-top: solid 1px $honne-gray-300;
  }
  .p-jigyosho-short-table__row {
    display: flex;
  }

  .p-jigyosho-short-table__head {
    width: 30%;
    border-bottom: solid 1px $honne-gray-300;
  }

  .p-jigyosho-short-table__col {
    width: 70%;
    border-left: none;
  }
}
