@charset "utf-8";

.p-question-request-form {
  margin-bottom: 32px;
}

.p-question-request-form__header {
  max-width: 960px;
  padding-top: 24px;
}

.p-question-request-form__title {
  margin-bottom: 8px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $lh-small;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-px2;
}

.p-question-request-form__desc {
  margin-bottom: 16px;
  font-size: $fs-md;
  line-height: $lh-medium;
  letter-spacing: $ls-small;
}

.p-question-request-form__h1 {
  margin-bottom: 16px;
  font-family: $font-family-700bold;
  font-size: $fs-2xl;
  font-weight: $font-weight-700bold;
}

.p-question-request-form__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 320px;
  padding: 12px 0;
  margin: 24px auto 40px;
  font-weight: bold;
}

.p-question-request-form__policy-text {
  margin-top: 24px;
  font-size: $fs-md;
  line-height: $lh-x-large;
  color: $honne-gray-700;
  letter-spacing: $ls-medium;
}

.p-question-request-form__user-info {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.p-question-request-form__use-info-input {
  flex: 1;
  margin-bottom: 24px;
}

.p-question-request-form__check-policy {
  display: flex;
  justify-content: center;
}

.p-question-request-form__policy-checkbox-error {
  display: flex;
  justify-content: center;
}

.p-question-request-form__check-policy-text {
  font-size: $fs-sm;
  letter-spacing: $ls-medium;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-question-request-form__header {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 24px auto;
  }

  .p-question-request-form__h1 {
    margin-bottom: 0;
    font-size: $fs-5xl;
  }

  .p-question-request-form__user-info {
    flex-direction: row;
  }

  .p-question-request-form__use-info-input:first-child {
    margin-right: 24px;
  }
}
