@charset "utf-8";

.p-brochure-table {
  width: 100%;
  margin-bottom: 12px;
  border-bottom: solid 1px $honne-gray-300;

  & > tbody > tr {
    border-top: solid 1px $honne-gray-300;
  }

  & > tbody > tr:nth-child(even) {
    background-color: $honne-white;
  }

  & > tbody > tr:nth-child(odd) {
    background-color: rgba(249, 249, 249, 0.47);
  }
}

.p-brochure-table__head {
  padding: 8px;
  font-size: $fs-md;
  text-align: center;
}

.p-brochure-table__head--col1 {
  min-width: 75px;
}

.p-brochure-table__head--col2 {
  min-width: 75px;
}

.p-brochure-table__head--col3 {
  text-align: left;
}

.p-brochure-table__col {
  padding: 16px 0;
  vertical-align: middle;
}

.p-brochure-table__checkbox {
  justify-content: center;
}

.p-brochure-table__jigyosho {
  display: flex;
  align-items: center;
  font-size: $fs-2md;
  color: $honne-gray-700;
  text-align: left;
}

.p-brochure-table__jigyosho__icon {
  margin-right: 8px;
  margin-left: auto;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochure-table__head {
    font-size: $fs-2md;
  }
}
