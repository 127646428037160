@charset "utf-8";

a {
  color: $honne-blue;
  text-decoration: none;
  outline: none;

  &:visited {
    color: $honne-blue;
  }

  &:hover {
    text-decoration: none;
    opacity: 0.6;
    transition: 0.2s;
  }

  &:active {
    color: $honne-blue;
  }
}
