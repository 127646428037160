@charset "utf-8";

.p-top-type {
  margin: 56px 0;
}

.p-top-type__title {
  margin-bottom: 16px;
}

.p-top-type__list {
  margin-bottom: 16px;
}

.p-top-type__list__item {
  padding-bottom: 12px;
  border-bottom: solid 1px $honne-gray-300;
}

.p-top-type__list__item:first-child {
  border-top: solid 1px $honne-gray-300;
}

.p-top-type__list__item__link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 48px);
  max-width: 524px;
  padding-top: 12px;
}

.p-top-type__list__item__image {
  width: 84px;
  height: 84px;
  margin-right: 16px;
}

.p-top-type__list__item__box {
  width: calc(100vw - 148px);
}

.p-top-type__list__item__title {
  margin-bottom: 4px;
  font-size: $fs-2md;
  font-weight: bold;
  color: $honne-gray-700;

  &:visited {
    color: $honne-gray-700;
  }
}

.p-top-type__list__item__desc {
  font-size: $fs-sm;
  line-height: $line-height-base;
  color: $honne-gray-500;
  text-align: justify;

  &:visited {
    color: $honne-gray-500;
  }
}

.p-top-type__list__item__sublink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6px;
  font-size: $fs-sm;
  text-decoration: underline;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-type {
    margin: 72px 0;
  }

  .p-top-type__title {
    margin-bottom: 24px;
  }

  .p-top-type__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 32px;
    margin-bottom: 0;
  }

  .p-top-type__list__item {
    padding-bottom: 0;
    border: 0;

    &:first-child {
      border: 0;
    }
  }

  .p-top-type__list__item__link {
    width: calc(50vw - 48px);
    padding: 0;
    border: 0;
  }

  .p-top-type__list__item__image {
    width: 90px;
    height: 90px;
    margin-right: 16px;
  }

  .p-top-type__list__item__box {
    width: calc(50vw - 154px);
  }

  .p-top-type__list__item__title {
    font-size: $fs-xl;
  }

  .p-top-type__list__item__desc {
    font-size: $fs-lg;
    line-height: $line-height9;
  }

  .p-top-type__list__item__sublink {
    margin-top: 0;
    font-size: $fs-2md;
  }
}
