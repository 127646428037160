@charset "utf-8";

.p-special3-faq__head {
  padding: 12px 24px 24px;
  font-size: $fs-3xl;
  font-weight: bold;
  text-align: center;
  letter-spacing: $letter-spacing-px2;
  border-bottom: 1px solid $honne-gray-300;
}

.p-special3-faq__list {
  margin-bottom: 32px;
}

.p-special3-faq__list__item {
  padding: 16px 24px 16px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid $honne-gray-300;
}

.p-special3-faq__subtitle {
  min-height: 32px;
  padding-left: 36px;
  margin-bottom: 8px;
  font-size: $fs-lg;
  letter-spacing: $letter-spacing-px2;
  background-image: url("~images/lp/special/q.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 24px 24px;
}

.p-special3-faq__text {
  min-height: 32px;
  padding-left: 36px;
  font-size: $fs-md;
  line-height: $line-height10;
  letter-spacing: $letter-spacing-px2;
  background-image: url("~images/lp/special/a.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 24px 24px;

  br {
    display: none;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special3-faq {
    padding: initial;
  }

  .p-special3-faq__head {
    padding: 4px 0 38px;
  }

  .p-special3-faq__caption {
    margin-bottom: 8px;
    font-size: $fs-2xl;
  }

  .p-special3-faq__title {
    margin-bottom: 40px;
    font-size: $font-size-big1;
    letter-spacing: $letter-spacing-px4;
  }

  .p-special3-faq__list {
    width: 1000px;
    margin: 0 auto 0;
  }

  .p-special3-faq__list__item {
    padding: 16px 20px;
    margin-bottom: 16px;
  }

  .p-special3-faq__subtitle {
    height: 40px;
    padding-top: 4px;
    padding-left: 48px;
    font-size: $fs-xl;
    background-size: 32px 32px;
  }

  .p-special3-faq__text {
    padding-left: 48px;
    background-size: 32px 32px;

    br {
      display: block;
    }
  }
}
