@charset "utf-8";

.p-theme-popular {
  margin-bottom: 24px;
}

.p-theme-popular__cards {
  padding: 0 24px;
  margin: 0 -24px;
}
