@charset "utf-8";

.o-cards {
  display: flex;
  flex-flow: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  @include scrollbar-none;
}

.o-cards__card + .o-cards__card {
  margin-left: 16px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .o-cards {
    margin-bottom: -24px;
  }

  .o-cards__card {
    margin-bottom: 24px;
  }

  .o-cards--col3 {
    flex-wrap: wrap;

    .o-cards__card {
      width: calc(calc(100% - 32px) / 3);
    }

    .o-cards__card:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
}
