@charset "utf-8";

/*doc
---
title: side-qa-request
name: side-qa-request
category: components
---
```html_example
<div class="c-side-qa-request" style="width:256px;">
  <div class="c-side-qa-request__box">
    <h1 class="c-side-qa-request__title">みなさんの介護の悩みや気になることを教えてください</h1>
    <div class="c-side-qa-request__main">
      <a href="#">
        <img class="c-side-qa-request__logo" src="http://placehold.it/40x40">
      </a>
      <p class="c-side-qa-request__desc">集まった質問は今後の参考にさせていただきます！</p>
    </div>
    <a class="c-button c-button--secondary c-button--large" href="#">
      質問を投稿する
    </a>
  </div>
</div>
```
*/

.c-side-qa-request {
  margin: 0 -24px 0;
  background-color: $honne-white;
}

.c-side-qa-request__box {
  position: relative;
  padding: 24px;
  margin-top: 16px;
  border-radius: 8px;
}

.c-side-qa-request__title {
  display: flex;
  margin-bottom: 4px;
  // logo 30px + margin-right 8px
  margin-left: 38px;
  font-size: $fs-2md;
  font-weight: bold;
  color: $honne-gray-700;
  letter-spacing: $ls-small;
}

.c-side-qa-request__logo {
  position: absolute;
  top: 32px;
  left: 24px;
  height: 30px;
}

.c-side-qa-request__main {
  display: flex;
  margin-bottom: 12px;
}

.c-side-qa-request__desc {
  margin-left: 38px;
  font-size: $fs-xs;
  font-weight: 500;
  line-height: $lh-small;
  color: $honne-gray-500;
  letter-spacing: $ls-medium;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-side-qa-request {
    margin: 0;
    border-radius: 8px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-side-qa-request__box {
    position: static;
    margin-top: 0;
  }

  .c-side-qa-request__title {
    margin-bottom: 8px;
    margin-left: 0;
    text-align: center;
  }

  .c-side-qa-request__logo {
    position: static;
    height: 40px;
    margin-right: 12px;
  }

  .c-side-qa-request__desc {
    margin-left: 0;
    font-size: $fs-sm;
    letter-spacing: $ls-small;
  }
}
