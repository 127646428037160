@charset "utf-8";

.c-markup__article {
  .c-mp-contact-rich {
    margin-top: 32px;
    @include reset-markup();

    .c-tel-box__tel__image {
      width: 32px;
      height: 23px;
      margin-right: 8px;
    }

    .c-tel-box__title {
      font-size: $fs-xl;
    }

    .c-tel-box__tel {
      font-size: $font-size-big1;
    }

    .c-tel-box__desc {
      font-size: $fs-md;
    }
  }

  .c-mp-contact-rich__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-mp-contact-rich__search {
    width: 100%;
    padding: 16px;
    background-color: $honne-yellow-white;
    border-radius: 8px;
  }

  .c-mp-contact-rich__search__button {
    padding: 12px;
    margin-top: 12px;
  }

  .c-mp-contact-rich__search__text {
    font-size: $fs-2md;
  }

  .c-mp-contact-rich__text {
    margin-bottom: 12px;
    font-size: $fs-lg;
    font-weight: bold;
  }

  .c-mp-contact-rich__list {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    margin-bottom: 16px;
    background-color: $honne-yellow-white;
    border-radius: 8px;
  }

  .c-mp-contact-rich__list__text {
    line-height: $lh-large;
  }

  /* 769px ~ */
  @include min-screen($breakpoint-pc-small-min) {
    .c-mp-contact-rich__text {
      font-size: $fs-xl;
    }

    .c-mp-contact-rich__list {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .c-mp-contact-rich__list__text {
      width: 50%;
    }

    .c-mp-contact-rich__search {
      width: calc(50% - 8px);
    }
  }
}
