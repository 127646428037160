@charset "utf-8";

/*doc
---
title: main-visual
name: main-visual
category: components
---
```html_example
<div class="c-main-visual">
  <picture>
    <img class="c-main-visual__img" src="http://placehold.it/400x200">
  </picture>
  <div class="c-main-visual__inner">
    <p>add some content here</p>
  </div>
</div>
```
*/

.c-main-visual__img {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  object-fit: cover;
}

.c-main-visual__inner {
  padding: 24px;
  background-color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-main-visual {
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    border-radius: 12px;
  }

  .c-main-visual__inner {
    padding: 32px;
  }

  .c-main-visual__img {
    max-width: 460px;
  }
}
