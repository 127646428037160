.p-lp-online-top__caption {
  margin-bottom: 8px;
  font-size: $fs-md;
  font-weight: bold;
}

.p-lp-online-top__title {
  font-size: $fs-3xl;
}

.p-lp-online-top-list {
  padding: 16px 24px;
  margin-bottom: 16px;
  background-color: rgba($honne-white, 0.8);
  border-radius: 8px;
}

.p-lp-online-top-list__item {
  display: flex;
  align-items: center;
  font-size: $fs-2md;
  font-weight: bold;

  i {
    margin-right: 8px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.p-lp-online-top__button {
  width: calc(100vw - 48px);
  max-width: 400px;
  margin: 0 auto 16px;

  a {
    width: 100%;
  }
}

.p-lp-online-top__text {
  text-align: center;
}

.p-lp-online-top__tel {
  font-size: $font-size-big3;
  font-weight: bold;
  color: color-lighten($honne-blue, 4%);
  text-align: center;
  letter-spacing: $letter-spacing-px2;

  span {
    display: block;
    margin-right: 8px;
  }
}

.p-lp-online-top__time {
  font-size: $fs-sm;
  font-weight: normal;
  color: initial;
}

.p-lp-online-top__note {
  position: absolute;
  right: 24px;
  bottom: 8px;
  font-size: $fs-3xs;
  text-align: right;
}

@include max-screen($breakpoint-tablet-max) {
  .p-lp-online-top__inner {
    &:first-child {
      height: 524px;
      padding: 32px 24px;
      background: url("~images/lp/online/bg_sp.jpg") no-repeat;
      background-position: center bottom;
      background-size: 430px auto;
    }

    &:last-child {
      padding: 0 24px 32px;
      margin-top: -70px;
    }
  }

  .p-lp-online-top__note {
    top: 524px;
    bottom: initial;
  }

  .p-lp-online-top__title {
    text-align: center;
  }

  .p-lp-online-top__caption {
    text-align: center;
  }
}
@include min-screen($breakpoint-pc-small-min) {
  .p-lp-online-top {
    position: relative;
    background: url("~images/lp/online/bg.jpg") no-repeat;
    background-position: 30px bottom;
    background-size: auto 120%;
  }

  .p-lp-online-top__box {
    padding: 56px 48px;
  }

  .p-lp-online-top__title {
    margin-bottom: 24px;
  }

  .p-lp-online-top-list {
    padding: 0;
    margin-bottom: 24px;
    background-color: transparent;
  }

  .p-lp-online-top__button {
    margin: 0 0 16px;
  }

  .p-lp-online-top__text,
  .p-lp-online-top__tel {
    text-align: left;
  }
}

@include min-screen($breakpoint-pc-max) {
  .p-lp-online-top {
    position: relative;
    max-width: 1400px;
    padding: 96px 0 0;
    margin: 0 auto;
    background: url("~images/lp/online/bg.jpg") no-repeat;
    background-position: right bottom;
    background-size: auto 120%;
  }

  .p-lp-online-top__box {
    width: 1080px;
    padding: 0 0 96px;
    margin: 0 auto;
  }

  .p-lp-online-top__caption {
    font-size: $fs-xl;
  }

  .p-lp-online-top__title {
    font-size: $font-size-big4;
  }

  .p-lp-online-top-list {
    margin-bottom: 32px;
  }

  .p-lp-online-top-list__item {
    font-size: $fs-xl;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .p-lp-online-top__button {
    width: initial;
    max-width: initial;
    margin: 0 auto 16px;

    a {
      width: 100%;
      padding: 16px 48px;
    }
  }

  .p-lp-online-top__text {
    font-size: $fs-lg;
    text-align: left;
  }

  .p-lp-online-top__tel {
    display: flex;
    align-items: center;
    margin-right: 8px;
    font-size: $font-size-big3;
    font-weight: bold;
    text-decoration: none;

    span {
      display: inline-block;
    }
  }

  .p-lp-online-top__time {
    font-size: $fs-sm;
    font-weight: normal;
  }

  .p-lp-online-top__note {
    position: initial;
    width: 1080px;
    padding-bottom: 12px;
    margin: 0 auto;
    text-align: right;
  }
}
