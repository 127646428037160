@charset "utf-8";

.p-top-voice {
  margin-bottom: 56px;
}

.p-top-voice__title {
  margin-bottom: 16px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-voice {
    margin-bottom: 72px;
  }

  .p-top-voice__title {
    margin-bottom: 24px;
  }
}
