@charset "utf-8";

.c-footer-contact__wrap {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.c-footer-contact__tel {
  font-size: $fs-4xl;
  font-weight: bold;
  color: $honne-gray-700;
}

.c-footer-contact__icon {
  margin-right: 8px;
}

.c-footer-contact__desc {
  margin-bottom: 12px;
  font-size: $fs-2xs;
  color: $honne-gray-700;
}

.c-footer-contact__button,
.c-footer-contact__button:visited {
  width: 100%;
  margin-bottom: 16px;
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .c-footer-contact__button,
  .c-footer-contact__button:visited {
    max-width: 320px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-footer-contact {
    margin-bottom: 40px;
  }
}
