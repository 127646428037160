@charset "utf-8";

/*doc
---
title: shadow
name: shadow
category: settings
---

```html_example
<div style="box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16); display: flex;align-items: center;justify-content: center; font-size: 18px; font-weight: bold; background-color: #fff; height: 120px;">x-small</div>
<div style="box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16); display: flex;align-items: center;justify-content: center; font-size: 18px; font-weight: bold; background-color: #fff; height: 120px;margin-top: 10px;">small</div>
<div style="box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16); display: flex;align-items: center;justify-content: center; font-size: 18px; font-weight: bold; background-color: #fff; height: 120px;margin-top: 10px;">medium</div>
<div style="box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16); display: flex;align-items: center;justify-content: center; font-size: 18px; font-weight: bold; background-color: #fff; height: 120px;margin-top: 10px;">large</div>

```
*/

$shadow-size-x-small: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
$shadow-size-small: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
$shadow-size-x-medium: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
$shadow-size-medium: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
$shadow-size-large: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
