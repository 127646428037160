@charset "utf-8";

%p-news__title-h2 {
  position: relative;
  padding-left: 18px;
  margin: 40px 0 16px;
  font-size: $fs-2xl;
  font-weight: bold;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 30px;
    content: "";
    background: $honne-yellow;
    border-radius: 1px;
  }
}

.p-news__title-h2 {
  @extend %p-news__title-h2;
}

%p-news__title-h3 {
  position: relative;
  padding-left: 0;
  margin: 40px 0 16px;
  font-size: $fs-xl;
  font-weight: bold;

  &::before {
    display: none;
  }
}

.p-news__title-h3 {
  @extend %p-news__title-h3;
}

.p-news-article {
  h2 {
    @extend %p-news__title-h2;
  }

  h3 {
    @extend %p-news__title-h3;
  }

  h4::before {
    background: $honne-yellow;
  }

  h5 {
    color: $honne-gray-600;
  }

  u {
    background: transparent;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news__title-h2 {
    line-height: $lh-large;
  }

  .p-news__title-h2:before {
    height: 36px;
    border-radius: 0 2px 2px 0;
  }

  .p-news-article {
    h2 {
      line-height: $lh-large;
    }

    h2:before {
      height: 36px;
      border-radius: 0 2px 2px 0;
    }
  }
}
