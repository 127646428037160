@charset "utf-8";

.p-cash-top-contact__h2 {
  margin-bottom: 12px;
}

.p-cash-top-contact__desc {
  @include typo-md-xl-md;
  margin-bottom: 12px;
}

.p-cash-top-contact__title {
  margin-bottom: 8px;
  text-align: center;
}

.p-cash-top-contact__contact__tel {
  margin-bottom: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cash-top-contact__h2 {
    @include typo-4xl-sm-md;
    margin-bottom: 16px;
    text-align: center;
  }

  .p-cash-top-contact__desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

  .p-cash-top-contact__title {
    margin-bottom: 16px;
  }

  .p-cash-top-contact__contact {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-cash-top-contact__contact__web {
    width: 260px;
    height: 48px;
  }

  .p-cash-top-contact__contact__tel {
    padding: 0;
    margin-right: 24px;
    border: none;
  }
}
