@charset "utf-8";

.p-jys-search-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px -8px;
}

.p-jys-search-head__result {
  font-weight: bold;
}

.p-jys-search-head__sort {
  width: 180px;
  background-color: transparent;
}

.p-jys-search-head__count {
  color: $honne-red-bright;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jys-search-head {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jys-search-head {
    margin: 16px 0;
  }

  .p-jys-search-head__result {
    font-size: $fs-2xl;
  }
}
