@charset "utf-8";

/*doc
---
title: main-img
name: main-img
category: components
---
```html_example
<picture class="c-main-img">
  <img class="c-main-img__img" src="http://placehold.it/500x200">
</picture>
```
*/

.c-main-img__img {
  width: 100%;
  min-width: $breakpoint-sp-min;
  max-height: 400px;
  vertical-align: middle;
  object-fit: cover;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-main-img {
    display: block;
    text-align: center;
  }

  .c-main-img__img {
    width: calc(100vw - 64px);
    margin-top: 24px;
    border-radius: 12px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-main-img__img {
    max-width: $breakpoint-pc-max;
    max-height: initial;
    object-fit: initial;
  }
}
