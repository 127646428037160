@charset "utf-8";

.p-news-ranking {
  background-color: $honne-white;

  .c-articles-box__list {
    padding: 0;
  }
}

.p-news-ranking__title {
  padding: 16px 0;
  margin-bottom: 12px;
  font-size: $fs-lg;
  line-height: $line-height-small;
  border-bottom: 1px solid $honne-gray-300;
}

.p-news-ranking__box-title {
  font-weight: bold;
}

.p-news-ranking__box-text {
  width: calc(100vw - 114px);
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-ranking {
    padding: 0;
  }

  .p-news-ranking__title {
    padding: 16px;
    border-top: 8px solid $honne-blue;
  }

  .p-news-ranking__box-title {
    font-size: $fs-md;
    line-height: $line-height-medium;
    -webkit-line-clamp: 3;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-news-ranking {
    width: 256px;
  }

  .p-news-ranking__box-text {
    width: 174px;
  }
}
