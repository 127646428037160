@charset "utf-8";

/*doc
---
title: review-card
name: review-card
category: components
---
```html_example
<div>
  <h3 class="c-review-card__desc">地元の方向けの施設だと思います。</h3>
  <div class="c-review-card__summary">name1866（女性/40代）</div>
  <div class="c-review-card__detail">
    施設の周辺には高い建物はあったが、密集してないので日当たりが良い。
    北向きにファミリールームがあったが、テラスからの眺めがよくスカイツリーが見える。
    もしかしたら、隅田川か江戸川の花火が見え...
  </div>
  <div class="c-link c-review-card__more">
    <span class="c-link__text">続きを読む</span>
    <i class="c-link__icon c-icon-arrow-right-blue c-icon--small"></i>
  </div>
</div>
```
*/

.c-review-card__desc {
  margin-bottom: 8px;
  font-size: $fs-md;
  font-weight: bold;
  line-height: $line-height5;
  color: $honne-gray-700;
}

.c-review-card__summary {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
  font-size: $fs-xs;
  color: $honne-gray-700;
}

.c-review-card__detail {
  display: -webkit-box;
  overflow: hidden;
  font-size: $fs-md;
  color: $honne-gray-700;
  -webkit-line-clamp: 4;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.c-review-card__more {
  justify-content: flex-end;
  margin-top: 8px;
}
