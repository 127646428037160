@charset "utf-8";

/*doc
---
title: state-map
name: state-map
category: components
---
```html_example
<div class="c-state-map">
  <div class="c-state-map__group c-state-map__group-tohoku c-state-map__group-2col">
    <a class="c-state-map__state" href="#">青森</a>
    <a class="c-state-map__state" href="#">岩手</a>
    <a class="c-state-map__state" href="#">秋田</a>
    <a class="c-state-map__state" href="#">宮城</a>
    <a class="c-state-map__state" href="#">山形</a>
    <a class="c-state-map__state" href="#">福島</a>
  </div>
  <div class="c-state-map__group c-state-map__group-hokaido">
    <a class="c-state-map__state" href="#">北海道</a>
  </div>
  <div class="c-state-map__group c-state-map__group-kantou c-state-map__group-2col">
    <a class="c-state-map__state" href="#">東京</a>
    <a class="c-state-map__state" href="#">神奈川</a>
    <a class="c-state-map__state" href="#">埼玉</a>
    <a class="c-state-map__state" href="#">千葉</a>
    <a class="c-state-map__state" href="#">群馬</a>
    <a class="c-state-map__state" href="#">茨城</a>
    <a class="c-state-map__state" href="#">栃木</a>
    <a class="c-state-map__state" href="#">山梨</a>
  </div>
  <div class="c-state-map__group c-state-map__group-shinetsu-hokuriku">
    <a class="c-state-map__state" href="#">新潟</a>
    <a class="c-state-map__state" href="#">長野</a>
    <a class="c-state-map__state" href="#">富山</a>
    <a class="c-state-map__state" href="#">石川</a>
    <a class="c-state-map__state" href="#">福井</a>
  </div>
  <div class="c-state-map__group c-state-map__group-kansai c-state-map__group-2col">
    <a class="c-state-map__state" href="#">大阪</a>
    <a class="c-state-map__state" href="#">兵庫</a>
    <a class="c-state-map__state" href="#">京都</a>
    <a class="c-state-map__state" href="#">奈良</a>
    <a class="c-state-map__state" href="#">滋賀</a>
    <a class="c-state-map__state" href="#">和歌山</a>
  </div>
  <div class="c-state-map__group c-state-map__group-chugoku">
    <a class="c-state-map__state" href="#">岡山</a>
    <a class="c-state-map__state" href="#">広島</a>
    <a class="c-state-map__state" href="#">山口</a>
    <a class="c-state-map__state" href="#">島根</a>
    <a class="c-state-map__state" href="#">鳥取</a>
  </div>
  <div class="c-state-map__group c-state-map__group-kyushu">
    <a class="c-state-map__state" href="#">福岡</a>
    <a class="c-state-map__state" href="#">佐賀</a>
    <a class="c-state-map__state" href="#">長崎</a>
    <a class="c-state-map__state" href="#">大分</a>
    <a class="c-state-map__state" href="#">熊本</a>
    <a class="c-state-map__state" href="#">宮崎</a>
    <a class="c-state-map__state" href="#">鹿児島</a>
  </div>
  <div class="c-state-map__group c-state-map__group-okinawa">
    <a class="c-state-map__state" href="#">沖縄</a>
  </div>
  <div class="c-state-map__group c-state-map__group-shikoku c-state-map__group-2col">
    <a class="c-state-map__state" href="#">愛媛</a>
    <a class="c-state-map__state" href="#">香川</a>
    <a class="c-state-map__state" href="#">高知</a>
    <a class="c-state-map__state" href="#">徳島</a>
  </div>
  <div class="c-state-map__group c-state-map__group-tokai c-state-map__group-2col">
    <a class="c-state-map__state" href="#">愛知</a>
    <a class="c-state-map__state" href="#">静岡</a>
    <a class="c-state-map__state" href="#">岐阜</a>
    <a class="c-state-map__state" href="#">三重</a>
  </div>
</div>
```
*/

$state-width: 68px;

.c-state-map {
  position: relative;
  width: 100%;
  height: 540px;
  margin: 0 auto 40px;
  background: url("~images/map-japan.svg") no-repeat;
  background-position-x: 40px;
  background-size: contain;
}

.c-state-map__group {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: $state-width;
  background-color: $honne-white;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);

  a:visited {
    color: $honne-gray-700;
  }
}

.c-state-map__state {
  width: $state-width;
  padding: 4px;
  font-size: $fs-xl;
  font-weight: bold;
  color: $honne-gray-700;
  text-align: center;
  border-right: 1px solid $honne-gray-300;
  border-bottom: 1px solid $honne-gray-300;
}

.c-state-map__state--disable {
  color: $honne-white;
  background-color: $honne-gray-400;
}

.c-state-map__group-2col {
  width: $state-width * 2;
}

/* 北海道 */
.c-state-map__group-hokaido {
  top: 20px;
  left: 650px;
}

/* 東北 */
.c-state-map__group-tohoku {
  top: 190px;
  left: 600px;
}

/* 関東 */
.c-state-map__group-kantou {
  top: 330px;
  left: 550px;
}

/* 信越・北陸 */
.c-state-map__group-shinetsu-hokuriku {
  top: 80px;
  left: 400px;
}

/* 関西 */
.c-state-map__group-kansai {
  top: 210px;
  left: 250px;
}

/* 中国 */
.c-state-map__group-chugoku {
  top: 180px;
  left: 165px;
}

/* 九州 */
.c-state-map__group-kyushu {
  top: 140px;
  left: 80px;
}

/* 沖縄 */
.c-state-map__group-okinawa {
  top: 410px;
  left: 40px;
}

/* 四国 */
.c-state-map__group-shikoku {
  top: 470px;
  left: 235px;
}

/* 東海 */
.c-state-map__group-tokai {
  top: 445px;
  left: 390px;
}

@include min-screen(990px) {
  .c-state-map--hidden-pc {
    display: none !important;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-state-map {
    width: 800px;
    height: 540px;
    margin-bottom: 0;
  }
}
