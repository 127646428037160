@charset "utf-8";

// 運営状況
.p-jigyosho-content-line {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.p-jigyosho-content-line__head {
  width: 100%;
  margin-right: 8px;
  font-weight: bold;
}

.p-jigyosho-content-line__content {
  width: 60%;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-content-line__head {
    width: 40%;
  }
}
