@charset "utf-8";

.p-brochure-checkbox {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 32px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.p-brochure-checkbox.col2 .c-checkbox + .c-checkbox {
  margin-top: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochure-checkbox {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .p-brochure-checkbox.col2 > .c-checkbox {
    width: calc(50% - 8px);
    margin: 0 8px 8px 0;
  }
}
