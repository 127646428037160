@charset "utf-8";

.p-ownership-pickup-content {
  padding: 0;
  margin-bottom: 24px;
}

.p-ownership-pickup-content__note {
  margin-top: 8px;
  font-size: $fs-sm;
  color: $honne-gray-500;
}
