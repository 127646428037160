@charset "utf-8";

.p-top-container {
  padding: 0 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-container {
    padding: 0 32px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-container {
    padding: 0;
  }
}
