@charset "utf-8";

.p-cashback-faq {
  background-color: $honne-yellow-white;
}

.p-cashback-faq__inner {
  width: 100%;
  padding: 48px 0;
}

.p-cashback-faq-detail {
  position: relative;
  padding: 0 16px 0 0;
  margin: 24px 0 0;
  overflow: hidden;
  background: $honne-white;
  border-radius: 8px;
}

.p-cashback-faq-detail:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 100%;
  content: "";
  background: $honne-white;
  border-radius: 8px 0 0 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cashback-faq__inner {
    max-width: 1080px;
    padding: 72px 0;
  }

  .p-cashback-faq-detail {
    position: relative;
    padding: 16px 24px;
    margin: 32px 0 0;
    overflow: hidden;
    background: $honne-white;
    border-radius: 8px;
  }

  .p-cashback-faq-detail:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 100%;
    content: "";
    background: $honne-white;
    border-radius: 8px 0 0 8px;
  }
}
