@charset "utf-8";

.p-ownership-box {
  .c-inquiry {
    padding: 0 24px 24px;
    background-color: $honne-white;
  }
}

.p-ownership-box__wrap {
  margin-bottom: 16px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-ownership-box {
    padding: 24px 0;

    .c-inquiry {
      width: calc(100% - 48px);
      padding: 24px;
      margin: 0 24px 24px;
      background-color: $honne-yellow-white;
    }
  }

  .p-ownership-box__wrap {
    width: calc(100vw - 64px);
    max-width: 1080px;
    margin: 0 auto 24px;
  }
}
