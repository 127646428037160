@charset "utf-8";

/*doc
---
title: text-field
name: text-field
category: component-parts
---

```html_example
<div class="c-text-field">
  <input type="text" name="q" id="q" value="" placeholder="施設名を入力してください">
</div>
```
*/

.c-text-field {
  display: block;
  width: 100%;

  input[type="text"],
  input[type="password"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    padding: 12px;
    font-size: $fs-2md;
    color: $honne-gray-700;
    appearance: none;
    background-color: $honne-white;
    border: $honne-gray-400 solid 2px;
    border-radius: 8px;
    outline: none;
    transition: all;
  }

  ::placeholder {
    color: $honne-gray-400 !important;
  }

  input:focus::placeholder,
  textarea:focus::placeholder {
    color: transparent;
  }

  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  textarea:focus {
    border: $honne-blue 2px solid !important;
  }
}
