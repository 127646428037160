@charset "utf-8";

// 入居条件 こだわり条件
.p-jg-condition {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.p-jg-condition__block {
  width: 100%;
  margin-bottom: 8px;
}

.p-jg-condition__note {
  margin-top: 8px;
  font-size: $fs-sm;
  line-height: $line-height8;
  color: $honne-gray-600;
}

.p-jg-condition__accepts {
  display: flex;
}

.p-jg-condition__accept {
  width: 32%;
  padding: 8px 0;
  font-size: $fs-sm;
  border-radius: 20px;
}

.p-jg-condition__accept + .p-jg-condition__accept {
  margin-left: 8px;
}

.p-jg-condition__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.p-jg-condition__tag {
  position: relative;
  display: block;
  width: 13%;
  padding-top: 13%; // make height equal width
  margin-right: 1%;
}

.p-jg-condition__tag__icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 8px;
  left: 0;
}

/* 768px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-jg-condition__tag {
    width: 42px;
    padding-top: 42px; // make height equal width
    margin-right: 4px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jg-condition__block {
    margin-bottom: 0;
  }

  .p-jg-condition__accept {
    font-size: $fs-lg;
  }

  .p-jg-condition__tag {
    width: 42px;
    padding-top: 42px; // make height equal width
    margin-right: 4px;
  }
}
