@charset "utf-8";

.p-jigyosho__title {
  padding: 16px 24px 0;
}

.p-jigyosho__title--pc {
  display: none;
}

.p-jigyosho__subtitle {
  margin-bottom: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho__title--pc {
    display: block;
  }

  .p-jigyosho__title--sp {
    display: none;
  }

  .p-jigyosho__subtitle {
    margin-bottom: 12px;
  }
}
