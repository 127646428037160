@charset "utf-8";

.p-news-head {
  padding: 32px 16px 8px;
}

.p-news-head__logo {
  display: block;
  width: 281px;
  height: 28px;
  margin: 0 auto 16px;
}

.p-news-head__text {
  font-size: $fs-sm;
  color: $honne-gray-500;
  text-align: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-head {
    padding: 32px 0 16px;
  }

  .p-news-head__inner {
    display: flex;
    align-items: center;
    max-width: 1144px;
    padding: 0 32px;
    margin: 0 auto;
  }

  .p-news-head__logo {
    width: 340px;
    height: initial;
    margin: 0 24px 0 0;
  }

  .p-news-head__text {
    font-size: $fs-2md;
    letter-spacing: $ls-large;
  }
}
