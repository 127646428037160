@charset "utf-8";

.c-markup__article {
  .c-mp-guides {
    @include reset-markup();
  }

  .c-mp-guides__title {
    margin: 40px 0 16px;
  }
}
