@charset "utf-8";

$jigyosho-table-th-width-sp: 90px;
$jigyosho-table-th-width-pc: 120px;

.p-jigyosho-table {
  width: 100%;
}

.p-jigyosho-table__th {
  width: $jigyosho-table-th-width-sp;
  padding: 8px;
  font-size: $fs-md;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
  background: $honne-gray-100;
  border: 1px solid $honne-gray-300;
}

.p-jigyosho-table__td {
  width: calc(100% - #{$jigyosho-table-th-width-sp});
  padding: 8px;
  font-size: $fs-md;
  border: 1px solid $honne-gray-300;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-table__th {
    width: $jigyosho-table-th-width-pc;
  }

  .p-jigyosho-table__td {
    width: calc(100% - #{$jigyosho-table-th-width-pc});
  }
}
