@charset "utf-8";

.p-special-feature {
  padding: 0 24px;
  margin-bottom: 32px;
}

.p-special-feature__title {
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: $lh-medium;
  text-align: center;
}

.p-special-feature__title2 {
  margin-bottom: 24px;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $lh-medium;
  text-align: center;
}

.p-special-feature__list {
  border-top: 1px solid $honne-gray-400;
}

.p-special-feature__block {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid $honne-gray-400;
}

.p-special-feature__image {
  width: 140px;
  height: 94px;

  img {
    width: 140px;
    height: 100%;
    object-fit: cover;
  }
}

.p-special-feature__text {
  font-weight: bold;
  line-height: $lh-large;

  & > span {
    font-size: $fs-xl;
    color: $honne-blue;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special-feature {
    margin-bottom: 48px;
  }

  .p-special-feature__title {
    margin-bottom: 8px;
    font-size: $fs-5xl;
  }

  .p-special-feature__title2 {
    font-size: $fs-2xl;
  }

  .p-special-feature__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;
    max-width: 678px;
    margin: 0 auto;
    border-top: none;
  }

  .p-special-feature__block:nth-child(1),
  .p-special-feature__block:nth-child(2) {
    border-top: 1px solid $honne-gray-400;
  }
}
