@charset "utf-8";

/*doc
---
title: column
name: column
category: objects
---

```html_example
<h2>基本系</h2>
<div class="o-column">
  <div class="o-column__main">main</div>
  <div class="o-column__side">side</div>
</div>

<h2>bottomある</h2>
<div class="o-column">
  <div class="o-column__main">main</div>
  <div class="o-column__bottom">bottom</div>
  <div class="o-column__side">side</div>
</div>
```
*/

/* 2カラム用レイアウト */

.o-column {
  display: flex;
  flex-direction: column;
  min-width: $breakpoint-sp-min;
  margin: 0 auto;
}

.o-column__side {
  padding: 0 $sp-page-margin;
  margin-top: 24px;
}

.o-column__side__block--full {
  margin-right: -$sp-page-margin;
  margin-left: -$sp-page-margin;
}

.o-column__side__block + .o-column__side__block {
  margin-top: 16px;
}

.o-column__bottom {
  margin-top: 16px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .o-column__main {
    padding: 24px 32px 0;
  }

  .o-column__side {
    padding: 0 32px;
  }

  .o-column__side__block--full {
    margin-right: 0;
    margin-left: 0;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .o-column {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: $breakpoint-pc-max;
    padding: 24px 32px 0;
  }

  .o-column__main {
    order: 1;
    width: calc(100% - 24px - 256px);
    padding: 0;
  }

  .o-column__side {
    order: 2;
    width: 256px;
    padding: 0;
    margin-top: 0;
    margin-left: 24px;
  }

  .o-column__side__inner {
    position: sticky;
    top: 24px;
    width: 100%;
    transition: padding 0.5s;
  }

  .o-column__bottom {
    order: 3;
    width: 100%;
  }

  .o-column--left {
    & .o-column__main {
      order: 2;
    }

    & .o-column__side {
      order: 1;
      margin-right: 24px;
      margin-left: 0;
    }
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .o-column {
    width: calc(100vw - 64px);
    max-width: $breakpoint-pc-max;
    padding: 24px 0 0;
  }
}
