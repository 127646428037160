@charset "utf-8";

.c-card-review {
  padding: 12px 16px;
  margin: 8px 16px 0;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.c-card-review__top {
  display: flex;
  align-items: center;

  & img {
    margin-right: 8px;
    border: $honne-gray-400 1px solid;
    border-radius: 50%;
  }
}

.c-card-review__title {
  font-size: $fs-md;
  font-weight: bold;
}

.c-card-review__reviewer {
  display: block;
  margin-top: 4px;
  font-size: $fs-2xs;
  font-weight: normal;
  color: $honne-gray-500;
}

.c-card-review__desc {
  font-size: $fs-sm;
  line-height: $lh-small;
  color: $honne-gray-600;
  letter-spacing: $ls-medium;
}

.c-card-review__main {
  margin-top: 8px;
  margin-bottom: -4px;
}

.c-card-review__category {
  font-size: $fs-sm;
  font-weight: bold;
  line-height: $lh-small;
  color: $honne-gray-600;
  letter-spacing: $ls-medium;
}

// min-width: 769px
@include min-screen($breakpoint-pc-small-min) {
  .c-card-review {
    margin: 12px 24px 0;
  }

  .c-card-review__top {
    & img {
      width: 48px;
      height: 48px;
    }
  }
}
