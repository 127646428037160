@charset "utf-8";

.p-top-faq {
  margin: 24px 0 32px;
}

.p-top-faq__title {
  margin-bottom: 16px;
  text-align: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-faq {
    padding: 0;
  }

  .p-top-faq__title {
    margin-bottom: 24px;
  }
}
