@charset "utf-8";

.p-top-qa__title {
  margin-bottom: 16px;
  text-align: center;

  img {
    width: 220px;
    height: 47px;
  }
}

.p-top-qa__desc {
  padding: 0 24px;
  margin-bottom: 16px;
}

/* 481px ~ 768px */
@include screen($breakpoint-tablet-min, $breakpoint-tablet-max) {
  .p-top-qa__desc {
    text-align: center;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-qa__title {
    img {
      width: 240px;
      height: 51px;
    }
  }

  .p-top-qa__desc {
    padding: 0;
  }
}
