@charset "utf-8";

.p-brochures-card {
  padding: 16px 24px;
}

.p-brochures-card__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.p-brochures-card__jigyosho {
  @include typo-sm-xl-md;
}

.p-brochures-card__link {
  font-weight: bold;
  text-decoration: underline;
}

.p-brochures-card__line + .p-brochures-card__line {
  margin-top: 4px;
}

.p-brochures-card__desc {
  @include typo-sm-xl-md;
  margin-right: 16px;
  color: $honne-gray-500;
}

.p-brochures-card__name {
  font-size: $fs-lg;
  font-weight: bold;
}

.p-brochures-card__box {
  margin-top: 12px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochures-card {
    padding: 16px 32px;
  }

  .p-brochures-card__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .p-brochures-card__box {
    margin-bottom: 0;
  }
}
