@charset "utf-8";

/*doc
---
title: form-date
name: form-date
category: components
---
```html_example
<div class="c-form-date">
  <select class="year">
    <option value="1900">1900</option>
    <option value="1901">1901</option>
    <option value="1902" selected="selected">1902</option>
    <option value="1903">1903</option>
  </select>年
  <select class="month">
    <option value="1" selected="selected">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
  </select>月
  <select class="day">
    <option value="1" selected="selected">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
  </select>日
</div>
```
*/

.c-form-date {
  display: flex;
  align-items: center;

  & .year,
  .month,
  .day {
    width: 100%;
    padding: 16px;
    font-weight: bold;
    appearance: none;
    background-color: $honne-white;
    background-image: url("~images/user_inline/icon/arrow-down-blue.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) 50%;
    border: $honne-gray-400 solid 2px;
    border-radius: $radius-size-medium;
    outline: none;
    transition: all;
  }

  & .year {
    flex: 2;
    margin-right: 4px;
  }

  & .month {
    flex: 1;
    margin: 0 4px;
  }

  & .day {
    flex: 1;
    margin: 0 4px;
  }

  & .year:focus,
  .month:focus,
  .day:focus {
    border: $honne-blue 2px solid !important;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-form-date {
    max-width: 450px;

    & .year {
      flex: 1;
      margin-right: 12px;
    }

    & .month {
      margin: 0 12px;
    }

    & .day {
      margin: 0 12px;
    }
  }
}
