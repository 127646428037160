@charset "utf-8";

.c-tooltip {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  }

  &::before {
    bottom: calc(100% + 6px);
    left: 50%;
    width: 0;
    height: 0;
    content: "";
    border-color: $honne-gray-700 transparent transparent transparent;
    border-style: solid;
    border-width: 7px 5px 0 5px;
    transform: translateX(-50%);
  }

  &::after {
    bottom: calc(100% + 13px);
    left: 50%;
    display: block;
    width: 180px;
    padding: 8px 12px;
    font-size: $fs-2xs;
    font-style: normal;
    line-height: $lh-medium;
    color: $honne-white;
    content: attr(data-tooltip);
    background: $honne-gray-700;
    border-radius: 4px;
    transform: translateX(-50%);
  }

  &:hover {
    cursor: pointer;
  }

  &:hover::before,
  &:hover::after {
    visibility: visible;
    opacity: 1;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-tooltip {
    &::after {
      width: auto;
      font-size: $fs-xs;
      white-space: nowrap;
    }
  }
}
