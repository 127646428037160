@charset "utf-8";

/*doc
---
title: icon
name: icon
category: component-parts
---
```html_example
<i class='c-icon-arrow-down'></i>
<i class='c-icon-arrow-left'></i>
<i class='c-icon-arrow-right'></i>
<p style="margin-top:8px;">各種サイズ</p>
<i class='c-icon-home c-icon--x-small'></i>
<i class='c-icon-home c-icon--small'></i>
<i class='c-icon-home c-icon--small-medium'></i>
<i class='c-icon-home'></i>
<i class='c-icon-home c-icon--medium'></i>
<i class='c-icon-home c-icon--large'></i>
<i class='c-icon-home c-icon--xlarge'></i>
<i class='c-icon-home c-icon--xx-large'></i>
```
*/

.c-icon-apply {
  @include icon();
  background-image: url("~images/user_inline/icon/apply.svg");
}

.c-icon-home {
  @include icon();
  background-image: url("~images/user_inline/icon/home.svg");
}

.c-icon-facility-search {
  @include icon();
  background-image: url("~images/user_inline/icon/facility-search.svg");
}

.c-icon-guide {
  @include icon();
  background-image: url("~images/user_inline/icon/guide.svg");
}

.c-icon-news {
  @include icon();
  background-image: url("~images/user_inline/icon/news.svg");
}

.c-icon-arrow-down {
  @include icon();
  background-image: url("~images/user_inline/icon/arrow-down.svg");
}

.c-icon-arrow-left {
  @include icon();
  background-image: url("~images/user_inline/icon/arrow-left.svg");
}

.c-icon-arrow-right {
  @include icon();
  background-image: url("~images/user_inline/icon/arrow-right.svg");
}

.c-icon-arrow-up-blue {
  @include icon();
  background-image: url("~images/user_inline/icon/arrow-up-blue.svg");
}

.c-icon-arrow-down-blue {
  @include icon();
  background-image: url("~images/user_inline/icon/arrow-down-blue.svg");
}

.c-icon-arrow-left-blue {
  @include icon();
  background-image: url("~images/user_inline/icon/arrow-left-blue.svg");
}

.c-icon-arrow-right-blue {
  @include icon();
  background-image: url("~images/user_inline/icon/arrow-right-blue.svg");
}

.c-icon-arrow-right-white {
  @include icon();
  background-image: url("~images/user_inline/icon/arrow-right-white.svg");
}

.c-icon-menus {
  @include icon();
  background-image: url("~images/user_inline/icon/menus.svg");
}

.c-icon-area-pin {
  @include icon();
  background-image: url("~images/user_inline/icon/area-pin.svg");
}

.c-icon-area-pin-black {
  @include icon();
  background-image: url("~images/user_inline/icon/area-pin-black.svg");
}

.c-icon-close {
  @include icon();
  background-image: url("~images/user_inline/icon/close.svg");
}

.c-icon-close-white {
  @include icon();
  background-image: url("~images/user_inline/icon/close-white.svg");
}

.c-icon-round-close {
  @include icon();
  background-image: url("~images/user_inline/icon/round-close.svg");
}

.c-icon-search {
  @include icon();
  background-image: url("~images/user_inline/icon/search.svg");
}

.c-icon-search-white {
  @include icon();
  background-image: url("~images/user_inline/icon/search-white.svg");
}

.c-icon-search-blue {
  @include icon();
  background-image: url("~images/user_inline/icon/search-blue.svg");
}

.c-icon-tel {
  @include icon();
  background-image: url("~images/user_inline/icon/tel.svg");
}

.c-icon-star-dark {
  @include icon();
  background-image: url("~images/user_inline/icon/star-dark.svg");
}

.c-icon-star-dark-gray {
  @include icon();
  background-image: url("~images/user_inline/icon/star-dark-gray.svg");
}

.c-icon-star {
  @include icon();
  background-image: url("~images/user_inline/icon/star.svg");
}

.c-icon-star-half {
  @include icon();
  background-image: url("~images/user_inline/icon/star-half.svg");
}

.c-icon-star-blue {
  @include icon();
  background-image: url("~images/user_inline/icon/star-blue.svg");
}

.c-icon-triangle-yellow {
  @include icon();
  background-image: url("~images/user_inline/icon/triangle-yellow.svg");
}

.c-icon-circle-red {
  @include icon();
  background-image: url("~images/user_inline/icon/circle-red.svg");
}

.c-icon-cross-mark {
  @include icon();
  background-image: url("~images/user_inline/icon/cross-mark.svg");
}

.c-icon-tel-freedial {
  @include icon();
  background-image: url("~images/user_inline/icon/tel-freedial.svg");
}

.c-icon-tel-recommend-check {
  @include icon();
  background-image: url("~images/user_inline/icon/recommend-check.svg");
}

.c-icon-history {
  @include icon();
  background-image: url("~images/user_inline/icon/history.svg");
}

.c-icon-my {
  @include icon();
  background-image: url("~images/user_inline/icon/my.svg");
}

.c-icon-train {
  @include icon();
  background-image: url("~images/user_inline/icon/train.svg");
}

.c-icon-online-check {
  @include icon();
  background-image: url("~images/user_inline/icon/online-check.svg");
}

.c-icon-all-image {
  @include icon();
  background-image: url("~images/user_inline/icon/all-image.svg");
}

.c-icon-add {
  @include icon();
  background-image: url("~images/user_inline/icon/add.svg");
}

.c-icon-delete {
  @include icon();
  background-image: url("~images/user_inline/icon/delete.svg");
}

.c-icon-qa {
  @include icon();
  background-image: url("~images/user_inline/icon/qa.svg");
}

.c-icon-city {
  @include icon();
  background-image: url("~images/user_inline/icon/city.svg");
}

.c-icon-station {
  @include icon();
  background-image: url("~images/user_inline/icon/station.svg");
}

.c-icon-checked {
  @include icon();
  background-image: url("~images/user_inline/icon/checked.svg");
}

.c-icon-info-blue {
  @include icon();
  background-image: url("~images/user_inline/icon/info-blue.svg");
}

.c-icon-info-red {
  @include icon();
  background-image: url("~images/user_inline/icon/info-red.svg");
}

.c-icon-user {
  @include icon();
  background-image: url("~images/user_inline/icon/user.svg");
}

.c-icon-sparkle {
  @include icon();
  background-image: url("~images/user_inline/icon/sparkle.svg");
}

.c-icon-campaign {
  @include icon();
  background-image: url("~images/user_inline/icon/campaign.svg");
}

.c-icon-event {
  @include icon();
  background-image: url("~images/user_inline/icon/event.svg");
}

.c-icon-gift {
  @include icon();
  background-image: url("~images/user_inline/icon/gift.svg");
}

.c-icon-flag {
  @include icon();
  background-image: url("~images/user_inline/icon/flag.svg");
}

.c-icon-notice {
  @include icon();
  background-image: url("~images/user_inline/icon/notice.svg");
}

.c-icon--x-small {
  @include icon(8px, 8px);
}

.c-icon--small {
  @include icon(12px, 12px);
}

.c-icon--small-medium {
  @include icon(14px, 14px);
}

.c-icon--medium {
  @include icon(20px, 20px);
}

.c-icon--large {
  @include icon(24px, 24px);
}

.c-icon--xlarge {
  @include icon(32px, 32px);
}

.c-icon--xx-large {
  @include icon(36px, 36px);
}
