@charset "utf-8";

.p-theme-detail-top {
  background-color: $honne-white;
}

.p-theme-detail-top__img {
  width: 100%;
  height: 140px;
  vertical-align: bottom;
  object-fit: cover;
}

.p-theme-detail-top__inner {
  padding: 24px;
}

.p-theme-detail-top__title {
  margin-bottom: 8px;
  font-size: $fs-sm;
  font-weight: bold;
  color: $honne-gray-500;
}

.p-theme-detail-top__h1 {
  margin-bottom: 8px;
}

.p-theme-detail-top__desc {
  font-size: $fs-sm;
  line-height: $lh-medium;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-theme-detail-top {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 32px;
    overflow: hidden;
    border-radius: 8px;
  }

  .p-theme-detail-top__img {
    width: 312px;
    height: 100%;
  }

  .p-theme-detail-top__inner {
    padding: 32px;
  }

  .p-theme-detail-top__title {
    font-size: $fs-2md;
  }

  .p-theme-detail-top__desc {
    font-size: $fs-2md;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-theme-detail-top {
    margin: 0;
  }
}
