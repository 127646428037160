@charset "utf-8";

/*doc
---
title: text-multiple-line
name: text-multiple-line
category: components
---
```html_example
<p class="c-text-multiple-line">SPの時一行で表示する</p>
<p class="c-text-multiple-line">、pcの時複数行表示する</p>
```
*/

// SPの時一行で表示する
// pcの時複数行表示する

.c-text-multiple-line {
  display: inline;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-text-multiple-line {
    display: block;
  }
}
