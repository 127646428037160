.p-complete-header {
  max-width: 960px;
  padding: 24px 0 0;
}

.p-complete-header__h1 {
  margin-bottom: 16px;
  font-family: $font-family-700bold;
  font-size: $fs-3xl;
  font-weight: $font-weight-700bold;
}

.p-complete-body {
  margin: 0 auto;
  text-align: center;
}

.p-complete-body__title {
  margin-bottom: 8px;
  font-size: $fs-2xl;
  font-weight: bold;
}

.p-complete-body__text {
  margin-bottom: 16px;
  font-size: $fs-md;
  line-height: $line-height3;
  text-align: left;

  br {
    display: none;
  }
}

.p-complete-body__text--bold {
  font-weight: bold;
}

.p-complete-body__link {
  width: 250px;
  margin-right: auto;
  margin-left: auto;
}

.p-complete-body__contact > .c-contact {
  padding-right: 0;
  padding-left: 0;
}

.p-complete-body__img {
  width: 180px;
}

.p-complete-body__contact {
  margin: 16px 0 40px;
}

.p-complete-body__button {
  height: 48px;
  margin-top: 24px;
  line-height: $line-height-px5;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-complete-header {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    margin: 0 auto;
  }

  .p-complete-body {
    max-width: 960px;
    padding: 40px;
    border-radius: $radius-size-medium;
    box-shadow: $shadow-size-medium;
  }

  .p-complete-body__img {
    width: 200px;
  }

  .p-complete-body__title {
    font-size: $fs-2xl;
  }

  .p-complete-body__text {
    margin-bottom: 24px;
    font-size: $fs-lg;
    line-height: $line-height3;
    text-align: center;

    br {
      display: block;
    }
  }

  .p-complete-body__contact {
    max-width: 400px;
    margin: 0 auto 40px;
  }

  .p-complete-body__button {
    max-width: 400px;
    margin: 24px auto;
  }
}
