@charset "utf-8";

.c-markup__article {
  .c-article-whitepaper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 16px;
    margin-top: 24px;
    overflow: hidden;
    background: url("~images/whitepaper/bg.png") no-repeat;
    background-size: cover;
    border-radius: 8px;
  }

  .c-article-whitepaper__image {
    width: 200px;
    margin-bottom: 0;
  }

  .c-article-whitepaper__title {
    width: 290px;
    margin-bottom: 12px;
    font-family: $font-family-700bold;
    font-size: $font-size-x-large;
    font-weight: 700;
    color: $honne-blue;
    text-align: center;
  }

  .c-article-whitepaper__caption {
    margin-bottom: 4px;
    font-size: $font-size-x-medium;
    font-weight: bold;
    color: $honne-blue;
  }

  .c-article-whitepaper__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 80px);
    max-width: 440px;
    margin: 0 auto;
    font-size: $font-size-x-medium;
    font-weight: bold;
    line-height: $line-height-px5;
    color: $honne-white;
    text-align: center;
    text-decoration: none;
    background-color: $honne-yellow;
    border-radius: 8px;

    &:visited {
      color: $honne-white;
    }
  }

  .c-article-whitepaper__button-text {
    display: inline-block;
    margin-right: 4px;
    text-shadow: color-darken($honne-yellow, 12%) 1px 0 10px;
  }

  /* 769px ~ */
  @include min-screen($breakpoint-tablet-min) {
    .c-article-whitepaper {
      padding: 16px 16px 24px;
      background: url("~images/whitepaper/bg-wide.png") no-repeat;
    }

    .c-article-whitepaper__image {
      width: 110px;
      margin-right: 8px;
      margin-bottom: 8px;
      margin-left: 0;
    }

    .c-article-whitepaper__title {
      margin-bottom: 8px;
      text-align: left;
    }
  }

  /* 1080px ~ */
  @include min-screen($breakpoint-pc-max) {
    .c-article-whitepaper {
      padding: 8px 12px;
    }

    .c-article-whitepaper__image {
      margin-bottom: 0;
    }

    .c-article-whitepaper__title {
      margin-bottom: 0;
    }

    .c-article-whitepaper__button {
      width: calc(100% - 428px);
      max-width: 240px;
    }
  }
}
