@charset "utf-8";

.p-brochures__note {
  @include typo-sm-xl-md;
  margin-top: 12px;
  line-height: $lh-medium;
  color: $honne-gray-600;
}

.p-brochures__visit {
  position: relative;
  padding: 12px;
  margin: 12px;
  font-weight: bold;
  text-align: center;
  background-color: $honne-white;
  border-radius: 12px;

  &:after {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: 0 auto;
    content: "";
    border-color: $honne-white transparent transparent transparent;
    border-style: solid;
    border-width: 8px;
  }
}

.p-brochures__preferred-date {
  margin-top: 16px;
}

.p-brochures__tel {
  font-size: $fs-lg;
  font-weight: bold;
}

.p-brochures__notice {
  font-size: $fs-xs;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochures__visit {
    margin: 0 0 24px;
    font-size: $fs-lg;
  }
}
