@charset "utf-8";

.p-special-top {
  position: relative;
  padding: 70px 0 32px;
  background-image: url("~images/lp/special/kv_sp.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.p-special-top__main {
  padding: 0 24px;
}

.p-special-top__caption {
  margin-bottom: 8px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $honne-white;
}

.p-special-top__title {
  margin-top: 82px;
  margin-bottom: 8px;
  font-size: $font-size-big2;
  font-weight: bold;
  line-height: $line-height4;
  color: $honne-white;
  text-shadow: 0 0 25px $honne-gray-700;
  letter-spacing: $letter-spacing-px1;
}

.p-special-top__action {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: $lh-large;
}

.p-special-top__action__image {
  width: 95px;
  margin-right: 8px;
  margin-bottom: 16px;

  img {
    width: 95px;
    height: 100%;
  }
}

.p-special-top__action__text {
  margin-bottom: 16px;
  font-size: $fs-sm;
  font-weight: bold;
  line-height: $line-height9;
  color: $honne-white;
  letter-spacing: $letter-spacing-px2;
}

.p-special-top__action__wrap {
  width: calc(100vw - 48px);
  padding: 12px 24px;
  margin: 0 auto 16px;
  color: $honne-white;
  text-align: center;
  background-color: $honne-red-bright;
  border-radius: 12px;
}

.p-special-top__action__link {
  color: $honne-white !important;
}

.p-special-top__action__name {
  font-size: $fs-2md;
  font-weight: bold;
  line-height: $lh-small;
  letter-spacing: $letter-spacing-px2;
}

.p-special-top__action__tel {
  font-size: $font-size-big1;
  font-weight: bold;
  line-height: initial;
  letter-spacing: $letter-spacing-px2;

  &::before {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    content: "";
    background-image: url("~images/lp/special/tel_w.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.p-special-top__action__time {
  font-size: $fs-2xs;
}

.p-special-top__action__button {
  width: calc(100vw - 48px);
  height: 48px;
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-red-bright;
  background-color: $honne-yellow-white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

@include link-color("p-special-top__action__button", $honne-red-bright);

.p-special-top__note {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px 24px;
  margin-top: auto;
  font-size: $fs-3xs;
  color: $honne-white;
  letter-spacing: $letter-spacing-px2;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special {
    padding-bottom: 64px;
    background-color: $honne-blue-bright;
  }

  .p-special-top {
    position: initial;
    padding: 100px 0 92px;
    background-image: url("~images/lp/special/kv.jpg");
    background-repeat: no-repeat;
    background-position: initial;
    background-size: cover;
  }

  .p-special-top__caption {
    margin-bottom: 16px;
    font-size: $fs-xl;
    line-height: initial;
    text-align: center;
    text-shadow: 0 0 35px $honne-gray-700;
  }

  .p-special-top__title {
    margin-top: 168px;
    margin-bottom: 8px;
    font-size: $font-size-big6;
    text-align: center;
    text-shadow: 0 0 35px $honne-gray-700;
    letter-spacing: $letter-spacing-px11;

    br {
      display: none;
    }
  }

  .p-special-top__action {
    justify-content: space-between;
    width: 1080px;
    padding: 16px 24px;
    margin: 0 auto 12px;
    background-color: $honne-white;
    border-radius: 12px;
  }

  .p-special-top__action__image {
    width: 130px;
    margin-right: initial;
    margin-bottom: initial;

    img {
      width: 130px;
      height: 100%;
    }
  }

  .p-special-top__action__text {
    margin-bottom: initial;
    font-size: $fs-lg;
    color: initial;
    letter-spacing: $letter-spacing-px5;
  }

  .p-special-top__action__wrap {
    width: initial;
    padding: 0 24px 0 0;
    margin: initial;
    color: initial;
    background-color: initial;
    border-right: 2px solid $honne-gray-100;
    border-radius: initial;
    box-shadow: initial;
  }

  .p-special-top__action__link {
    color: initial !important;
    pointer-events: none;
  }

  .p-special-top__action__name {
    display: none;
  }

  .p-special-top__action__tel {
    margin-bottom: 4px;
    font-size: $font-size-big3;
    line-height: initial;
    letter-spacing: $letter-spacing-px2;

    &::before {
      background-image: url("~images/lp/special/tel.png");
    }
  }

  .p-special-top__action__time {
    font-size: $fs-xs;
    font-weight: normal;
    text-align: center;
  }

  .p-special-top__action__button {
    width: 200px;
    height: 64px;
    font-size: $fs-lg;
    color: $honne-white;
    background-color: $honne-red-bright;
  }

  @include link-color("p-special-top__action__button", $honne-white);

  .p-special-top__note {
    position: initial;
    bottom: initial;
    left: initial;
    padding: initial;
    font-size: $fs-sm;
    text-align: center;
  }
}
