@charset "utf-8";

.c-markup__article {
  .c-mp-jigyosho {
    @include reset-markup();

    .c-guide-jigyosho__title {
      font-weight: bold;
    }
  }
}
