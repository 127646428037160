@charset "utf-8";

@include min-screen($breakpoint-pc-max) {
  .p-top-header {
    .c-hover-menu {
      top: 80px;
      width: calc(100vw - 32px);
      max-width: 1300px;
    }

    .c-hover-menu__content {
      border-radius: 24px;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
    }
  }
}
