@charset "utf-8";

.p-regist-enterprise-section__inner {
  margin: 0 auto;
}

.p-regist-enterprise-section--worry {
  padding: 40px 24px;
  margin-bottom: 40px;
  background: $honne-gray-50;
}

.p-regist-enterprise-section--feature {
  padding: 40px 24px;
}

.p-regist-enterprise-section--form {
  padding: 38px 24px 40px;
  background: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-enterprise-section__inner {
    max-width: 1080px;
  }

  .p-regist-enterprise-section--worry {
    padding: 64px 32px 16px;
    margin-bottom: 80px;
  }

  .p-regist-enterprise-section--feature {
    padding: 64px 32px;
  }

  .p-regist-enterprise-section--form {
    padding: 64px 32px;
  }

  .p-regist-enterprise-section__pc-none {
    display: none;
  }
}
