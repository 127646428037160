@charset "utf-8";

.c-search-tel__text {
  margin-bottom: 12px;
  font-size: $fs-xl;
  font-weight: bold;
}

.c-search-tel__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
