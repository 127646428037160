@charset "utf-8";

.p-brochure-dropdown {
  margin-top: 16px;
  margin-bottom: 16px;
}

.p-brochure-dropdown__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.p-brochure-dropdown__title {
  margin-right: 6px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $honne-blue-lighten;
}

.p-brochure-dropdown__text {
  margin-top: 12px;
}
