@charset "utf-8";

.p-line-hero {
  height: 375px;
  min-height: 300px;
  max-height: 400px;
  color: $honne-yellow-white;
}

.p-line-hero__title {
  margin-bottom: 8px;
  font-size: $fs-5xl;
  font-weight: bold;
  text-align: center;
}

.p-line-hero__desc {
  margin-bottom: 24px;
  font-size: $fs-md;
  font-weight: bold;
  line-height: $line-height-medium;
  text-align: center;
}

.p-line-hero__check {
  margin-bottom: 4px;
}

.p-line-hero__check__text {
  margin-left: 0;
  color: $honne-white;
}

.p-line-hero__rule {
  margin-bottom: 24px;
  font-size: $fs-2xs;
  color: $honne-white;

  a,
  a:visited {
    color: $honne-white;
  }
}

.p-line-hero__error {
  display: none;
  width: 100%;
  margin-top: -8px;
  margin-bottom: 16px;
  font-size: $fs-sm;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-line-hero {
    height: 526px;
    max-height: 526px;
  }

  .p-line-hero__title {
    margin-bottom: 16px;
    font-size: $font-size-big5;
    line-height: $line-height-base;
  }

  .p-line-hero__desc {
    font-size: $fs-xl;
    line-height: $line-height9;
  }

  .p-line-hero__check {
    margin-bottom: 8px;
  }

  .p-line-hero__check__text {
    font-size: $fs-lg;
    color: $honne-white;
  }

  .p-line-hero__rule {
    font-size: $fs-sm;
  }

  .p-line-hero__button {
    width: 400px;
    font-size: $fs-2xl;
  }

  .p-line-hero__error {
    width: 400px;
  }
}
