@charset "utf-8";

.p-special-action__image {
  img {
    width: 100%;
    height: 100%;
  }
}

.p-special-action__title {
  padding: 0 24px;
  margin-bottom: 16px;
  font-size: $fs-xl;
  line-height: $line-height10;
  text-align: center;
  letter-spacing: $letter-spacing-px4;

  span {
    color: $honne-blue-lighten;
  }

  br {
    display: none;
  }
}

.p-special-action__box {
  margin: 0 auto 32px;
}

.p-special-action__box__wrap {
  width: calc(100vw - 48px);
  margin: 0 auto;

  &:last-child {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

.p-special__action__link {
  color: $honne-white !important;
}

.p-special-action__box__text {
  width: calc(100vw - 154px);
  letter-spacing: $letter-spacing-px2;

  br {
    display: none;
  }
}

.p-special-action__telbutton {
  padding: 12px 24px;
  margin-bottom: 24px;
  color: $honne-white;
  text-align: center;
  background-color: $honne-red-bright;
  border-radius: 12px;

  .p-special-action__box__text {
    margin: 0 auto;
  }
}

.p-special-action__box__tel {
  font-size: $font-size-big1;
  font-weight: bold;
  letter-spacing: $letter-spacing-px2;

  &::before {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    content: "";
    background-image: url("~images/lp/special/tel_w.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.p-special-action__box__time {
  font-size: $fs-2xs;
}

.p-special-action__box__image {
  width: 90px;

  img {
    width: 90px;
    height: 100%;
  }
}

.p-special-action__box__button {
  width: calc(100vw - 48px);
  margin-top: 16px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-red-bright;
  background-color: $honne-yellow-white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

@include link-color("p-special-action__box__button", $honne-red-bright);

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special-action {
    padding-top: 24px;
    margin-bottom: 48px;
  }

  .p-special-action__title {
    padding: 0;
    margin-bottom: 24px;
    font-size: $font-size-big1;
    line-height: $line-height10;
    letter-spacing: $letter-spacing-px4;

    br {
      display: block;
    }
  }

  .p-special-action__box {
    width: 952px;
    padding: 24px 40px;
    margin: 0 auto 48px;
    background-color: $honne-yellow-white;
    border-radius: 12px;
  }

  .p-special__action__link {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    color: initial !important;
    pointer-events: none;
  }

  .p-special-action__box__text {
    width: initial;
    font-size: $fs-xl;
    font-weight: bold;

    br {
      display: block;
    }
  }

  .p-special-action__telbutton {
    width: initial;
    padding: initial;
    margin: initial;
    color: initial;
    text-align: initial;
    background-color: initial;
    border-radius: initial;
    box-shadow: initial;

    .p-special-action__box__text {
      margin: initial;
    }
  }

  .p-special-action__box__wrap {
    flex-wrap: initial;
    width: initial;
    margin: initial;

    &:first-child {
      padding-bottom: 16px;
      margin-bottom: 24px;
      border-bottom: 2px solid $honne-gray-300;
    }
  }

  .p-special-action__box__tel {
    font-size: $font-size-big5;

    &::before {
      display: inline-block;
      width: 38px;
      height: 38px;
      margin-right: 4px;
      content: "";
      background-image: url("~images/lp/special/tel.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .p-special-action__box__image {
    width: 160px;

    img {
      width: 100%;
    }
  }

  .p-special-action__box__button {
    width: 364px;
    padding: 16px 12px;
    margin-top: initial;
    font-size: $fs-2xl;
    color: $honne-white;
    background-color: $honne-red-bright;
  }

  @include link-color("p-special-action__box__button", $honne-white);

  .p-special-action__box__time {
    font-size: $fs-md;
  }
}
