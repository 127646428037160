@charset "utf-8";

.p-sitemap-column {
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-sitemap-column {
    margin-top: 32px;
  }
}
