@charset "utf-8";

/*doc
---
title: guide-small
name: guide-small
category: components
---

```html_example
<a title="介護付き有料老人ホームとは｜費用・入居条件・サービスなど基本情報をご紹介" class="c-guide-small" href="http://localhost:5000/guide/home/type/chargedwithcare">
  <img class="c-guide-small__image" src="http://placehold.it/72x48">
  <div class="c-guide-small__main">
    <div class="c-guide-small__title">関連ガイド</div>
    <div class="c-guide-small__text">介護付き有料老人ホームとは｜費用・入居条件・サービスなど基本情報をご紹介</div>
  </div>
</a>
```
*/

.c-guide-small {
  display: flex;
  padding: 16px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.c-guide-small__image {
  width: 64px;
  min-width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 4px;
}

.c-guide-small__main {
  margin-left: 16px;
}

.c-guide-small__title {
  margin-bottom: 4px;
  font-size: $fs-sm;
  font-weight: bold;
  color: $honne-gray-500;
}

.c-guide-small__text {
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-blue;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-guide-small__image {
    width: 72px;
    min-width: 72px;
    height: 72px;
  }

  .c-guide-small__text {
    font-size: $fs-lg;
  }
}
