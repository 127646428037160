@charset "utf-8";

.c-markup__article {
  .c-mp-question {
    @include reset-markup();

    .c-question {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      margin: 32px 0;
      background-color: $honne-yellow-white;
      border-bottom: 0;
      border-radius: 12px;
    }

    .c-question__title {
      font-size: $fs-md;
    }

    .c-question__title__q {
      padding: 4px 8px;
      margin-right: 8px;
      font-size: $fs-lg;
      border-radius: 10px 10px 0 10px;
    }

    .c-question__main {
      padding-left: 40px;
    }

    .c-question-editor__photo {
      margin-right: 12px;
    }
  }

  .c-mp-question__category {
    margin-top: 8px;
    margin-bottom: 0;
  }

  .c-mp-question__editor__photo {
    width: 36px;
    height: 36px;
  }

  /* 769px ~ */
  @include min-screen($breakpoint-pc-small-min) {
    .c-mp-question {
      .c-question {
        padding: 16px;
      }

      .c-question__title {
        font-size: $fs-2md;
      }
    }

    .c-mp-question__category {
      margin-top: 4px;
    }
  }
}
