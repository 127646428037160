@charset "utf-8";

@mixin reset-markup() {
  font-size: $fs-md;
  line-height: $lh-small;

  a {
    text-decoration: none;
  }

  p {
    margin: 0;
  }

  img {
    margin: 0;
  }

  ul,
  ol {
    margin-left: 0;
    line-height: $lh-small;
  }

  ul li {
    list-style: none;
  }
}
