.p-lp-online-action__title {
  margin-bottom: 8px;
  font-size: $fs-xl;
}

.p-lp-online-action__button {
  width: calc(100vw - 96px);
  max-width: 400px;
  margin: 0 auto 8px;

  a {
    width: 100%;
  }
}

.p-lp-online-action__text {
  margin-bottom: 16px;
  font-size: $fs-lg;
}

.p-lp-online-action__tel {
  font-size: $fs-4xl;
  font-weight: bold;
  color: $honne-white;
  text-align: center;
  letter-spacing: $letter-spacing-px2;

  a {
    color: $honne-white;
  }
}

.p-lp-online-action__time {
  display: block;
  font-size: $fs-2xs;
  font-weight: normal;
}

@include min-screen($breakpoint-pc-small-min) {
  .p-lp-online-action__title {
    text-align: center;
  }

  .p-lp-online-action__text {
    text-align: center;
  }

  .p-lp-online-action__tel {
    text-align: center;
  }
}

@include min-screen($breakpoint-pc-max) {
  .p-lp-online-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .p-lp-online-action__title {
    font-size: $fs-4xl;
    text-align: left;
  }

  .p-lp-online-action__text {
    text-align: left;
  }

  .p-lp-online-action__button {
    margin-bottom: 8px;

    a {
      width: initial;
      padding: 16px 48px;
    }
  }

  .p-lp-online-action__tel {
    display: flex;
    align-items: center;
  }

  .p-lp-online-action__time {
    display: inline-block;
    margin-left: 8px;
  }
}
