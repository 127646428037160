@charset "utf-8";

// ジョブメドレー問い合わせ
.c-jg-jm {
  margin: 24px 0 0;
  text-align: center;

  &__logo {
    display: none;
  }

  &__link {
    display: block;
    padding: 8px 0;
    font-size: $fs-sm;
    font-weight: bold;
    color: $honne-jm-blue;
    background-color: $honne-white;
    border: solid 2px $honne-jm-blue;
    border-radius: 8px;

    &:visited {
      color: $honne-jm-blue;
    }
  }

  &__text-br {
    display: none;
  }

  &__text--strong {
    font-size: $fs-lg;
  }

  &__footer {
    margin-top: 8px;
    font-size: $fs-2xs;
    font-weight: bold;
    color: $honne-gray-500;
  }
}

/* 990px ~ */
@include min-screen(990px) {
  .c-jg-jm {
    margin: 0;

    &__logo {
      display: block;
      margin: 0 auto 8px;
    }

    &__link {
      padding: 16px;
    }

    &__text-br {
      display: block;
    }
  }
}
