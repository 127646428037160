@charset "utf-8";

.p-news-facility {
  margin-top: 48px;
}

.p-news-facility__title {
  margin-bottom: 8px;
  font-size: $fs-lg;
}
