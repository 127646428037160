@charset "utf-8";

/*doc
---
title: form-radio
name: form-radio
category: components
---
```html_example
<label class="c-form-radio">
  <input type="radio" value="1" checked="checked">
  <span class="c-form-radio__mimic"></span>
  <span class="c-form-radio__text">はい</span>
</label>
```
*/

$width: 16px;
$height: 16px;

.c-form-radio {
  display: flex;
  align-items: center;

  input[type="radio"] {
    display: none;
    appearance: none;
  }

  &__mimic {
    position: relative;
    width: $width;
    height: $height;
    background: $honne-white;
    border: 2px solid $honne-gray-400;
    border-radius: 100%;
  }

  input[type="radio"]:checked + &__mimic {
    border: none;
  }

  input[type="radio"]:checked + &__mimic::before {
    display: block;
    width: $width;
    height: $height;
    content: "";
    background: $honne-white;
    border: 2px solid $honne-gray-400;
    border-radius: 100%;
  }

  input[type="radio"]:checked + &__mimic::after {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    content: "";
    background: $honne-red-bright;
    border-radius: 50%;
  }

  &__text {
    margin-left: 8px;
    font-size: $fs-lg;
    font-weight: bold;
  }
}
