@charset "utf-8";

.p-ranking-jigyosho__title {
  padding: 24px;
  text-align: center;
}

.p-ranking-jigyosho__cards .c-card {
  position: relative;
}

.p-ranking-jigyosho__head {
  padding-right: 84px;
}

.p-ranking-jigyosho__badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $z-index-1;
  width: 52px;
  height: 68px;
  margin-right: 16px;
  background-size: cover;

  &.rank1 {
    background-image: url("images/ranking/01.png");
  }

  &.rank2 {
    background-image: url("images/ranking/02.png");
  }

  &.rank3 {
    background-image: url("images/ranking/03.png");
  }

  &.rank4 {
    background-image: url("images/ranking/04.png");
  }

  &.rank5 {
    background-image: url("images/ranking/05.png");
  }

  &.rank6 {
    background-image: url("images/ranking/06.png");
  }

  &.rank7 {
    background-image: url("images/ranking/07.png");
  }

  &.rank8 {
    background-image: url("images/ranking/08.png");
  }

  &.rank9 {
    background-image: url("images/ranking/09.png");
  }

  &.rank10 {
    background-image: url("images/ranking/10.png");
  }
}

.p-ranking-jigyosho__bottom {
  display: flex;
  padding: 0 24px;
}

.p-ranking-jigyosho__bottom__link {
  display: inline-flex;
  gap: 8px;
  width: auto;
  margin: 24px auto;
  line-height: $lh-small;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-ranking-jigyosho__head {
    padding-right: 116px;
  }

  .p-ranking-jigyosho__badge {
    width: 68px;
    height: 86px;
    margin-right: 24px;
  }
}
