@charset "utf-8";
/*doc
---
title: side-contact-small
name: side-contact-small
category: components
---
```html_example
<div class="c-side-contact-small" style="width: 256px;">
  <div class="c-side-contact-small__block">
    <p class="c-side-contact-small__title">
      <img src="http://placehold.it/44x52" class="c-side-contact-small__title__image">
      <span><span class="c-side-contact-small__title--small">老人ホーム探しなら</span><br>介護のほんね</span>
    </p>
    <p class="c-side-contact-small__desc">入居相談員が無料でフルサポート！</p>
    <a class="c-inquiry-tel c-side-contact-small__tel" href="#">
      <div class="c-inquiry-tel__top">
        <i class="c-icon c-icon-tel-freedial c-inquiry-tel__icon"></i>
        <span>0120-002718</span>
      </div>
      <p class="c-inquiry-tel__desc">
        営業時間：<span class="c-inquiry-tel__desc--bold">9:00〜18:00</span>
      </p>
    </a>
  </div>
  <div class="c-side-contact-small__bottom">
    <a class="c-button c-button--large c-button--secondary" href="#">WEBでお問い合わせ</a>
  </div>
</div>
```
*/

.c-side-contact-small {
  background-color: $honne-white;
  border: 1px solid $honne-blue;
  border-radius: 8px;
}

.c-side-contact-small__block {
  padding: 12px 12px 0;
}

.c-side-contact-small__title {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  font-size: $fs-3xl;
  font-weight: bold;
  color: $honne-blue;
  text-align: left;
}

.c-side-contact-small__title--small {
  font-size: $fs-lg;
}

.c-side-contact-small__title__image {
  display: block;
  margin-right: 8px;
}

.c-side-contact-small__desc {
  font-size: $fs-sm;
  font-weight: bold;
  color: $honne-gray-600;
  text-align: center;
}

.c-side-contact-small__tel {
  padding: 0;
  border: none;
}

.c-side-contact-small__bottom {
  padding: 8px 16px 16px;
}
