@charset "utf-8";

// 空室情報

.c-jg-price__line {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.c-jg-price__line__item {
  display: flex;
  align-items: center;
}

.c-jg-price__line__item + .c-jg-price__line__item {
  margin-top: 8px;
}

.c-jg-price__line__text {
  margin-left: 8px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $honne-gray-600;
}

.c-jg-price__line__text-strong {
  font-size: $fs-2xl;
  color: $honne-red-bright;
}

.c-price__availability-request {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 16px;
  margin: 12px 0 auto 0;
}

a.c-price__availability-request {
  box-sizing: border-box;
  background: $honne-white;
  border: 2px solid $honne-blue;
  border-radius: 8px;
}

.c-price__availability-request-label {
  margin-right: 8px;
  font-size: $fs-xs;
  font-weight: bold;
  line-height: $line-height-x-small;
  color: $honne-blue;
  letter-spacing: $letter-spacing-px1;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-jg-price__line {
    flex-direction: row;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .c-jg-price__line__item + .c-jg-price__line__item {
    margin-top: 0;
    margin-left: 8px;
  }

  .c-price__availability-request {
    width: auto;
    margin: 0 0 0 auto;
  }
}
