@charset "utf-8";

.p-jigyosho-photos {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $jigyosho-image-modal;
  display: none;
  overflow-y: scroll;
  color: $honne-white;
  background-color: $honne-gray-700;
  @include scrollbar-none;
}

.p-jigyosho-photos__head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.p-jigyosho-photos__close {
  width: 32px;
  height: 32px;
  padding-top: 48px;
  padding-right: 66px;
  margin-left: auto;
  cursor: pointer;
  background: url("~images/icon/close-white.svg") no-repeat center;
  background-size: 16px 16px;
}

.p-jigyosho-photos__category {
  display: flex;
  padding: 16px 8px;
  overflow-x: scroll;
  white-space: nowrap;
  @include scrollbar-none;
}

.p-jigyosho-photos__category__label {
  padding: 4px 12px;
  font-size: $fs-sm;
  cursor: pointer;
  border: 1px solid $honne-white;
  border-radius: 32px;

  &.selected {
    color: $honne-gray-700;
    background-color: $honne-white;
  }
}

.p-jigyosho-photos__category__label + .p-jigyosho-photos__category__label {
  margin-left: 8px;
}

.p-jigyosho-photos__main {
  display: flex;
  align-items: center;
  height: calc(100vh - 200px);
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  @include scrollbar-none;
}

.p-jigyosho-photos__main__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100vw;
  height: 100%;
  scroll-snap-align: start;
}

.p-jigyosho-photos__img {
  position: relative;
  width: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.p-jigyosho-photos__img__prev {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20%;
  height: 20%;
  cursor: pointer;
  transform: translateY(-50%);

  &::before {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 24px;
    height: 24px;
    content: "";
    background: url("~images/icon/arrow-left-white.svg") no-repeat center;
    filter: drop-shadow(0 0 4px #000);
    transform: translateY(-50%);
  }
}

.p-jigyosho-photos__img__next {
  position: absolute;
  top: 50%;
  right: 0;
  width: 20%;
  height: 20%;
  cursor: pointer;
  transform: translateY(-50%);

  &::before {
    position: absolute;
    top: 50%;
    right: 8px;
    width: 24px;
    height: 24px;
    content: "";
    background: url("~images/icon/arrow-right-white.svg") no-repeat center;
    filter: drop-shadow(0 0 4px #000);
    transform: translateY(-50%);
  }
}

.p-jigyosho-photos__desc {
  padding: 16px 24px;
}

.p-jigyosho-photos__desc__text {
  padding-bottom: 12px;
  border-bottom: 1px solid $honne-white;
}

.p-jigyosho-photos__desc__comment {
  margin-top: 12px;
  font-size: $fs-xs;
  color: $honne-gray-600;
}

.p-jigyosho-photos__bottom {
  display: flex;
  flex-shrink: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  @include scrollbar-none;
}

.p-jigyosho-photos__bottom__img {
  position: relative;
  width: 90px;
  min-width: 90px;
  height: 90px;
  padding: 1px 1px 0;

  & img {
    width: 90px;
    height: 90px;
    cursor: pointer;
    object-fit: cover;
  }

  &.focus::before {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    left: 0;
    content: "";
    border: 3px solid $honne-blue;
  }
}

.p-jigyosho-photos__close-pc {
  display: none;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-photos__head {
    display: none;
  }

  .p-jigyosho-photos__category {
    padding: 54px 32px 24px;
  }

  .p-jigyosho-photos__main {
    height: calc(100vh - 246px);
  }

  .p-jigyosho-photos__main__box {
    flex-direction: row;
    padding: 0 32px;
  }

  .p-jigyosho-photos__bottom {
    padding: 0 32px 16px;
    margin-top: 32px;
  }

  .p-jigyosho-photos__close-pc {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 32px;
    height: 32px;
    padding-top: 64px;
    padding-right: 64px;
    margin-left: auto;
    cursor: pointer;
    background: url("~images/icon/close-white.svg") no-repeat center;
    background-size: 16px 16px;
  }
}
