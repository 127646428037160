@charset "utf-8";

.p-line {
  min-width: $breakpoint-sp-min;
  background-color: $honne-yellow-white;
}

.p-line__inner {
  max-width: $breakpoint-pc-max;
  padding: 40px $sp-page-margin;
  margin: 0 auto;
  color: $honne-gray-700;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-line__inner {
    max-width: $breakpoint-pc-max;
    padding: 40px 0 80px;
    margin: 0 auto;
    color: $honne-gray-700;
  }
}
