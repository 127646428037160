@charset "utf-8";

.p-brochure-form__field {
  margin-top: 8px;
}

.p-brochure-form__line {
  display: flex;
  margin-top: -8px;
  margin-bottom: -8px;
}

.p-brochure-form__line__state {
  flex: 1;
  min-width: 120px;
}

.p-brochure-form__line__city {
  flex: 1;
  margin-left: 8px;
}

.p-brochure-form__legal {
  margin-top: 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochure-form__line {
    margin-top: 0;
    margin-bottom: 0;
  }

  .p-brochure-form__line__city {
    flex: 1;
    margin-left: 32px;
  }
}
