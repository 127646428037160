@charset "utf-8";

.p-consultant-voice {
  margin-bottom: 24px;
}

.p-consultant-voice__title {
  margin-bottom: 24px;
  font-size: $fs-2xl;
  text-align: center;
  letter-spacing: $ls-medium;
}

.p-consultant-voice__inner {
  width: 100%;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-consultant-voice {
    margin-bottom: 40px;
  }

  .p-consultant-voice__title {
    font-size: $fs-5xl;
    letter-spacing: $ls-large;
  }

  .p-consultant-voice__inner {
    margin-bottom: 32px;
  }
}
