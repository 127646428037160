@charset "utf-8";

.c-card-event {
  padding: 8px 16px;
}

.c-car-event__block + .c-car-event__block {
  margin-top: 12px;
}

.c-card-event__label {
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  width: 144px;
  margin-bottom: 8px;
}

.c-card-event__desc + .c-card-event__desc {
  margin-top: 8px;
}

.c-card-event__title {
  font-weight: bold;
}

.c-card-event__text {
  font-size: $fs-sm;
}

.c-card-event__link {
  font-size: $fs-sm;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-card-event {
    padding: 8px 24px;
    border-bottom: 1px solid $honne-gray-300;
  }

  .c-car-event__block {
    display: flex;
    gap: 12px;
  }

  .c-car-event__block + .c-car-event__block {
    margin-top: 16px;
  }
}
