@charset "utf-8";

.c-price {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: baseline;
}

.c-price__old {
  font-size: $fs-xs;
  color: $honne-gray-600;
  text-decoration: line-through;
}

.c-price__new {
  display: flex;
  align-items: baseline;
  color: $honne-red-bright;
}

.c-price__number {
  font-size: $fs-lg;
  font-weight: bold;
}

.c-price__text {
  font-size: $fs-md;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-price__number {
    font-size: $fs-xl;
  }
}
