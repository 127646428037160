.p-ownership-head__h1 {
  padding: 16px 24px;
  font-weight: bold;
  border-bottom: 1px solid $honne-gray-300;
}

.p-ownership-head__h1__title {
  display: inline;
  font-size: $fs-3xl;

  a,
  a:visited {
    color: $honne-gray-700;
  }
}

.p-ownership-head__h1__page {
  margin-left: 4px;
  font-size: $fs-lg;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-ownership-head__h1 {
    padding: 24px;
  }
}
