@charset "utf-8";

/*doc
---
title: label
name: label
category: component-parts
---
```html_example
<div style="margin-bottom: 16px;">
  <span class="c-label c-label--green">ラベル</span>
  <span class="c-label c-label--yellow">ラベル</span>
  <span class="c-label c-label--yellow-white">ラベル</span>
  <span class="c-label c-label--yellow-bright">ラベル</span>
  <span class="c-label c-label--red">ラベル</span>
</div>
<div style="margin-bottom: 16px;">
  <span class="c-label c-label--white">ラベル</span>
  <span class="c-label c-label--white-coral">ラベル</span>
  <span class="c-label c-label--blue">ラベル</span>
  <span class="c-label c-label--blue-bright">ラベル</span>
  <span class="c-label c-label--gray-100">ラベル</span>
</div>
<div style="margin-bottom: 16px;">
  <span class="c-label c-label--gray-200">ラベル</span>
  <span class="c-label c-label--gray-600">ラベル</span>
  <span class="c-label c-label--article">まとめ</span>
</div>
```
*/

.c-label {
  padding: 3px 8px;
  font-size: $fs-xs;
  font-weight: bold;
  text-align: center;
  word-break: keep-all;
  border-radius: 4px;

  &.icon {
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
  }
}

@include label("c-label--green", $honne-green, $honne-white);

@include label("c-label--yellow", $honne-yellow, $honne-gray-700);

@include label("c-label--yellow-white", $honne-yellow-white, $honne-gray-600);

@include label(
  "c-label--yellow-white-700",
  $honne-yellow-white,
  $honne-gray-700
);

@include label("c-label--yellow-bright", $honne-yellow-bright, $honne-gray-700);

@include label(
  "c-label--yellow-light",
  rgba(242, 184, 48, 0.2),
  $honne-gray-700
);

@include label("c-label--red", $honne-red-bright, $honne-white);

@include label("c-label--white-coral", $honne-white-coral, $honne-gray-700);

@include label("c-label--blue", rgba(18, 152, 179, 0.1), $honne-blue);

@include label("c-label--blue-bright", $honne-blue-bright, $honne-gray-700);

@include label("c-label--gray-100", $honne-gray-100, $honne-gray-700);

@include label("c-label--gray-200", $honne-gray-200, $honne-gray-700);

@include label("c-label--gray-600", $honne-gray-600, $honne-white);

.c-label--white {
  color: $honne-gray-600;
  background-color: $honne-white;
  border: solid 2px $honne-gray-400;
}

.c-label--article {
  color: $honne-blue;
  border: 1px solid $honne-blue;
  border-radius: 12px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-label {
    padding: 4px 12px;
  }
}
