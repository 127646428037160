@charset "utf-8";

.p-jigyosho-contents + .p-jigyosho-contents {
  margin-top: 24px;
}

.p-jigyosho-contents__comment {
  margin-top: 8px;
  font-size: $fs-sm;
  color: $honne-gray-500;
}

.p-jigyosho-contents__guide {
  padding: 24px 0;
  margin-bottom: 12px;
  background-color: $honne-white;
}

.p-jigyosho-contents__qa {
  padding: 24px 0;
  background-color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-contents__guide {
    width: calc(50vw - 44px);
    max-width: 528px;
    height: 100%;
    padding: 40px;
    border-radius: 12px;
  }

  .p-jigyosho-contents__qa {
    width: calc(50vw - 44px);
    max-width: 528px;
    height: 100%;
    padding: 40px;
    border-radius: 12px;
  }
}
