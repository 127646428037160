@charset "utf-8";

.p-offer-status {
  padding: 16px 24px;
  margin: 16px 0;
  text-align: center;
  background-color: $honne-white;
}

.p-offer-status__title {
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-blue-lighten;
}

.p-offer-status__desc {
  margin-bottom: 12px;
  font-size: $fs-sm;
  font-weight: bold;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-offer-status__button {
    max-width: 240px;
    margin: 0 auto;
  }

  .p-offer-status__title {
    font-size: $fs-lg;
  }

  .p-offer-status__desc {
    font-size: $fs-md;
  }
}
