@charset "utf-8";

.o-body {
  padding-bottom: 40px;
  background-color: $honne-yellow-white;
}

.o-body--white {
  background-color: $honne-white;
}
