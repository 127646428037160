@charset "utf-8";

// font-size
$fs-3xs: 10px;
$fs-2xs: 11px;
$fs-xs: 12px;
$fs-sm: 13px;
$fs-md: 14px;
$fs-2md: 15px;
$fs-lg: 16px;
$fs-xl: 18px;
$fs-2xl: 20px;
$fs-3xl: 22px;
$fs-4xl: 24px;
$fs-5xl: 26px;

// line-height
$lh-x-small: 1.2;
$lh-small: 1.5;
$lh-medium: 1.7;
$lh-large: 1.8;
$lh-x-large: 2;

// letter-spacing
$ls-small: 0.05em;
$ls-medium: 0.08em;
$ls-large: 0.1em;

// font-family
$font-family-base:
  "Helvetica Neue",
  // 欧文フォント
  "Helvetica",
  // 欧文フォント
  "Arial",
  // 欧文フォント
  "Hiragino Kaku Gothic Pro",
  // ヒラギノ
  "Hiragino Sans",
  // ヒラギノ
  "Meiryo",
  "Yu Gothic",
  sans-serif !default; // Sans serif fallback
$font-family-700bold:
  Hiragino Sans,
  sans-serif;

// font-weight
$font-weight-700bold: 700;
$font-weight-800bold: 800;
$font-weight-900bold: 900;
