@charset "utf-8";

.p-brand-box {
  padding: 24px;
  overflow: visible;
  background-color: $honne-yellow-white;
  box-shadow: none;
}

.c-box + .p-brand-box {
  margin-top: 0;
}

.p-brand-box--empty {
  padding: 24px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-600;
  text-align: center;
}

.p-brand-box__pager {
  padding: 0 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brand-box {
    position: relative;
    width: 100%;
    padding: 0 24px 24px;
    margin-top: 0;
    background-color: $honne-white;
  }

  .p-brand-box:before {
    position: absolute;
    top: -20px;
    left: 0;
    display: block;
    width: 100%;
    height: 30px;
    content: "";
    background: $honne-white;
  }

  .p-brand-box__pager {
    padding: 0;
    margin-top: 24px;
  }
}
