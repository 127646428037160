@charset "utf-8";

/*doc
---
title: brochure-button
name: brochure-button
category: components
---

```html_example
<h2 style="margin-bottom: 24px;">問い合わせボタン</h2>
<a class="c-brochure-button" href="#">
  <div class="c-brochure-button__label c-brochure-button__label--large">無料</div>
  <div class="c-brochure-button__main">
    <p class="c-brochure-button__desc c-brochure-button__desc--large">\ 見学申込み 24時間受付中！ /</p>
    <p class="c-brochure-button__title c-brochure-button__title--large">資料請求・見学申込み</p>
  </div>
  <i class="c-icon-arrow-right-white c-brochure-button__icon"></i>
</a>
<h2 style="margin: 24px 0;">問い合わせボタン footer用</h2>
<a class="c-brochure-button c-brochure-button--fixed" href="#">
  <div class="c-brochure-button__main">
    <p class="c-brochure-button__desc">\ 見学申込み 24時間受付中！/</p>
    <p class="c-brochure-button__title">資料請求・見学申込み</p>
  </div>
  <i class="c-icon-arrow-right-white c-brochure-button__icon"></i>
</a>
<h2 style="margin: 24px 0;">問い合わせボタン disable</h2>
<a class="c-brochure-button c-brochure-button--fixed c-brochure-button--disable" href="#">
  <div class="c-brochure-button__main">
    <p class="c-brochure-button__desc">\ 見学申込み 24時間受付中！/</p>
    <p class="c-brochure-button__title">資料請求・見学申込み</p>
  </div>
  <i class="c-icon-arrow-right-white c-brochure-button__icon"></i>
</a>
```
*/

.c-brochure-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  font-weight: bold;
  color: $honne-white;
  background: $honne-red-bright;
  border-radius: 8px;
  box-shadow: $shadow-size-x-small;
}

.c-brochure-button--fixed {
  width: calc(100vw - 145px);
}

@include link-color("c-brochure-button", $honne-white);

.c-brochure-button--disable {
  pointer-events: none;
  background: $honne-gray-400;
}

.c-brochure-button__label {
  padding: 2px 8px;
  margin-right: 4px;
  color: $honne-red-bright;
  background: $honne-white;
  border-radius: 24px;
}

.c-brochure-button__label--large {
  font-size: $fs-2md;
}

.c-brochure-button__main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-brochure-button__desc {
  font-size: $fs-xs;
}

.c-brochure-button__desc--large {
  font-size: $fs-sm;
}

.c-brochure-button__title {
  font-size: $fs-xl;
}

.c-brochure-button__title--large {
  font-size: $fs-xl;
}

.c-brochure-button__icon {
  display: none;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-brochure-button {
    width: 100%;
    padding: 8px;
  }

  .c-brochure-button__label {
    margin-right: 12px;
    font-size: $fs-xl;
  }

  .c-brochure-button__label--large {
    font-size: $fs-lg;
  }

  .c-brochure-button__desc {
    font-size: $fs-2md;
  }

  .c-brochure-button__desc--large {
    font-size: $fs-2md;
  }

  .c-brochure-button__title {
    font-size: $fs-2xl;
  }

  .c-brochure-button__title--large {
    font-size: $fs-xl;
  }

  .c-brochure-button__icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(-50%, -50%);
  }
}
