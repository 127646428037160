@charset "utf-8";

.p-ranking-links__h2 {
  margin-bottom: 16px;
  font-size: $fs-2xl;
  font-weight: bold;
}

.p-ranking-links__block + .p-ranking-links__block {
  margin-top: 24px;
}

.p-ranking-links__h3 {
  margin-bottom: 12px;
}

.p-ranking-links__title {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-ranking-links__links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  font-weight: bold;
}

.p-ranking-links__b2 + .p-ranking-links__b2 {
  margin-top: 16px;
}
