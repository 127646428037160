@charset "utf-8";

.p-regist-caremanager__button {
  padding: 16px;
  margin: 0 auto;
  font-size: $fs-xl;
  border-radius: 48px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-caremanager__button {
    max-width: 400px;
    padding: 24px;
    font-size: $fs-3xl;
  }
}
