@charset "utf-8";

.p-cash-complete__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px 0 40px;
  text-align: center;
}

.p-cash-complete__img {
  width: 180px;
  margin: 0 auto;
}

.p-cash-complete__title {
  margin-bottom: 8px;
  font-size: $fs-xl;
  font-weight: bold;
  color: $honne-gray-700;
}

.p-cash-complete__desc {
  margin-bottom: 16px;
  font-size: $fs-md;
  line-height: $line-height9;
  color: $honne-black;
  text-align: left;

  br {
    display: none;
  }
}

.p-cash-complete__button {
  margin: 0 auto;
}

.p-cash-complete__attention {
  padding: 24px;
  margin-bottom: 32px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.p-cash-complete__attention__title {
  margin-bottom: 12px;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $line-height-px4;
  color: $honne-gray-700;
  text-align: center;
}

.p-cash-complete__attention__container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.p-cash-complete__attention__inner {
  text-align: left;
}

.p-cash-complete__attention__subtitle {
  margin-bottom: 4px;
  font-size: $fs-2md;
  font-weight: bold;
  color: $honne-blue;
}

.p-cash-complete__attention__main {
  font-size: $fs-sm;
  line-height: $line-height9;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cash-complete__main {
    margin: 40px 0;
  }

  .p-cash-complete__title {
    margin-bottom: 16px;
    font-size: $fs-4xl;
  }

  .p-cash-complete__img {
    width: 200px;
    object-fit: contain;
  }

  .p-cash-complete__desc {
    margin-bottom: 32px;
    font-size: $fs-lg;
    text-align: center;
  }

  .p-cash-complete__button {
    width: 240px;
  }

  .p-cash-complete__attention__title {
    font-size: $fs-xl;
  }

  .p-cash-complete__attention__subtitle {
    font-size: $fs-lg;
  }
}
