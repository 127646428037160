@charset "utf-8";

.p-jigyosho-plan {
  padding: 8px 16px;
  margin-top: 16px;
  background-color: $honne-yellow-white;
  border-radius: $radius-size-medium;
}

.p-jigyosho-plan__head {
  margin-bottom: 16px;
}

.p-jigyosho-plan__head-text {
  width: 85%;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-jigyosho-plan__head-icon {
  display: flex;
  align-items: center;
  min-width: 12px;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
}

.p-jigyosho-plan__head-icon-detail {
  margin-right: 4px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $honne-blue;
  word-break: break-all;
}

.p-jigyosho-plan__blocks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.p-jigyosho-plan__block {
  display: flex;
  width: 100%;
  padding: 4px 8px;
  font-size: $fs-xs;
  background-color: $honne-white;
  border-radius: 4px;
}

.p-jigyosho-plan__block + .p-jigyosho-plan__block {
  margin-top: 8px;
}

.p-jigyosho-plan__block-head {
  width: 72px;
}

.p-jigyosho-plan__block-content {
  font-weight: bold;
}

.p-jigyosho-plan__tables {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-jigyosho-plan__table-block {
  width: calc(50vw - 46px);
}

.p-jigyosho-plan__subheading {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: $fs-sm;
  font-weight: bold;
}

.p-jigyosho-plan__table {
  width: 100%;
  font-size: $fs-xs;
  background-color: $honne-white;
  border-radius: 4px;
}

.p-jigyosho-plan__table-row {
  display: flex;
  align-items: center;
  padding: 4px;
  text-align: center;
  border-bottom: 1px solid $honne-gray-200;
}

.p-jigyosho-plan__table-row:last-child {
  border-bottom: 0;
}

.p-jigyosho-plan__table-col {
  flex: 1;
  font-weight: bold;
  line-height: $line-height4;
}

.p-jigyosho-plan__table-price {
  display: inline-block;
  font-size: $fs-2xl;
  font-weight: bold;
  color: $honne-red-bright;
}

.p-jigyosho-plan__title {
  margin: 8px 0;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-jigyosho-plan__note {
  margin-bottom: 16px;
  font-size: $fs-sm;
  color: $honne-gray-600;
}

.p-jigyosho-plan__note-text {
  word-break: break-word;
}

.p-jigyosho-plan__desc {
  margin-top: 16px;
  color: $honne-gray-600;
}

.p-jigyosho-plan__desc-title {
  margin-bottom: 8px;
  font-weight: bold;
}

.p-jigyosho-plan__desc-content {
  font-size: $fs-sm;
}

.p-jigyosho-plan__table + .p-jigyosho-plan__table {
  margin-top: 8px;
}

/* 640px ~ */
@include min-screen(640px) {
  .p-jigyosho-plan__blocks {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .p-jigyosho-plan__block {
    width: 100%;
  }

  .p-jigyosho-plan__table-block {
    width: 50%;
  }

  .p-jigyosho-plan__table-block + .p-jigyosho-plan__table-block {
    margin-top: 0;
    margin-left: 16px;
  }

  .p-jigyosho-plan__block + .p-jigyosho-plan__block {
    margin-top: 0;
    margin-left: 16px;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-plan {
    padding: 12px 16px;
  }
}
