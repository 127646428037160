@charset "utf-8";

.c-jigyosho-summary {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.c-jigyosho-summary__head {
  min-width: 80px;
  font-size: $fs-md;
  font-weight: bold;
  text-align: left;
}

/* 空室 */
.c-jigyosho-summary__room {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.c-jigyosho-summary__room__vacancy {
  display: flex;
  align-items: baseline;
  font-weight: bold;
  color: $honne-red-bright;
}

.c-jigyosho-summary__room__total {
  font-size: $fs-lg;
}

.c-jigyosho-summary__room__wait {
  color: $honne-black;
}

.c-jigyosho-summary__room__date {
  margin-left: 8px;
  font-size: $fs-xs;
  color: $honne-gray-600;
}

.c-jigyosho-summary__room__icon {
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
}

.c-jigyosho-summary__availability {
  margin-left: auto;
}

.c-jigyosho-summary__availability__text {
  font-size: $fs-xs;
}

.c-jigyosho-summary__address {
  font-size: $fs-md;
  line-height: $line-height-base;
}

.c-jigyosho-summary__block + .c-jigyosho-summary__block {
  margin-top: 8px;
}

.c-jigyosho-summary__accepts {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -4px;
}

.c-jigyosho-summary__labels {
  display: flex;
  flex-wrap: wrap;
  margin-top: -4px;
}

%c-jigyosho-summary__label {
  margin-top: 4px;
  margin-right: 4px;
}

.c-jigyosho-summary__label {
  @extend %c-jigyosho-summary__label;
}

.c-jigyosho-summary__cashback {
  @extend %c-jigyosho-summary__label;
  display: flex;
  gap: 2px;
  align-items: center;
}

.c-jigyosho-summary__tags {
  display: flex;
  flex-wrap: wrap;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-jigyosho-summary {
    padding: 12px 0;
  }

  .c-jigyosho-summary__head {
    min-width: 80px;
  }

  .c-jigyosho-summary__room {
    justify-content: flex-start;
  }

  .c-jigyosho-summary__room__date {
    align-self: auto;
  }

  .c-jigyosho-summary__block + .c-jigyosho-summary__block {
    margin-top: 0;
  }
}
