@charset "utf-8";

/*doc
---
title: form
name: form
category: components
---
```html_example
<div class="c-form">
  <div class="c-form__title">ログイン</div>
  <img class="c-form__head" src="http://placehold.it/720x135">
  <div class="c-form__inner">
    <ul class='c-form__errors'>
      <li class="c-form__errors__item">氏名を入力してください。</li>
      <li class="c-form__errors__item">ふりがなを入力してください。</li>
    </ul>
    <div class="c-form__field">
      <div class="c-form__field__head">
        <label class="c-form__field__title" for="request_name">氏名</label>
        <span class="c-label c-label--red c-form__field__label">必須</span>
      </div>
      <p class="c-form__field__desc">説明説明説明説明説明</p>
      <div class="c-text-field c-form__field__content">
        <input placeholder="本音太郎" type="text" name="request[name]" id="request_name">
        <div class="c-form__error">
          <p class="parsley-required">氏名を入力してください</p>
        </div>
      </div>
    </div>
    <div class="c-form__field c-form__field--small">
      <div class="c-form__field__head">
        <label class="c-form__field__title" for="request_furigana">ふりがな</label>
        <span class="c-label c-label--red c-form__field__label">必須</span>
      </div>
      <div class="c-text-field c-form__field__content">
        <input required="required" placeholder="ほんねたろう" type="text" name="request[furigana]" id="request_furigana">
      </div>
    </div>
    <button type="submit" class="c-button c-button--primary c-form__button">次へ</button>
  </div>
</div>
```
*/

.c-form {
  background-color: $honne-white;
  border-radius: $radius-size-medium;

  input[type="text"],
  input[type="password"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  textarea {
    border: $honne-gray-400 solid 2px;
  }
}

.c-form__head {
  width: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.c-form__inner {
  padding: 8px 0;
}

.c-form__title {
  margin-bottom: 24px;
  font-size: $fs-2xl;
  color: $honne-blue;
}

.c-form__title--top {
  margin-top: 24px;
}

.c-form__field + .c-form__field {
  margin-top: 16px;
}

.c-form__field--top {
  margin-top: 16px;
}

.c-form__field__desc {
  margin: 12px 0;
  font-size: $fs-md;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-px2;
}

.c-form__text {
  margin-bottom: 24px;
  font-size: $fs-md;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-px2;
}

.c-form__text--strong {
  font-weight: bold;
}

.c-form__field__head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.c-form__field__title {
  font-size: $fs-2md;
  font-weight: bold;
  color: $honne-gray-700;
}

.c-form__field--small {
  margin-top: 12px !important;

  & .c-form__field__title {
    font-size: $fs-xs;
  }
}

.c-form__field__title + .c-form__field__label {
  padding: 2px 8px;
  margin-left: 8px;
}

.c-form__field__content {
  width: 100%;
  margin-top: 8px;
}

.c-form__error {
  margin-top: 8px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $honne-red;
}

.c-form__button {
  padding: 16px;
  margin-top: 24px;
  font-size: $fs-xl;
  line-height: $line-height6;
  border-color: $honne-red-bright;
  border-radius: 40px;
  box-shadow: $shadow-size-medium;
}

.c-form__errors {
  padding: 16px;
  margin-bottom: 16px;
  background-color: rgba($honne-red-bright, 0.1);
  border-radius: 8px;
}

.c-form__errors__item {
  font-weight: bold;
  color: $honne-red-bright;
}

.c-form__errors__item + .c-form__errors__item {
  margin-top: 4px;
}

.c-form__desc {
  margin-top: 12px;
  font-size: $fs-xs;
  line-height: $lh-small;
  color: $honne-gray-500;
}

.c-form__legal {
  margin-top: 24px;
  text-align: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-form {
    max-width: 960px;
    margin: 0 auto;
    box-shadow: $shadow-size-medium;
  }

  .c-form__inner {
    padding: 32px 40px;
  }

  .c-form__field__head {
    margin-bottom: 8px;
  }

  .c-form__field + .c-form__field {
    margin-top: 24px;
  }

  .c-form__field--top {
    margin-top: 24px;
  }

  .c-form__field__title {
    font-size: $fs-lg;
  }

  .c-form__field__content {
    margin-top: 16px;
  }

  .c-form__field--small {
    display: flex;
    align-items: center;

    & .c-form__field__head {
      min-width: 60px;
      margin-right: 8px;
      margin-bottom: 0;
    }

    & .c-form__field__title {
      font-size: $fs-sm;
    }

    & .c-form__field__content {
      margin-top: 0;
    }
  }
}
