@charset "utf-8";

.c-jg-notification {
  &__title {
    margin-top: 8px;
    margin-bottom: 8px;
    font-family: $font-family-700bold;
    font-size: $fs-xl;
    font-weight: $font-weight-700bold;
    line-height: $line-height-x-large;
  }
  &__text {
    line-height: $line-height-base;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-jg-notification {
    &__text {
      font-size: $fs-lg;
      line-height: $line-height9;
    }
  }
}
