@charset "utf-8";

.p-cashback-flow__inner {
  width: 100%;
  padding: 48px 0;
}

.p-cashback-flow__items {
  width: 100%;
  margin: 32px 0;
}

.p-cashback-flow__item {
  position: relative;
  width: 100%;
  padding: 24px 16px;
  margin-bottom: 16px;
  text-align: center;
  background: $honne-yellow-white;
  border-radius: 8px;
}

.p-cashback-flow__item-title {
  font-size: $fs-lg;
  line-height: $line-height-base;
  text-align: center;

  br {
    display: none;
  }
}

.p-cashback-flow__title-sub {
  position: relative;
  margin-bottom: 8px;
  font-size: $fs-lg;
  line-height: $line-height-px2;
  text-align: center;
}

.p-cashback-flow__title-sub__inner {
  position: relative;
  display: table;
  padding: 0 16px;
  margin: 0 auto;
  background: $honne-white;
}

.p-cashback-flow__title-sub:before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  content: "";
  background: $honne-gray-400;
}

.p-cashback-flow__item-text {
  margin-top: 12px;
  font-size: $fs-md;
  line-height: $line-height3;
  text-align: left;
}

.p-cashback-flow__item-button {
  margin: 16px auto 0;
}

.p-cashback-title__count {
  display: inline-block;
  margin-right: 8px;
  font-size: $fs-3xl;
  color: $honne-yellow;
}

.p-cashback-flow__text-sub {
  margin-bottom: 16px;
  font-size: $fs-2md;
  line-height: $line-height3;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cashback-flow__inner {
    max-width: 1080px;
    padding: 72px 0;
  }

  .p-cashback-flow__items {
    display: flex;
    justify-content: space-between;
  }

  .p-cashback-flow__item-title {
    font-size: $fs-xl;
  }

  .p-cashback-flow__title-sub {
    margin-top: 24px;
    font-size: $fs-xl;
    line-height: $line-height-px3;
  }

  .p-cashback-flow__item {
    width: calc(25vw - 25px);
    max-width: 258px;
    padding: 24px;
  }

  .p-cashback-flow__text-sub {
    text-align: center;
  }
}

@include min-screen($breakpoint-pc-max) {
  .p-cashback-flow__item-title {
    br {
      display: block;
    }
  }
}
