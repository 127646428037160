@charset "utf-8";

.p-top-map-keywords__inner {
  width: 100%;
  padding: 56px 0 0;

  .c-map-keywords__keywords {
    display: none;
  }
}

.p-top-map-keywords__body {
  margin-top: 16px;
  margin-bottom: 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-map-keywords__inner {
    padding: 56px 0 0;

    .c-map-keywords__keywords {
      display: block;
    }
  }

  .p-top-map-keywords__body {
    margin-bottom: 32px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-map-keywords__body {
    margin-bottom: 56px;
  }
}
