@charset "utf-8";

.p-regist-contact {
  margin-top: 16px;
}

.p-regist-contact__table {
  width: 100%;
  overflow: hidden;
  background-color: $honne-white;
}

.p-regist-contact__form {
  max-width: none;
  margin: 0;
  box-shadow: none;
}

.p-regist-contact__error {
  margin-top: 4px;
  color: $honne-red-bright;
}

.p-regist-contact__submit {
  margin-top: 24px;
  text-align: center;
}

.p-regist-contact__submit-button {
  display: table;
  width: 100%;
  padding: 16px 24px;
  margin: 0 auto 20px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-white;
  background: $honne-red-bright;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-contact {
    margin-top: 32px;
  }

  .p-regist-contact__table {
    padding: 32px;
    border-radius: 8px;
    box-shadow: $shadow-size-small;
  }

  .p-regist-contact__submit-button {
    width: auto;
    padding: 12px 60px;
    font-size: $fs-xl;
  }

  .p-regist-contact__submit-button:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-regist-contact__table {
    padding: 56px;
  }
}
