@charset "utf-8";

/*doc
---
title: checkbox
name: checkbox
category: component-parts
---

```html_example
<label class="c-checkbox" style="margin-bottom:8px;">
  <input type="checkbox">
  <span class="c-checkbox__mimic-wrap"><span class="c-checkbox__mimic"></span></span>
  <span class="c-checkbox__text">普通サイズ</span>
</label>

<label class="c-checkbox">
  <input type="checkbox">
  <span class="c-checkbox__mimic-wrap"><span class="c-checkbox__mimic--large"></span></span>
  <span class="c-checkbox__text--large">大きいサイズ</span>
</label>
```
*/

$mimic-size: 24px;
$mimic-background-color: $honne-white;

.c-checkbox__mimic {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 16px;
  height: 16px;
  background: $honne-white;
  border: 2px solid $honne-gray-400;
  border-radius: 4px;
}

.c-checkbox__mimic--large {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: $mimic-size;
  height: $mimic-size;
  background: $honne-white;
  border: 2px solid $honne-gray-400;
  border-radius: 4px;
}

.c-checkbox__text--large {
  font-size: $fs-md;
  line-height: $line-height6;
}

.c-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type="checkbox"] {
    display: none;
    appearance: none;
  }

  input[type="checkbox"]:checked + &__mimic-wrap > &__mimic {
    background-color: $honne-blue-lighten;
    border: none;
  }

  input[type="checkbox"]:checked + &__mimic-wrap > &__mimic::before {
    position: absolute;
    top: 10px;
    left: 3px;
    display: block;
    width: 5px;
    height: 2px;
    content: "";
    background-color: $mimic-background-color;
    transform: rotate(45deg);
    transform-origin: right center;
  }

  input[type="checkbox"]:checked + &__mimic-wrap > &__mimic::after {
    position: absolute;
    top: 10px;
    left: 7px;
    display: block;
    width: 8px;
    height: 2px;
    content: "";
    background-color: $mimic-background-color;
    transform: rotate(-53deg);
    transform-origin: left center;
  }

  input[type="checkbox"]:checked + &__mimic-wrap > &__mimic--large {
    background-color: $honne-blue-lighten;
    border: none;
  }

  input[type="checkbox"]:checked + &__mimic-wrap > &__mimic--large::before {
    position: absolute;
    top: 15px;
    left: 3px;
    display: block;
    width: 8px;
    height: 3px;
    content: "";
    background-color: $mimic-background-color;
    border-radius: 2px;
    transform: rotate(45deg);
    transform-origin: right center;
  }

  input[type="checkbox"]:checked + &__mimic-wrap > &__mimic--large::after {
    position: absolute;
    top: 15px;
    left: 10px;
    display: block;
    width: 12px;
    height: 3px;
    content: "";
    background-color: $mimic-background-color;
    border-radius: 2px;
    transform: rotate(-45deg);
    transform-origin: left center;
  }

  input[type="checkbox"]:disabled + &__mimic-wrap > &__mimic--large {
    background: $honne-gray-400;
  }

  input[type="checkbox"]:checked + &__mimic-wrap + &__text--large {
    font-weight: bold;
  }
}

.c-checkbox__mimic-wrap {
  display: block;
  margin-right: 8px;
}

.c-checkbox__text {
  font-size: $fs-sm;
  font-weight: bold;
}

.c-checkbox__text--normal {
  font-size: $fs-sm;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-checkbox__mimic-wrap {
    margin-right: 12px;
  }

  .c-checkbox__text--large {
    font-size: $fs-lg;
  }

  .c-checkbox__text--normal {
    font-size: $fs-md;
  }
}
