@charset "utf-8";

.p-questions-qa-list {
  border-radius: 0;
}

.p-questions-qa-list__items {
  border-top: 1px solid $honne-gray-300;

  & .c-question {
    padding-right: 24px;
    padding-left: 24px;
  }
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .p-questions-qa-list {
    border-radius: 8px;
  }

  .p-questions-qa-list__items {
    display: flex;
    flex-wrap: wrap;
    padding-right: 24px;
    padding-bottom: 24px;
    border-top: none;

    & .c-question {
      width: calc(calc(100% - 56px) / 2);
    }

    .c-question:nth-child(odd) {
      padding-left: 0;
      margin-right: 32px;
      margin-left: 24px;
    }

    .c-question:nth-child(1),
    .c-question:nth-child(2) {
      border-top: 1px solid $honne-gray-300;
    }
  }
}
