@charset "utf-8";

.p-special4-split {
  height: 16px;
  background-color: $honne-blue-bright;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special4-split {
    height: 40px;
  }
}
