@charset "utf-8";

.p-my-form__head {
  display: flex;
  padding: 12px 24px;
  font-size: $fs-xl;
  font-weight: bold;
  background-color: $honne-white;
}

.p-my-form__main {
  padding: 0 24px 24px;
  background-color: $honne-white;
}

.p-my-form__submit {
  padding: 16px;
  margin-top: 24px;
  font-size: $fs-2md;
}

.p-my-form__hint {
  margin-top: 8px;
  font-size: $fs-sm;
  color: $honne-gray-600;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-my-form__head {
    padding: 24px;
    margin-bottom: 16px;
    font-size: $fs-2xl;
    border-radius: 8px;
  }

  .p-my-form__main {
    padding-top: 24px;
    border-radius: 8px;
  }

  .p-my-form__submit {
    max-width: 240px;
    margin-right: auto;
    margin-left: auto;
  }
}
