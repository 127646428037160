@charset "utf-8";

.p-jigyosho-card-list {
  padding-left: 24px;
  margin-right: 0;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-card-list {
    padding: 0 24px 0;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-card-list {
    margin-right: -32px;
  }
}
