@charset "utf-8";

/*doc
---
title: editor
name: editor
category: components
---
```html_example
<div class="c-editor">
  <div class="c-editor__head">
    <div class="c-editor__thumb">
      <img alt="" src="http://placehold.it/74x74">
    </div>
    <div class="c-editor__data">
      <p class="c-editor__label">この記事の監修</p>
      <h2 class="c-editor__name">平栗 潤一</h2>
      <p class="c-editor__posts">
        <a class="c-editor__post" target="_blank" rel="noopener" href="https://j-care.or.jp/about#1">一般社団法人 日本介護協会 理事長</a>
      </p>
    </div>
  </div>
  <p class="c-editor__text">大手介護専門学校にて12年で約2,000名の人材育成に関わり、その後、人材定着に悩む介護事業所の人材育成や運営支援を実施。2020年4月からは一般社団法人日本介護協会の理事長に就任し、介護業界の発展を目指して介護甲子園を主催している。</p>
</div>
```
*/

.c-editor {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 24px 24px;
  background: $honne-yellow-white;
  border-radius: 8px;
}

.c-editor__head {
  display: flex;
}

.c-editor__thumb {
  margin-right: 16px;
}

.c-editor__image {
  width: 74px;
  height: 74px;
  object-fit: cover;
  border-radius: 50%;
}

.c-editor__label {
  margin-bottom: 8px;
  font-size: $fs-xs;
  color: $honne-gray-600;
}

.c-editor__name {
  margin-bottom: 4px;
  font-size: $fs-xl;
  line-height: $line-height-medium;
}

.c-editor__posts {
  display: flex;
  flex-direction: column;
  font-size: $fs-xs;
  line-height: $line-height-x-small;
}

.c-editor__post + .c-editor__post {
  margin-top: 4px;
}

.c-editor__text {
  margin-top: 16px;
  line-height: $line-height-px2;
  text-align: justify;
  word-break: break-all;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-editor__head {
    width: 240px;
    margin-right: 24px;
  }

  .c-editor__data {
    width: 150px;
  }

  .c-editor__text {
    width: calc(100% - 264px);
    margin-top: 0;
  }
}
