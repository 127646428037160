@charset "utf-8";

$sp-image-height: 210px;
$sp-thumb-height: 56px;

$pc-image-height: 335px;
$pc-thumb-height: 96px;

.p-jigyosho-main-img {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: $honne-gray-100;
}

.p-jigyosho-main-img__picture {
  position: relative;
  width: 100%;
  height: $sp-image-height;
}

.p-jigyosho-main-img__img {
  width: 100%;
  height: $sp-image-height;
  object-fit: cover;
}

.p-jigyosho-main-img__description {
  position: absolute;
  bottom: 0;
  display: none;
  width: 100%;
  padding: 4px 8px;
  font-family: $font-family-700bold;
  font-size: $fs-xs;
  color: $honne-white;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: $honne-black;
  opacity: 0.6;
}

.p-jigyosho-main-img__title {
  font-weight: bold;
}

.p-jigyosho-main-img__footer {
  position: relative;
}

.p-jigyosho-main-img__button {
  position: absolute;
  top: -68px;
  right: 12px;
  z-index: $z-index-3;
  width: 115px;
  font-size: $fs-sm;
  font-weight: normal;
  cursor: pointer;
  border-radius: 4px;
}

.p-jigyosho-main-img__thumb {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  @include scrollbar-none;
}

.p-jigyosho-main-img__thumb__wrapper {
  position: relative;
  width: $sp-thumb-height;
  height: $sp-thumb-height;
  margin: 2px 2px 0 0;
}

.p-jigyosho-main-img__thumb__wrapper:last-child {
  margin-right: 0;
}

.p-jigyosho-main-img__thumb__wrapper.focus::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border: 3px solid $honne-blue;
}

.p-jigyosho-main-img__thumb__img {
  width: $sp-thumb-height;
  height: $sp-thumb-height;
  object-fit: cover;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-main-img {
    width: calc(100% - 550px);
    margin-right: 24px;
    background: none;
    border-radius: 8px;
  }

  .p-jigyosho-main-img__picture {
    width: 100%;
    height: $pc-image-height;
  }

  .p-jigyosho-main-img__img {
    height: $pc-image-height;
  }

  .p-jigyosho-main-img__thumb__wrapper {
    width: $pc-thumb-height;
    height: $pc-thumb-height;
  }

  .p-jigyosho-main-img__thumb__img {
    width: $pc-thumb-height;
    height: $pc-thumb-height;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-main-img {
    min-width: 380px;
    max-width: 420px;
  }
}
