@charset "utf-8";

.p-special3-step {
  padding-top: 30px;
}

.p-special3-step__caption {
  margin: 16px 0 8px 0;
  font-size: $fs-2md;
  text-align: center;
  letter-spacing: $letter-spacing-px5;

  span {
    font-weight: bold;
    color: $honne-blue-lighten;
  }
}

.p-special3-step__title {
  margin-bottom: 24px;
  font-size: $fs-3xl;
  text-align: center;
  letter-spacing: $letter-spacing-px5;
}

.p-special3-step__list {
  padding: 0 24px;
}

.p-special3-step__list__item {
  position: relative;
  width: 100%;
  padding-bottom: 48px;
  padding-left: 48px;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 8px;

    &::after {
      position: absolute;
      bottom: 8px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 18px;
      content: "";
      background-image: url("~images/lp/special/line.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

$numbers: one, two, three;

@each $number in $numbers {
  .p-special3-step__list__item--#{$number} {
    background-image: url("~images/lp/special/#{$number}.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 32px;
  }
}

.p-special3-step__list__item--one {
  br {
    display: none;
  }
}

.p-special3-step__list__item--three {
  br {
    display: none;
  }
}

.p-special3-step__subtitle {
  margin-bottom: 8px;
  font-size: $fs-lg;
  letter-spacing: $letter-spacing-px2;
}

.p-special3-step__text {
  font-size: $fs-sm;
  line-height: $line-height9;
  color: $honne-gray-600;
  letter-spacing: $letter-spacing-px2;
}

.p-special3-step__campaign {
  position: relative;
  width: calc(100vw - 48px);
  padding: 36px 16px 24px;
  margin: 0 auto 16px;
  background-color: $honne-yellow-white;
  border-radius: 12px;
}

.p-special3-step__campaign__more {
  position: absolute;
  top: -12px;
  left: 35%;
  display: inline-block;
  padding: 4px 24px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-white;
  letter-spacing: $letter-spacing-px2;
  background-color: $honne-blue-lighten;
  transform: rotate(-4deg);
}

.p-special3-step__campaign__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-special3-step__campaign__image {
  width: 64px;
  margin-right: 12px;

  img {
    width: 41.92px;
    height: 100%;
  }
}

.p-special3-step__campaign__caption {
  margin-bottom: 8px;
  font-size: $fs-sm;
  font-weight: bold;
  letter-spacing: $letter-spacing-px2;
}

.p-special3-step__campaign__text {
  font-size: $fs-lg;
  font-weight: bold;
  text-align: center;
  text-align: left;
  letter-spacing: $letter-spacing-px5;

  span {
    color: $honne-blue-lighten;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special3-step {
    padding-top: 48px;
  }

  .p-special3-step__caption {
    margin-top: 48px;
    font-size: $fs-3xl;
    font-weight: bold;
    letter-spacing: $letter-spacing-px4;
  }

  .p-special3-step__title {
    margin-bottom: 40px;
    font-size: $font-size-big1;
    letter-spacing: $letter-spacing-px4;
  }

  .p-special3-step__list {
    padding: initial;
  }

  .p-special3-step__list__item {
    width: 800px;
    padding-bottom: 64px;

    &:not(:last-child) {
      margin-bottom: 32px;

      &::after {
        bottom: 8px;
        width: 800px;
        height: 18px;
      }
    }
  }

  .p-special3-step__list__item--one {
    br {
      display: block;
    }
  }

  .p-special3-step__list__item--three {
    br {
      display: block;
    }
  }

  .p-special3-step__subtitle {
    margin-bottom: 12px;
    font-size: $fs-2xl;
  }

  .p-special3-step__text {
    font-size: $fs-lg;
    line-height: $line-height9;
  }

  .p-special3-step__campaign {
    align-items: center;
    width: 952px;
    padding: 36px 76px 28px 42px;
    margin: 0 auto;
  }

  .p-special3-step__campaign__more {
    left: 47%;
  }

  .p-special3-step__campaign__wrap {
    gap: 72px;
    justify-content: flex-start;
  }

  .p-special3-step__campaign__image {
    img {
      width: 64px;
    }
  }

  .p-special3-step__campaign__caption {
    margin-bottom: 12px;
    font-size: $fs-xl;
    text-align: center;
  }

  .p-special3-step__campaign__text {
    font-size: $fs-4xl;
    text-align: center;
    letter-spacing: $letter-spacing-px4;
  }
}
