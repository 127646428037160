@charset "utf-8";

.c-fixed-tel {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-4;
  margin: 0 24px 8px 24px;
  line-height: $lh-x-small;
}

.c-fixed-tel__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  background-color: $honne-white;
  border: 3px $honne-blue solid;
  border-radius: 8px;
}

.c-fixed-tel__contents {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.c-fixed-tel__img {
  margin-right: 4px;
}

.c-fixed-tel__title {
  font-size: $fs-lg;
  font-weight: $font-weight-700bold;
  color: $honne-gray-700;
  text-align: center;
}

.c-fixed-tel__tel {
  display: flex;
  gap: 4px;
  align-items: center;
}

.c-fixed-tel__tel-num {
  font-size: $fs-3xl;
  font-weight: $font-weight-700bold;
  color: $honne-blue;
}

.c-fixed-tel__text {
  font-size: $fs-xs;
  color: $honne-gray-600;
  text-align: center;
}

.c-fixed-tel__close {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 35px;
  height: 35px;
  text-align: center;
  background-color: $honne-blue;
  border-radius: 50%;
}

.c-fixed-tel__close-img {
  position: absolute;
  top: 11px;
  left: 11px;
}
