@charset "utf-8";

.p-jigyosho-category-photo__box {
  margin-bottom: 16px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: $shadow-size-medium;
  transition: 0.2s;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    opacity: 0.6;
  }
}

.p-jigyosho-category-photo__img {
  height: 175px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
}

.p-jigyosho-category-photo__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 0 0 8px 8px;
}

.p-jigyosho-category-photo__title {
  font-weight: bold;
}

.p-jigyosho-category-photo__arrow {
  @include icon(8px, 12px);
  background-image: url("~images/user_inline/icon/arrow-right.svg");
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-jigyosho-category-photo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -16px;
  }

  .p-jigyosho-category-photo__box {
    width: calc(50% - 8px);

    &:last-child {
      margin-bottom: 16px;
    }
  }

  .p-jigyosho-category-photo__img {
    height: 200px;
  }
}
