@charset "utf-8";

.c-question-editor {
  display: flex;
  align-items: flex-start;
}

.c-question-editor__a {
  padding: 4px 8px;
  margin-right: 12px;
  font-size: $fs-2md;
  font-weight: bold;
  line-height: initial;
  color: $honne-white;
  background-color: $honne-yellow;
  border-radius: 10px 10px 0 10px;
}

.c-question-editor__photo {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  object-fit: cover;
  border-radius: 18px;
}

.c-question-editor__name {
  margin-bottom: 4px;
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-gray-700;

  span {
    display: inline-block;
    margin-left: 8px;
    font-size: $fs-xs;
    font-weight: normal;
    color: $honne-gray-600;
  }
}

.c-question-editor__profile {
  font-size: $fs-xs;
  color: $honne-gray-700;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-question-editor__a {
    padding: 4px 8px;
    font-size: $fs-2md;
    border-radius: 10px 10px 0 10px;
  }
}
