@charset "utf-8";

/*doc
---
title: article-qa
name: article-qa
category: components
---

```html_example
<ul class="c-article-qa">
  <li class="c-article-qa__item">
    <span class="c-article-qa__title__q">Q</span>
    <div class="c-article-qa__inner">
      <a class="c-article-qa__link" href="#">認知症の母が施設入居を嫌がる場合の対応方法を教えてください</a>
      <div class="o-line o-line--small">
        <a class="o-line__item c-link--tag" href="#">認知症</a>
        <a class="o-line__item c-link--tag" href="#">介護施設</a>
        <a class="o-line__item c-link--tag" href="#">家族の介護</a>
        <a class="o-line__item c-link--tag" href="#">施設入居・入所</a>
      </div>
    </div>
  </li>
  <li class="c-article-qa__item">
    <span class="c-article-qa__title__q">Q</span>
    <div class="c-article-qa__inner">
      <a class="c-article-qa__link" href="#">介護保険証の再発行の手続きについて教えてください</a>
      <div class="o-line o-line--small">
        <a class="o-line__item c-link--tag" href="#">介護保険</a>
        <a class="o-line__item c-link--tag" href="#">介護保険料</a>
        <a class="o-line__item c-link--tag" href="#">入居手続き</a>
      </div>
    </div>
  </li>
</ul>
```
*/

$article-qa-border: 1px solid $honne-gray-300;

.c-article-qa__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 12px 24px;
  border-bottom: $article-qa-border;

  &:first-child {
    border-top: $article-qa-border;
  }
}

.c-article-qa__title__q {
  padding: 6px 10px;
  margin-right: 12px;
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: initial;
  color: $honne-white;
  background-color: $honne-blue-lighten;
  border-radius: 12px 12px 0 12px;
}

.c-article-qa__inner {
  width: calc(100% - 42px);
}

.c-article-qa__link {
  display: block;
  margin-bottom: 8px;
  font-size: $fs-md;
  font-weight: bold;
  line-height: $line-height-large;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-px2;

  &:visited {
    color: $honne-gray-700;
  }
}

.c-article-qa__editor {
  margin-top: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-article-qa {
    display: flex;
    flex-wrap: wrap;
  }

  .c-article-qa__title__q {
    padding: 6px 10px;
    margin-right: 12px;
    font-size: $fs-3xl;
    border-radius: 12px 12px 0 12px;
  }

  .c-article-qa__item {
    padding: 12px 0;
  }

  .c-article-qa__link {
    font-size: $fs-2md;
    line-height: $line-height-x-large;
    letter-spacing: $letter-spacing-px2;
  }
}
