@charset "utf-8";

.c-guide-content {
  padding: 40px $sp-page-margin 0;
  text-align: justify;
}

.c-guide-content__inner {
  max-width: 1080px;
  margin: 0 auto;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-guide-content {
    padding: 32px;
  }
}
