@charset "utf-8";

.o-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: $breakpoint-sp-min;
  padding-right: $sp-page-margin;
  padding-left: $sp-page-margin;
}

.o-section--mb {
  margin-bottom: 48px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .o-section {
    padding-right: 32px;
    padding-left: 32px;
  }

  .o-section__inner {
    max-width: $breakpoint-pc-max;
  }
}
