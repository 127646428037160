@charset "utf-8";

/*doc
---
title: side-jigyosho
name: side-jigyosho
category: components
---
```html_example
<div class="c-side-jigyosho" style="width: 256px;">
  <h2 class="c-heading-h3 c-side-jigyosho__head">最近閲覧した施設</h2>
  <div class="c-side-jigyosho__main">
    <div class="c-side-jigyosho__top">
      <div class="c-side-jigyosho__img">
        <a href="#">
          <img src="http://placehold.it/64x64">
        </a>
      </div>
      <div>
        <p class="c-side-jigyosho__name">
          <span class="c-side-jigyosho__name__pu">おすすめ</span><a href="#">ニチイホーム住吉</a>
        </p>
        <p class="c-side-jigyosho__desc">東京都江東区北砂1-10-1</p>
      </div>
    </div>
    <div class="c-side-jigyosho__list">
      <div class="c-side-jigyosho__summary">
        <div class="c-side-jigyosho__summary__head">空室状況</div>
        <div>
          <span class="c-side-jigyosho__summary__text">残りわずか</span>
          <span class="c-side-jigyosho__desc">03月30日更新</span>
        </div>
      </div>

      <div class="c-side-jigyosho__summary">
        <div class="c-side-jigyosho__summary__head">入居費用</div>
        <div>
          <span class="c-side-jigyosho__summary__text">50.0 〜 1,680.0万円</span>
        </div>
      </div>
    </div>
    <a class="c-link c-link--button c-side-jigyosho__button" href="#">
      <span class="c-link__text">閲覧履歴を見る</span>
      <i class="c-link__icon c-icon-arrow-right-blue c-icon--small"></i>
    </a>
  </div>
</div>
```
*/

.c-side-jigyosho {
  background: $honne-white;
  border-radius: 8px;
}

.c-side-jigyosho__head {
  padding: 16px;
  border-top: 3px solid $honne-blue;
  border-bottom: 1px solid $honne-gray-300;
}

.c-side-jigyosho__main {
  padding: 24px;
}

.c-side-jigyosho__top {
  display: flex;
  margin-bottom: 12px;
}

.c-side-jigyosho__img {
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin-right: 12px;
  overflow: hidden;
  border-radius: 4px;

  img {
    vertical-align: middle;
  }
}

.c-side-jigyosho__name {
  margin-bottom: 4px;
  font-size: $fs-2md;
  font-weight: bold;
}

.c-side-jigyosho__name__pu {
  display: inline-block;
  padding: 2px 5px;
  margin-right: 6px;
  font-size: $fs-3xs;
  font-weight: bold;
  color: $honne-white;
  background-color: $honne-yellow;
  border-radius: 3px;
}

.c-side-jigyosho__desc {
  font-size: $fs-xs;
  color: $honne-gray-600;
}

.c-side-jigyosho__summary {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.c-side-jigyosho__summary__head {
  min-width: 52px;
  margin-right: 12px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $honne-gray-600;
}

.c-side-jigyosho__summary__text {
  font-size: $fs-sm;
  font-weight: bold;
  color: $honne-red-bright;
}

.c-side-jigyosho__price {
  & .c-price__number {
    font-size: $fs-sm;
  }
}

.c-side-jigyosho__button {
  margin-top: 16px;
}

@include min-screen($breakpoint-pc-max) {
  .c-side-jigyosho__main {
    padding: 16px;
  }
}
