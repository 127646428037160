@charset "utf-8";

.c-footer__link {
  padding: 32px 0;
  border-top: 1px solid $honne-gray-300;
}

.c-footer__link__head {
  font-size: $fs-lg;
}

.c-footer__link__list {
  margin-top: 16px;
}

.c-footer__link__item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: $fs-xs;
  font-weight: bold;
  line-height: $line-height11;

  a,
  a:visited {
    display: inline-block;
    margin-left: 8px;
    color: $honne-gray-700;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-footer__link__item {
    font-size: $fs-xs;
    line-height: $line-height-xx-large;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-footer__link {
    padding: 0;
    margin-bottom: 32px;
    border: 0;
  }

  .c-footer__link__list {
    display: flex;
    flex-wrap: wrap;
  }

  .c-footer__link__item {
    width: 32%;
    margin-right: 12px;
  }
}
