@charset "utf-8";

.p-top-theme {
  margin-bottom: 48px;

  .o-cards {
    padding-left: 24px;
    margin: 0 -24px;
  }
}

.p-top-theme__title {
  margin-bottom: 12px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-theme {
    margin-bottom: 72px;

    .o-cards {
      padding-left: initial;
      margin: initial;
    }
  }

  .p-top-theme__title {
    margin-bottom: 16px;
  }
}
