@charset "utf-8";

.c-pr-card {
  position: relative;
  display: block;
  margin: 0 16px;
  overflow: hidden;
  background-color: $honne-white;
  border: 4px solid $honne-yellow;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);

  &:visited {
    color: $honne-gray-700;
  }

  &:active {
    color: $honne-gray-700;
  }
}

.c-pr-card__link {
  &:hover {
    opacity: 0.9;
  }
}

@include link-color("c-pr-card__link", $honne-gray-700);

.c-pr-card + .c-pr-card {
  margin-top: 16px;
}

.c-pr-card__badge {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 110px;
  height: 110px;
  font-size: $fs-sm;
  font-weight: bold;
  line-height: $line-height12;
  color: $honne-white;
  background-color: $honne-yellow;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.c-pr-card__img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.c-pr-card__main {
  padding: 4px 16px 0;
}

.c-pr-card__top {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
}

.c-pr-card__pr {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  margin-right: 8px;
  font-weight: bold;
  color: $honne-yellow;
  background: $honne-white;
  border: 1px solid $honne-yellow;
  border-radius: $radius-size-small;
}

.c-pr-card__title {
  font-size: $fs-2xl;
  color: $honne-blue;
}

.c-pr-card__desc {
  margin-bottom: 8px;
  font-size: $fs-sm;
  line-height: $lh-small;
}

.c-pr-card__bottom {
  padding: 0 16px 12px;
  margin-top: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-pr-card {
    gap: 24px;
    margin: 0;
  }

  .c-pr-card__img {
    width: 320px;
    height: auto;
    border-radius: 8px;
  }

  .c-pr-card__main {
    padding: 0;
  }

  .c-pr-card__top {
    align-items: center;
  }

  .c-pr-card__title {
    font-size: $fs-3xl;
  }

  .c-pr-card__inner {
    display: flex;
    gap: 24px;
    padding: 16px 20px 0;
  }

  .c-pr-card__bottom {
    padding: 0 20px 16px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-pr-card {
    margin: 0 -24px;
  }

  .c-pr-card__bottom {
    margin-top: 12px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-pr-card {
    margin: 0;
  }
}
