@charset "utf-8";

.p-brochure-inner__header--complete {
  padding: 24px 0 0;
}

.p-bro-complete {
  margin: 0 auto;
  text-align: center;

  &__title {
    margin-bottom: 16px;
    font-size: $fs-2xl;
    font-weight: bold;
  }

  &__text {
    margin-bottom: 24px;
    font-size: $fs-md;
    line-height: $line-height3;
    text-align: left;

    br {
      display: none;
    }
  }

  &__text--bold {
    font-weight: bold;
  }

  &__link {
    margin-top: 32px;
  }

  &__contact > .c-contact {
    padding-right: 0;
    padding-left: 0;
  }
}

.p-bro-complete__img {
  width: 180px;
}

.p-bro-complete__contact {
  margin: 16px 0 40px;
}

.p-bro-complete__button {
  height: 48px;
  margin-top: 24px;
  line-height: $line-height-px5;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochure-inner__header--complete {
    padding-top: 32px;
  }

  .p-bro-complete {
    max-width: 960px;
    padding: 32px 40px;
    border-radius: $radius-size-medium;
    box-shadow: $shadow-size-medium;
  }

  .p-bro-complete__img {
    width: 240px;
  }

  .p-bro-complete__title {
    font-size: $fs-4xl;
  }

  .p-bro-complete__text {
    font-size: $fs-lg;
    line-height: $line-height3;
    text-align: center;

    br {
      display: block;
    }
  }

  .p-bro-complete__contact {
    max-width: 400px;
    margin: 0 auto 40px;
  }

  .p-bro-complete__button {
    max-width: 400px;
    margin: 24px auto;
  }
}
