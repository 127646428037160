@charset "utf-8";

/*doc
---
title: line
name: line
category: objects
---

```html_example
<h2 style="margin: 24px 0;">一行で表示、アイテム間隔は8px、行間4px</h2>
<div class="o-line o-line--small" style="width: 100px;">
  <div class="o-line__item">item</div>
  <div class="o-line__item">item</div>
  <div class="o-line__item">item</div>
  <div class="o-line__item">item</div>
</div>
```
*/

.o-line {
  display: flex;
  flex-wrap: wrap;
}

.o-line--small {
  margin-bottom: -4px;

  & .o-line__item {
    margin-right: 8px;
    margin-bottom: 4px;
  }

  & .o-line__item:last-child {
    margin-right: 0;
  }
}
