@charset "utf-8";

.p-review-jigyosho {
  padding: 24px;
}

.p-review-jigyosho__head {
  margin-bottom: 8px;
  font-family: $font-family-700bold;
  font-size: $fs-2xl;
  font-weight: $font-weight-700bold;
}

.p-review-jigyosho__link-large {
  margin: 8px 0;
  font-size: $fs-xl;
  font-weight: bold;
}

.p-review-jigyosho__link {
  margin-bottom: 12px;
  font-size: $fs-md;
}

.p-review-jigyosho__address {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.p-review-jigyosho__address__text {
  font-weight: bold;
  color: $honne-gray-600;
}

.p-review-jigyosho__address__link {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-weight: bold;
}

.p-review-jigyosho__category {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 8px;

  .c-jigyosho-summary__cashback {
    margin: 0;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-review-jigyosho {
    padding: 0;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-review-jigyosho {
    max-width: 1124px;
  }
}
