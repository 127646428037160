@charset "utf-8";

.p-guide-index {
  display: flex;
  padding: 16px 16px 14px;
  margin-top: 24px;
  background: $honne-yellow-white;
  border-radius: 8px;
}

.p-guide-index__title {
  width: 44px;
  min-width: 44px;
  color: $honne-gray-600;
}

.p-guide-index__list-item:not(:last-child) {
  margin-bottom: 12px;
}

.p-guide-index__list-link:before {
  display: inline-block;
  content: "・";
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-guide-index {
    display: flex;
    padding: 16px 32px;
  }

  .p-guide-index__title {
    width: 52px;
    min-width: 52px;
  }
}
