@charset "utf-8";

@mixin heading-color($color) {
  position: relative;
  padding-left: 16px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 100%;
    content: "";
    background: $color;
    border-radius: 1px;
  }
}
