@charset "utf-8";

.p-footer-line {
  overflow: hidden;
  background-color: $honne-yellow-white;
  border: solid 1px $line-brand-color;
  border-radius: 8px;
}

.p-footer-line__text {
  padding: 16px 8px 16px 24px;
  text-align: center;
  background-color: $line-brand-color;
}

.p-footer-line__name {
  display: inline-block;
  padding: 4px 8px;
  margin-bottom: 8px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $line-brand-color;
  background-color: $honne-white;
  border-radius: 4px;
}

.p-footer-line__copy {
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-white;
}

.p-footer-line__action {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.p-footer-line__action__text {
  display: none;
}

.p-footer-line__action__qr {
  display: none;
}

.p-footer-line__action__button {
  width: 100%;
}

/* 480px ~ */
@include min-screen($breakpoint-sp) {
  .p-footer-line__name {
    font-size: $fs-sm;
  }

  .p-footer-line__copy {
    font-size: $fs-2xl;
  }

  .p-footer-line__action__text {
    display: block;
    margin-right: 16px;
    font-size: $fs-md;
    font-weight: bold;
    color: $honne-gray-500;
  }

  .p-footer-line__action__text--green {
    font-size: $fs-2md;
    color: $line-brand-color;
  }

  .p-footer-line__action__qr {
    display: block;
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }

  .p-footer-line__action__button {
    width: 190px;
  }
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .p-footer-line {
    display: flex;
  }

  .p-footer-line__name {
    font-size: $fs-2md;
  }

  .p-footer-line__copy {
    font-size: $fs-4xl;
  }

  .p-footer-line__text {
    text-align: left;
  }

  .p-footer-line__action {
    justify-content: flex-start;
    padding: 16px 16px 16px 48px;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      content: "";
      border-top: 100px solid transparent;
      border-bottom: 100px solid transparent;
      border-left: 26px solid $line-brand-color;
      transform: translateY(-50%);
    }
  }
}
