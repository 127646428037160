@charset "utf-8";

/* 検索条件 */
.p-search-cond {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $jigyosho-search-modal;
  display: none;
}

.p-search-cond__frame {
  height: 100%;
}

.p-search-cond__inner {
  z-index: $jigyosho-search-modal-inner;
  height: 100%;
  background-color: $honne-white;
}

.p-search-cond__head {
  display: flex;
  align-items: center;
  border-bottom: solid 1px $honne-gray-200;
}

.p-search-cond__head__title {
  flex: 1;
  padding: 16px 0 16px 24px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-search-cond__head__closer {
  width: 32px;
  height: 32px;
  padding-top: 48px;
  padding-right: 66px;
  margin-left: auto;
  cursor: pointer;
  background: url("~images/icon/close.svg") no-repeat center;
  background-size: 16px 16px;
}

.p-search-cond__content {
  height: calc(100% - 146px);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  @include scrollbar-none;
}

.p-search-cond__footer {
  display: flex;
  padding: 16px;
  font-weight: bold;
  border-top: solid 1px $honne-gray-200;
}

.p-search-cond__footer__left {
  display: flex;
  flex-direction: column;
  min-width: 90px;
  padding: 4px 0;
  margin-right: 8px;
}

.p-search-cond__footer__text {
  font-size: $fs-xs;
}

.p-search-cond__footer__count {
  font-size: $fs-2xl;
  color: $honne-red-bright;
}

.p-search-cond__footer__button {
  padding: 16px;
  font-size: $fs-lg;
  cursor: pointer;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-cond {
    position: static;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .p-search-cond__head {
    border-top: 3px solid $honne-blue;
  }

  .p-search-cond__head__title {
    padding: 16px;
    font-size: $fs-xl;
  }

  .p-search-cond__head__closer {
    display: none;
  }

  .p-search-cond__content {
    height: auto;
    padding: 16px;
  }

  .p-search-cond__footer {
    flex-direction: column;
  }

  .p-search-cond__footer__left {
    flex-direction: row;
    align-items: baseline;
    padding: 0;
    margin-right: 0;
    margin-bottom: 8px;
  }
}
