@charset "utf-8";

.c-tag-group {
  margin: 24px 0;
  background-color: $honne-white;

  &__head {
    padding: 16px 0;
    font-size: $fs-md;
    font-weight: bold;
    color: $honne-gray-700;
    text-align: center;
    background-color: $honne-gray-100;
    border: solid 1px $honne-gray-300;
  }

  &__content {
    padding: 16px;
    font-size: $fs-md;
    font-weight: bold;
    line-height: $line-height-base;
    color: $honne-gray-600;
    border: solid 1px $honne-gray-300;
    border-top: none;
  }

  &__list {
    margin-left: 24px;
  }

  &__list__item {
    list-style: disc outside;
  }
}
