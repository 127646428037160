@charset "utf-8";

/*doc
---
title: article-visual
name: article-visual
category: components
---

```html_example
<div class="c-article-visual">
  <img src="http://placehold.it/700x470">
</div>
```
*/

.c-article-visual {
  margin: 0 -24px 24px;

  & img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-article-visual {
    margin: 0 0 32px;
  }
}
