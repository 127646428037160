@charset "utf-8";

.p-market-price-top__main {
  position: relative;
}

.p-market-price-top__img {
  width: 100%;
  height: 350px;
  vertical-align: bottom;
  object-fit: cover;
}

.p-market-price-top__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.p-market-price-top__block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px;
}

.p-market-price-top__h1 {
  font-family: $font-family-700bold;
  font-size: $fs-4xl;
  font-weight: $font-weight-700bold;
  line-height: $line-height-px4;
  color: $honne-white;
  text-shadow: rgba($honne-black, 0.6) 0 0 32px;
  letter-spacing: 1px;
}

.p-market-price-top__title {
  padding: 24px 16px 8px 16px;
  background: linear-gradient(rgba($honne-black, 0.63), rgba($honne-black, 0));
}

.p-market-price-top__cost {
  display: flex;
  height: 70px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
}

.p-market-price-top__cost__title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  font-size: $fs-lg;
  font-weight: $font-weight-700bold;
  color: $honne-black;
  background-color: $honne-blue-bright;
  border-radius: 8px 0 0 8px;
}

.p-market-price-top__cost__text {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: $font-size-big1;
  font-weight: $font-weight-700bold;
  color: $honne-blue;
  background-color: $honne-white;
  border-radius: 0 8px 8px 0;
}

.p-market-price-top__search {
  margin: 16px auto 0 auto;
  text-align: center;
}

.p-market-price-top__search__text {
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-blue-lighten;
}

.p-market-price-top__search__link {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-blue-lighten;
  background-color: $honne-white;
  border-radius: 8px;
}

.p-market-price-top__amount {
  font-size: $fs-xl;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-market-price-top__img {
    height: 250px;
  }

  .p-market-price-top__block {
    flex-direction: row;
    gap: 12px;
    align-items: flex-end;
    justify-content: center;
    padding: 48px 12px 0 12px;
    margin: 0;
  }

  .p-market-price-top__cost {
    flex: 1;
    max-width: 260px;
  }

  .p-market-price-top__cost__title {
    width: 90px;
  }

  .p-market-price-top__search {
    margin: 0;
  }

  .p-market-price-top__search__link {
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-market-price-top {
    overflow: hidden;
    border-radius: 8px 8px 0 0;
  }

  .p-market-price-top__h1 {
    font-size: $font-size-big1;
  }

  .p-market-price-top__title {
    padding: 24px 32px 8px 32px;
  }
}
