@charset "utf-8";

/*doc
---
title: more-responsive
name: more-responsive
category: components
---
```html_example
<div class="c-more-responsive js-more-responsive__trigger">
  <div class="c-more-responsive__inner">
    <div class="c-link c-more-responsive__link">
      <span class="c-link__text">続きを読む</span>
      <i class="c-link__icon c-icon--x-small c-icon-arrow-down-blue"></i>
    </div>
  </div>
</div>
```
*/

.c-more-responsive {
  position: relative;
  height: 30px;
  cursor: pointer;
}

.c-more-responsive__inner {
  position: relative;
  padding-bottom: 10px;
  text-align: center;
}

.c-more-responsive__inner:before {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 90px;
  content: "";
  background: linear-gradient(
    360deg,
    #ffffff 43.53%,
    rgba(255, 255, 255, 0.67152) 74.48%,
    rgba(255, 255, 255, 0.0001) 100%
  );
}

.c-more-responsive__link {
  position: relative;
}

.c-more-responsive:hover .c-more-responsive__link {
  opacity: 0.5;
}
