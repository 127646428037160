@charset "utf-8";

// 命名 typo-{font-size}-{line-height}-{letter-spacing}

// 26px / 1.5 / 0.08em
@mixin typo-5xl-sm-md {
  font-size: $fs-5xl;
  line-height: $lh-small;
  letter-spacing: $ls-medium;
}

// 24px / 1.5 / 0.08em
@mixin typo-4xl-sm-md {
  font-size: $fs-4xl;
  line-height: $lh-small;
  letter-spacing: $ls-medium;
}

// 22px / 1.5 / 0.08em
@mixin typo-3xl-sm-md {
  font-size: $fs-3xl;
  line-height: $lh-small;
  letter-spacing: $ls-medium;
}

// 20px / 1.5 / 0.05em
@mixin typo-2xl-sm-sm {
  font-size: $fs-2xl;
  line-height: $lh-small;
  letter-spacing: $ls-small;
}

// 18px / 1.5 / 0.05em
@mixin typo-xl-sm-sm {
  font-size: $fs-xl;
  line-height: $lh-small;
  letter-spacing: $ls-small;
}

// 16px / 1.5 / 0.05em
@mixin typo-lg-sm-sm {
  font-size: $fs-lg;
  line-height: $lh-small;
  letter-spacing: $ls-small;
}

// 16px / 1.8 / 0.08em
@mixin typo-lg-lg-md {
  font-size: $fs-lg;
  line-height: $lh-large;
  letter-spacing: $ls-medium;
}

// 15px / 2 / 0.08em
@mixin typo-2md-xl-md {
  font-size: $fs-2md;
  line-height: $lh-x-large;
  letter-spacing: $ls-medium;
}

// 14px / 2 / 0.08em
@mixin typo-md-xl-md {
  font-size: $fs-md;
  line-height: $lh-x-large;
  letter-spacing: $ls-medium;
}

// 13px / 2 / 0.08em
@mixin typo-sm-xl-md {
  font-size: $fs-sm;
  line-height: $lh-x-large;
  letter-spacing: $ls-medium;
}
