@charset "utf-8";

/*doc
---
title: radius
name: radius
category: settings
---

```html_example
<div style="border-radius: 2px; display: flex;align-items: center;justify-content: center; font-size: 18px; font-weight: bold; color: #fff; background-color: #009999; height: 120px;">x-small</div>
<div style="border-radius: 4px; display: flex;align-items: center;justify-content: center; font-size: 18px; font-weight: bold; color: #fff; background-color: #009999; height: 120px;margin-top: 10px;">small</div>
<div style="border-radius: 8px; display: flex;align-items: center;justify-content: center; font-size: 18px; font-weight: bold; color: #fff; background-color: #009999; height: 120px;margin-top: 10px;">medium</div>
<div style="border-radius: 16px; display: flex;align-items: center;justify-content: center; font-size: 18px; font-weight: bold; color: #fff; background-color: #009999; height: 120px;margin-top: 10px;">large</div>
```
*/

$radius-size-x-small: 2px;
$radius-size-small: 4px;
$radius-size-medium: 8px;
$radius-size-large: 16px;
