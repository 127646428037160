@charset "utf-8";

.p-top-search {
  padding: 24px 0;
  margin-bottom: 48px;
}

.p-top-search__content {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.p-top-search__image {
  width: 60px;
  height: 60px;
  margin-right: 12px;
}

.p-top-search__text {
  span {
    display: block;
    margin-top: 2px;
    font-weight: normal;
    line-height: $line-height-base;
  }
}

.p-top-search__link {
  margin-top: 16px;
  letter-spacing: $letter-spacing-px5;
}

/* 481px ~ 768px */
@include screen($breakpoint-tablet-min, $breakpoint-tablet-max) {
  .p-top-search__main {
    width: 100%;
    max-width: 530px;
    margin: 0 auto;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-search {
    padding: 40px 0;
    margin-bottom: 72px;
  }

  .p-top-search__image {
    width: 90px;
    height: 90px;
  }

  .p-top-search__main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .p-top-search__content {
    width: calc((100vw - 72px) / 3);
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-search__text {
    width: 247px;
    font-size: $fs-xl;
    line-height: $line-height-xx-large;

    span {
      margin-top: 4px;
      line-height: $line-height9;
    }
  }

  .p-top-search__image {
    width: 80px;
    height: 80px;
    margin-right: 16px;
  }

  .p-top-search__content {
    width: 347px;
  }
}
