@charset "utf-8";

.p-special4 {
  min-width: $breakpoint-sp-min;
  background-color: $honne-blue-bright;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special4 {
    padding-bottom: 40px;
  }
}
