@charset "utf-8";

.c-markup__article {
  .c-mp-guide {
    @include reset-markup();

    .c-guide__title {
      font-weight: bold;
    }
  }
}
