@charset "utf-8";

.p-ownership__jigyosho-image {
  margin-bottom: 32px;
  overflow: hidden;
}

.p-ownership__jigyosho-image__h3 {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-ownership__jigyosho-image__list {
  position: relative;
  margin-bottom: 24px;
}

.p-ownership__jigyosho-image__list__container {
  position: relative;
  display: flex;
  flex-flow: nowrap;
  overflow: scroll;
  @include scrollbar-none;
}

.p-ownership__jigyosho-image__list__item {
  float: left;
  margin: 0 8px;
}

.p-ownership__jigyosho-image__list__item__img {
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.p-ownership__jigyosho-image__next {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-1;
  display: none;
  width: 20%;
}

.p-ownership__jigyosho-image__next__icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  background-color: rgba(0, 0, 0, 0.5);

  background-image: url("~images/icon/arrow-right-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.p-ownership__jigyosho-image__prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-1;
  display: none;
  width: 20%;
}

.p-ownership__jigyosho-image__prev__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("~images/icon/arrow-left-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}
