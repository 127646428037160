.p-lp-online-case {
  margin-bottom: 24px;
}

.p-lp-online-case__title {
  margin-bottom: 16px;
  font-size: $fs-2xl;
  font-weight: bold;
  text-align: center;
}

.p-lp-online-case-list__item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.p-lp-online-case-list__image {
  width: 50px;
  margin-right: 16px;

  img {
    width: 100%;
  }
}

.p-lp-online-case-list__text {
  font-size: $fs-lg;
  font-weight: bold;
}

@include min-screen($breakpoint-pc-small-min) {
  .p-lp-online-case-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-lp-online-case-list__item {
    &:not(:last-child) {
      margin-right: 24px;
      margin-bottom: 0;
    }
  }
}

@include min-screen($breakpoint-pc-max) {
  .p-lp-online-case__title {
    margin-bottom: 24px;
    font-size: $fs-4xl;
  }

  .p-lp-online-case-list__item {
    &:not(:last-child) {
      margin-right: 40px;
      margin-bottom: 0;
    }
  }

  .p-lp-online-case-list__text {
    font-size: $fs-lg;
  }
}
