@charset "utf-8";

/*doc
---
title: special-small-card
name: special-small-card
category: components
---
```html_example
<a class="c-special-small-card" href="#">
  <img class="c-special-small-card__img" src="http://placehold.it/84x84">
  <div>
    <h3 class="c-special-small-card__title">二人部屋ありの施設特集</h3>
    <p class="c-special-small-card__desc">家を離れても夫婦で暮らしたい方に。二人でも入居可能な施設を集めました。</p>
  </div>
</a>
```
*/

.c-special-small-card {
  display: flex;
  background-color: $honne-white;
}

.c-special-small-card__img {
  width: 72px;
  height: 72px;
  margin-right: 12px;
  object-fit: cover;
  border-radius: 4px;
}

.c-special-small-card__img + div {
  width: calc(100% - 84px);
}

.c-special-small-card__title {
  margin-bottom: 2px;
  font-size: $fs-2md;
  color: $honne-gray-700;
  letter-spacing: $ls-small;
}

.c-special-small-card__desc {
  font-size: $fs-sm;
  line-height: $lh-large;
  color: $honne-gray-500;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-special-small-card__img {
    width: 84px;
    height: 84px;
    border-radius: 8px;
  }

  .c-special-small-card__title {
    margin-bottom: 4px;
    font-size: $fs-lg;
  }

  .c-special-small-card__desc {
    font-size: $fs-md;
  }
}
