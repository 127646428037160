@charset "utf-8";

.p-review-nav {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 12px 24px;
}

.p-review-nav__link {
  padding: 12px 24px;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-review-nav {
    padding: 16px 0;
  }

  .p-review-nav__link {
    width: 240px;
  }
}
