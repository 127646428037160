.p-ownership-search {
  padding: 16px;
  margin-bottom: 24px;
  font-weight: bold;
  line-height: $lh-large;
  background-color: $honne-white;
  border-radius: 8px;
}

.p-ownership-search__areas {
  display: flex;
  flex-wrap: wrap;
}

.p-ownership-search__area {
  margin-right: 16px;
  font-size: $fs-md;

  a {
    text-decoration: underline;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-ownership-search {
    display: flex;
    align-items: center;
  }
}
