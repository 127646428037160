@charset "utf-8";

.p-cash-form {
  max-width: 960px;
  padding-top: 24px;
  margin: 0 auto 32px;

  & .c-form__field--small .c-form__field__head {
    min-width: 100px;
  }
}

.p-cash-form__h1 {
  margin-bottom: 24px;
  font-size: $fs-3xl;
  font-weight: bold;
}

.p-cash-form__h2 {
  position: relative;
  padding-left: 16px;
  margin: 32px 0 16px;
  font-size: $fs-xl;
  font-weight: bold;
}

.p-cash-form__h2:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 6px;
  height: 100%;
  content: "";
  background: $honne-blue;
  border-radius: 1px;
}

.p-cash-form__desc {
  margin: 12px 0;
  font-size: $fs-md;
}

.p-cash-form__button {
  max-width: 400px;
  margin: 40px auto 0;
}

.ui-helper-hidden-accessible {
  display: none;
}

.p-cash-form__bottom {
  display: flex;
  gap: 16px;
  justify-content: center;
}
