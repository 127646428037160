@charset "utf-8";

.p-condition__main {
  margin-bottom: 32px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-condition__main {
    margin-top: 32px;
  }
}
