@charset "utf-8";

.u-pt-24 {
  padding-top: 24px !important;
}

.u-pb-8 {
  padding-bottom: 8px !important;
}

.u-pl-24 {
  padding-left: 24px !important;
}
