@charset "utf-8";

/*doc
---
title: faq
name: faq
category: components
---
```html_example
<div class="c-faq c-faq--border">
  <div class="c-faq__item">
    <div class="c-faq__button">
      介護のほんねはどんなことができるサービスですか？
      <i class="c-icon-arrow-down-blue c-faq__button__arrow"></i>
    </div>
    <div class="c-faq__answer">
      <p>介護のほんねは納得できる施設がすぐに見つかる老人ホーム・介護施設の検索サイトです。介護のほんねのプロの入居相談員がご要望に沿ったぴったりな施設をご紹介し、ご入居まで無料でフルサポートさせていただきます。</p>
    </div>
  </div>
  <div class="c-faq__item">
    <div class="c-faq__button">
      介護や老人ホーム探しなどの相談に利用料はかかりますか？
      <i class="c-icon-arrow-down-blue c-faq__button__arrow"></i>
    </div>
    <div class="c-faq__answer">
      <p>下記のような原因が考えられます。</p>
      <ol class="c-faq__answer__ol">
        <li class="c-faq__answer__ol__li">
          <b>メールアドレスを誤って入力している</b>
          <p>→ お手数ですが、正しいメールアドレスで、再度 <a href="#">会員登録ページ</a> を行ってください。</p>
        </li>
        <li class="c-faq__answer__ol__li">
          <b>迷惑メールフォルダに紛れてしまっている</b>
          <p>→ 場合によっては、届いたメールが迷惑メールフォルダに振り分けられてしまうことがあるため、迷惑メールフォルダにメールが届いていないか、ご確認ください。</p>
        </li>
      </ol>
    </div>
  </div>
</div>
```
*/

.c-faq--border {
  border-top: 1px solid $honne-gray-300;
  border-bottom: 1px solid $honne-gray-300;
}

.c-faq__item {
  position: relative;
}

.c-faq__item + .c-faq__item {
  border-top: 1px solid $honne-gray-300;
}

.c-faq__item:before {
  position: absolute;
  top: 16px;
  left: 24px;
  display: block;
  width: 24px;
  height: 24px;
  content: "";
  background: url("~images/faq/icon-q.png");
  background-size: 24px;
}

.c-faq__button {
  position: relative;
  display: block;
  padding: 16px 40px 16px 64px;
  font-size: $fs-2md;
  font-weight: bold;
  line-height: $line-height-large;
  letter-spacing: $letter-spacing-px5;
}

.c-faq__button__arrow {
  position: absolute;
  top: calc(50% - 7px);
  right: 16px;
  width: 11px;
}

.c-faq__answer {
  position: relative;
  padding: 0 40px 16px 64px;
  font-size: $fs-md;
  line-height: $line-height-large;
  letter-spacing: $letter-spacing-px5;
}

.c-faq__answer--bold {
  font-weight: bold;
}

.c-faq__answer--small {
  font-size: $fs-xs;
}

.c-faq__answer:before {
  position: absolute;
  top: 0;
  left: 24px;
  display: block;
  width: 24px;
  height: 24px;
  content: "";
  background: url("~images/faq/icon-a.png");
  background-size: 24px;
}

.c-faq__answer__blank {
  margin-bottom: 12px;
}

.c-faq__answer__ol {
  margin: 16px 0 0 16px;
}

.c-faq__answer__ol__li {
  margin: 0 0 16px 0;
  list-style: decimal;
}

.c-faq__answer__ul {
  margin: 16px 0 0 16px;
}

.c-faq__answer__ul__li {
  margin: 0 0 8px 0;
  list-style: disc;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-faq {
    padding-right: 24px;
    padding-left: 24px;
  }

  .c-faq__item:before {
    top: 18px;
    left: 0;
  }

  .c-faq__button {
    padding: 16px 16px 12px 48px;
    font-size: $fs-lg;
    cursor: pointer;
  }

  .c-faq__answer {
    padding: 0 16px 16px 48px;
    font-size: $fs-2md;
    line-height: $line-height3;
  }

  .c-faq__answer:before {
    top: 4px;
    left: 0;
  }
}
