@charset "utf-8";

.p-cash-top-pickup__title {
  margin-bottom: 4px;
  font-size: $fs-lg;
}

.p-cash-top-pickup__desc {
  @include typo-md-xl-md;
  margin-bottom: 12px;
}
