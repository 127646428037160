@charset "utf-8";

.p-regist-caremanager-flow {
  padding-top: 40px;
  padding-bottom: 40px;
}

.p-regist-caremanager-flow__top {
  padding: 0 24px;
  text-align: center;
}

.p-regist-caremanager-flow__title {
  margin-bottom: 16px;
  font-size: $fs-4xl;
  font-weight: bold;
}

.p-regist-caremanager-flow__desc {
  margin-bottom: 32px;
  line-height: $line-height3;
}

.p-regist-caremanager-flow__step {
  max-width: 960px;
  margin: 0 auto 8px;
  background-color: color-lighten($honne-blue-bright, 3.5%);
}

.p-regist-caremanager-flow__step__inner {
  padding: 24px;
}

.p-regist-caremanager-flow__step__title {
  margin-bottom: 8px;
  font-size: $fs-xl;
  text-align: center;
}

.p-regist-caremanager-flow__step__desc {
  font-size: $fs-sm;
}

.p-regist-caremanager-flow__bottom {
  padding: 24px 24px 0;
}

.p-regist-caremanager-flow__bottom__desc {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $line-height9;
  text-align: center;
}

.p-regist-caremanager-flow__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-caremanager-flow {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .p-regist-caremanager-flow__title {
    font-size: $font-size-big3;
  }

  .p-regist-caremanager-flow__desc {
    display: flex;
    flex-direction: column;
    font-size: $fs-lg;
  }

  .p-regist-caremanager-flow__step {
    margin-bottom: 16px;
  }

  .p-regist-caremanager-flow__step__title {
    margin-bottom: 8px;
    font-size: $fs-3xl;
  }

  .p-regist-caremanager-flow__step__desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $fs-lg;
    line-height: $line-height3;
  }

  .p-regist-caremanager-flow__bottom__desc {
    font-size: $fs-3xl;
  }

  .p-regist-caremanager-flow__text {
    flex-direction: row;
  }

  .p-regist-caremanager-flow__step__inner {
    padding: 32px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-regist-caremanager-flow__step {
    border-radius: 8px;
  }
}
