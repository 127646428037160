@charset "utf-8";

.c-guide-about-main__item {
  padding: 16px 24px 24px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.c-guide-about-main__item:not(:last-child) {
  margin-bottom: 16px;
}

.c-guide-about-main__icon {
  width: 90px;
  height: 90px;
  margin: 0 auto 8px;

  img {
    width: 100%;
  }
}

.c-guide-about-main__head {
  margin-bottom: 8px;
  text-align: center;
}

.c-guide-about-main__strong {
  display: block;
  font-size: $fs-lg;
}

.c-guide-about-main__description {
  margin-bottom: 8px;
  font-size: $fs-md;
  line-height: $line-height-x-large;
  color: $honne-gray-500;
}

.c-guide-about-main__more {
  a {
    justify-content: center;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-guide-about-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-guide-about-main__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(50vw - 44px);
    padding: 24px 32px;
  }

  .c-guide-about-main__icon {
    width: 100px;
    height: 100px;
    margin: 0 12px 0 0;
  }

  .c-guide-about-main__wrap {
    width: calc(50vw - 102px);
  }

  .c-guide-about-main__head {
    margin-bottom: 4px;
    font-size: $fs-lg;
    line-height: $line-height-px3;
    text-align: left;
  }

  .c-guide-about-main__strong {
    font-size: $fs-xl;
  }

  .c-guide-about-main__more {
    a {
      justify-content: flex-end;
    }
  }

  .c-guide-about-main__item:nth-child(-n + 2) {
    margin-bottom: 16px;
  }

  .c-guide-about-main__item:nth-child(n + 3) {
    margin-bottom: 0;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-guide-about-main {
    width: 1080px;
    margin: 0 auto;
  }

  .c-guide-about-main__item {
    width: 532px;
  }

  .c-guide-about-main__wrap {
    width: 100%;
  }
}
