.p-ownership-jigyoshos__head {
  margin: 16px 0;
}

.p-ownership-jigyoshos__head__line {
  display: block;
  font-size: $fs-md;
}

.p-ownership-jigyoshos__head__main {
  margin-bottom: 10px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-ownership-jigyoshos__head--red {
  color: $honne-red-bright;
}

.p-ownership-jigyoshos__pager {
  margin-top: 24px;
}

/* 481px ~ 768px */
@include screen($breakpoint-tablet-min, $breakpoint-tablet-max) {
  .p-ownership-jigyoshos {
    padding-bottom: 16px;
  }
}

@include min-screen($breakpoint-pc-small-min) {
  .p-ownership-jigyoshos__head__line {
    font-size: $fs-xl;
  }
}
