@charset "utf-8";

.p-special3-top__kv-top {
  position: relative;
  width: 100%;
  height: auto;
  padding: 216px 24px 18px 24px;
}

.p-special3-top__kv-top-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-special3-top__kv-bottom {
  display: flex;
  align-items: flex-end;
  /* heightを設定しないと表示されない */
  min-height: 362px;
  padding: 0 24px 24px 24px;
  background-image: url("~images/lp/special3/kv2_sp.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.p-special3-top__kv-bottom__container {
  width: 100%;
  margin: 0 auto;
}

.p-special3-top__container {
  position: relative;
  color: $honne-white;
}

.p-special3-top__title {
  margin-bottom: 12px;
  font-size: $font-size-big1;
  line-height: $line-height6;
  text-align: left;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.p-special3-top__br-pc {
  display: none;
}

.p-special3-top__br-sp {
  display: block;
}

.p-special3-top__caption {
  margin: 0 0 12px 4px;
  font-size: $fs-xs;
  font-weight: bold;
  line-height: initial;
  text-align: left;
}

.p-special3-top__action {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 24px 12px;
  margin-bottom: 12px;
  line-height: $lh-large;
  background-color: $honne-red-bright;
  border-radius: 8px;
}

@include link-color("p-special3-top__action__link", $honne-white);

.p-special3-top__action__name {
  display: none;
}

.p-special3-top__action__tel {
  font-size: $font-size-big1;
  font-weight: bold;
  line-height: initial;
  letter-spacing: $letter-spacing-px2;

  &::before {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    content: "";
    background-image: url("~images/lp/special/tel_w.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.p-special3-top__action__time {
  font-size: $fs-2xs;
  font-weight: normal;
  text-align: center;
}

.p-special3-top__action__button {
  font-size: $fs-md;
  font-weight: bold;
  background-color: $honne-white;
  border-radius: 8px;
}

@include link-color("p-special3-top__action__button", $honne-red-bright);

.p-special3-top__intro {
  display: block;
  width: 100%;
  padding: 16px;
  font-weight: bold;
  letter-spacing: $letter-spacing-px6;
  background-color: $honne-white;
  border-radius: 30px;
}

.p-special3-top__intro__desc {
  margin-bottom: 12px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $lh-large;
  text-align: center;
}

.p-special3-top__intro__list {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: fit-content;
  margin: auto;
  list-style: none;
}

.p-special3-top__intro__list__item {
  position: relative;
  padding: 0 0 0 1em;
  font-size: $fs-md;
  font-weight: bold;
  letter-spacing: $letter-spacing-px6;
}

.p-special3-top__intro__list__item::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 0.3em solid transparent;
  border-left: 0.4em solid $honne-blue;
  transform: translateY(-50%);
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special3-top__kv-top {
    max-width: 1440px;
    min-height: 533px;
    padding: 0;
    margin: 0 auto;
    background-size: auto;
  }

  .p-special3-top__kv-top-image {
    right: 0;
    left: auto;
    width: 885px;
    height: auto;
  }

  .p-special3-top__kv-bottom {
    align-items: first baseline;
    min-height: 510px;
    background-image: url("~images/lp/special3/kv2_pc.png");
  }

  .p-special3-top__kv-bottom__container {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
  }

  .p-special3-top__container {
    position: relative;
    display: flex;
    padding: 108px 0 0 186px;
    color: $honne-gray-800;
  }

  .p-special3-top__title {
    margin-bottom: 16px;
    font-size: $font-size-big5;
    line-height: $line-height1;
    text-shadow:
      1px 1px 2px $honne-white,
      0 0 1em $honne-white,
      0 0 0.2em $honne-white;
  }

  .p-special3-top__br-pc {
    display: block;
  }

  .p-special3-top__br-sp {
    display: none;
  }

  .p-special3-top__caption {
    margin: 0 0 28px 4px;
    font-size: $fs-xl;
  }

  .p-special3-top__action {
    justify-content: space-between;
    width: 100%;
    padding: 12px 0 8px 24px;
    margin: 0;
    background-color: $honne-white;
    border-radius: 59px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
  }

  .p-special3-top__action__link {
    pointer-events: none;
  }

  @include link-color("p-special3-top__action__link", $honne-gray-700);

  .p-special3-top__action__tel {
    font-size: $font-size-big3;

    &::before {
      background-image: url("~images/lp/special/tel.png");
    }
  }

  .p-special3-top__action__time {
    font-size: $fs-xs;
  }

  .p-special3-top__link-wrapper {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 532px;
    margin: 0 auto 12px;
  }

  .p-special3-top__action__button {
    position: absolute;
    width: 200px;
    height: 64px;
    margin: 12px 12px 12px 0;
    font-size: $fs-lg;
    background-color: $honne-red-bright;
    border-radius: 68px;
  }

  @include link-color("p-special3-top__action__button", $honne-white);

  .p-special3-top__intro {
    position: absolute;
    top: -32px;
    right: 200px;
    width: auto;
    padding: 48px 44px 64px 48px;
  }

  .p-special3-top__intro__desc {
    margin-bottom: 40px;
    font-size: $font-size-big2;
    text-align: left;
  }

  .p-special3-top__intro__list__item {
    font-size: $fs-4xl;
    line-height: $lh-large;
  }
}
