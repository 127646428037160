@charset "utf-8";

.p-brochure-user {
  margin-top: 24px;
}

.p-brochure-user__delete {
  justify-content: space-between;
}

.p-brochure-user__delete__inner {
  display: flex;
  align-items: center;
}

.p-brochure-user__delete__icon {
  margin-right: 4px;
}

.p-brochure-user__add {
  display: flex;
  align-items: center;
  margin: 4px 0;
}

.p-brochure-user__add__icon {
  margin-right: 4px;
}

.p-brochure-user__add__note {
  color: $honne-gray-500;
}

.p-brochure-user__add__hint {
  font-size: $fs-3xs;
  color: $honne-gray-500;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochure-user__add__hint {
    font-size: $fs-sm;
  }
}
