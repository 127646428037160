@charset "utf-8";

.p-cash-top-voice__head {
  margin-bottom: 16px;
}

.p-cash-top-voice__blocks {
  border-top: solid 1px $honne-gray-300;
}

.p-cash-top-voice__block {
  padding: 24px 0;
  border-bottom: solid 1px $honne-gray-300;
}

.p-cash-top-voice__top {
  display: flex;
  margin-bottom: 16px;
}

.p-cash-top-voice__top__image {
  width: 50px;
  height: 50px;
  margin-right: 16px;

  & > img {
    max-width: 50px;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.p-cash-top-voice__top__user {
  @include typo-sm-xl-md;
}

.p-cash-top-voice__top__type {
  margin-left: 4px;
}

.p-cash-top-voice__score {
  margin-top: 8px;
}

.p-cash-top-voice__desc {
  @include typo-md-xl-md;
}

.p-cash-top-voice__date {
  font-size: $fs-xs;
  color: $honne-gray-600;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cash-top-voice__top__user {
    display: inline;
    margin-right: 8px;
  }
}
