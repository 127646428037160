@charset "utf-8";

.c-footer {
  background-color: $honne-white;
  border-top: 1px solid $honne-gray-300;
}

.c-footer__main {
  padding: 24px;
}

.c-footer__main__desc {
  padding-bottom: 32px;
  font-size: $fs-xs;
  line-height: $line-height-large;
  color: $honne-gray-600;
  text-align: justify;
  border-bottom: 1px solid $honne-gray-300;
}

.c-footer__top__title {
  margin-bottom: 12px;
  text-align: center;
}

.c-footer__top__logo {
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 300px;
  margin: 0 auto;

  & img {
    width: 220px;
    height: 37px;
    margin-bottom: 8px;
  }
}

.c-footer__sns {
  margin-bottom: 32px;
  text-align: center;
}

.c-footer__sns__image {
  width: 30px;
  margin-right: 8px;
  opacity: 0.4;
}

.c-footer__contact {
  margin-top: 24px;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .c-footer__main {
    padding-right: 32px;
    padding-left: 32px;
  }

  .c-footer__top__logo {
    max-width: 400px;

    & img {
      width: 70%;
      height: 70%;
    }
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-footer {
    padding-top: 48px;
  }

  .c-footer__main__desc {
    max-width: 1080px;
    margin: 0 auto 32px;
    font-size: $fs-xs;
    line-height: $line-height-large;
    color: $honne-gray-600;
    text-align: justify;
  }

  .c-footer__top {
    display: flex;
  }

  .c-footer__top__title {
    margin-bottom: 0;
    text-align: left;
  }

  .c-footer__top__logo {
    display: inline-block;
    width: 45%;
    margin-right: 8px;
    vertical-align: middle;

    & img {
      width: 100%;
      height: 100%;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  .c-footer__sns {
    margin-left: 16px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-footer__main {
    padding: 0;
  }

  .c-footer__top__logo {
    width: 200px;
    margin-left: 0;
  }

  .c-footer__inner {
    padding-bottom: 0;
  }

  .c-footer__sns {
    text-align: left;
  }
}
