@charset "utf-8";

.p-sign__h1 {
  margin-bottom: 16px;
  font-family: $font-family-700bold;
  font-size: $fs-2xl;
  font-weight: $font-weight-700bold;
}

.p-sign-inner__header {
  max-width: 720px;
  margin: 0 auto;
}

.p-sign-inner__header__text {
  font-size: $fs-xl;
  color: $honne-blue;
}

.p-sign {
  padding: 24px 0 2px;
  margin-bottom: 48px;
}

.p-sign__form {
  margin-top: 16px;
}

.p-sign__boxes {
  display: flex;
}

.p-sign__box {
  flex: 1;
  padding: 16px;
  background-color: $honne-white;
  border: 2px solid $honne-gray-400;
  border-radius: $radius-size-medium;
}

.p-sign__box + .p-sign__box {
  margin-left: 16px;
}

.p-sign__button {
  margin-bottom: 24px;
}

.p-sign__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -16px;
}

.p-sign__link {
  margin-top: 16px;
}

.p-sign__link + .p-sign__link {
  margin-left: 16px;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-sign__button {
    width: 80%;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-sign-inner__header {
    display: flex;
    justify-content: space-between;
  }

  .p-sign__h1 {
    margin-bottom: 32px;
    font-size: $fs-5xl;
  }

  .p-sign__form {
    max-width: 720px;
    margin: 0 auto;
    background-color: $honne-white;
    border-radius: $radius-size-medium;
    box-shadow: $shadow-size-medium;
  }

  .p-sign__button {
    margin-bottom: 32px;
  }
}
