@charset "utf-8";

.o-list {
  border-bottom: 1px solid $honne-gray-300;
}

.o-list__item {
  display: block;
  padding: 12px 24px;
  border-top: 1px solid $honne-gray-300;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .o-list {
    border-bottom: none;
  }

  .o-list__item {
    padding: 0;
    border-top: none;
  }

  .o-list--col2 {
    display: flex;
    flex-wrap: wrap;

    .o-list__item {
      width: calc(calc(100% - 32px) / 2);
      margin-right: 32px;
      margin-bottom: 16px;
    }

    .o-list__item:nth-child(2n) {
      margin-right: 0;
    }
  }
}
