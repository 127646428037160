@charset "utf-8";

.p-guideline-ng {
  padding: 12px 0 12px 16px;
  border-left: $honne-gray-400 solid 3px;
}

.p-guideline-ng__head {
  font-size: $fs-xl;
  font-weight: bold;
}

.p-guideline-ng__title {
  margin-top: 16px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-red-bright;
}

.p-guideline-ng__mark {
  margin-right: 8px;
  color: $honne-red-bright;
}
