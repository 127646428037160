@charset "utf-8";

.p-guide__header {
  margin-bottom: 24px;
}

.p-guide__title {
  margin-bottom: 12px;
}

.p-guide__read-text {
  margin: 12px 0 16px;
  line-height: $line-height-px2;
}

.p-guide__date-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.p-guide__date {
  margin-right: 12px;
  font-size: $fs-sm;
  color: $honne-gray-600;
}

.p-guide__search-tel {
  margin-top: 32px;
}

.p-guide__more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  padding: 12px;
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.p-guide__side {
  .c-side-sns {
    background-color: $honne-white;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-guide__inner {
    padding: 32px 48px;
  }

  .p-guide__more {
    margin-top: 24px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-guide__side {
    position: static;
  }
}
