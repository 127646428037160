@charset "utf-8";

.c-tag-disease {
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: $honne-white;
  border-radius: 8px;

  &--yellow {
    background-color: $honne-yellow-white;
  }

  &__h2 {
    margin-bottom: 8px;
    font-weight: bold;
    color: $honne-gray-600;
  }

  &__h3 {
    margin-bottom: 12px;
    font-size: $fs-xl;
    font-weight: bold;
    line-height: $line-height4;
    color: $honne-blue;
  }

  &__h4 {
    margin-bottom: 12px;
    font-size: $fs-md;
    font-weight: bold;
  }

  &__content {
    margin-bottom: 16px;
  }

  &__contact__tel-wrap {
    display: block;
    padding: 8px;
    margin-bottom: 10px;
    text-align: center;
    background-color: $honne-white;
    border: solid 2px $honne-blue;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  &__content--strong {
    font-weight: bold;
    color: $honne-red-bright;
  }

  &__content__p {
    line-height: $line-height10;
  }

  &__content__p + &__content__p {
    margin-top: 8px;
  }

  &__group {
    padding: 24px 16px;
    margin-bottom: 16px;
    background-color: $honne-white;
    border-radius: 8px;
  }

  &__group__title {
    margin-bottom: 16px;
    font-size: $fs-xl;
    color: $honne-blue;
  }

  &__group__items {
    margin-left: 24px;
  }

  &__group__item {
    margin-bottom: 8px;
    font-weight: bold;
    line-height: $line-height-base;
    color: $honne-gray-700;
    list-style: disc outside;
  }

  &__group__sub-items {
    margin-bottom: 8px;

    .c-tag-disease__group__item {
      display: flex;
      margin-bottom: 4px;
      font-weight: normal;
      list-style-type: none;

      &:before {
        width: 4px;
        min-width: 4px;
        height: 1px;
        margin: 10px 8px 0 0;
        content: "";
        border-top: 1px solid $honne-gray-700;
      }
    }
  }

  &__contact {
    margin: 32px auto;
  }

  &__contact__desc {
    font-weight: bold;
    color: $honne-gray-700;
    letter-spacing: $letter-spacing-px8;
  }

  &__contact__img {
    height: 24px;
  }

  &__contact__number {
    margin-left: 8px;
    font-size: $fs-4xl;
    font-weight: bold;
    color: $honne-gray-800;
    letter-spacing: $letter-spacing1;
  }

  &__contact__time {
    font-size: $fs-3xs;
    color: $honne-gray-700;
  }

  &__contact__button {
    position: static;
    width: 100%;
    height: 48px;
    font-size: $fs-lg;
    color: $honne-blue;
    background: $honne-white;

    &:visited {
      color: $honne-blue;
    }
  }

  &__footer {
    font-size: $fs-3xs;
    color: $honne-gray-600;
    text-align: right;
  }
}

.c-tag-disease__lp {
  display: block;
  width: 100%;
  max-width: 280px;
  height: 40px;
  padding: 14px;
  margin: 0 auto;
  color: $honne-white;
  background-color: $honne-blue-lighten;

  &:visited {
    color: $honne-white;
  }
}

@include min-screen($breakpoint-pc-min) {
  .c-tag-disease {
    margin-bottom: 32px;

    &__h3 {
      font-size: $fs-2xl;
    }

    &__h4 {
      font-size: $fs-lg;
    }

    &__group {
      padding: 24px;
    }

    &__contact {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__contact__tel-wrap {
      background: none;
      border: none;
      box-shadow: none;
    }

    &__contact__img {
      margin-top: 4px;
    }

    &__contact__number {
      font-size: $font-size-big3;
    }

    &__contact__time {
      font-size: $fs-lg;
    }

    &__contact__button {
      width: 304px;
      margin-left: 20px;
      font-size: $fs-lg;
      color: $honne-white;
      background: $honne-red-bright;
      border: none;

      &:visited {
        color: $honne-white;
      }
    }
  }
}
