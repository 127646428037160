@charset "utf-8";

.p-brochures-jigyosho {
  display: flex;
}

.p-brochures-jigyosho__img {
  width: 80px;
  margin-right: 16px;

  img {
    vertical-align: middle;
    border-radius: 4px;
  }
}

.p-brochures-jigyosho__address {
  margin-bottom: 2px;
  font-size: $fs-2xs;
  color: $honne-gray-500;
}

.p-brochures-jigyosho__line {
  display: flex;
  align-items: center;
}

.p-brochures-jigyosho__head {
  min-width: 60px;
  margin-right: 12px;
  font-weight: bold;
  color: $honne-gray-800;
}

.p-brochures-jigyosho__number {
  font-weight: bold;
  color: $honne-red-bright;
}

.p-brochures-jigyosho__desc {
  font-size: $fs-2xs;
  color: $honne-gray-500;
}

.p-brochures-jigyosho__price .c-price__number {
  font-size: $fs-md;
}
