@charset "utf-8";

.p-staff-cards {
  margin-bottom: 16px;
}

.p-staff-card {
  padding: 24px;
  background-color: $honne-white;
}

.p-staff-card + .p-staff-card {
  margin-top: 16px;
}

.p-staff-card__top {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.p-staff-card__img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.p-staff-card__name {
  margin-top: 6px;
  margin-bottom: 4px;
  font-size: $fs-xl;
  font-weight: bold;
}

.p-staff-card__date {
  margin-bottom: 4px;
  color: $honne-gray-600;
}

.p-staff-card__qa {
  color: $honne-gray-600;
}

.p-staff-card__block + .p-staff-card__block {
  margin-top: 12px;
}

.p-staff-card__title {
  margin-bottom: 4px;
  font-size: $fs-2md;
  font-weight: bold;
}

.p-staff-card__desc {
  color: $honne-gray-700;
}

.p-staff-card__link {
  justify-content: flex-start;
}

.p-staff-cards__pager {
  margin-bottom: 16px;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-staff-cards {
    margin-bottom: 24px;
  }

  .p-staff-card {
    padding: 32px;
    border-radius: 12px;
  }

  .p-staff-card + .p-staff-card {
    margin-top: 24px;
  }

  .p-staff-card__line {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 4px;
  }

  .p-staff-card__name {
    margin: 0;
  }

  .p-staff-card__date {
    margin-bottom: 0;
  }

  .p-staff-cards__pager {
    margin-bottom: 24px;
  }
}
