@charset "utf-8";

.c-jg-selfpayment-table {
  width: 100%;
  border-top: solid 1px $honne-gray-300;
}

.c-jg-selfpayment-table__service {
  display: block;
  width: 100%;
  margin-bottom: 32px;
  table-layout: fixed;

  & thead {
    display: block;
    float: left;
    width: 20%;
  }

  & thead tr {
    width: 100%;
  }

  & thead tr th {
    height: 50px;
  }

  & tbody {
    display: flex;
    width: 80%;
  }

  & tbody tr {
    width: 100%;
    margin-left: -6px;

    &:first-child {
      margin-left: -1px;
    }
  }

  & tbody tr td {
    height: 50px;
  }
}

.c-jg-selfpayment-table__service__head {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  margin-bottom: -1px;
  font-size: $fs-sm;
  font-weight: bold;
  line-height: $line-height6;
  background-color: $honne-gray-100;
  border: solid 1px $honne-gray-300;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-jg-selfpayment-table__service__col {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  margin-bottom: -1px;
  font-size: $fs-sm;
  font-weight: bold;
  line-height: $line-height6;
  background-color: $honne-white;
  border: solid 1px $honne-gray-300;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-jg-selfpayment-table__service__row {
  display: inline-block;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .c-jg-selfpayment-table {
    width: 100%;
    border-top: solid 1px $honne-gray-300;
  }

  .c-jg-selfpayment-table__head {
    border-bottom: solid 1px $honne-gray-300;
  }

  .c-jg-selfpayment-table__service {
    & thead {
      display: block;
      float: none;
      width: 100%;
      margin-bottom: -1px;
    }

    & thead tr {
      width: auto;
    }

    & tbody {
      display: block;
      width: auto;
    }

    & tbody tr {
      width: auto;
      margin-left: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .c-jg-selfpayment-table__service__head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 4px 8px;
    margin-left: -1px;
    font-size: $fs-sm;
    font-weight: bold;
    line-height: $line-height6;
    background-color: $honne-gray-100;
    border: solid 1px $honne-gray-300;

    &:first-child {
      margin-left: 0;
    }
  }

  .c-jg-selfpayment-table__service__row {
    display: flex;
    margin-bottom: -1px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .c-jg-selfpayment-table__service__col {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 8);
    height: 48px;
    padding: 4px 8px;
    margin-left: -1px;
    font-size: $fs-sm;
    font-weight: bold;
    line-height: $line-height6;
    background-color: $honne-white;
    border: solid 1px $honne-gray-300;

    &:first-child {
      justify-content: flex-start;
      margin-left: 0;
    }
  }

  .c-jg-selfpayment-table__service__name {
    background-color: $honne-gray-100;
  }
}
