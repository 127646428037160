.p-whitepaper__container {
  background-color: $honne-white;

  > div {
    padding-top: 24px;
  }
}

.p-whitepaper__title {
  margin-bottom: 16px;
  font-size: $font-size-x-large;
  font-weight: bold;
  line-height: $line-height-px4;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-medium;
}

.p-whitepaper__inner-content {
  display: flex;
  flex-direction: column;
}

.p-whitepaper__inner-content-display {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.p-whitepaper__inner_content-display__image {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  background: $honne-gray-50;
  border-radius: 8px;
}

.p-whitepaper__inner_content-display__title {
  margin-top: 12px;
  font-size: $font-size-x-medium;
  font-weight: bold;
  line-height: $line-height-medium;
  color: $honne-gray-700;
}

.p-whitepaper__inner_content-display__description {
  margin-top: 4px;
  font-size: $font-size-small;
  line-height: $line-height-x-large;
  color: $honne-gray-600;
  letter-spacing: $letter-spacing-medium;
}

.p-whitepaper__inner-content__text {
  font-size: $font-size-medium;
  font-weight: bold;
  letter-spacing: $letter-spacing-medium;
}

.p-whitepaper__inner-content-form {
  display: flex;
  flex-direction: column;
}

.p-whitepaper__inner-content__title {
  margin-bottom: 8px;
  font-size: $font-size-medium;
  line-height: $line-height-medium;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-large;
}

.p-whitepaper__inner-content__separater {
  margin: 16px 0;
  border: 1px solid #eeeeee;
}

.p-whitepaper__inner-content-form__form {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.p-whitepaper__inner-content-form__check {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  font-size: $font-size-small;
  line-height: $line-height-px1;
  color: $honne-gray-700;
  letter-spacing: 0.5px;
}

.p-whitepaper__inner-content-form__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 320px;
  padding: 12px 0;
  margin: 24px auto 40px;
  font-weight: bold;
}

.p-whitepaper__inner-content-form__check__text {
  font-size: $font-size-small;
  letter-spacing: $letter-spacing-medium;
}

.p-whitepaper__reason {
  margin-top: 16px;
}

.p-whitepaper__reason__radio {
  margin-top: 12px;
}

.p-whitepaper__inner-content-form__check
  + .p-whitepaper__inner-content-form__check {
  margin-top: 12px;
}

@include min-screen($breakpoint-pc-small-min) {
  .p-whitepaper__container {
    text-align: left;
    background-color: initial;

    > div {
      padding-top: 24px;
    }
  }
  .p-whitepaper__inner-content-display {
    width: 320px;
  }
  .p-whitepaper__inner {
    padding: 32px 48px;
    background: $honne-white;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(206, 206, 206, 0.25);
  }
  .p-whitepaper__title {
    width: 100%;
    margin-bottom: 24px;
    font-size: $font-size-xxxx-large;
  }
  .p-whitepaper__inner_content-display__title {
    margin-top: 16px;
  }
  .p-whitepaper__inner_content-display__description {
    font-size: $font-size-medium;
  }
  .p-whitepaper__inner-content__title {
    font-size: $font-size-x-medium;
  }
  .p-whitepaper__inner-content {
    flex-direction: row;
    width: 100%;
  }
  .p-whitepaper__inner-content-form {
    margin-left: 32px;

    .c-form__field {
      margin-top: 16px;
    }

    .c-form__field__content {
      margin-top: 0;
    }
  }
  .p-whitepaper__inner-content__separater {
    display: none;
  }
  .p-whitepaper__inner-content-form__form {
    margin: 8px 0 0 0;
    box-shadow: none;
  }
  .p-whitepaper__inner-content-form__button {
    width: 272px;
    margin: 24px auto;
  }
  .p-whitepaper__reason {
    margin-top: 24px;
  }
  .p-whitepaper__inner-content-form__check {
    margin-top: 24px;
  }
}
