@charset "utf-8";

%p-guide__title-h2 {
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 24px;
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: $line-height6;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    content: "";
    background: $honne-blue;
    border-radius: 4px;
  }
}

.p-guide__title-h2 {
  @extend %p-guide__title-h2;
}

%p-guide__title-h3 {
  position: relative;
  padding-left: 16px;
  margin: 40px 0 16px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-small;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 100%;
    content: "";
    background: $honne-blue;
    border-radius: 1px;
  }
}

.p-guide__title-h3 {
  @extend %p-guide__title-h3;
}

.p-guide__text {
  margin-bottom: 20px;
  font-size: $fs-lg;
  line-height: $line-height7;
}

.p-guide-article {
  h2 {
    @extend %p-guide__title-h2;
  }

  h3 {
    @extend %p-guide__title-h3;
  }
}

.p-guide-article__bottom {
  max-width: 1080px;
  border-radius: 0;
}

.p-guide-article__bottom__balloon {
  position: relative;
  width: calc(100vw - 48px);
  max-width: 1080px;
  padding: 12px 0;
  margin: 24px auto;
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-blue-lighten;
  text-align: center;
  background-color: $honne-white;
  border-radius: 100px;

  &:after {
    position: absolute;
    bottom: -24px;
    left: 50%;
    content: "";
    border: 15px solid transparent;
    border-top: 15px solid $honne-white;
    transform: translateX(-50%);
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-guide__title-h2 {
    font-size: $fs-4xl;
  }

  .p-guide-article__bottom__balloon {
    width: calc(100vw - 64px);
    font-size: $fs-lg;

    &:after {
      border-top: 15px solid $honne-white;
    }

    br {
      display: none;
    }
  }
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .p-guide-article__bottom {
    width: calc(100vw - 64px);
    border-radius: 8px;
  }
}
