@charset "utf-8";

.p-news-top {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.p-news-top__slider {
  position: relative;
  order: 1;
}

.p-news-top__ranking {
  order: 4;
  width: 100%;
  padding-bottom: 24px;

  .p-news-ranking__title {
    padding: 16px;
  }

  .c-articles-box__lists {
    padding: 0 16px;
  }
}

.p-news-top__articles {
  order: 2;
  margin: 0 0 24px;
  border-top: 1px solid $honne-gray-300;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-top__slider {
    order: 1;
    width: calc(100% - 280px);
    margin-right: 24px;
  }

  .p-news-top__ranking {
    order: 2;
    width: 256px;
    padding-bottom: 0;

    .p-news-ranking__title {
      padding: 16px 0;
    }

    .c-articles-box__lists {
      padding: 0;
    }
  }

  .p-news-top__articles {
    order: 3;
    width: 100%;
    margin: 40px 0;
    border-top: 0;
  }
}
