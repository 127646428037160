@charset "utf-8";

.p-jg-img-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $jigyosho-image-modal;
  display: none;
  overflow: auto;
  overflow-y: scroll;
  background-color: $honne-gray-700;
}

.p-jg-img-modal__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.p-jg-img-modal__head {
  position: absolute;
  right: 0;
  left: 0;
  z-index: $z-index-1;
  height: 60px;
  padding-top: 2vh;
  color: $honne-white;
  text-align: center;
}

.p-jg-img-modal__head__index {
  position: relative;
  width: 90px;
  padding: 5px;
  margin: 0 auto;
  line-height: $line-height2;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 30px;
}

.p-jg-img-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 60px;
  -webkit-filter: drop-shadow(0 0 3px #000);
  filter: drop-shadow(0 0 3px #000);
}

.p-jg-img-modal__close__icon {
  position: absolute;
  top: 20px;
  left: 40px;
  width: 16px;
  height: 16px;
  margin-left: auto;
  background: url("~images/icon/close-white.svg") no-repeat center;
}

.p-jg-img-modal__img__container {
  display: flex;
  align-items: center;
  height: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  @include scrollbar-none;
}

.p-jg-img-modal__img__container__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 100vw;
  height: 100%;
  scroll-snap-align: start;
}

.p-jg-img-modal__img__container__box__img__wrapper {
  flex-basis: 100%;
  width: 100%;
  height: 0;
  text-align: center;
}

.p-jg-img-modal__img__container__box__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-jg-img-modal-thumb-box {
  display: flex;
  flex-shrink: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  @include scrollbar-none;
}

.p-jg-img-modal-thumb-img__wrapper {
  position: relative;
  width: 90px;
  min-width: 90px;
  height: 90px;
  padding: 1px 1px 0;
}

.p-jg-img-modal-thumb-img__wrapper.focus::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border: 3px solid $honne-blue;
}

.p-jg-img-modal-thumb-img__img {
  width: 90px;
  height: 90px;
  cursor: pointer;
  object-fit: cover;
}

.p-jg-img-modal__prev {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20%;
  height: 20%;
  cursor: pointer;
  transform: translateY(-50%);
}

.p-jg-img-modal__prev__icon {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 24px;
  height: 24px;
  background: url("~images/icon/arrow-left-white.svg") no-repeat center;
  -webkit-filter: drop-shadow(0 0 4px #000);
  filter: drop-shadow(0 0 4px #000);
}

.p-jg-img-modal__next {
  position: absolute;
  top: 50%;
  right: 0;
  width: 20%;
  height: 20%;
  cursor: pointer;
  transform: translateY(-50%);
}

.p-jg-img-modal__next__icon {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 24px;
  height: 24px;
  background: url("~images/icon/arrow-right-white.svg") no-repeat center;
  -webkit-filter: drop-shadow(0 0 4px #000);
  filter: drop-shadow(0 0 4px #000);
}

.p-jg-img-modal__detail {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: solid 1px $honne-gray-200;
}

.p-jg-img-modal__detail__title {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-jg-img-modal__desc {
  max-width: 100%;
  padding: 0 10vw 16px;
  color: $honne-white;
}

.p-jg-img-modal__desc__name {
  margin-bottom: 8px;
}

.p-jg-img-modal__desc__date {
  margin-bottom: 8px;
}

.p-jg-img-modal__desc__comment {
  font-size: $fs-xs;
  color: $honne-gray-600;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jg-img-modal__prev__icon {
    left: 24px;
  }

  .p-jg-img-modal__next__icon {
    right: 24px;
  }

  .p-jg-img-modal__desc {
    flex-basis: 30%;
    padding: 32px 16px;
  }

  .p-jg-img-modal__img__container__box {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .p-jg-img-modal__img__container__box__img__wrapper {
    width: 0;
    height: 100%;
  }

  .p-jg-img-modal__img__container__box__img {
    height: 100%;
  }

  .p-jg-img-modal-thumb-img__wrapper {
    width: 120px;
    min-width: 120px;
    height: 120px;
  }

  .p-jg-img-modal-thumb-img__img {
    width: 120px;
    height: 120px;
  }
}
