@charset "utf-8";

.c-footer-privacy {
  padding: 24px 24px 0;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-footer-privacy {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 1080px;
    padding: 0 0 24px;
    margin: 0 auto;
  }
}
