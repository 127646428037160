.p-top-contact {
  display: none;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-contact {
    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: $z-index-4;
    padding: 0 24px 24px;
    text-align: center;
    background-color: $honne-yellow-white;
    border-radius: 12px;
    box-shadow: $shadow-size-medium;
  }

  .p-top-contact__image {
    width: 150px;
    margin: -48px auto 8px;

    img {
      width: 100%;
    }
  }

  .p-top-contact__caption {
    font-size: $fs-xl;
    font-weight: bold;
    letter-spacing: $letter-spacing-px2;

    span {
      font-size: $fs-sm;
      font-weight: normal;
    }
  }

  .p-top-contact__tel {
    display: flex;
    align-items: center;
    font-size: $fs-4xl;
    font-weight: bold;
  }

  .p-top-contact__icon {
    margin-right: 4px;
  }

  .p-top-contact__time {
    margin-bottom: 12px;
    font-size: $fs-2xs;
  }

  .p-top-contact__action__link {
    display: block;
    padding: 12px 16px;
    font-size: $fs-2md;
    font-weight: bold;
    color: $honne-white;
    background-color: $honne-blue-lighten;
    border-radius: 8px;

    &:visited {
      color: $honne-white;
    }
  }
}
