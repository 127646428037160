@charset "utf-8";

// ページ説明文
.p-jys-feature {
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: $honne-white;
  border-radius: $radius-size-medium;
}

.p-jys-feature__head {
  margin-bottom: 8px;
  font-size: $fs-2xl;
  font-weight: bold;
}

.p-jys-feature__content {
  font-size: $fs-sm;
  color: $honne-gray-600;
}
