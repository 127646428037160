@charset "utf-8";

.c-card {
  & .c-jigyosho-summary {
    padding: 8px 0;
  }

  & .c-jigyosho-price {
    line-height: $lh-x-small;
  }

  & .c-jigyosho-tel {
    padding: 8px 0;
  }

  & .c-jigyosho-tel__for {
    font-size: $fs-3xl;
  }

  & .c-jigyosho-tel__desc {
    font-size: $fs-3xs;
  }

  & .c-jigyosho-summary__block {
    display: flex;
    flex-wrap: wrap;
    gap: 4px 4px;
  }

  & .c-jigyosho-tel__note {
    padding-right: 8px;
    padding-left: 8px;
    font-size: $fs-3xs;
    text-align: center;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-card {
    & .c-jigyosho-tel {
      padding: 12px 0;
    }

    & .c-jigyosho-tel__title {
      line-height: $lh-x-small;
    }

    & .c-jigyosho-tel__tel {
      line-height: $lh-x-small;
    }

    & .c-jigyosho-tel__number {
      line-height: $lh-x-small;
    }

    & .c-jigyosho-tel__desc {
      line-height: $lh-x-small;
    }
  }
}
