/*doc
---
title: block-nav
name: block-nav
category: components
---

```html_example
<a class="c-block-nav" href="#">
  <i class="c-icon-home c-block-nav__icon"></i>
  <span class="c-block-nav__title">介護のほんねTOP</span>
</a>
```
*/

.c-block-nav {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  background-color: $honne-white;

  &.current {
    border-left: 3px solid $honne-blue;
  }
}

.c-block-nav__icon {
  margin-right: 16px;
}

.c-block-nav__title {
  font-size: $fs-2md;
  font-weight: bold;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-px3;
}

.c-block-nav__badge {
  width: 8px;
  height: 8px;
  margin-left: 8px;
  background-color: $honne-red-bright;
  border-radius: 8px;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-block-nav__title {
    font-size: $fs-lg;
  }
}
