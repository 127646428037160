@charset "utf-8";

$mimic-size: 24px;

/*doc
---
title: radio
name: radio
category: component-parts
---

```html_example

<div style="margin-bottom: 8px;display: flex;width: 300px;">
  <label class="c-radio">
    <input type="radio" value="1" checked="checked" name="sex">
    <span class="c-radio__mimic-wrap"><span class="c-radio__mimic"></span></span>
    <div class="c-radio__text">男性</div>
  </label>
  <label class="c-radio">
    <input type="radio" value="2" name="sex">
    <span class="c-radio__mimic-wrap"><span class="c-radio__mimic"></span></span>
    <div class="c-radio__text">女性</div>
  </label>
</div>

<div style="margin-bottom: 8px;display: flex;width: 300px;">
  <label class="c-radio">
    <input type="radio" value="1" checked="checked" name="sex">
    <span class="c-radio__mimic-wrap"><span class="c-radio__mimic--large"></span></span>
    <div class="c-radio__text--large">男性</div>
  </label>
  <label class="c-radio">
    <input type="radio" value="2" name="sex">
    <span class="c-radio__mimic-wrap"><span class="c-radio__mimic--large"></span></span>
    <div class="c-radio__text--large">女性</div>
  </label>
</div>
```
*/

.c-radio__mimic {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 16px;
  height: 16px;
  background: $honne-white;
  border: 2px solid $honne-gray-400;
  border-radius: 100%;
}

.c-radio__mimic--large {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: $mimic-size;
  height: $mimic-size;
  background: $honne-white;
  border: 2px solid $honne-gray-400;
  border-radius: 100%;
}

.c-radio__text {
  margin-left: 8px;
  color: $honne-blue;
}

.c-radio__text--large {
  margin-left: 16px;
  font-size: $fs-md;
  line-height: $line-height6;
}

.c-radio {
  display: flex;
  align-items: center;
  width: 100%;

  input[type="radio"] {
    display: none;
    appearance: none;
  }

  input[type="radio"]:checked + &__mimic-wrap > &__mimic {
    position: relative;
    background: transparent;
    border: 0;
  }

  input[type="radio"]:checked + &__mimic-wrap > &__mimic::before {
    display: block;
    width: 16px;
    height: 16px;
    content: "";
    background: $honne-white;
    border: 6px solid $honne-blue;
    border-radius: 100%;
  }

  input[type="radio"]:checked + &__mimic-wrap > &__mimic--large {
    position: relative;
    background: transparent;
    border: 0;
  }

  input[type="radio"]:checked + &__mimic-wrap > &__mimic--large::before {
    display: block;
    width: $mimic-size;
    height: $mimic-size;
    content: "";
    background: $honne-white;
    border: 6px solid $honne-blue;
    border-radius: 100%;
  }

  input[type="radio"]:checked + &__mimic-wrap + &__text {
    font-weight: bold;
  }

  input[type="radio"]:checked + &__mimic-wrap + &__text--large {
    font-weight: bold;
  }
}

.c-radio__mimic-wrap {
  display: block;
  width: 16px;
}
