@charset "utf-8";

.c-radio-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.c-radio-group__item {
  width: auto;
  margin-right: 16px;
  margin-bottom: 8px;
}

.c-radio-group__item:last-child {
  margin-right: 0;
}
