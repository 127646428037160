@charset "utf-8";

.p-news-col__side {
  border-top: solid 8px $honne-blue;
}

.p-news-col__bottom {
  padding-top: 16px;
  background-color: $honne-yellow-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-col__main {
    .c-box {
      box-shadow: $shadow-size-small;
    }
  }

  .p-news-col__side {
    border-top: none;
  }

  .p-news-col__bottom {
    padding-top: 0;
    background-color: transparent;
  }
}
