@charset "utf-8";

.u-mt-16 {
  margin-top: 16px !important;
}

.u-mt-24 {
  margin-top: 24px !important;
}

.u-mb-0 {
  margin-bottom: 0 !important;
}

.u-mb-8 {
  margin-bottom: 8px !important;
}

.u-mb-16 {
  margin-bottom: 16px !important;
}

.u-mb-24 {
  margin-bottom: 24px !important;
}

.u-ml-8 {
  margin-left: 8px !important;
}

.u-ml-24 {
  margin-left: 24px !important;
}
