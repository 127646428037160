@charset "utf-8";

.p-review-contents {
  margin-bottom: 24px;
  font-size: $fs-lg;
}

.p-review-contents__content + .p-review-contents__content {
  margin-top: 24px;
}

.p-review-contents__head {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}

.p-review-contents__title {
  font-weight: bold;
}
