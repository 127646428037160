@charset "utf-8";

%c-markup__title-h2 {
  position: relative;
  margin: 56px 0 24px;
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: $line-height6;
}

.c-markup__title-h2 {
  @extend %c-markup__title-h2;
}

%c-markup__title-h3 {
  position: relative;
  padding-left: 16px;
  margin: 40px 0 16px;
  line-height: $line-height-small;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 100%;
    content: "";
    background: $honne-blue;
    border-radius: 1px;
  }
}

.c-markup__title-h3 {
  @extend %c-markup__title-h3;
}

%c-markup__text {
  margin-bottom: 20px;
  font-size: $fs-lg;
  line-height: $line-height7;
  text-align: justify;
}

.c-markup__text {
  @extend %c-markup__text;
}

.c-markup__article {
  margin-bottom: 32px;
  font-size: $fs-lg;
  line-height: $line-height-px4;

  h2 {
    @extend %c-markup__title-h2;
  }

  h3 {
    @extend %c-markup__title-h3;
  }

  h4 {
    position: relative;
    padding-left: 18px;
    margin: 32px 0 8px;
    font-size: $fs-lg;
    font-weight: bold;
    line-height: $line-height-medium;
  }

  h4:before {
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    background: $honne-blue;
    border-radius: 50%;
  }

  h5 {
    position: relative;
    margin: 16px 0 8px;
    font-size: $fs-md;
    font-weight: normal;
    line-height: $line-height-px2;
  }

  p {
    @extend %c-markup__text;
  }

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
    color: $honne-gray-600;
  }

  u {
    padding: 2px;
    font-size: $fs-2md;
    font-weight: bold;
    text-decoration: none;
    background: rgba(242, 184, 48, 0.3);
  }

  ul,
  ol {
    margin-left: 24px;
    line-height: $line-height-px4;
  }

  // 直下のみmarginつける、nestされてる場合やtable中などは不要
  > ul,
  > ol {
    margin-bottom: 24px;
  }

  ul > li {
    list-style: disc;
  }

  ol > li {
    list-style: decimal;
  }

  .text-underline {
    text-decoration: underline;
  }

  .fs-xxx-small {
    font-size: $fs-3xs;
  }

  .fs-x-small {
    font-size: $fs-xs;
  }

  .fs-medium {
    font-size: $fs-md;
  }

  .fs-large {
    font-size: $fs-lg;
  }

  .fs-x-large {
    font-size: $fs-xl;
  }

  .color-blue {
    color: $honne-blue;
  }

  .color-red {
    color: $honne-red-bright;
  }

  .color-yellow {
    color: $honne-yellow;
  }

  .color-gray {
    color: $honne-gray-600;
  }

  .table-border-top-none {
    border-top: none;
  }

  a {
    text-decoration: underline;
  }

  .blank {
    padding-right: 24px;
    background: url("~images/questions/target-blank.svg") no-repeat right center;
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0 auto 24px;
  }

  table {
    width: 100%;
    margin-bottom: 24px;
    overflow: hidden;
    border-collapse: unset;
    border-radius: 8px;
  }

  tr {
    border-left: 2px solid $honne-white;
  }

  th {
    width: 90px;
    max-width: 90px;
    padding: 8px 12px;
    font-size: $fs-md;
    font-weight: bold;
    line-height: $line-height-base;
    text-align-last: left;
    background: $honne-yellow-white;
    border-right: 2px solid $honne-white;
    border-bottom: 2px solid $honne-white;
  }

  thead th {
    max-width: unset;
    padding: 8px 12px;
    font-size: $fs-md;
    color: $honne-white;
    background-color: $honne-blue-lighten;
  }

  td {
    width: calc(100% - 90px);
    padding: 8px 12px;
    font-size: $fs-md;
    line-height: $line-height-base;
    background: $honne-yellow-white;
    border-top: 2px solid $honne-white;
    border-right: 2px solid $honne-white;
  }

  th:last-child,
  td:last-child {
    border-right: none;
  }

  tr:first-child td {
    border-top: none;
  }

  tr:last-child th {
    border-bottom: none;
  }

  thead tr:last-child th,
  thead tr:last-child td {
    border-bottom: 2px solid $honne-white;
  }

  .center {
    margin: 0 auto;
  }

  .youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 24px;
  }

  iframe {
    max-width: 100%;
    margin: 0 auto 24px;
  }

  .youtube iframe {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 24px;
  }

  .list-box {
    padding: 12px 16px;
    margin-bottom: 24px;
    background: $honne-white;
    border: 1px solid $honne-gray-300;
    border-radius: 8px;
  }

  .list-box h5 {
    margin-top: 0;
  }

  .list-box ol li u,
  .list-box ul li u {
    padding: 3px 6px;
    margin-bottom: 4px;
    font-size: $fs-md;
    text-decoration: none;
    background: #e7f4f7;
  }

  .desc {
    margin-bottom: 16px;

    & > :first-child {
      margin-bottom: 0;
    }

    & > span {
      display: block;
      padding: 8px 0;
      font-size: $fs-3xs;
      line-height: $line-height-base;
    }

    & img {
      margin-bottom: 0;
    }
  }

  blockquote {
    padding: 16px 24px 16px 66px;
    margin-bottom: 24px;
    font-size: $fs-lg;
    background: url("~images/icon/blockquote.svg") no-repeat 22px 20px
      $honne-gray-100;
    background-size: 24px auto;
    border-radius: 8px;
  }

  .clearfix {
    margin-bottom: 24px;
  }

  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }

  .balloon {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    margin-bottom: 24px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: center;
    }

    & > div > span {
      font-size: $fs-sm;
      font-weight: bold;
      white-space: nowrap;
    }

    & img {
      width: 55px;
      max-width: initial;
      height: 55px;
      margin: 0;
      object-fit: cover;
      border: 3px solid $honne-blue-lighten;
      border-radius: 50%;
    }

    & > p {
      position: relative;
      padding: 12px;
      margin-bottom: 0;
      font-size: $fs-md;
      line-height: $lh-medium;
      background-color: $honne-blue-bright;
      border-radius: 8px;
    }

    $balloon-size: 6px;
    &.right {
      justify-content: flex-end;

      & > p::before {
        position: absolute;
        top: 18px;
        right: -$balloon-size;
        content: "";
        border-top: $balloon-size solid transparent;
        border-bottom: $balloon-size solid transparent;
        border-left: $balloon-size solid $honne-blue-bright;
      }
    }

    &.left {
      & img {
        border-color: $honne-yellow;
      }

      & > p {
        background-color: $honne-white-coral;
      }

      & > p::before {
        position: absolute;
        top: 18px;
        left: -$balloon-size;
        content: "";
        border-top: $balloon-size solid transparent;
        border-right: $balloon-size solid $honne-white-coral;
        border-bottom: $balloon-size solid transparent;
      }
    }
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-markup__article {
    .list-box {
      padding: 16px 24px;
    }

    h2 {
      font-size: $fs-4xl;
    }

    %img-wrap {
      width: 320px;

      img {
        margin-bottom: 0;
      }
    }

    .img-wrap {
      @extend %img-wrap;
    }

    .img-wrap-left {
      @extend %img-wrap;
      float: left;
      margin-right: 16px;
    }

    .img-wrap-right {
      @extend %img-wrap;
      float: right;
      margin-left: 16px;
    }

    .balloon {
      gap: 24px;
      margin-bottom: 32px;

      & img {
        width: 80px;
        height: 80px;
      }

      & > p {
        padding: 24px;
        font-size: $fs-2md;
      }

      & > div > span {
        font-size: $fs-md;
      }

      $balloon-pc-size: 10px;
      &.right > p::before {
        top: 27px;
        right: -$balloon-pc-size;
        border-top: $balloon-pc-size solid transparent;
        border-bottom: $balloon-pc-size solid transparent;
        border-left: $balloon-pc-size solid $honne-blue-bright;
      }

      &.left > p::before {
        top: 27px;
        left: -$balloon-pc-size;
        border-top: $balloon-pc-size solid transparent;
        border-right: $balloon-pc-size solid $honne-white-coral;
        border-bottom: $balloon-pc-size solid transparent;
      }
    }
  }

  .c-markup__title-h2 {
    font-size: $fs-4xl;
  }
}
