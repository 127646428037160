@charset "utf-8";

.p-regist-caremanager-can {
  background-color: $honne-yellow-white;
}

.p-regist-caremanager-can__inner {
  padding: 40px 24px;
  font-weight: bold;
}

.p-regist-caremanager-can__top {
  border-top: 52px solid $honne-white;
  border-right: 50vw solid transparent;
  border-left: 50vw solid transparent;
}

.p-regist-caremanager-can__h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  font-size: $fs-3xl;
  line-height: $line-height-xx-large;
  color: $honne-blue;
}

.p-regist-caremanager-can__h2--small {
  font-size: $fs-xl;
}

.p-regist-caremanager-can__desc {
  margin-bottom: 32px;
  font-size: $fs-xl;
  line-height: $line-height3;
}

.p-regist-caremanager-can__text {
  margin-bottom: 16px;
  font-size: $fs-lg;
  line-height: $line-height9;
  text-align: center;
  letter-spacing: $letter-spacing-px2;
}

.p-regist-caremanager-can__detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  font-size: $fs-lg;
  line-height: $line-height9;
}

.p-regist-caremanager-can__detail--large {
  font-size: $fs-3xl;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-caremanager-can__top {
    border-top: 124px solid $honne-white;
  }

  .p-regist-caremanager-can__h2 {
    flex-direction: row;
    font-size: $font-size-big3;
    letter-spacing: $letter-spacing-px9;
  }

  .p-regist-caremanager-can__h2--small {
    font-size: $fs-2xl;
  }

  .p-regist-caremanager-can__inner {
    max-width: $breakpoint-pc-max;
    padding: 72px 24px;
    margin: 0 auto;
  }

  .p-regist-caremanager-can__desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $fs-5xl;
    letter-spacing: $letter-spacing-px4;
  }

  .p-regist-caremanager-can__text {
    font-size: $fs-3xl;
    line-height: $line-height9;
    letter-spacing: $letter-spacing-px2;
  }

  .p-regist-caremanager-can__detail {
    flex-direction: row;
    margin-bottom: 40px;
    font-size: $fs-2xl;
    letter-spacing: $letter-spacing-px4;
  }

  .p-regist-caremanager-can__detail--large {
    font-size: $fs-5xl;
  }
}
