@charset "utf-8";

.c-jigyosho-price {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.c-jigyosho-price__block {
  display: flex;
  align-items: center;
}

.c-jigyosho-price__title {
  width: 64px;
  font-size: $fs-sm;
  font-weight: bold;
  color: $honne-gray-600;
}
