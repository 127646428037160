@charset "utf-8";

.p-review-summary {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.p-review-summary__image {
  width: 50px;
  height: 50px;
  margin-right: 12px;

  & img {
    width: 50px;
    height: 50px;
    border: $honne-gray-400 1px solid;
    border-radius: 50%;
  }
}

.p-review-summary__title {
  margin-bottom: 4px;
  font-size: $fs-2xl;
  font-weight: bold;
}

.p-review-summary__info {
  font-size: $fs-xs;
}
