@charset "utf-8";

.p-my-condition__title {
  margin-bottom: 8px;
  font-weight: bold;
  word-break: keep-all;
}

.p-my-condition__block {
  width: 100%;

  .c-radio-group {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.p-my-condition__checkbox__block + .p-my-condition__checkbox__block {
  margin-top: 8px;
}

.p-my-condition__checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.p-my-condition__checkbox.col2 > .c-checkbox {
  width: calc(50% - 8px);
}

.p-my-condition__checkbox__head {
  width: 100%;
  margin-bottom: 8px;
  font-size: $fs-md;
  font-weight: bold;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-my-condition__checkbox {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .p-my-condition__checkbox.col2 > .c-checkbox {
    width: calc(25% - 8px);
  }
}
