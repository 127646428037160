@charset "utf-8";

.c-guide-jigyosho {
  overflow: hidden;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.c-guide-jigyosho__image {
  overflow: hidden;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-guide-jigyosho__inner {
  padding: 16px;
}

.c-guide-jigyosho__title {
  margin-bottom: 8px;
  font-size: $fs-lg;
}

.c-guide-jigyosho__price {
  margin-bottom: 8px;
  font-weight: bold;
  color: $honne-red-bright;
}

.c-guide-jigyosho__row {
  display: flex;
  align-items: center;
}

.c-guide-jigyosho__price__title {
  min-width: 58px;
  margin-right: 16px;
  font-weight: normal;
  color: $honne-gray-500;
}

.c-guide-jigyosho__link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  i {
    display: inline-block;
    margin-left: 4px;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-guide-jigyosho {
    display: flex;
    justify-content: flex-start;
    padding: 24px;
  }

  .c-guide-jigyosho__image {
    width: 260px;
    height: 155px;
    margin-right: 24px;
  }

  .c-guide-jigyosho__inner {
    width: 100%;
    padding: 0;
  }

  .c-guide-jigyosho__image + .c-guide-jigyosho__inner {
    width: calc(100% - 284px);
  }

  .c-guide-jigyosho__title {
    font-size: $fs-2xl;
  }

  .c-guide-jigyosho__link {
    justify-content: flex-end;
  }
}
