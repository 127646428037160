@charset "utf-8";

.p-jys-guide {
  margin-top: 32px;
  text-align: center;
  background-color: $honne-yellow-bright;
  border-radius: 8px;
}

.p-jys-guide__image {
  width: 100%;
  height: 100%;
}

.p-jys-guide__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.p-jys-guide__wrap {
  padding: 16px 0;
}

.p-jys-guide__title {
  margin-bottom: 8px;
  font-family: $font-family-700bold;
  font-size: $fs-xl;
  font-weight: $font-weight-700bold;
}

.p-jys-guide__content {
  margin-bottom: 12px;
  font-size: $fs-sm;
  font-weight: bold;
  text-align: center;
}

.p-jys-guide__content--red {
  color: $honne-red-bright;
}

.p-jys-guide__tel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  margin-bottom: 8px;
}

.p-jys-guide__tel__number {
  margin-left: 8px;
  font-size: $font-size-big3;
  font-weight: $font-weight-800bold;
  color: $honne-gray-800;
  letter-spacing: $letter-spacing1;
}

.p-jys-guide__desc {
  font-size: $fs-2xs;
  font-weight: bold;
  color: $honne-gray-600;
}

@include min-screen($breakpoint-pc-min) {
  .p-jys-guide {
    display: flex;
    flex-direction: row-reverse;
    height: 244px;
  }

  .p-jys-guide__image {
    width: 45%;
    height: 100%;
  }

  .p-jys-guide__image > img {
    border-radius: 0 8px 8px 0;
  }

  .p-jys-guide__wrap {
    width: 55%;
  }

  .p-jys-guide__title {
    font-size: $fs-2xl;
  }

  .p-jys-guide__content {
    font-size: $fs-md;
  }

  .p-jys-guide__desc {
    font-size: $fs-xs;
  }
}
