@charset "utf-8";

.c-card-list {
  display: flex;
  margin: -16px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  @include scrollbar-none;
}

.c-card-list-wrap {
  display: flex;
  flex-flow: nowrap;
  width: 100%;
  padding: 0;
}

.c-card-list__item + .c-card-list__item {
  margin-left: 16px;
}

.c-card-list__more {
  width: 280px;
  margin: 32px auto 0;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .c-card-list {
    margin: -16px -32px;
  }

  .c-card-list-wrap {
    padding: 0 16px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-card-list__more {
    margin-top: 40px;
  }
}
