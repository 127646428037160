@charset "utf-8";

.c-form-block__head {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.c-form-block__main {
  display: flex;
  flex-direction: column;
}

.c-form-block__title {
  position: relative;
  padding-left: 16px;
  font-size: $fs-lg;
}

.c-form-block__title::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 4px;
  height: 100%;
  content: "";
  background-color: $honne-blue;
  border-radius: 1px;
}

.c-form-block__badge {
  padding: 2px 8px;
  margin-left: 8px;
  font-size: $fs-sm;
}

.c-form-block + .c-form-block {
  margin-top: 32px;
}
