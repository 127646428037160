@charset "utf-8";

.p-jg-side-column {
  display: none;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jg-side-column {
    top: 64px;
    display: block;
  }

  .p-jg-side-column .c-side-contact {
    margin-bottom: 16px;
  }
}

/* 899px ~ */
@include max-screen($breakpoint-pc-small-max) {
  .p-jg-side-column .c-inquiry {
    background-color: $honne-white;
  }
}
