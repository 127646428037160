@charset "utf-8";

.c-jg-fixed-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $jigyosho-footer;
  display: none;
  width: 100%;
  padding: 16px;
  background-color: rgba($honne-white, 0.9);
}

.c-jg-fixed-footer__inner {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

.c-jg-fixed-footer__tel {
  flex-direction: column;
  height: 100%;
  padding: 8px;
  color: $honne-gray-700;
  border: 2px solid $honne-blue;
  box-shadow: 0 3px $honne-blue;
  &:visited {
    color: $honne-gray-700;
  }
}

.c-jg-fixed-footer__tel__title {
  font-size: $fs-sm;
}

.c-jg-fixed-footer__tel__text {
  display: inline-block;
  margin-top: 4px;
  font-size: $fs-3xs;
  font-weight: normal;
}

.c-jg-fixed-footer__tel__bottom {
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: $fs-xs;
}

.c-jg-fixed-footer__tel__icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.c-jg-fixed-footer__brochure {
  position: relative;
  flex-direction: column;
  min-width: 175px;
  padding: 8px;
  box-shadow: 0 3px #ca3e3e;
}

.c-jg-fixed-footer__brochure__desc {
  margin-bottom: 4px;
  font-size: $fs-2xs;
}

.c-jg-fixed-footer__brochure__title {
  font-size: $fs-md;
}

.c-jg-fixed-footer__inner--pc {
  display: none;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-jg-fixed-footer {
    padding: 24px 32px;
  }

  .c-jg-fixed-footer__tel {
    width: calc(50vw - 40px);
  }

  .c-jg-fixed-footer__brochure {
    width: calc(50vw - 40px);
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-jg-fixed-footer {
    width: calc(100% - 344px);
    padding: 16px 24px;
    margin-left: 32px;
  }

  .c-jg-fixed-footer__inner--sp {
    display: none;
  }

  .c-jg-fixed-footer__inner--pc {
    display: block;

    & .p-jigyosho-contact-inquiry {
      margin-top: 12px;
    }
  }
}

/* 1145px ~ */
@include min-screen(1145px) {
  .c-jg-fixed-footer {
    width: 800px;
    margin-left: calc(50% - 540px);
  }
}
