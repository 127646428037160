@charset "utf-8";

.p-market-price-table {
  display: table;
  width: 100%;
  margin-bottom: 32px;
  table-layout: fixed;
}

.p-market-price-table__head {
  padding: 12px 8px;
  vertical-align: middle;
  background-color: $honne-white-coral;
  border: 1px solid $honne-gray-400;

  &.left {
    text-align: left;
  }
}

.p-market-price-table__col {
  padding: 12px 4px;
  font-size: $fs-lg;
  font-weight: $font-weight-700bold;
  text-align: center;
  vertical-align: middle;
  background-color: $honne-white;
  border: 1px solid $honne-gray-400;

  &.top-ranking {
    color: $honne-blue;
  }

  &.top-price {
    font-size: $fs-xl;
    color: $honne-red-bright;
  }

  &.common {
    font-size: $fs-lg;
    font-weight: normal;
  }
}

.p-market-price-table__text {
  font-size: $fs-xs;
}

.p-market-price-table__title {
  margin-bottom: 16px;
  font-size: $fs-xl;
}

.p-market-price-table__button {
  max-width: 87px;
  margin: 0 auto;
}

.p-market-price-table__unit {
  font-size: $fs-md;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-market-price-table__head {
    font-size: $fs-lg;
  }

  .p-market-price-table__col {
    font-size: $fs-2xl;
    &.top-price {
      font-size: $fs-3xl;
    }
  }

  .p-market-price-table__text {
    font-size: $fs-lg;
  }

  .p-market-price-table__button {
    font-size: $fs-lg;
  }
}
