@charset "utf-8";

.p-faq__primary-button {
  width: calc(100% + 32px);
  height: 56px;
  margin: 16px 0 0 -32px;
  font-size: $fs-lg;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-faq__primary-button {
    width: 305px;
    margin: 16px auto 0;
    font-size: $fs-xl;
  }
}
