@charset "utf-8";

$z-index-1: 1;
$z-index-2: 2;
$z-index-3: 3;
$z-index-4: 4;
$contents-mask-layer: 10;
$dropdown-menu-zindex: 101;
$global-header-zindex: 1000;
$slide-menu-zindex: 1001;
$jigyosho-footer: 1030;
$jigyosho-nav-header: 1040;
$modal-frame-zindex: 1041;
$modal-zindex: 1042;
$jigyosho-image-modal: 1050;
$jigyosho-share-modal: 1050;
$notification-zindex: 1060;

$brochure-footer: 1070;
$jigyosho-search-filter: 1071;
$jigyosho-search-modal: 1080;
$jigyosho-search-modal-inner: 1090;
$jigyosho-search-slide: 1100;

$favorite-modal-frame: 1110;
$favorite-modal: 1111;

$offer-bro-modal: 1120;

$loader-zindex: 2000;
