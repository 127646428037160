@charset "utf-8";

.c-hover-menu__content {
  display: none;
}

@include min-screen($breakpoint-pc-max) {
  .c-hover-menu {
    position: absolute;
    top: 72px;
    left: 50%;
    z-index: $dropdown-menu-zindex;
    display: none;
    width: 100%;
    transform: translateX(-50%);
  }

  .c-hover-menu__content {
    display: block;
    padding: 48px 0;
    background-color: $honne-white;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
  }

  .c-hover-menu__inner {
    max-width: 1200px;
    padding: 0 16px;
    margin: 0 auto;
  }

  .c-hover-menu__head {
    display: flex;
  }

  .c-hover-menu__title {
    margin-left: 8px;
    font-size: $fs-2xl;
    font-weight: bold;
    line-height: $line-height-base;
    color: $honne-gray-700;
    letter-spacing: $letter-spacing-px4;
  }

  .c-hover-menu__area {
    margin-top: 24px;
    line-height: $line-height-base;
  }
}
