@charset "utf-8";

.p-cashback-about__inner {
  padding: 48px 0;
}

.p-cashback-about__title {
  margin-bottom: 24px;
}

.p-cashback-about__text {
  margin-bottom: 24px;
  font-size: $fs-md;
  line-height: $line-height3;
}

.p-cashback-about__note {
  display: table;
  padding: 20px;
  margin: 24px auto auto;
  font-size: $fs-xs;
  line-height: $line-height-x-small;
  color: $honne-gray-600;
  border: 1px solid $honne-gray-200;
  border-radius: 8px;
}

.p-cashback-about__decided {
  text-align: center;

  & img {
    width: 100%;
    max-width: 450px;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cashback-about__inner {
    padding: 72px 0;
  }

  .p-cashback-about__title {
    letter-spacing: $letter-spacing-px2;
  }

  .p-cashback-about__text {
    font-size: $fs-xl;
    line-height: $line-height3;
    letter-spacing: $letter-spacing-px2;
  }
}
