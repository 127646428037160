@charset "utf-8";

.c-slider__pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: $honne-gray-400;
    border-radius: 50%;
  }

  .swiper-pagination-bullet-active {
    background: $honne-blue;
  }
}

.c-slider__button-next,
.c-slider__button-prev {
  position: absolute;
  top: 42%;
  z-index: $z-index-1;
  width: 32px;
  height: 32px;
  overflow: hidden;
  cursor: pointer;
  background: $honne-white;
  border-radius: 4px;
  outline: none;
  opacity: 0.6;
  transition: all 0.3s;
}

.c-slider__button-next {
  right: 24px;
}

.c-slider__button-prev {
  left: 24px;
  transform: rotate(-180deg);
}

.c-slider__button-next:hover,
.c-slider__button-prev:hover {
  opacity: 0.8;
}

.c-slider__button-next:before,
.c-slider__button-prev:before,
.c-slider__button-next:after,
.c-slider__button-prev:after {
  position: absolute;
  top: 50%;
  left: 40%;
  display: block;
  width: 12px;
  height: 12px;
  content: "";
}

.c-slider__button-next:before,
.c-slider__button-prev:before {
  background: $honne-gray-700;
  transform: translate(-50%, -50%) rotate(45deg);
}

.c-slider__button-next:after,
.c-slider__button-prev:after {
  background: $honne-white;
  transform: translate(-82%, -50%) rotate(45deg);
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-slider__button-next,
  .c-slider__button-prev {
    top: 34%;
  }
}
