@charset "utf-8";

.p-special-message {
  width: calc(100vw - 48px);
  margin: 24px;
  text-align: center;
}

.p-special-message__contents {
  padding: 24px;
  margin: 30px 0 0;
  background-color: $honne-yellow-white;
  border-radius: 12px;
}

.p-special-message__title {
  margin-bottom: 12px;
  font-size: $fs-xl;
  font-weight: bold;
  letter-spacing: $letter-spacing-px2;
}

.p-special-message__text {
  font-size: $fs-2md;
  line-height: $line-height13;
  text-align: left;
  letter-spacing: $letter-spacing-px2;

  span {
    border-bottom: 1px dotted color-darken($honne-yellow-white, 12%);
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special-message {
    width: 952px;
    padding: 32px 0 0;
    margin: 0 auto;
  }

  .p-special-message__contents {
    padding: 48px;
    margin: 24px auto;
  }

  .p-special-message__title {
    margin-bottom: 12px;
    font-size: $fs-4xl;
    letter-spacing: $letter-spacing-px4;

    br {
      display: none;
    }
  }

  .p-special-message__text {
    font-size: $fs-lg;
    line-height: initial;
    text-align: center;
    letter-spacing: $letter-spacing-px5;

    span {
      display: block;
      padding-bottom: 8px;
      border-bottom: 1px dotted color-darken($honne-yellow-white, 12%);
    }
  }
}
