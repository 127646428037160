@charset "utf-8";

/*doc
---
title: heading
name: heading
category: components
---
```html_example
<h1 class="c-heading-h1" style="margin-bottom:8px;">見出しH1</h1>
<h1 class="c-heading-h2" style="margin-bottom:8px;">見出しH2</h1>
<h2 class="c-heading-h2" style="margin-bottom:8px;">
  <span class="c-heading-h2__sub">見出しH2サブタイトル</span>
</h2>
<h1 class="c-heading-h3" style="margin-bottom:16px;">見出しH3</h1>
<h1 class="c-heading-h1 c-heading--center" style="margin-bottom:8px;">見出しH1 text中央寄せ</h1>
<h1 class="c-heading-h1 c-heading--blue" style="margin-bottom:8px;">見出しH1 blue線</h1>
<h1 class="c-heading-h1 c-heading--yellow" style="margin-bottom:8px;">見出しH1 yellow線</h1>
```
*/

.c-heading-h1 {
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: $line-height-large;
  letter-spacing: $ls-small;
}

.c-heading-h2 {
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-base;
  letter-spacing: $ls-small;
}

.c-heading-h2__sub {
  display: block;
  font-size: $fs-2md;
}

.c-heading-h3 {
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $line-height-base;
  letter-spacing: $ls-medium;
}

.c-heading--center {
  text-align: center;
}

.c-heading--blue {
  @include heading-color($honne-blue);
}

.c-heading--yellow {
  @include heading-color($honne-yellow);
}

.c-heading--mb-sm {
  margin-bottom: 16px;
}

.c-heading--mb {
  margin-bottom: 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-heading-h1 {
    font-size: $fs-5xl;
    font-weight: bold;
    line-height: $line-height-base;
    letter-spacing: $ls-small;
  }

  .c-heading-h2 {
    position: relative;
    font-size: $fs-2xl;
    font-weight: bold;
    line-height: $line-height-base;
    letter-spacing: $ls-small;
  }

  .c-heading-h2__sub {
    display: inline;
    font-size: $fs-2xl;
  }

  .c-heading-h3 {
    font-size: $fs-xl;
    font-weight: bold;
    line-height: $line-height-base;
    letter-spacing: $ls-small;
  }
}
