@charset "utf-8";

.p-search-area__state {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.p-search-area__state__name {
  font-weight: bold;
}

.p-search-area__change {
  min-width: 30px;
  margin-left: 8px;
  font-size: $fs-sm;
}

.p-search-area__line {
  display: flex;
  justify-content: space-between;
}

.p-search-area__line__text {
  font-size: $fs-md;

  &:before {
    display: inline-block;
    margin-right: 8px;
    content: "┗";
  }
}

.p-search-area__line__text--selected {
  font-weight: bold;
}

.p-search-area__button {
  width: initial;
  padding: 16px;
  margin: 0 24px;
}

.p-search-area__detail {
  margin-right: 24px;
  margin-left: 24px;
}

.p-search-area__detail + .p-search-area__detail {
  margin-top: 12px;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-state__group {
    display: flex;
    align-items: baseline;
    margin-bottom: 24px;
  }

  .p-search-state__head {
    min-width: 200px;
  }

  .p-search-area__button {
    width: 100%;
    margin: 0;
  }

  .p-search-area__detail {
    margin-right: 0;
    margin-left: 0;
  }
}
