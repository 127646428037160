@charset "utf-8";

/*doc
---
title: step
name: step
category: components
---
```html_example
<div class="c-step" style="max-width: 400px;margin-left: 40px;">
  <div class="c-step__block c-step__block--done">
    <div class="c-step__graph">
      <span class="c-step__marker"></span>
      <span class="c-step__line"></span>
    </div>
    <p class="c-step__title p-cash-form-step__title1">必要項目の入力</p>
  </div>
  <div class="c-step__block">
    <div class="c-step__graph">
      <span class="c-step__marker"></span>
      <span class="c-step__line"></span>
    </div>
    <p class="c-step__title p-cash-form-step__title2">入力内容の確認</p>
  </div>
  <div class="c-step__block">
    <div class="c-step__graph">
      <span class="c-step__marker"></span>
    </div>
    <p class="c-step__title p-cash-form-step__title3">お申込み完了</p>
  </div>
</div>
```
*/

$marker-width: 24px;

.c-step {
  display: flex;
  width: 100%;
}

.c-step__marker {
  width: $marker-width;
  height: $marker-width;
  border: 7px solid $honne-gray-400;
  border-radius: 50%;
}

.c-step__line {
  width: calc(100% - #{$marker-width} - 16px);
  height: 6px;
  margin: 0 8px;
  background-color: $honne-gray-400;
  border-radius: 3px;
}

.c-step__title {
  margin-top: 8px;
  font-weight: bold;
  color: $honne-gray-500;
  text-align: center;
}

.c-step__block {
  flex: 1 1 0;

  // 最後のblockを伸ばさないようにする
  &:last-child {
    flex-grow: 0;
  }
}

.c-step__block--done {
  & .c-step__marker {
    border-color: $honne-blue;
  }

  & .c-step__line {
    background-color: $honne-blue;
  }

  & .c-step__title {
    color: $honne-blue;
  }
}

.c-step__graph {
  display: flex;
  align-items: center;
}
