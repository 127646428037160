@charset "utf-8";

.p-consultant-flow {
  margin-bottom: 48px;
}

.p-consultant-flow__inner {
  width: 100%;
}

.p-consultant-flow__steps {
  margin-top: 24px;
}

.p-consultant-flow__step {
  position: relative;
  padding: 38px 100px 20px 20px;
  border: 1px solid $honne-blue;

  &:not(:last-child) {
    border-bottom: none;
  }
}

.p-consultant-flow__step + .p-consultant-flow__step:after {
  position: absolute;
  top: -29px;
  left: 50%;
  display: block;
  width: 30px;
  height: 60px;
  content: "";
  background: url("~images/consultant/double-arrow.png") no-repeat center center;
  background-size: auto 100%;
  -ms-transform: rotate(0);
  transform: translateX(-50%) rotate(90deg);
}

.p-consultant-flow__step-count {
  position: absolute;
  top: 0;
  left: 0;
  width: 72px;
  font-family: Arial, sans-serif;
  font-size: $fs-md;
  font-weight: bold;
  line-height: $lh-x-large;
  color: $honne-white;
  text-align: center;
  background-color: $honne-blue;
  border-radius: 0 0 4px 0;
}

.p-consultant-flow__step-icon {
  position: absolute;
  top: 54px;
  right: 19px;
  display: block;
  width: 70px;
  height: 70px;
}

.p-consultant-flow__step-title {
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $lh-large;
  color: $honne-blue;
}

.p-consultant-flow__title {
  font-size: $fs-2xl;
  text-align: center;
  letter-spacing: 1px;
}

.p-consultant-flow__step-text {
  font-size: $fs-md;
  line-height: $lh-large;
}

.p-consultant-flow__line {
  padding: 16px;
  margin-top: 24px;
  background: $honne-gray-50;
  border-radius: 8px;
}

.p-consultant-flow__line-left {
  display: table;
  margin: 0 auto;
  color: $honne-green-dark;
}

.p-consultant-flow__line-title {
  font-size: $fs-3xl;
  line-height: $lh-large;
  letter-spacing: $ls-large;
}

.p-consultant-flow__line-fukidashi {
  width: 76px;
  vertical-align: bottom;
}

.p-consultant-flow__line-text {
  margin: 7px 0 15px;
  font-size: $fs-xs;
  line-height: $lh-medium;
}

.p-consultant-flow__line-center {
  display: none;
}

.p-consultant-flow__line-button {
  height: 56px;
  background: #53b535;
}

.p-consultant-flow__line-icon {
  width: 50px;
}

.p-consultant-flow__line-button-text {
  width: calc(100% - 42px);
  font-family: $font-family-700bold;
  font-size: $fs-xl;
  color: $honne-white;
  text-align: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-consultant-flow {
    margin-bottom: 72px;
  }

  .p-consultant-flow__steps {
    display: flex;
    margin-top: 32px;
  }

  .p-consultant-flow__step {
    width: 25%;
    padding: 48px 24px 24px;

    &:not(:last-child) {
      border-bottom: 1px solid $honne-blue;
    }
  }

  .p-consultant-flow__title {
    font-size: $fs-5xl;
    letter-spacing: 2px;
  }

  .p-consultant-flow__step + .p-consultant-flow__step {
    border-left: none;
  }

  .p-consultant-flow__step + .p-consultant-flow__step:after {
    top: 54px;
    left: -18px;
    width: 47px;
    height: 70px;
    transform: inherit;
  }

  .p-consultant-flow__step-icon {
    position: static;
    width: 90px;
    height: 90px;
    margin: 0 auto 9px;
  }

  .p-consultant-flow__step-title {
    margin-bottom: 8px;
    font-size: $fs-2xl;
    font-weight: bold;
    text-align: center;
  }

  .p-consultant-flow__line {
    display: flex;
    align-items: stretch;
    padding: 0;
    overflow: hidden;
    background: $honne-gray-50;
  }

  .p-consultant-flow__line-left {
    position: relative;
    display: block;
    padding: 24px;
    margin: 0;
    margin-right: auto;
    background: $honne-gray-50;
  }

  .p-consultant-flow__line-title {
    display: flex;
    align-items: center;
    font-size: $font-size-big1;
    letter-spacing: $letter-spacing0;
  }

  .p-consultant-flow__line-br {
    display: none;
  }

  .p-consultant-flow__line-fukidashi {
    width: 97px;
  }

  .p-consultant-flow__line-text {
    margin: 6px 0 0;
    font-size: $fs-md;
    line-height: $lh-medium;
    text-align: center;
  }

  .p-consultant-flow__line-center {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 0 16px 40px;
    background: $honne-blue-bright;
  }

  .p-consultant-flow__line-qr-text {
    margin-bottom: 2px;
    font-size: $fs-lg;
    font-weight: bold;
    line-height: $lh-medium;
    color: $honne-blue;
  }

  .p-consultant-flow__line-qr-code {
    width: 80px;
    margin-left: 10px;
  }

  .p-consultant-flow__line-right {
    display: flex;
    align-items: center;
    padding: 0 24px 0 16px;
    background: $honne-blue-bright;
  }

  .p-consultant-flow__line-center:before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    content: "";
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;
    border-left: 26px solid $honne-gray-50;
    transform: translateY(-50%);
  }

  .p-consultant-flow__line-button {
    width: 241px;
    height: 80px;
  }

  .p-consultant-flow__line-icon {
    width: 63px;
  }

  .p-consultant-flow__line-button-text {
    width: calc(100% - 42px);
    font-family: $font-family-700bold;
    font-size: $fs-xl;
    color: $honne-white;
    text-align: center;
  }
}
