@charset "utf-8";

.p-my-offers {
  padding-top: 0;
}

.p-my-offer {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid $honne-gray-200;
}

@include link-color("p-my-offer", $honne-gray-700);

.p-my-offer__image {
  width: 65px;
  height: 65px;
  margin-right: 16px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.p-my-offer__main {
  flex: 1;
  font-weight: bold;
}

.p-my-offer__time {
  margin-bottom: 4px;
  font-size: $fs-xs;
  color: $honne-gray-500;
}

.p-my-offer__top {
  margin-bottom: 4px;
}

.p-my-offer__fav {
  display: flex;
  gap: 4px;
  align-items: center;
  width: fit-content;
}

.p-my-offer__title {
  font-size: $fs-md;
}

.p-my-offer__text {
  font-size: $fs-xs;
}

.p-my-offers__pager {
  margin-top: 24px;
  margin-bottom: 24px;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-my-offers {
    margin-top: 12px !important;
  }

  .p-my-offer {
    padding: 16px 0;
  }

  .p-my-offer__time {
    font-size: $fs-sm;
  }

  .p-my-offer__image {
    width: 80px;
    height: 80px;
  }

  .p-my-offer__title {
    margin-right: 8px;
    margin-bottom: 0;
    font-size: $fs-lg;
  }

  .p-my-offer__top {
    display: flex;
    margin-bottom: 8px;
  }
}
