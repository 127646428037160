@charset "utf-8";

.p-market-price-faqs {
  padding: 24px 0 12px 0;
  background-color: $honne-white;
  border-radius: 8px;
}

.p-market-price-faq__title {
  padding: 0 0 12px 24px;
  font-size: $fs-2xl;
  font-weight: $font-weight-700bold;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-market-price-faqs {
    padding: 24px 24px 12px 24px;
  }

  .p-market-price-faq__title {
    padding: 0 0 12px 0;
  }
}
