@charset "utf-8";

.p-consultant-promise {
  margin-bottom: 24px;
}

.p-consultant-promise__body {
  margin-top: 24px;
}

.p-consultant-promise__text {
  margin-bottom: 16px;
  font-size: $fs-lg;
  line-height: $line-height3;
  text-align: center;
}

.p-consultant-promise__text-br {
  display: none;
}

.p-consultant-promise__logo {
  display: block;
  margin: 0 auto;
}

.p-consultant-promise__title {
  font-size: $fs-2xl;
  text-align: center;
  letter-spacing: $ls-medium;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-consultant-promise {
    margin-bottom: 40px;
  }

  .p-consultant-promise__title {
    font-size: $fs-5xl;
    letter-spacing: $ls-large;
  }

  .p-consultant-promise__inner {
    margin-bottom: 32px;
  }

  .p-consultant-promise__text {
    margin-bottom: 24px;
  }

  .p-consultant-promise__text-br {
    display: block;
  }
}
