.p-optout__container {
  max-width: 640px;
  margin: 0 auto 64px auto;
}

.p-optout__title {
  margin: 24px 0;
  font-size: $fs-2xl;
  color: $honne-blue;
}

.p-optout__title__error {
  color: $honne-red-bright;
}

.p-optout__content {
  padding-bottom: 32px;
}

.p-optout__button {
  font-weight: bold;
}

.p-optout-email {
  margin-bottom: 32px;
}

.p-optout-email-inner__header {
  max-width: 960px;
  padding: 24px 0 8px;
}

.p-optout-email__h1 {
  font-family: $font-family-700bold;
  font-size: $fs-2xl;
  font-weight: $font-weight-700bold;
}

.p-optout-email__button {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-optout-email-inner__header {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 24px auto;
  }
  .p-optout-email__h1 {
    margin-bottom: 0;
    font-size: $fs-5xl;
  }
  .p-optout-email__button {
    max-width: 400px;
  }
}
