@charset "utf-8";

.p-jigyosho-photo__items {
  display: flex;
  flex-direction: column;
}

.p-jigyosho-photo__item {
  width: 100%;
  margin-bottom: 16px;
}

.p-jigyosho-photo__img {
  position: relative;
  height: 260px;
}

.p-jigyosho-photo__img__inner {
  width: 100%;
  height: 100%;
  margin: auto;
  cursor: pointer;
  object-fit: cover;
}

.p-jigyosho-photo__text {
  margin: 8px 24px 0;
  font-size: $fs-sm;
  line-height: $lh-small;
}

.p-jigyosho-photo__title {
  margin-top: 8px;
  font-size: $fs-2md;
  font-weight: bold;
  color: $honne-gray-700;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-jigyosho-photo__items {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .p-jigyosho-photo__item {
    width: calc(50% - 8px);
  }

  .p-jigyosho-photo__img {
    height: 260px;
    margin-right: 0;
    margin-left: 0;
  }

  .p-jigyosho-photo__img__inner {
    border-radius: 8px;
  }

  .p-jigyosho-photo__text {
    margin: 8px 0 0;
    line-height: $lh-medium;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-photo__img {
    height: 320px;
  }
}

/* 990px ~ */
@include min-screen(990px) {
  .p-jigyosho-photo__img {
    height: 260px;
  }
}
