@charset "utf-8";

.p-jigyosho-summary {
  padding: 16px 24px 0;
  margin: 0;
}

.p-jigyosho-summary > .p-jigyosho-contact-inquiry {
  margin-top: 8px;
}

.p-jigyosho-summary__labels {
  display: flex;
  gap: 4px;
  align-items: flex-start;
  margin-bottom: 8px;
}

.p-jigyosho-summary__labels__main {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.p-jigyosho-summary__favorite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 34px;
  min-width: 34px;
  height: 34px;
  margin-left: auto;
  background-color: $honne-yellow-white;
  border-radius: 26px;
}

.p-jigyosho-summary__title {
  margin-bottom: 6px;
  font-family: $font-family-700bold;
  font-size: $fs-4xl;
  font-weight: bold;
  color: $honne-gray-700;
  letter-spacing: $ls-small;
}

.p-jigyosho-summary__owner {
  margin-bottom: 8px;
  font-size: $fs-sm;
}

.p-jigyosho-summary__list {
  width: 100%;
  margin: 12px 0 0;
  border-bottom: 1px solid $honne-gray-300;
  & .c-list__item {
    padding: 8px 0;
  }
}

.p-jigyosho-summary__address__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  font-size: $fs-sm;
  line-height: $line-height2;
  text-decoration: underline;
}

.p-jigyosho-summary__note__link {
  margin-top: 4px;
  margin-left: 4px;
  font-size: $fs-sm;
  text-decoration: underline;
}

.p-jigyosho-summary__share {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 12px;
  font-size: $fs-2xs;
  border: 1px solid $honne-gray-400;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.p-jigyosho-summary__share__icon {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-image: url("~images/share_icon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.p-jigyosho-summary__share__text {
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-700;
}

.p-jigyosho-summary__link {
  display: block;
  margin: 16px auto 0;
  text-align: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-summary {
    padding: 16px 32px;
  }

  .p-jigyosho-summary > .p-jigyosho-contact-inquiry {
    margin-top: 12px;
  }

  .p-jigyosho-summary__list {
    margin: 0 0 16px;
  }

  .p-jigyosho-summary__wrap {
    .c-jigyosho-summary__availability {
      margin-right: 24px;
    }
  }

  .p-jigyosho-summary__favorite {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    min-width: 165px;
    height: 36px;
    border-radius: 8px;
  }

  .p-jigyosho-summary__favorite__text {
    color: $honne-gray-700;
  }

  .p-jigyosho-summary__owner {
    font-size: $fs-md;
  }

  .p-jigyosho-summary__title {
    margin-bottom: 8px;
  }

  .p-jigyosho-summary__share {
    display: none;
  }

  .p-jigyosho-summary__cost {
    margin-right: 16px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-summary {
    width: 100%;
    padding: 0;
  }

  .p-jigyosho-main-img + .p-jigyosho-summary {
    width: 526px;
  }

  .p-jigyosho-summary__link {
    display: none;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-main-img + .p-jigyosho-summary {
    width: calc(100% - 310px);
  }
}
