@charset "utf-8";

.c-guide-summary__description {
  font-size: $fs-md;
  line-height: $line-height3;

  br {
    display: none;
  }
}

.c-guide-summary__description--first {
  margin-bottom: 12px;
}

.c-guide-summary__highlight {
  background: linear-gradient(transparent 50%, rgba($honne-yellow, 0.3) 50%);
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-guide-summary__description {
    font-size: $fs-lg;
    text-align: center;
    letter-spacing: $letter-spacing-px5;

    br {
      display: block;
    }
  }

  .c-guide-summary__br {
    display: none;
  }
}
