@charset "utf-8";

.c-guide-contact {
  padding: 0;
}

.c-guide-map {
  margin-top: 0;
}

.c-guide-map-keywords__inner {
  padding: 0;
}

.c-guide-map__keywords {
  margin-top: 24px;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-guide-map {
    max-width: 1080px;
    margin: 40px auto 56px;
  }

  .c-guide-map__keywords {
    margin-top: 0;
  }
}
