@charset "utf-8";

// 3つのポイント
.c-jg-point {
  margin-top: 24px;

  &__head {
    font-size: $fs-lg;
    font-weight: bold;
  }

  &__inner {
    margin-top: 32px;
  }

  &__item + &__item {
    margin-top: 24px;
  }

  &__label {
    width: 140px;
    padding: 8px;
    margin: 0 auto;
    font-weight: bold;
    color: $honne-red-bright;
    text-align: center;
    background-color: $honne-gray-200;
    border-radius: 100px;
  }

  &__text {
    margin-top: 8px;
    font-weight: bold;
    color: $honne-gray-600;
    text-align: center;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-jg-point {
    &__inner {
      display: flex;
      margin-top: 32px;
    }

    &__item {
      width: 33%;
      padding: 0 8px;
    }

    &__item + &__item {
      margin-top: 0;
    }
  }
}
