@charset "utf-8";

/*doc
---
title: side-whitepaper
name: side-whitepaper
category: components
---
```html_example
<div class="c-side-whitepaper" style="width:256px;">
  <img class="c-side-whitepaper__image" src="http://placehold.it/256x126">
  <div class="c-side-whitepaper__box">
    <p class="c-side-whitepaper__caption">これから介護をはじめる方向け</p>
    <p class="c-side-whitepaper__title">初心者のための<br>介護スタートガイド</p>
    <a class="c-side-whitepaper__button" href="#">
      <span class="c-side-whitepaper__button-text">資料を無料ダウンロード</span>
      <i class="c-icon-arrow-right-white"></i>
    </a>
  </div>
</div>
```
*/

.c-side-whitepaper__image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.c-side-whitepaper__box {
  padding: 16px;
  overflow: hidden;
  background: url("~images/whitepaper/bg.png") no-repeat;
  background-size: cover;
  border-radius: 0 0 8px 8px;
}

.c-side-whitepaper__caption {
  margin-bottom: 4px;
  font-size: $font-size-x-medium;
  font-weight: bold;
  color: $honne-blue;
  text-align: center;
}

.c-side-whitepaper__title {
  margin-bottom: 12px;
  font-family: $font-family-700bold;
  font-size: $font-size-xx-large;
  font-weight: 700;
  color: $honne-blue;
  text-align: center;
}

.c-side-whitepaper__button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  font-size: $font-size-large;
  font-weight: bold;
  line-height: $line-height-px5;
  color: $honne-white;
  text-align: center;
  background-color: $honne-yellow;
  border-radius: 8px;

  &:visited {
    color: $honne-white;
  }
}

.c-side-whitepaper__button-text {
  display: inline-block;
  margin-right: 4px;
  text-shadow: color-darken($honne-yellow, 12%) 1px 0 10px;
}
