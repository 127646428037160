@charset "utf-8";

.p-ranking-filter {
  padding: 20px 16px 16px 16px;
  background-color: $honne-white;
}

.p-ranking-filter__container {
  display: grid;
  grid-template-areas:
    "grid1 grid2"
    "grid3 grid3";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.p-ranking-filter__option {
  display: flex;
  flex-direction: column;

  &.grid-area1 {
    grid-area: grid1;
  }

  &.grid-area2 {
    grid-area: grid2;
  }

  &.grid-area3 {
    grid-area: grid3;
  }
}

.p-ranking-filter__label {
  font-size: $fs-xs;
  font-weight: bold;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-ranking-filter {
    padding: 20px 24px 28px 24px;
    border-radius: 0 0 8px 8px;
  }

  .p-ranking-filter__container {
    grid-template-areas: "grid1 grid2 grid3";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .p-ranking-filter__label {
    font-size: $fs-md;
  }
}
