@charset "utf-8";

.p-jys-search-col__main__inner {
  padding: 0 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jys-search-col__main {
    padding: 0;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jys-search-col__main__inner {
    padding: 0;
  }
}
