@charset "utf-8";

.p-news-related__list {
  display: flex;
  padding: 16px 24px;
  border-top: 1px solid $honne-gray-300;
}

.p-news-related__thumb {
  margin-right: 12px;

  & img {
    width: 90px;
    height: 90px;
    vertical-align: middle;
    object-fit: cover;
    border-radius: 4px;
  }
}

.p-news-related__main {
  width: 100%;
}

.p-news-related__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.p-news-related__date {
  font-size: $fs-3xs;
  color: $honne-gray-600;
}

.p-news-related__desc {
  font-weight: bold;
  color: $honne-gray-700;
}

.p-news-related__desc:visited {
  color: $honne-gray-700;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-related__date {
    font-size: $fs-sm;
  }

  .p-news-related__desc {
    font-size: $fs-lg;
  }

  .p-news-related__list {
    padding: 16px 0;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-news-related__lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .p-news-related__list {
    width: calc(50% - 12px);

    &:nth-last-child(-n + 2) {
      border-bottom: 1px solid $honne-gray-300;
    }
  }

  .p-news-related__thumb {
    margin-right: 16px;
  }
}
