@charset "utf-8";

.p-special3-problem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $honne-blue-lighten;
}

.p-special3-problem__caption {
  padding: 16px 24px 8px 24px;
  font-size: $fs-2xl;
  color: $honne-white;
  text-align: center;
  letter-spacing: $letter-spacing-px6;
}

.p-special3-problem__image {
  position: relative;
  bottom: -8px;
  width: 221px;
  height: 128px;
  background-image: url("~images/lp/special3/solution.png");
  background-repeat: no-repeat;
  background-size: cover;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special3-problem {
    flex-direction: row;
    border-radius: 60px 60px 0 0;
  }

  .p-special3-problem__caption {
    padding: 60px 0 60px 100px;
    font-size: $font-size-big2;
    text-align: left;
  }

  .p-special3-problem__image {
    right: 0;
    bottom: -20px;
    width: 353px;
    height: 204px;
    margin-right: 120px;
  }
}
