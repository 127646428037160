@charset "utf-8";

.p-top-hero-keywords {
  margin-top: 12px;
}

.p-top-hero-keywords__link {
  margin-right: 4px;
  margin-bottom: 12px;

  &::after {
    right: -2px;
    width: 22px;
    height: 22px;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-hero-keywords {
    width: 85%;
    margin: 0 auto;
  }

  .p-top-hero-keywords__link {
    padding: 4px 12px;
    font-size: $fs-sm;
    box-shadow: $shadow-size-small;

    &::after {
      right: -2px;
      width: 24px;
      height: 24px;
    }
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-hero-keywords {
    width: 760px;
  }
}
