@charset "utf-8";

.p-my-side-head {
  display: none;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-my-side-head {
    display: block;
    padding: 16px 24px;
    font-size: $fs-xl;
    font-weight: bold;
    background-color: $honne-white;
    border-bottom: 1px solid $honne-gray-300;
    border-radius: 8px 8px 0 0;
  }
}
