@charset "utf-8";

.p-questions__search-tel {
  margin-top: 32px;
}

.p-questions__side {
  .c-side-sns {
    background-color: $honne-white;
  }
}
