@charset "utf-8";

.p-cash-top__inner {
  margin-top: 16px;
}

.p-cash-top__pickup {
  width: 75px;
  margin-top: -24px;
  margin-bottom: 12px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cash-top__inner {
    margin-top: 0;
  }

  .p-cash-top__pickup {
    width: 90px;
  }
}
