@charset "utf-8";

.p-sitemap-link-list__item {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: bold;

  &:before {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 8px;
    vertical-align: middle;
    content: "";
    background-color: $honne-gray-700;
    border-radius: 50%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-sitemap-link-list {
    display: flex;
    flex-wrap: wrap;
  }

  .p-sitemap-link-list__item {
    width: 50%;
  }
}
