@charset "utf-8";

// キーワード検索
.p-jys-keyword {
  margin: 12px 24px 0;
}

.p-jys-keyword__title {
  margin-bottom: 8px;
  font-size: $fs-2md;
  font-weight: bold;
}

.p-jys-keyword input[type="text"] {
  margin-bottom: 16px;
  border: solid 2px $honne-gray-300;
}

@include min-screen($breakpoint-pc-min) {
  .p-jys-keyword {
    margin: 16px 0 32px;
  }

  .p-jys-keyword__title {
    margin-bottom: 24px;
    font-size: $fs-2xl;
  }

  .p-jys-keyword input[type="text"] {
    margin-bottom: 0;
  }
}
