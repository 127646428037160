@charset "utf-8";

/*doc
---
title: guide
name: guide
category: components
---

```html_example
<a class="c-guide" href="#">
  <div class="c-guide__image">
    <img alt="介護を始めるにあたって必要な準備と手続きを紹介" src="http://placehold.it/300x200">
  </div>
  <div class="c-guide__text">
    <h3 class="c-guide__title">【はじめに】家族の介護を始める際に知っておきたいこと</h3>
    このページでは「まだ介護が必要ではないが、そろそろ準備したい」とお考えの人に向けて「介護に取り組む前に知っておきたいこと」をお伝えします。この記事さえ読んでいれば、緊急事態にも安心して対応できますので「まだ必要ない」とお考えの場合でもぜひご覧く...
    <div class="c-guide__more">
      <span class="c-link__text">詳しくみる</span>
      <i class="c-link__icon c-icon-arrow-right-blue c-icon--small"></i>
    </div>
  </div>
</a>
```
*/

.c-guide__contents + .c-guide__contents {
  margin-top: 32px;
}

.c-guide__image {
  width: 100%;
  margin-bottom: 16px;
}

.c-guide__img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.c-guide__title {
  font-size: $fs-xl;
  line-height: $line-height10;
  color: $honne-gray-700;
}

.c-guide__text {
  font-size: $fs-md;
  line-height: $line-height10;
  color: $honne-gray-700;
}

.c-guide__text:after {
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
  content: ".";
}

.c-guide__more {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 8px;
  margin-left: auto;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-guide__contents + .c-guide__contents {
    margin-top: 32px;
  }

  .c-guide {
    display: flex;
  }

  .c-guide__image {
    flex-shrink: 1;
    max-width: 280px;
    height: 186px;
    margin-right: 24px;
    margin-bottom: 0;
  }

  .c-guide__text {
    flex-shrink: 1;
    max-width: 400px;
  }
}
