@charset "utf-8";

.p-jigyosho-articles {
  padding: 20px 16px;
  margin: 24px 16px;
  background: $honne-white;
  border-radius: 4px;
  box-shadow: $shadow-size-small;
}

.p-jigyosho-articles__list-item {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid $honne-gray-300;
}

.p-jigyosho-articles__list-item:last-child {
  padding-bottom: 5px;
  margin-bottom: 0;
  border: none;
}

.p-jigyosho-articles__list-title {
  margin-bottom: 8px;
  font-size: $fs-lg;
  line-height: $line-height-large;
}

.p-jigyosho-articles__list-text {
  position: relative;
  font-size: $fs-md;
  line-height: $line-height-px2;
}

.p-jigyosho-articles__list-text:after {
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
  content: ".";
  opacity: 0;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-articles {
    padding: 20px 24px;
    margin: 0 0 24px;
  }
  .p-jigyosho-articles__list-title {
    font-size: $fs-xl;
  }
}

// /* 900px ~ */
// @include min-screen($breakpoint-pc-small-max) {
//   .p-jigyosho-articles {
//     padding: 20px 24px;
//     margin: 0 0 36px;
//   }
// }
