@charset "utf-8";

/*doc
---
title: color
name: color
category: settings
---

```html_example
<div style="display: flex;">
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #1298b3;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">blue</div>
    <div>#1298b3</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #ffffff;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">white</div>
    <div>#ffffff</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #000;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">black</div>
    <div>#000</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #d92b2b;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">red</div>
    <div>#d92b2b</div>
  </div>
</div>

<div style="display: flex;margin-top: 20px;">
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #ff5959;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">red-bright</div>
    <div>#ff5959</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #f2b830;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">yellow</div>
    <div>#f2b830</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #faf2eb;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">yellow-bright</div>
    <div>#faf2eb</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #009999;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">green</div>
    <div>#009999</div>
  </div>
</div>

<div style="display: flex;margin-top: 20px;">
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #222222;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">gray-800</div>
    <div>#222222</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #333333;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">gray-700</div>
    <div>#333333</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #666666;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">gray-600</div>
    <div>#666666</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #888888;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">gray-500</div>
    <div>#888888</div>
  </div>
</div>

<div style="display: flex;margin-top: 20px;">
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #cccccc;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">gray-400</div>
    <div>#cccccc</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #eeeeee;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">gray-300</div>
    <div>#eeeeee</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #f2f2f2;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">gray-200</div>
    <div>#f2f2f2</div>
  </div>
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #f7f7f7;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">gray-100</div>
    <div>#f7f7f7</div>
  </div>
</div>

<div style="display: flex;margin-top: 20px;width: 25%;">
  <div style="flex: 1;text-align: center;font-size: 18px;border: 1px solid #dfe3e6;">
    <div style="background-color: #F24949;height: 120px;border-bottom: 1px solid #dfe3e6;"></div>
    <div style="margin-top: 10px;font-weight: bold;">primary-button-hover</div>
    <div>#F24949</div>
  </div>
</div>
```
*/

$honne-blue: #1298b3;
$honne-blue-lighten: #1aa7c5;
$honne-blue-bright: #e7f4f7;
$honne-blue-gray: #e5e9f2;
$honne-white: #fff;
$honne-black: #000;

$honne-red: #d92b2b;
$honne-red-bright: #ff5959;
$honne-red-light: #fbeeee;

$honne-yellow: #f2b830;
$honne-yellow-bright: #faf2eb;
$honne-yellow-bright2: #fbeac1;
$honne-yellow-white: #f8f7f5;
$honne-yellow-dark: #fefaef;
$honne-white-coral: #faf3e3;

$honne-green: #009999;
$honne-green-dark: #53b535;

$honne-gray-800: #222222;
$honne-gray-700: #333333;
$honne-gray-600: #666666;
$honne-gray-500: #888888;
$honne-gray-400: #cccccc;
$honne-gray-300: #eeeeee;
$honne-gray-200: #f2f2f2;
$honne-gray-100: #f7f7f7;
$honne-gray-50: #f9f9f9;
$honne-brown: #672500;

// for JM
$honne-jm-blue: #39bfb9;

// for SNS
$twitter-brand-color: #1da1f2;
$facebook-brand-color: #1a78f2;
$line-brand-color: #07c655;
