@charset "utf-8";

.c-staff {
  min-width: 320px;
  max-width: 344px;
  padding: 24px;
  border: 1px solid $honne-blue-gray;
  border-radius: 12px;
}

.c-staff__top {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.c-staff__img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.c-staff__name {
  margin-top: 6px;
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: bold;
}

.c-staff__date {
  margin-bottom: 4px;
  font-size: $fs-sm;
  color: $honne-gray-600;
}

.c-staff__desc {
  font-size: $fs-sm;
  color: $honne-gray-600;
}

.c-staff__text {
  line-height: $lh-medium;
}
