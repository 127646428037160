@charset "utf-8";

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jg-line {
    display: flex;
    margin-bottom: 8px;
  }

  .p-jg-line__item {
    flex: 1;
    max-width: 50%;
  }

  .p-jg-line__item + .p-jg-line__item {
    margin-left: 24px;
  }
}
