@charset "utf-8";

.p-theme-detail-special__top {
  margin-bottom: 12px;
  font-weight: bold;
}

.p-theme-detail-special__title {
  font-size: $fs-2md;
}

.p-theme-detail-special__head {
  font-size: $fs-xl;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-theme-detail-special__top {
    display: flex;
  }

  .p-theme-detail-special__title {
    font-size: $fs-2xl;
  }

  .p-theme-detail-special__head {
    font-size: $fs-2xl;
  }
}
