@charset "utf-8";

.p-offer-block__button {
  flex: 1;
  max-width: 200px;
}

.p-offer-block__form {
  flex: 1;
  min-width: 160px;
  max-width: 200px;
}
