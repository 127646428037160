.p-questions__description {
  font-size: $fs-xs;
  line-height: $lh-large;
  color: $honne-gray-600;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-questions__description {
    font-size: $fs-md;
    line-height: $lh-large;
  }
}
