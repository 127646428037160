@charset "utf-8";

.p-jigyosho-chart-block {
  padding: 16px 0;
}

.p-jigyosho-chart-block__head {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-700;
}

.p-jigyosho-chart-block__content {
  width: 100%;
}

.p-jigyosho-chart-block__doughnut {
  width: 80%;
  max-width: 200px;
  margin: 0 auto 12px;
}

.p-jigyosho-chart-block__bar {
  width: 100%;
}

.p-jigyosho-chart-block__comment {
  margin-top: 8px;
  font-size: $fs-sm;
  color: $honne-gray-500;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-jigyosho-chart-block__doughnut {
    margin: 0 auto;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-chart-block {
    display: flex;
  }

  .p-jigyosho-chart-block__head {
    width: 160px;
    min-width: 160px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  .p-jigyosho-chart-block__content {
    font-size: $fs-lg;
  }

  .p-jigyosho-chart-block__doughnut {
    margin: 0 40px;
  }
}
