@charset "utf-8";

.p-jigyosho-recommend-message {
  padding: 16px;
  margin-bottom: 24px;
  background: $honne-yellow-white;
  border-radius: $radius-size-medium;
}

.p-jigyosho-recommend-message__item {
  position: relative;
  padding-left: 38px;
  margin-bottom: 12px;
  font-size: $fs-md;
  font-weight: bold;
}

.p-jigyosho-recommend-message__item:last-child {
  margin-bottom: 0;
}

.p-jigyosho-recommend-message__icon {
  position: absolute;
  top: 3px;
  left: 0;
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
