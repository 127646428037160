@charset "utf-8";

.p-search-state {
  padding: 24px;
}

.p-search-state__group {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid $honne-gray-300;

  &:last-child {
    margin-bottom: 0;
  }
}

.p-search-state__head {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-search-state__items {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.p-search-state__item {
  padding: 6px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  border: 1px solid $honne-gray-400;
  border-radius: 4px;
}

.p-search-state__number {
  font-size: $fs-2xs;
  font-weight: normal;
  color: $honne-gray-700;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-state__group {
    display: flex;
    align-items: baseline;
  }

  .p-search-state__head {
    min-width: 200px;
  }
}
