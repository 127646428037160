@charset "utf-8";

.p-news__inner {
  padding: 24px;
}

.p-news__meta {
  margin-bottom: 40px;
}

.p-news__meta-title {
  margin-top: 12px;
  font-size: $fs-2xl;
}

.p-news__meta-date-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.p-news__meta-date {
  flex-shrink: 0;
  margin-right: 12px;
  font-size: $fs-sm;
  color: $honne-gray-600;
}

.p-news__meta-read {
  margin: 8px 0;
  font-size: $fs-sm;
  color: $honne-gray-600;
}

/* ~ 1080px */
@include max-screen($breakpoint-pc-max) {
  .p-news {
    box-shadow: initial;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news {
    border-radius: 12px;
  }

  .p-news__inner {
    padding: 32px 48px;
  }

  .p-news__meta-title {
    font-size: $fs-4xl;
  }

  .p-news__meta-read {
    font-size: $fs-2md;
    line-height: $lh-x-large;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-news-col__main {
    border-radius: 12px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-news__meta-title {
    /* stylelint-disable sh-waqar/declaration-use-variable */
    font-size: 32px;
    /* stylelint-enable sh-waqar/declaration-use-variable */
  }
}
