@charset "utf-8";

.p-jigyosho-map {
  position: relative;
  width: 100%;
  height: 300px;
  padding: 39% 0 0 0;
  margin-bottom: 16px;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-map {
    height: 400px;
  }
}
