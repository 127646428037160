@charset "utf-8";

.p-release__date-tags-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 16px;
}

.p-release__date {
  font-size: $fs-sm;
  color: $honne-gray-600;
}

.p-release__tags {
  margin-left: 12px;
}

.p-release__tag {
  display: inline-block;
  min-width: 60px;
  height: 20px;
  margin-right: 3px;
  font-size: $fs-sm;
  color: $honne-gray-700;
  text-align: center;
  background: $honne-gray-300;
  border-radius: 4px;
}

.p-release__h1 {
  margin-bottom: 12px;
}

.p-release__body {
  font-size: $fs-sm;
  line-height: $line-height-base;
}

.p-release__body h2 {
  margin-bottom: 1.5em;
  font-size: $fs-xl;
  line-height: $line-height-base;
}

.p-release__body h3 {
  margin-bottom: 1.5em;
  font-size: $fs-lg;
  line-height: $line-height-base;
}

.p-release__body h4 {
  margin-bottom: 1.5em;
  font-size: $fs-md;
  line-height: $line-height-base;
}

.p-release__body p {
  margin-bottom: 1.5em;
}

.p-release__body ul,
.p-release__body ol {
  margin-bottom: 1.5em;
}

.p-release__body ol li {
  margin-left: 1.5em;
  list-style: disc;
}

.p-release__body ul li {
  margin-left: 1.5em;
  list-style: decimal;
}

.p-release__body img {
  display: block;
  max-width: 100%;
  margin: 0 auto 1.5em;
  border-radius: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-release__h1 {
    margin-bottom: 24px;
  }

  .p-release__date-tags-wrap {
    display: flex;
    align-items: center;
    margin: -8px 0 16px;
  }

  .p-release__body img {
    max-width: 70%;
  }
}
