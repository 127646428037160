.p-qustions-request__box {
  display: flex;
  flex-direction: column;
}

.p-questions-request__title-h2 {
  position: relative;
  margin: 40px 0 24px;
  font-size: $fs-2md;
  font-weight: bold;
  line-height: $lh-small;
  border-top: 1px dashed $honne-yellow;
}

.p-questions-request__title-h2--archive {
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 4px;
  border: none;
}

.p-questions-request__logo {
  height: 32px;
  margin-right: 8px;
}

.p-questions-request__box-right,
.p-questions-request__box-right:visited {
  flex: 1;
  margin-top: 12px;
  font-weight: bold;
  color: $honne-white;
  background-color: $honne-blue-lighten;
}

.p-questions-request__box-left {
  display: flex;
  flex: 4;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-qustions-request__box {
    flex-direction: row;
  }

  .p-questions-request__box-right {
    margin-top: 0;
  }

  .p-questions-request__title-h2 {
    font-size: $fs-2xl;
  }
}
