@charset "utf-8";

.p-privacy-box {
  margin-bottom: 24px;
}

.p-privacy__title {
  padding: 24px 24px 0;
}

.p-privacy__subtitle {
  margin-bottom: 16px;
}

.p-privacy__date {
  text-align: right;
}
