@charset "utf-8";

/*doc
---
title: tel-box
name: tel-box
category: components
---
```html_example
<a class="c-tel-box" href="#">
  <p class="c-tel-box__title">入居相談室に無料電話相談</p>
  <div class="c-tel-box__tel">
    <i class="c-icon c-icon-tel c-tel-box__icon"></i>
    0120-002718
  </div>
  <p class="c-tel-box__desc">営業時間：9:00〜18:00</p>
</a>
```
*/

// guide,QA記事内で使用
.c-tel-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 0;
  margin-top: 16px;
  color: $honne-gray-700;
  border: 2px solid $honne-blue;
  border-radius: $radius-size-large;
}

.c-tel-box:visited {
  color: $honne-gray-700;
}

.c-tel-box__title {
  font-size: $fs-lg;
  font-weight: bold;
}

.c-tel-box__tel {
  display: flex;
  align-items: center;
  font-size: $fs-5xl;
  font-weight: bold;
  line-height: initial;
}

.c-tel-box__tel__image {
  width: 32px;
  height: 23px;
  margin-right: 8px;
}

.c-tel-box__desc {
  font-size: $fs-sm;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-tel-box {
    width: calc(50% - 8px);
    padding: 8px 0;
    margin-top: 0;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-tel-box__desc {
    font-size: $fs-xs;
  }
}
