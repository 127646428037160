@charset "utf-8";

.p-regist-caremanager-form {
  background: $honne-yellow-white;
}

.p-regist-caremanager-form__top {
  padding: 40px 0 24px;
  text-align: center;
}

.p-regist-caremanager-form__h2 {
  font-size: $fs-4xl;
}

.p-regist-caremanager-form__form {
  padding: 24px 24px 0;
}

.p-regist-caremanager-form__content {
  margin-bottom: 8px;

  span {
    font-size: $fs-sm;
  }
}

.p-regist-caremanager-form__bottom {
  margin-top: 24px;
}

.p-regist-caremanager-form__button {
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 46px;
}

.p-regist-caremanager-form__checkbox {
  margin-left: 0;
  color: $honne-gray-700;
}

.p-regist-caremanager-form__legal {
  text-align: center;
}

.p-brochure-checkbox {
  margin-top: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-caremanager-form {
    padding-bottom: 24px;
  }

  .p-regist-caremanager-form__top {
    padding: 72px 0 40px;
  }

  .p-regist-caremanager-form__h2 {
    font-size: $font-size-big3;
  }

  .p-regist-caremanager-form__inner {
    max-width: 960px;
    margin: 0 auto;
  }

  .p-regist-caremanager-form__form {
    padding: 56px;
    margin-bottom: 24px;
    border-radius: 24px;
    box-shadow: 0 2px 24px rgba(223, 223, 223, 0.25);
  }

  .p-regist-caremanager-form__checkbox {
    font-size: $fs-md;
  }

  .p-regist-caremanager-form__button {
    max-width: 400px;
    padding: 24px;
    margin-right: auto;
    margin-left: auto;
    font-size: $fs-3xl;
  }

  .p-brochure-checkbox {
    margin-top: 16px;
  }

  .p-regist-caremanager-form__content {
    span {
      font-size: $fs-md;
    }
  }
}
