@charset "utf-8";

.p-history {
  margin: 24px;
}

.p-history__alert {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-600;
  text-align: center;
}

%p-history__alert-button {
  width: 250px;
  padding: 14px;
  margin: 0 auto;
  font-size: $fs-lg;
  border-radius: 4px;
}

.p-history__alert-button {
  @extend %p-history__alert-button;
}

.p-history__alert-button:hover {
  @extend %p-history__alert-button;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-history {
    margin: 24px 0;
  }
}
