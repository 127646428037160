@charset "utf-8";

.p-offer-column {
  padding-bottom: 0;
}

.p-offer-column__inner {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 35px);
  background-color: $honne-white;
}

.p-offer-column__msg {
  flex: 1;
  padding: 24px 16px 0;
  overflow-y: scroll;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-offer-column__inner {
    height: calc(100vh - 135px);
    padding-bottom: 16px;
    background-color: initial;
  }

  .p-offer-column__msg {
    padding: 24px 24px initial;
    background-color: $honne-white;
  }
}
