@charset "utf-8";

.p-jg-side-history .c-jigyosho-card__desc {
  background-color: $honne-yellow-white;
}

.p-jg-side-history__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: -8px;
}

.p-jg-side-history__title {
  font-weight: bold;
}
