@charset "utf-8";

.p-jigyosho-qa__title {
  padding: 0 24px;
  margin-bottom: 16px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-px4;
  color: $honne-gray-700;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-qa__title {
    padding: 0;
  }
}
