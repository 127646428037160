@charset "utf-8";

.c-guide-heading__h2 {
  margin-bottom: 16px;
  font-size: $fs-2xl;
  text-align: center;
  letter-spacing: $letter-spacing-px5;
}

.c-guide-heading__h3 {
  margin-bottom: 16px;
  font-family: $font-family-700bold;
  font-size: $fs-2xl;
  font-weight: $font-weight-700bold;
  color: $honne-blue;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-guide-heading__h2 {
    margin-bottom: 24px;
    font-size: $fs-4xl;
  }
}
