@charset "utf-8";

.p-error__image {
  display: block;
  width: 90px;
  margin: 0 auto 24px;
}

.p-error__title {
  margin-bottom: 8px;
  text-align: center;
}

.p-error__text {
  @include typo-md-xl-md;
  margin-bottom: 16px;
}

.p-error__button {
  width: 100%;
  margin: 0 auto;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-error {
    padding: 40px 0;
  }

  .p-error__image {
    width: auto;
  }

  .p-error__text {
    text-align: center;
  }

  .p-error__button {
    max-width: 200px;
  }
}
