@charset "utf-8";

.c-nav-bar {
  display: none;
}

@include min-screen($breakpoint-pc-max) {
  .c-nav-bar {
    display: flex;
    align-items: center;
    background-color: $honne-white;
  }

  .c-nav-bar__inner {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    max-width: $breakpoint-pc-max;
    margin: 0 auto;
  }

  .c-nav-bar__item {
    margin-right: 16px;
    cursor: pointer;

    &:last-child {
      margin-right: 4px;
    }
  }

  .c-nav-bar__item--hover {
    margin-top: -24px;
    margin-bottom: -24px;
  }

  .c-nav-bar__item__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .c-nav-bar__item__inner--hover {
    margin-top: 24px;
  }

  .c-nav-bar__item__text {
    font-size: $fs-md;
    font-weight: bold;
    color: $honne-gray-700;
    letter-spacing: $letter-spacing-px3;

    &:visited {
      color: $honne-gray-700;
    }
  }

  .c-nav-bar__item__icon {
    margin-left: 4px;
  }
}
