@charset "utf-8";

.p-faq-contact {
  margin-top: 16px;
  line-height: $lh-small;
}

.p-faq-contact__submit {
  width: 190px;
  padding: 16px;
  margin: 24px auto;
  font-size: $fs-lg;
  color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-faq-contact__submit {
    font-size: $fs-xl;
  }
}
