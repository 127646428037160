@charset "utf-8";

.p-brand-detail {
  border-radius: 0;
  box-shadow: none;
}

.p-brand-detail__inner {
  max-width: 1124px;
  padding: 24px;
  margin: 0 auto;
}

.p-brand-detail__title {
  margin-bottom: 16px;
}

.p-brand-detail__desc {
  margin-bottom: 16px;
}

.p-brand-detail__photos {
  position: relative;
}

.p-brand-detail__photos__prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-1;
  display: none;
  width: 20%;
}

.p-brand-detail__photos__prev__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("~images/icon/arrow-left-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.p-brand-detail__photos__next {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-1;
  display: none;
  width: 20%;
}

.p-brand-detail__photos__next__icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("~images/icon/arrow-right-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.p-brand-detail__photos__items {
  position: relative;
  display: flex;
  flex-flow: nowrap;
  margin-bottom: 16px;
  overflow: scroll;
  @include scrollbar-none;
}

.p-brand-detail__photos__item {
  margin: 0 8px;
}

.p-brand-detail__photos__item:first-child {
  margin-left: 0;
}

.p-brand-detail__table {
  width: 100%;
  text-align: center;
}

.p-brand-detail__table__title {
  padding: 12px 8px;
  background-color: $honne-yellow-white;
  border: 1px solid $honne-gray-300;
}

.p-brand-detail__table__text {
  padding: 12px 16px;
  border: 1px solid $honne-gray-300;
}

.p-brand-detail__table__link {
  margin-right: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brand-detail__inner {
    padding: 24px;
  }

  .p-brand-detail__table__title {
    width: 240px;
  }

  .p-brand-detail__table__text {
    padding-left: 32px;
  }
}
