@charset "utf-8";

.p-jigyosho-automated-appeal-message__text {
  font-size: $fs-2md;
  line-height: $lh-x-large;
}

.p-jigyosho-automated-appeal-message__text--small {
  margin-top: 12px;
  font-size: $fs-sm;
  line-height: $lh-medium;
}

.p-jigyosho-automated-appeal-message__subtitle {
  display: inline-block;
  margin-bottom: 4px;
  font-size: $fs-2md;
  font-weight: bold;
}

.p-jigyosho-automated-appeal-message__note {
  position: absolute;
  top: 50%;
  left: 15%;
  font-size: $fs-2md;
  font-weight: bold;
  transform: translateY(-50%) translateX(-10%);
}

.p-jigyosho-automated-appeal-message-preview {
  position: relative;
}

.p-jigyosho-automated-appeal-message-preview__inner {
  filter: blur(2px);
  opacity: 0.3;
}
