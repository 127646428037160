@charset "utf-8";

.c-footer-bottom {
  padding: 0 24px;
}

.c-footer-bottom__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -8px;
}

.c-footer-bottom__item {
  margin-right: 16px;
  margin-bottom: 8px;
  font-size: $fs-2xs;
  font-weight: bold;

  & a,
  a:visited {
    color: $honne-gray-700;
  }

  &:last-child {
    margin-right: 0;
  }
}

.c-footer-bottom__copyright {
  padding: 24px 0;
  color: $honne-gray-700;
  text-align: center;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-footer-bottom {
    padding: 0;
  }

  .c-footer-bottom__inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 1080px;
    padding: 32px 0;
    margin: 0 auto;
    border-top: 1px solid $honne-gray-300;
  }

  .c-footer-bottom__list {
    margin-bottom: 0;
  }

  .c-footer-bottom__copyright {
    padding: 0;
  }
}
