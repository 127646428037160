@charset "utf-8";

.p-events__inner {
  padding: 24px;
}

.p-events__head {
  padding: 40px 0;
  background: url("~images/events/bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
}

.p-events__title {
  width: calc(100vw - 48px);
  max-width: 1080px;
  margin: 0 auto;
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: $line-height10;
  color: $honne-white;
  letter-spacing: $letter-spacing-px2;
}

.p-events__note {
  padding: 12px 16px;
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-blue;
  letter-spacing: $letter-spacing-px2;
  background-color: rgba($honne-blue, 0.1);
  border-radius: 8px;
}

#h2-1 {
  margin-top: 16px;
}

.p-events-content h2 {
  margin-top: 48px;
}

.p-events-content h3 {
  margin-top: 24px;
}

.p-events-action--sp {
  position: fixed;
  bottom: 0;
  z-index: $z-index-2;
  width: 100%;
  background-color: rgba($honne-white, 0.8);
}

.p-events-action__inner--sp {
  width: 100%;
  max-width: 1080px;
  padding: 12px 24px;
  margin: 0 auto;
  text-align: center;
}

.p-events-action__link {
  display: inline-block;
  width: 100%;
  max-width: 500px;
  height: 48px;
  margin: 0 auto;
  font-size: $fs-2md;
  font-weight: bold;
  line-height: $line-height-px5;
  color: $honne-white;
  text-align: center;
  text-decoration: none !important;
  letter-spacing: $letter-spacing-px2;
  background-color: $honne-blue-lighten;
  border-radius: 8px;

  &:visited {
    color: $honne-white;
  }
}

.p-events-action--pc {
  display: none;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-events__note {
    padding: 16px;
    font-size: $fs-xl;
  }

  #h2-1 {
    margin-top: 32px;
  }

  .p-events-content h3 {
    margin-top: 40px;
  }

  .p-events-content h2 {
    margin-top: 48px;
  }

  .p-events-action h2 {
    margin-top: 32px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-events__head {
    padding: 48px 0;
  }

  .p-events__title {
    font-size: $font-size-big4;
  }

  .p-events__inner {
    flex-direction: row;
    max-width: 1144px;
    padding: 32px 32px 0;
    margin: 0 auto;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-events-flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
  }

  .p-events-flex__inner {
    width: calc(100vw - 404px);
    max-width: 748px;
  }

  .p-events__head {
    padding: 80px 0;
  }

  .p-events-action--sp {
    display: none;
  }

  .p-events-action--pc {
    position: sticky;
    top: 0;
    display: block;
    width: 300px;
  }
}
