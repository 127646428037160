@charset "utf-8";

.p-regist-caremanager-mainvisual {
  min-width: $breakpoint-sp-min;
  padding: 40px 24px;
  background: url("~images/caremanager/back.png");
  background-size: cover;
}

.p-regist-caremanager-mainvisual__inner {
  max-width: 343px;
  margin: 0 auto;
}

.p-regist-caremanager-mainvisual__title {
  margin-bottom: 24px;
  font-size: $font-size-big1;
  color: $honne-blue;
  text-align: center;
  letter-spacing: $letter-spacing-px4;
}

.p-regist-caremanager-mainvisual__title--yellow {
  color: $honne-yellow;
}

.p-regist-caremanager-mainvisual__box {
  position: relative;
  padding: 24px;
  margin-bottom: 48px;
  text-align: center;
  background: $honne-white;
  border-radius: 8px;
}

.p-regist-caremanager-mainvisual__text {
  position: relative;
  display: table;
  font-size: $fs-xl;
  font-weight: bold;
  color: $honne-blue;
  text-align: left;
}

.p-regist-caremanager-mainvisual__text:before {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 8px;
  content: "";
  background: $honne-yellow-bright2;
}

.p-regist-caremanager-mainvisual__text
  + .p-regist-caremanager-mainvisual__text {
  margin-top: 16px;
}

.p-regist-caremanager-mainvisual__text__inner {
  position: relative;
}

.p-regist-caremanager-mainvisual__honnesan {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 54px;
  transform: rotate(10deg);
}

.p-regist-caremanager-mainvisual__fukidashi {
  position: absolute;
  right: -8px;
  bottom: -25px;
  width: 90px;
  height: 90px;
  overflow: hidden;
  text-indent: -100vw;
  background: url("~images/caremanager/fukidashi.png");
  background-position: right top;
  background-size: auto 90px;
  border-radius: 50%;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-caremanager-mainvisual {
    padding: 64px 0;
  }

  .p-regist-caremanager-mainvisual__inner {
    width: 80%;
    max-width: none;
  }

  .p-regist-caremanager-mainvisual__title {
    font-size: $font-size-big4;
  }

  .p-regist-caremanager-mainvisual__box {
    display: flex;
    justify-content: center;
    padding: 24px 0;
    margin-bottom: 32px;
    margin-left: 16px;
  }

  .p-regist-caremanager-mainvisual__text {
    font-size: $fs-2xl;
    line-height: $line-height-large;
  }

  .p-regist-caremanager-mainvisual__text
    + .p-regist-caremanager-mainvisual__text {
    margin-top: 0;
    margin-left: 24px;
  }

  .p-regist-caremanager-mainvisual__honnesan {
    bottom: -22px;
    left: -100px;
    width: 150px;
    transform: rotate(0deg);
  }

  .p-regist-caremanager-mainvisual__fukidashi {
    right: -70px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-regist-caremanager-mainvisual__inner {
    width: 100%;
    max-width: 700px;
  }

  .p-regist-caremanager-mainvisual__fukidashi {
    right: -140px;
    bottom: -8px;
    width: 172px;
    height: 160px;
    background-size: auto 160px;
    border-radius: 0;
  }
}

/* ~ 320px */
@include max-screen($breakpoint-sp-min) {
  .p-regist-caremanager-mainvisual__text {
    font-size: $fs-md;
  }

  .p-regist-caremanager-mainvisual__honnesan {
    left: -16px;
  }

  .p-regist-caremanager-mainvisual__fukidashi {
    right: -16px;
    width: 70px;
    height: 70px;
    background-size: auto 70px;
  }
}
