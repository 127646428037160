@charset "utf-8";

/*doc
---
title: side-contact
name: side-contact
category: components
---
```html_example
<div class="c-side-contact" style="width: 256px;">
  <a href="#">
    <div class="c-side-contact__block">
      <p class="c-side-contact__title">
        <img src="http://placehold.it/44x52">
        介護のほんね<br>無料入居相談室
      </p>
      <p class="c-side-contact__desc">いつでもお気軽にご相談ください！</p>
      <div class="c-inquiry-tel c-inquiry-tel--white c-side-contact__tel">
        <div class="c-inquiry-tel__top">
          <i class="c-icon c-icon-tel-freedial c-inquiry-tel__icon"></i>
          <span>0120-002718</span>
        </div>
        <p class="c-inquiry-tel__desc">営業時間：<span class="c-inquiry-tel__desc--bold">9:00〜18:00</span></p>
      </div>
      <div class="c-link c-side-contact__link">
        <span class="c-link__text">入居相談室とは</span>
        <i class="c-link__icon c-icon-arrow-right-blue c-icon--small"></i>
      </div>
    </div>
    <img src="http://placehold.it/254x128" class="c-side-contact__image">
  </a>
  <div class="c-side-contact__bottom">
    <a class="c-button c-button--large c-button--secondary" href="#">WEBでお問い合わせ</a>
  </div>
</div>
```
*/

.c-side-contact {
  overflow: hidden;
  background-color: $honne-white;
  border: 1px solid $honne-blue;
  border-radius: 8px;
}

.c-side-contact__block {
  padding: 16px 12px 0;
}

.c-side-contact__image {
  width: 100%;
}

.c-side-contact__title {
  display: flex;
  margin-bottom: 8px;
  font-size: $fs-3xl;
  font-weight: bold;
  text-align: left;

  & img {
    display: block;
    margin-right: 8px;
  }
}

.c-side-contact__desc {
  font-size: $fs-sm;
  color: $honne-gray-600;
  text-align: center;
}

.c-side-contact__link {
  justify-content: center;
  margin-bottom: 12px;
}

.c-side-contact__bottom {
  padding: 12px 16px 16px;
}

.c-side-contact__tel {
  margin-bottom: 8px;
}
