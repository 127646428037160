@charset "utf-8";

.p-my-box {
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  background-color: $honne-white;

  &.sp-full {
    padding: 0;
  }
}

.p-my-box--bg {
  width: calc(100% - 32px);
  margin: 16px 16px 0;
  background-color: $honne-yellow-white;
}

.p-my-box__head {
  font-size: $fs-2xl;
  font-weight: bold;
}

.p-my-box__title {
  padding-top: 16px;
  padding-left: 24px;
  margin-bottom: 16px;
  font-size: $fs-xl;
  line-height: $line-height-large;
  text-align: left;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-my-box--bg {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-my-box {
    border-radius: 8px;

    &.sp-full {
      padding: 24px;
    }
  }

  .p-my-box__title {
    padding: 0;
  }
}
