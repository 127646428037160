@charset "utf-8";

.c-jg-news__card {
  width: 280px;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .c-jg-news__card {
    width: 317px;
  }
}
