/*doc
---
title: line-button
name: line-button
category: components
---
```html_example
<a class="c-line-button c-line-button--full">
  <span class="c-line-button__icon"></span>
  <span class="c-line-button__text">LINEで相談する</span>
</a>
```
*/

$line_green: #00c300;
$line_mouse_over: #00e000;
$line_click: #00b300;
$line_disable: #c6c6c6;
$line_text_color: #ffffff;

.c-line-button {
  display: flex;
  align-items: center;
  width: auto;
  height: 48px;
  font-size: $fs-lg;
  font-weight: 600;
  color: $line_text_color;
  text-align: center;
  cursor: pointer;
  background-color: $line-brand-color;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.c-line-button--full {
  width: 100%;
}

.c-line-button:visited {
  color: $line_text_color;
}

.c-line-button:hover {
  background: $line_mouse_over;
  opacity: initial;
}

.c-line-button:active {
  color: $line_text_color;
  background: $line_click;
}

.c-line-button__icon {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 0 0 8px;
}

.c-line-button__icon:after {
  width: 32px;
  height: 32px;
  content: url("~images/user_inline/icon/line.svg");
}

.c-line-button__text {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding: 8px;
}
