@charset "utf-8";

$wide-img-height: 268px;
$wide-img-pc-height: 493px;

.p-jigyosho-wide-img {
  position: relative;
  height: $wide-img-height;
}

.p-jigyosho-wide-img__photos {
  display: flex;
  height: 100%;
  @include scrollbar-none;
}

.p-jigyosho-wide-img__photo {
  position: relative;
  height: $wide-img-height;

  & img {
    width: 100vw;
    height: 100%;
    object-fit: cover;
  }
}

.p-jigyosho-wide-img__photo__desc {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 6px 12px;
  font-size: $fs-3xs;
  color: $honne-white;
  background-color: $honne-black;
  opacity: 0.8;
}

.p-jigyosho-wide-img__photo__title {
  font-weight: bold;
}

.p-jigyosho-wide-img__prev {
  position: absolute;
  top: 50%;
  left: 0;
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-color: rgba($honne-gray-700, 0.6);
  border-radius: 4px;
  transform: translateY(-50%);
}

.p-jigyosho-wide-img__prev__icon {
  position: absolute;
  top: 50%;
  left: 6px;
  width: 14px;
  height: 10px;
  background: url("~images/icon/arrow-left-white.svg") no-repeat center;
  transform: translateY(-50%);
}

.p-jigyosho-wide-img__next {
  position: absolute;
  top: 50%;
  right: 0;
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-color: rgba($honne-gray-700, 0.6);
  border-radius: 4px;
  transform: translateY(-50%);
}

.p-jigyosho-wide-img__next__icon {
  position: absolute;
  top: 50%;
  right: 6px;
  width: 14px;
  height: 10px;
  background: url("~images/icon/arrow-right-white.svg") no-repeat center;
  transform: translateY(-50%);
}

.p-jigyosho-wide-img__count {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px 6px;
  font-size: $fs-xs;
  color: $honne-white;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 33px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-wide-img {
    margin-left: -32px;
  }

  .p-jigyosho-wide-img__photo__desc {
    font-size: $fs-sm;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-wide-img {
    width: calc(100% + 64px);
    margin-top: -24px;
    margin-bottom: 16px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-wide-img {
    width: calc(100vw - var(--scrollbar-width));
    max-width: calc(100vw - var(--scrollbar-width));
    height: $wide-img-pc-height;
    margin-top: 0;
  }

  .p-jigyosho-wide-img__photo {
    width: 1080px;
    height: $wide-img-pc-height;

    & img {
      width: 1080px;
    }
  }

  $wide-img-pc-action-size: calc((100vw - 1080px) / 2);

  .p-jigyosho-wide-img__prev {
    width: $wide-img-pc-action-size;
    height: $wide-img-pc-action-size;
    background-color: rgba(0, 0, 0, 0);
  }

  .p-jigyosho-wide-img__prev__icon {
    width: 32px;
    height: 32px;
    background-color: rgba(51, 51, 51, 0.6);
    border-radius: 4px;
  }

  .p-jigyosho-wide-img__next {
    width: $wide-img-pc-action-size;
    height: $wide-img-pc-action-size;
    background-color: rgba(0, 0, 0, 0);
  }

  .p-jigyosho-wide-img__next__icon {
    width: 32px;
    height: 32px;
    background-color: rgba(51, 51, 51, 0.6);
    border-radius: 4px;
  }

  .p-jigyosho-wide-img__count {
    right: calc((100vw - 1080px) / 2 + 12px);
  }
}

/* 1144px ~ */
@include min-screen($breakpoint-pc-max + 64px) {
  .p-jigyosho-wide-img {
    margin-left: calc((100vw - 1080px) / 2 * -1);
  }
}
