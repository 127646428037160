@charset "utf-8";

.c-grid {
  border: solid 1px $honne-gray-300;
}

.c-grid__row {
  display: table;
  width: 100%;
  text-align: center;
}

.c-grid__row + .c-grid__row {
  border-top: solid 1px $honne-gray-300;
}

.c-grid__cell {
  display: table-cell;
  width: 25%;
  vertical-align: middle;
}

.c-grid__cell + .c-grid__cell {
  border-left: solid 1px $honne-gray-300;
}

.c-grid__cell-head {
  flex: 1;
  padding: 8px;
  font-size: $fs-xs;
  font-weight: bold;
  line-height: $line-height6;
  background-color: $honne-gray-100;
}

.c-grid__cell-content {
  flex: 1;
  padding: 8px 0;
  font-weight: bold;
  background-color: $honne-white;
}
