@charset "utf-8";

@mixin label($class_name, $background-color, $color) {
  .#{$class_name} {
    color: $color;
    background-color: $background-color;
  }

  .#{$class_name}:visited {
    color: $color;
  }

  .#{$class_name}:active {
    color: $color;
  }
}
