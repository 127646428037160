@charset "utf-8";

.p-login__links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 16px;
  text-align: center;
}

.p-login__link {
  margin-top: 8px;
}

.p-login-form__title {
  margin-top: 32px;
  margin-bottom: 24px;
  font-size: $fs-lg;
  color: $honne-black;
}

%p-login__registration__button {
  font-size: $fs-lg;
  color: $honne-red-bright;
  border: none;
}

.p-login__registration__button {
  @extend %p-login__registration__button;

  &:visited,
  &:hover {
    @extend %p-login__registration__button;
  }
}
