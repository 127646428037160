@charset "utf-8";

.p-top-links {
  display: flex;
  gap: 12px;
  max-width: 1080px;
  padding: 0 24px;
  margin: 24px auto 24px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  @include scrollbar-none;
}

.p-top-links__image {
  width: 300px;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-links {
    padding: 0;
    margin: 40px auto 24px;
    overflow-x: initial;
  }

  .p-top-links__image {
    width: 100%;
  }
}
