@charset "utf-8";

.c-markup__article {
  .c-mp-jigyoshos {
    margin: 16px 0;
    @include reset-markup();

    .c-jigyosho-card__title {
      @include typo-lg-lg-md;
      padding-left: 0;
      margin: 0 0 4px 0;
    }

    .c-jigyosho-card__title:before {
      display: none;
    }

    .c-jigyosho-card__address {
      font-size: $fs-xs;
    }
  }
}
