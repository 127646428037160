@charset "utf-8";

.p-brochures-form__inner {
  padding: 24px;
  background-color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochures-form {
    padding: 0 32px;
    margin-top: 24px;
  }

  .p-brochures-form__inner {
    padding: 32px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-brochures-form {
    padding: 0;
  }
}
