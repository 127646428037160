@charset "utf-8";

.c-jg-pickup_content__item {
  width: 100%;
  margin-bottom: 16px;
}

.c-jg-pickup_content__item:last-child {
  margin-bottom: 0;
}

.c-jg-pickup_content__img__inner {
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
}

.c-jg-pickup_content__description {
  width: 100%;
  line-height: $lh-small;
}

.c-jg-pickup_content__title {
  margin-top: 12px;
  margin-bottom: 8px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $lh-small;
}

.c-jg-pickup_content__item--noimg {
  display: block;
  padding-top: 16px;
  border-top: 1px solid $honne-gray-300;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-jg-pickup_content__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-jg-pickup_content__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-jg-pickup_content__img {
    width: calc(46% - 8px);
    margin-right: 0;
    margin-left: 0;
  }

  .c-jg-pickup_content__img__inner {
    border-radius: $radius-size-small;
  }

  .c-jg-pickup_content__description {
    width: calc(54% - 8px);
    line-height: $lh-large;
  }

  .c-jg-pickup_content__title {
    margin-top: 0;
  }

  .c-jg-pickup_content__item--noimg {
    display: block;
    width: calc(50% - 8px);
  }

  .c-jg-pickup_content__item--full {
    width: 100%;
  }
}
