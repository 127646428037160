@charset "utf-8";

.p-brand-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.p-brand-card__item {
  width: 100%;
  background-color: $honne-white;
  border-radius: 12px;
}

.p-brand-card__item + .p-brand-card__item {
  margin-top: 24px;
}

.p-brand-card__link {
  display: block;
}

.p-brand-card__link:hover {
  opacity: 0.6;
}

.p-brand-card__image {
  height: 214px;
}

.p-brand-card__image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
}

.p-brand-card__content {
  padding: 11px 16px;
}

.p-brand-card__name-wrap {
  display: flex;
  justify-content: space-between;
}

.p-brand-card__brand-name {
  margin-bottom: 6px;
  font-size: $fs-md;
  color: $honne-blue;
}

.p-brand-card__owner-name {
  font-size: $fs-xs;
  color: $honne-black;
}

.p-brand-card__logo {
  width: 116px;
  height: 40px;
}

.p-brand-card__area {
  position: relative;
  padding-left: 24px;
  margin-top: 14px;
  font-size: $fs-xs;
  color: $honne-black;
}

.p-brand-card__icon {
  position: absolute;
  left: 0;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brand-card__item {
    width: calc(50% - 12px);
    margin-bottom: 24px;
    box-shadow: $shadow-size-medium;
  }

  .p-brand-card__item + .p-brand-card__item {
    margin-top: 0;
  }

  .p-brand-card__item:nth-child(2n) {
    margin-left: 24px;
  }

  .p-brand-card__image {
    height: 227px;
  }

  .p-brand-card__content {
    padding: 21px 13px;
  }
}
