@charset "utf-8";

.p-line-qr {
  display: none;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-line-qr {
    display: flex;
    align-items: center;
    margin-bottom: 80px;
    background-color: $honne-white;
    border-radius: 8px;

    img {
      width: 120px;
      padding: 4px;
    }
  }

  .p-line-qr__text {
    margin: 16px 24px 16px 16px;
    font-size: $fs-2xl;
    font-weight: bold;
    line-height: $line-height10;
    letter-spacing: $letter-spacing-px2;
  }
}
