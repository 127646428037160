@charset "utf-8";

.p-special-problem {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 48px 0 24px;

  &::after {
    position: absolute;
    bottom: -32px;
    z-index: $z-index-2;
    display: inline-block;
    content: "";
    border-top: 32px solid $honne-white;
    border-right: 50vw solid transparent;
    border-left: 50vw solid transparent;
  }
}

.p-special-problem__wrap {
  order: 2;
}

.p-special-problem__title {
  padding: 0 24px;
  margin-bottom: 16px;
  font-size: $fs-xl;
  line-height: $line-height-base;
  text-align: center;
  letter-spacing: $letter-spacing-px4;
}

.p-special-problem__list {
  max-width: 375px;
  margin: 0 auto;
}

.p-special-problem__list__item {
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-size: $fs-2md;
  font-weight: bold;
  letter-spacing: $letter-spacing-px5;

  &::before {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    content: "";
    background-image: url("~images/lp/special/check.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.p-special-problem__image {
  order: 1;
  width: 174px;
  margin: 0 auto 16px;

  img {
    width: 174px;
    height: 100%;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special-problem {
    display: flex;
    flex-direction: initial;
    align-items: center;
    justify-content: center;
    padding: 48px 0 32px;

    &::after {
      bottom: -50px;
      border-top: 50px solid $honne-white;
      border-right: 540px solid transparent;
      border-left: 540px solid transparent;
    }
  }

  .p-special-problem__wrap {
    order: 1;
    margin-right: 56px;
  }

  .p-special-problem__title {
    padding: initial;
    margin-bottom: 24px;
    font-size: $font-size-big1;
    line-height: $line-height10;
    text-align: left;
  }

  .p-special-problem__list {
    max-width: initial;
    margin: initial;
  }

  .p-special-problem__list__item {
    padding: initial;
    font-size: $fs-2xl;

    &::before {
      margin-right: 12px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .p-special-problem__image {
    order: 2;
    width: 237px;
    margin: initial;

    img {
      width: 237px;
      height: 100%;
    }
  }
}
