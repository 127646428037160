@charset "utf-8";

.p-my-head {
  padding: 12px 24px;
  background-color: $honne-white;
  border-bottom: 1px solid $honne-gray-300;

  &.message {
    margin-bottom: 0;
  }
}

.p-my-head__left {
  display: flex;
  gap: 8px;
  align-items: center;
}

.p-my-head__title {
  font-size: $fs-xl;
  font-weight: bold;
}

.p-my-head__desc {
  margin-top: 8px;
  font-size: $fs-sm;
}

.p-my-head__alert {
  color: $honne-red-bright;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-my-head {
    padding: 24px;
    margin-bottom: 16px;
    border-radius: 8px;

    &.message {
      border-bottom: none;
      border-radius: 8px;
    }
  }

  .p-my-head__title {
    font-size: $fs-2xl;
  }

  .p-my-head__desc {
    font-size: $fs-2md;
  }
}
