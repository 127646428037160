@charset "utf-8";

.p-special-footer__line {
  padding: 32px;
  background-color: $honne-blue-bright;
}

.p-special-footer__privacy {
  padding: 32px 24px 8px;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special-footer {
    background-color: $honne-blue-bright;
  }

  .p-special-footer__line {
    width: $breakpoint-pc-max;
    padding: 0;
    margin: 0 auto 72px;
  }

  .p-special-footer__privacy {
    padding-bottom: 32px;
    background-color: $honne-white;
  }
}
