@charset "utf-8";

.p-line-guide {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
}

.p-line-guide__image {
  margin-right: 12px;
}

.p-line-guide__text {
  font-size: $fs-2xs;
  font-weight: bold;
  line-height: $line-height-x-small;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-line-guide {
    justify-content: center;
    margin-bottom: 56px;
  }

  .p-line-guide__text {
    font-size: $fs-lg;
    line-height: $line-height10;
  }

  .p-line-guide__text:before {
    margin-top: 0;
  }
}
