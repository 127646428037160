@charset "utf-8";

/*doc
---
title: article-box
name: article-box
category: components
---

```html_example
<div class="c-articles-box">
  <h2 class="p-news-ranking__title">今週のニュースランキング</h2>
  <ul class="c-articles-box__lists">
    <li class="c-articles-box__list">
      <a class="c-articles-box__thumb" href="#">
        <img alt="子、孫、ひ孫、玄孫、その次は何？" class="c-articles-box__image" loading="lazy" src="http://placehold.it/70x70">
        <span class="c-articles-box__num c-articles-box__num--1">1</span>
      </a>
      <div class="c-articles-box__text">
        <a class="c-articles-box__title" href="#">
          子、孫、ひ孫、玄孫、その次は何？
        </a></div>
    </li>
    <li class="c-articles-box__list">
      <a class="c-articles-box__thumb" href="#">
        <img alt="「認知症になりやすい人」の特徴とは？ 食生活や運動、生活習慣など" class="c-articles-box__image" loading="lazy" src="http://placehold.it/70x70">
        <span class="c-articles-box__num c-articles-box__num--1">2</span>
      </a>
      <div class="c-articles-box__text">
        <a class="c-articles-box__title" href="/news/article/1409">
          「認知症になりやすい人」の特徴とは？ 食生活や運動、生活習慣など
        </a></div>
    </li>
  </ul>
</div>
```
*/

.c-articles-box {
  border-top: solid 8px $honne-blue;
}

.c-articles-box__list {
  display: flex;
  width: 100%;
  padding: 0 16px;
  font-weight: bold;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.c-articles-box__thumb {
  position: relative;
  width: 70px;
  height: 70px;
  margin-right: 12px;
  overflow: hidden;
  border-radius: 8px;
}

.c-articles-box__image {
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
}

.c-articles-box__num {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  color: $honne-gray-700;
  text-align: center;
  background: $honne-gray-300;
}

.c-articles-box__num--1 {
  color: $honne-white;
  background: $honne-yellow;
  border-radius: 4px 0 0 0;
}

.c-articles-box__title {
  display: -webkit-box;
  overflow: hidden;
  font-size: $fs-md;
  font-weight: normal;
  color: $honne-gray-700;
  -webkit-line-clamp: 4;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.c-articles-box__title:visited {
  color: $honne-gray-700;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-articles-box {
    padding: 24px;
    border-top: none;
  }

  .c-articles-box__list {
    padding: 0;
  }

  .c-articles-box__thumb {
    margin-left: 0;
  }

  .c-articles-box__title {
    font-size: $fs-lg;
    font-weight: bold;
    line-height: $fs-4xl;
    -webkit-line-clamp: 2;
  }
}
