@charset "utf-8";

.c-guide-flow {
  padding-bottom: 20px;
}

.c-guide-flow__description {
  margin-bottom: 16px;
  font-size: $fs-md;
  line-height: $line-height-x-large;
  letter-spacing: $letter-spacing-px5;

  br {
    display: none;
  }
}

.c-guide-flow-step {
  margin-bottom: 16px;
}

.c-guide-flow-step__item {
  position: relative;
  padding: 24px;
  margin-bottom: 15px;
  background-color: $honne-white;
  border: 2px solid $honne-blue-bright;
  border-radius: 8px;
}

.c-guide-flow-step__number {
  position: absolute;
  top: -24px;
  left: 0;
  font-size: $font-size-big6;
  font-weight: bold;
  color: $honne-blue-bright;
  text-align: center;
}

.c-guide-flow-step__head-wrap {
  position: relative;
}

.c-guide-flow-step__head {
  position: relative;
  z-index: $z-index-2;
  padding-top: 8px;
  font-size: $fs-xl;
  font-weight: bold;
  text-align: center;
}

.c-guide-flow-step__description {
  line-height: $line-height-x-large;
  color: $honne-gray-600;
}

.c-guide-flow-step__icon {
  width: 90px;
  height: 90px;
  margin: 16px auto 8px;

  img {
    width: 100%;
  }
}

.c-guide-flow-step__link {
  margin-top: 8px;
}

.c-guide-flow-money {
  padding: 24px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.c-guide-flow-money__description {
  margin-top: -8px;
  font-size: $fs-2md;
  line-height: $line-height-x-large;
  letter-spacing: $letter-spacing-px5;

  br {
    display: none;
  }
}

.c-guide-flow-money__title {
  text-align: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-guide-flow {
    padding-bottom: 40px;
  }

  .c-guide-flow__description {
    font-size: $fs-2md;
    text-align: center;

    br {
      display: block;
    }
  }

  .c-guide-flow-step {
    display: block;
    margin-top: 24px;
  }

  .c-guide-flow-step__items {
    display: flex;
    justify-content: space-between;
    width: initial;
    max-width: 1080px;
    margin: 0 auto;
  }

  .c-guide-flow-step__item {
    width: calc(25vw - 28px);
    margin-bottom: initial;
  }

  .c-guide-flow-step__head-wrap {
    height: 60px;
  }

  .c-guide-flow-step__number {
    font-size: $font-size-big7;
  }

  .c-guide-flow-step__head {
    padding-top: 16px;
    font-size: $fs-xl;
  }

  .c-guide-flow-step__description {
    font-size: $fs-sm;
  }

  .c-guide-flow-step__icon {
    width: 105px;
    height: 105px;
    margin: 16px auto 8px;
  }

  .c-guide-flow-money {
    max-width: 1080px;
    padding: 32px;
    margin: 0 auto;
    text-align: center;
  }

  .c-guide-flow-money__title {
    br {
      display: none;
    }
  }

  .c-guide-flow-money__description {
    br {
      display: block;
    }
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-guide-flow-step__item {
    width: 258px;
  }
}
