@charset "utf-8";

.c-markup__article {
  .c-mp-states {
    margin: 0 -24px;
    @include reset-markup();

    .c-area__title {
      font-weight: bold;
    }

    .c-area__map {
      margin-left: -24px;
    }
  }
}
