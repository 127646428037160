@charset "utf-8";

.c-login {
  padding-top: 24px;
  margin-bottom: 48px;
}

.c-login__title {
  margin-bottom: 16px;
}

.c-login__desc {
  margin-top: 12px;
  font-size: $fs-xs;
}

.c-login__button {
  margin-bottom: 12px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-login__form {
    max-width: 720px;
  }

  .c-login__button {
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
  }
}
