@charset "utf-8";

/*doc
---
title: link
name: link
category: component-parts
---

```html_example
<a class="c-link" href="#" style="margin-bottom: 8px;">
  <span class="c-link__text">アイコン右</span>
  <i class="c-link__icon c-icon-arrow-right-blue"></i>
</a>

<a class="c-link" href="#" style="margin-bottom: 8px;">
  <i class="c-link__icon c-icon-arrow-left-blue"></i>
  <span class="c-link__text">アイコン左</span>
</a>

<a class="c-link" href="#" style="margin-bottom: 8px;">
  <span class="c-link__text c-link__text--large">大きいfontサイズ</span>
</a>

<a class="c-link c-link--button" href="#" style="margin-bottom: 8px;">
  <span class="c-link__text">ボタン</span>
  <i class="c-link__icon c-icon-arrow-right-blue"></i>
</a>

<a class="c-link c-link--blue-button" href="#" style="margin-bottom: 8px;">
  北海道・東北
</a>

<a class="c-link c-link--white-button" href="#" style="margin-bottom: 8px;">
  <span class="c-link__text">空室通知</span>
  <i class="c-link__icon c-icon-arrow-right-blue c-icon--small"></i>
</a>

<a class="c-link c-link--tag" href="#" style="margin-bottom: 8px;">
  生活保護
</a>
```
*/

.c-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-link--button {
  padding: 12px;
  font-size: $fs-lg;
  background-color: $honne-yellow-white;
  border-radius: $radius-size-medium;
}

.c-link--blue-button {
  padding: 8px;
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-blue;
  background-color: $honne-blue-bright;
  border: 1px solid color-darken($honne-blue-bright, 7%);
  border-radius: $radius-size-medium;
}

.c-link--white-button {
  padding: 4px 12px;
  background: $honne-white;
  border: 2px solid $honne-blue;
  border-radius: 8px;
}

.c-link--tag {
  font-size: $fs-xs;

  &::before {
    font-size: $fs-xs;
    content: "#";
  }
}

.c-link__text {
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-blue;
}

.c-link__text--large {
  font-size: $fs-lg;
}

.c-link__text + .c-link__icon {
  margin-left: 8px;
}

.c-link__icon + .c-link__text {
  margin-left: 8px;
}
