@charset "utf-8";

.p-offer__side {
  display: none;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-offer__side {
    display: block;
  }
}
