@charset "utf-8";

.p-offer-bro__state {
  flex: 1;
}

.p-offer-bro__form {
  & .c-form__error:empty {
    margin-top: 0;
  }
}

/* ~ 899px */
@include max-screen($breakpoint-pc-small-max) {
  .p-offer-bro {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $offer-bro-modal;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .p-offer-bro__form {
    padding: 24px;
    margin-top: 100vh;
    overflow-y: scroll;
    background-color: $honne-white;
    border-radius: 12px 12px 0 0;
  }

  .p-offer-bro__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: $fs-lg;
    font-weight: bold;
  }

  .p-offer-bro__title.required::after {
    display: inline;
    margin-left: 4px;
    color: $honne-red;
    content: "*";
  }

  .p-offer-bro__types {
    display: none;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-offer-bro {
    padding: 16px 24px;
    background-color: $honne-white;
    border-top: 1px solid $honne-gray-200;
  }

  .p-offer-bro__head {
    display: none;
  }

  .p-offer-bro__types {
    display: flex;
    margin-bottom: 16px;
  }
}
