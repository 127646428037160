@charset "utf-8";

.p-regist-enterprise-card {
  margin-top: 24px;
}

.p-regist-enterprise-card__item {
  background: $honne-gray-50;
  border-radius: 8px;
}

.p-regist-enterprise-card__item + .p-regist-enterprise-card__item {
  margin-top: 16px;
}

.p-regist-enterprise-card__title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 8px;
  margin-bottom: 0;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-medium;
  border-bottom: 1px solid $honne-gray-300;
}

.p-regist-enterprise-card__title-number {
  display: block;
  font-family: Arial, sans-serif;
  font-size: $font-size-big6;
  font-weight: bold;
  color: $honne-yellow;
}

.p-regist-enterprise-card__title-text {
  display: block;
  margin-left: 20px;
}

.p-regist-enterprise-card__title-small {
  display: block;
  font-size: $fs-md;
  font-weight: normal;
  line-height: $line-height-px2;
}

.p-regist-enterprise-card__title-large {
  display: block;
  font-size: $fs-3xl;
  line-height: $line-height-px4;
}

.p-regist-enterprise-card__text {
  padding: 16px 24px 24px;
  font-size: $fs-md;
  line-height: $line-height-px2;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-regist-enterprise-card {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .p-regist-enterprise-card__item {
    width: 344px;
    max-width: 32.3%;
  }

  .p-regist-enterprise-card__item + .p-regist-enterprise-card__item {
    margin-top: 0;
  }
}
