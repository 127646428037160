@charset "utf-8";

/*doc
---
title: side-line
name: side-line
category: components
---
```html_example
<div class="c-side-line" style="width:256px;">
  <div class="c-side-line__content">
    <h3 class="c-side-line__title">
      <img class="c-side-line__fukidashi" src="http://placehold.it/63x26">で無料入居相談
    </h3>
    <p class="c-side-line__desc">LINEで気軽に相談OK！</p>
    <a class="c-button c-side-line__button" href="#">
      <img class="c-side-line__button__icon" src="http://placehold.it/42x40">
      <span class="c-side-line__button__text">LINEで相談する</span>
    </a>
    </div>
  <div class="c-side-line__qr u-hidden-sp">
    <img class="c-side-line__qr__code" src="http://placehold.it/75x75">
    <p class="c-side-line__qr__text">QRコードを読み取ると、<span>LINEで気軽にご相談が可能</span>です！</p>
  </div>
</div>
```
*/

.c-side-line {
  padding: 16px 12px 12px;
  overflow: hidden;
  background-color: color-lighten($honne-green-dark, 50%);
  border: 1px solid $honne-green-dark;
  border-radius: 8px;
}

.c-side-line__content {
  text-align: center;
}

.c-side-line__title {
  display: inline-block;
  margin-bottom: 8px;
  font-size: $fs-3xl;
  color: $honne-green-dark;
  text-align: left;
  letter-spacing: $letter-spacing-px2;
}

.c-side-line__fukidashi {
  display: inline-block;
  width: 76px;
  height: 32px;
  margin-bottom: -6px;
}

.c-side-line__desc {
  margin-bottom: 12px;
}

.c-side-line__button {
  color: $honne-white;
  background-color: $honne-green-dark;

  &:visited {
    color: $honne-white;
  }
}

.c-side-line__button__icon {
  width: 42px;
}

.c-side-line__button__text {
  width: calc(100% - 42px);
  font-size: $fs-xl;
}

.c-side-line__qr {
  display: none;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-side-line__content {
    text-align: left;
  }

  .c-side-line__title {
    display: block;
    font-size: $fs-2xl;
    line-height: $line-height8;
    text-align: center;
  }

  .c-side-line__fukidashi {
    width: 63px;
    height: 26px;
  }

  .c-side-line__button,
  .c-side-line__desc {
    display: none;
  }

  .c-side-line__qr {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .c-side-line__qr__text {
    margin-left: 8px;
    font-size: $fs-2md;
    font-weight: bold;

    span {
      color: $honne-green-dark;
    }
  }

  .c-side-line__qr__code {
    width: 75px;
    height: 75px;
    border-radius: 8px;
  }
}
