@charset "utf-8";

/*doc
---
title: container
name: container
category: objects
---

```html_example
<div class="o-container">
  <div class="o-container__inner"></div>
</div>

<div class="o-container">
  <div class="o-container__main"></div>
</div>
```
*/

/* 1カラム用レイアウト */

.o-container {
  min-width: $breakpoint-sp-min;
  margin: 0 auto;
}

.o-container__inner {
  padding: 0 $sp-page-margin;
}

.o-container__inner--mb {
  margin-bottom: 48px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .o-container__main {
    padding: 24px 32px 0;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .o-container {
    width: calc(100vw - 64px);
    max-width: $breakpoint-pc-max;
  }

  .o-container__inner {
    padding: 0;
  }

  .o-container__main {
    padding: 24px 0 0;
  }
}
