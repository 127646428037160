@charset "utf-8";

.p-offer-form {
  padding: 16px;
  border-top: 1px solid $honne-gray-200;

  & .c-form__error:empty {
    margin-top: 0;
  }
}

.p-offer-form__field + .p-offer-form__field {
  margin-top: 8px;
}

.p-offer-form__field__head {
  margin-bottom: 8px;
  font-weight: bold;

  &.required::after {
    display: inline;
    color: $honne-red;
    content: "*";
  }
}

.p-offer-form__field__main {
  width: 100%;

  &.column {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.p-offer-form__desc {
  margin-bottom: 8px;
}

.p-offer-form__submit {
  margin-top: 16px;
}

.p-offer-form__radios {
  display: flex;
  gap: 16px;
}

.p-offer-form__radio {
  gap: 8px;
  width: auto;
}

.p-offer-form__radio__text {
  font-size: $fs-sm;
}

.p-offer-form__msg {
  position: relative;
}

.p-offer-form__input {
  width: 100%;
  height: 70px;
  padding: 12px 54px 12px 16px;
  font-size: $fs-lg;
  resize: none;
  background-color: $honne-gray-100;
  border: none;
  border-radius: 4px;
}

.p-offer-form__send {
  position: absolute;
  right: 16px;
  bottom: 12px;
  width: 30px;
  height: 30px;
  background-image: url("images/icon/send-talk.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-offer-form {
    background-color: $honne-white;
  }

  .p-offer-form__field {
    display: flex;
  }

  .p-offer-form__field + .p-offer-form__field {
    margin-top: 16px;
  }

  .p-offer-form__field__head {
    min-width: 100px;
    font-size: $fs-2md;

    &.sp-none {
      display: block;
    }
  }

  .p-offer-form__submit {
    max-width: 240px;
    margin: 16px auto 0;
  }

  .p-offer-form__input {
    height: 90px;
  }
}
