@charset "utf-8";

.p-my-notice {
  padding-bottom: 8px;
  margin-bottom: 16px;
  background-color: $honne-white;
}

.p-my-notice__title {
  padding-top: 16px;
  padding-left: 24px;
  margin-bottom: 16px;
  font-size: $fs-xl;
  line-height: $line-height-large;
  text-align: left;
}

.p-my-notice__block {
  padding: 0 24px;
  border-top: 1px solid $honne-gray-200;
}

.p-my-notice__button {
  max-width: 240px;
  padding: 16px;
  margin: 24px auto 16px;
  font-size: $fs-2md;
}

.p-my-notice__empty {
  padding: 12px 24px;
  margin-bottom: 16px;
  background-color: $honne-white;
}

.p-my-notice__empty__title {
  margin-bottom: 12px;
  font-size: $fs-xl;
  font-weight: bold;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-my-notice {
    padding: 24px;
    border-radius: 8px;
  }

  .p-my-notice__title {
    padding: 0;
  }

  .p-my-notice__empty {
    padding: 24px;
    border-radius: 8px;
  }

  .p-my-notice__empty__text {
    font-size: $fs-2md;
  }

  .p-my-notice__block {
    padding: 0;
  }
}
