@charset "utf-8";

.p-cash-form-step {
  padding: 0 16px;
  margin-bottom: 16px;
}

.p-cash-form-step__title1 {
  width: 70px;
  margin-left: -20px;
}

.p-cash-form-step__title2 {
  width: 70px;
  margin-left: -20px;
}

.p-cash-form-step__title3 {
  width: 70px;
  margin-right: -40px;
  margin-left: -30px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cash-form-step {
    max-width: 400px;
    margin: 0 auto 24px;
  }

  .p-cash-form-step__title1 {
    width: auto;
    margin-left: -150px;
  }

  .p-cash-form-step__title2 {
    width: auto;
    margin-left: -150px;
  }

  .p-cash-form-step__title3 {
    width: auto;
  }
}
