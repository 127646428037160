@charset "utf-8";

.p-my-line__button {
  max-width: 200px;
  min-height: 40px;
  margin: 16px 0;
}

.p-my-line-friend {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.p-my-line__title {
  margin: 24px 0;
  font-size: $fs-xl;
}

.p-my-line__box {
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: $shadow-size-medium;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-my-line__box {
    padding: 24px;
    margin-bottom: 32px;
  }
}
