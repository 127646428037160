@charset "utf-8";

.p-top-system__title {
  margin-bottom: 16px;
}

.p-top-system__tag {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-bottom: 32px;
}

.p-top-system__tag__item__link {
  display: inline-block;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 8px;
  font-size: $fs-md;
  font-weight: bold;
  line-height: initial;
  color: $honne-gray-700;
  border: solid 2px $honne-blue;
  border-radius: 100px;

  &:visited {
    color: $honne-gray-700;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-system__tag {
    margin-top: -12px;
    margin-bottom: 72px;
  }

  .p-top-system__tag__item__link {
    padding: 8px 16px;
    margin-top: 12px;
    margin-right: 12px;
    font-size: $fs-2md;
  }
}
