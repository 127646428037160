@charset "utf-8";

.p-cashback-title {
  font-size: $fs-xl;
  line-height: $line-height-large;
  text-align: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cashback-title {
    font-size: $fs-4xl;
  }

  .p-cashback-title__br {
    display: none;
  }
}
