@charset "utf-8";

.c-pagination {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: $honne-white;
  border-radius: $radius-size-medium;
}

.c-pagination__prev {
  width: 24px;
}

.c-pagination__prev__text {
  display: none;
}

.c-pagination__content {
  width: calc(100% - 48px);
  font-weight: bold;
  color: $honne-gray-500;
  text-align: center;
}

.c-pagination__next {
  width: 24px;
}

.c-pagination__next__text {
  display: none;
}

@include min-screen($breakpoint-pc-min) {
  .c-pagination__prev {
    display: flex;
    align-items: center;
    width: 120px;
  }

  .c-pagination__prev__text {
    display: block;
    margin-left: 8px;
    font-weight: bold;
  }

  .c-pagination__content {
    width: calc(100% - 240px);
  }

  .c-pagination__next {
    display: flex;
    align-items: center;
    width: 120px;
  }

  .c-pagination__next__text {
    display: block;
    margin-right: 8px;
    font-weight: bold;
  }
}
