@charset "utf-8";

.p-line-steps__title {
  margin-bottom: 16px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-px4;
  color: $honne-gray-700;
  text-align: center;
}

.p-line-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 0;
  margin-bottom: 24px;
  background-color: $honne-white;
  border-radius: $radius-size-medium;
  box-shadow: 0 0 0 rgba(203, 203, 203, 0.25);
}

.p-line-step__image {
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
}

.p-line-step__title {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $line-height10;
  text-align: center;
}

.p-line-step__desc {
  margin-bottom: 16px;
  font-size: $fs-sm;
  line-height: $line-height3;
  letter-spacing: $letter-spacing-px2;
}

.p-line-step__detail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;

  & img {
    height: 100%;
    object-fit: contain;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-line-steps {
    margin-bottom: 32px;
  }

  .p-line-steps__inner {
    display: flex;
  }

  .p-line-step__image {
    width: 53px;
    height: 53px;
  }

  .p-line-steps__title {
    margin-bottom: 32px;
    font-size: $font-size-big1;
    letter-spacing: $letter-spacing-px2;
  }

  .p-line-step {
    width: calc((100% - 48px) / 3);
    margin-bottom: 0;
  }

  .p-line-step + .p-line-step {
    margin-left: 24px;
  }

  .p-line-step__title {
    font-size: $fs-xl;
  }

  .p-line-step__desc {
    margin-bottom: 24px;
    font-size: $fs-md;
  }

  .p-line-step__detail {
    height: 177px;
  }
}
