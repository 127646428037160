@charset "utf-8";

.c-selectbox {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $honne-white;

  select {
    width: 100%;
    padding: 12px;
    font-size: $fs-2md;
    font-weight: bold;
    appearance: none;
    background-color: $honne-white;
    background-image: url("~images/user_inline/icon/arrow-down-blue.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) 50%;
    background-size: 10px 10px;
    border: $honne-gray-400 solid 2px;
    border-radius: $radius-size-medium;
    outline: none;
    transition: all;
  }

  select:focus {
    border: $honne-blue 2px solid !important;
  }
}

.c-selectbox.flat {
  select {
    font-size: $fs-md;
    font-weight: normal;
    background-position: calc(100% - 12px) 50%;
  }
}
