@charset "utf-8";

.p-news-tabs {
  border-bottom: 1px solid $honne-gray-300;
}

.p-news-tabs__inner {
  display: flex;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.p-news-tab {
  position: relative;
}

.p-news-tab__link {
  position: relative;
  display: block;
  height: 44px;
  padding: 12px 24px;
  font-weight: bold;
  color: $honne-gray-700;
}

.p-news-tab__link:visited {
  color: $honne-gray-700;
}

.p-news-tab__link--current:before {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 4px;
  content: "";
  background: $honne-blue;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-tabs__inner {
    max-width: 1080px;
    margin: 0 auto;
  }
}
