@charset "utf-8";

.p-jigyosho-tags__label {
  padding: 2px 6px;
}

.p-jigyosho-tags__more {
  margin-top: 6px;
  margin-left: 4px;
  font-size: $fs-sm;
  text-decoration: underline;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-tags__label {
    padding: 2px 8px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-tags__more {
    margin-top: 8px;
  }
}
