@charset "utf-8";

/*doc
---
title: article-news
name: article-news
category: components
---

```html_example
<ul class="c-article-news">
  <li class="c-article-news__item">
    <a class="c-article-news__link" href="#">
      <div class="c-article-news__image">
        <img alt="地域包括支援センターの相談事例を7種類ご紹介！ どんな悩みに対応してくれるの？" src="http://placehold.it/100x100">
      </div>
      <div class="c-article-news__main">
        <div class="c-article-news__text">地域包括支援センターの相談事例を7種類ご紹介！ どんな悩みに対応してくれるの？</div>
        <div class="c-article-news__tags">
          <span class="c-label c-label--gray-100 c-article-news__tag">二人部屋あり</span>
          <span class="c-label c-label--gray-100 c-article-news__tag">広い居室有り</span>
        </div>
        <p class="c-article-news__date">2020.12.11更新</p>
      </div>
    </a>
  </li>
  <li class="c-article-news__item">
    <a class="c-article-news__link" href="#">
      <div class="c-article-news__image">
        <img alt="【後編】“一歩先”を行くおもてなしとは トラストグレイス御影の倶楽部コンシェルジュがいる暮らし" src="http://placehold.it/100x100">
      </div>
      <div class="c-article-news__main">
        <div class="c-article-news__text">【後編】“一歩先”を行くおもてなしとは トラストグレイス御影の倶楽部コンシェルジュがいる暮らし</div>
        <div class="c-article-news__tags">
        </div>
        <p class="c-article-news__date">2020.12.09更新</p>
      </div>
    </a>
  </li>
</ul>
```
*/

$article-news-border: 1px solid $honne-gray-300;

.c-article-news__item {
  width: 100%;
  padding: 12px 24px;
  border-bottom: $article-news-border;

  &:first-child {
    border-top: $article-news-border;
  }
}

.c-article-news__link {
  display: flex;
}

.c-article-news__image {
  position: relative;
  width: 90px;
  height: 90px;
  margin-right: 12px;
  overflow: hidden;
  border-radius: 4px;

  &:before {
    position: absolute;
    top: 6px;
    left: 6px;
    display: inline-block;
    width: 22px;
    height: 22px;
    content: "";
    background: url("~images/top/new.svg") no-repeat;
    background-size: contain;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-article-news__main {
  width: calc(100vw - 150px);
}

.c-article-news__text {
  margin-bottom: 8px;
  font-size: $fs-2md;
  font-weight: bold;
  line-height: $line-height-large;
}

.c-article-news__tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.c-article-news__tag {
  margin: 0 4px 4px 0;
}

.c-article-news__date {
  font-size: $fs-xs;
  color: $honne-gray-500;
  text-align: right;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-article-news {
    display: flex;
    flex-wrap: wrap;
  }

  .c-article-news__item {
    padding: 12px 0;
  }

  .c-article-news__main {
    width: calc(100% - 116px);
  }

  .c-article-news__text {
    font-size: $fs-2md;
    line-height: $line-height-x-large;
    letter-spacing: $letter-spacing-px1;
  }

  .c-article-news__image {
    width: 100px;
    height: 100px;
    margin-right: 16px;
  }
}
