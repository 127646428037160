@charset "utf-8";

.p-market-price-content {
  padding: 15px 24px 40px 24px;
  margin-bottom: 32px;
  background-color: $honne-white;
  border-radius: 0 0 8px 8px;
}

.p-market-price-content__text {
  padding-bottom: 16px;
  color: $honne-black;
}

.p-market-price-content__hint {
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 32px;
  background: $honne-white-coral;
  border: 1px solid $honne-yellow;
  border-radius: 8px;
}

.p-market-price-content__hint__icon {
  flex: none;
  width: 18px;
  height: 18px;
  margin-right: 12px;
}

.p-market-price-content__hint__text {
  color: $honne-gray-800;
}

.p-market-price-content__subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 24px;
  background-color: $honne-blue-bright;
  border-radius: 40px;
}

.p-market-price-content__subtitle__text {
  margin-left: 8px;
  font-size: $fs-2xl;
  font-weight: $font-weight-700bold;
}

.p-market-price-content__wallet {
  @include icon(40px, 40px);
  min-width: 40px;
  background-image: url("~images/consultant/wallet.svg");
}

.p-market-price-content__crown {
  @include icon(40px, 40px);
  min-width: 40px;
  background-image: url("~images/icon/crown.svg");
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-market-price-content {
    padding: 24px;
    margin-bottom: 24px;
  }

  .p-market-price-content__subtitle__text {
    font-size: $fs-4xl;
  }
}
