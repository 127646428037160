@charset "utf-8";

.p-questions {
  padding-top: 0;
}

.p-questions__header {
  padding-bottom: 20px;
  margin-bottom: 24px;
  border-bottom: 1px solid $honne-gray-300;
}

.p-questions__title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}

.p-questions__title__q {
  padding: 6px 10px;
  margin-right: 12px;
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: initial;
  color: $honne-white;
  background-color: $honne-blue-lighten;
  border-radius: 12px 12px 0 12px;
}

.p-questions__read-text {
  margin: 8px 0 16px;
  line-height: $line-height-px2;
  text-align: justify;
}

.p-questions__date-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.p-questions__date {
  flex-shrink: 0;
  margin-right: 12px;
  font-size: $fs-sm;
  color: $honne-gray-600;
}

.p-questions__category {
  margin: 8px 0;
}

.p-questions__h2 {
  margin-bottom: 16px;
  text-align: left;
}

.p-questions__h2--area {
  text-align: center;
}

.c-box + .p-questions__box {
  margin-top: 16px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-questions__box {
    box-shadow: none;
  }

  .p-questions__title {
    align-items: center;
  }

  .p-questions__title__q {
    padding: 10px 16px;
    margin-right: 16px;
    font-size: $font-size-big8;
    border-radius: 24px 24px 0 24px;
  }

  .p-questions__category {
    width: 100%;
  }

  .p-questions__inner {
    position: relative;
    padding: 32px 48px;
  }

  .p-questions__h2--area {
    text-align: left;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-questions {
    padding-top: 24px;
  }
}
