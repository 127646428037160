@charset "utf-8";

.p-top-hero-search-box__wrap {
  width: 100%;
}

.p-top-hero-search-box {
  padding: 12px;
  background-color: rgba($honne-white, 0.5);
  border-radius: 8px;
}

.p-top-hero-search-box__input {
  height: 52px;
  padding: 0;
  margin-bottom: 12px;

  input[type="text"] {
    height: 100%;
    border: $honne-white solid 1px;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-hero-search-box__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: 0 auto 16px;
  }

  .p-top-hero-search-box {
    min-width: calc(100% - 20px);
    padding: initial;
    margin-right: 16px;
    margin-bottom: initial;
    background-color: initial;
    box-shadow: $shadow-size-small;
  }

  .p-top-hero-search-box__input {
    height: 56px;
    margin-bottom: 0;
    line-height: $line-height-px6;

    input[type="text"] {
      height: 100%;
    }
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-hero-search-box__wrap {
    width: initial;
  }

  .p-top-hero-search-box {
    width: 750px;
    min-width: initial;
  }
}
