@charset "utf-8";

.c-area-search {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  background: $honne-white;
}

.c-area-search__group {
  flex: 1;
  min-width: 100px;
  margin-bottom: 24px;
}

.c-area-search__title {
  margin-bottom: 16px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-base;
  color: $honne-gray-600;
  letter-spacing: $letter-spacing-px7;
}

.c-area-search__link {
  margin-bottom: 12px;
  font-size: $fs-2md;
  line-height: $line-height3;
}

.c-area-search__link + .c-area-search__link {
  margin-top: 4px;
}

.c-area-search__count {
  font-size: $fs-3xs;
  font-weight: bold;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-area-search {
    flex-wrap: nowrap;
  }

  .c-area-search__group {
    flex: 1;
    margin-bottom: 0;
  }

  .c-area-search__group + .c-area-search__group {
    margin-left: 32px;
  }

  .c-area-search__count {
    font-size: $fs-3xs;
    font-weight: bold;
  }

  .c-area-search__link {
    margin-bottom: 0;
  }
}
