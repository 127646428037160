@charset "utf-8";

.c-card-article {
  display: flex;
  padding: 12px;
  margin: 8px 16px 0;
  background-color: $honne-white-coral;
  border-radius: 8px;
}

.c-card-article__left {
  min-width: 38px;
  margin-right: 8px;
  font-size: $fs-2xs;
  font-weight: bold;

  & img {
    display: block;
    width: 32px;
    height: 28px;
    margin: 0 auto;
  }
}

.c-card-article__text {
  font-size: $fs-xs;
  line-height: $lh-small;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-card-article {
    padding: 12px 16px;
    margin: 12px 24px 0;
  }

  .c-card-article__left {
    min-width: 40px;
    font-size: $fs-xs;
  }

  .c-card-article__text {
    font-size: $fs-sm;
  }
}
