@charset "utf-8";

.c-msg__day + .c-msg__day {
  margin-top: 32px;
}

.c-msg__date {
  width: fit-content;
  padding: 6px 16px;
  margin: 0 auto 16px;
  background-color: $honne-gray-100;
  border-radius: 32px;
}

.c-msg__mark {
  @include icon(12px, 12px);
  min-width: 12px;
  margin-bottom: 4px;
  background-image: url("~images/icon/mark.svg");
}

.c-msg__talk {
  padding: 16px 24px;
  font-size: $fs-md;
  line-height: $lh-medium;
  word-break: break-all;
  border-radius: 12px;
}

.c-msg__time {
  font-size: $fs-xs;
}

.c-msg__block__inner {
  display: flex;
  gap: 8px;
  width: 95%;
  max-width: 600px;

  &.right {
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: auto;
  }

  &.left {
    align-items: flex-end;

    .c-msg__time {
      order: 2;
    }

    .c-msg__mark {
      order: 3;
    }

    .c-msg__talk {
      order: 1;
    }
  }
}

.c-msg__block {
  margin-bottom: 16px;

  &.left .c-msg__talk {
    color: $honne-black;
    background: $honne-blue-bright;
  }

  &.right .c-msg__talk {
    color: $honne-white;
    background: $honne-blue;
  }
}

.c-msg__auto {
  padding: 8px 16px;
  margin-bottom: 16px;
  font-weight: bold;
  text-align: center;
  background: $honne-yellow-white;
  border-radius: 8px;
}

.c-msg__auto__desc {
  margin-top: 4px;
  font-size: $fs-sm;
  font-weight: normal;
}

.c-msg__button {
  max-width: 240px;
  margin-top: 16px;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-msg__auto {
    padding: 12px 24px;
    font-size: $fs-2md;
  }
}
