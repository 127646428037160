@charset "utf-8";

.p-guideline-box + .p-guideline-box {
  margin-top: 24px;
}

.p-guideline-ng + .p-guideline-box {
  margin-top: 24px;
}

.p-guideline-box__subtitle {
  margin-bottom: 16px;
}

.p-guideline-box__number-bracket {
  margin-bottom: 12px;
  margin-left: 24px;
  line-height: $lh-small;
}
