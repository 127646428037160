@charset "utf-8";

.p-sitemap-list {
  margin-top: 16px;
}

.p-sitemap-list__title {
  margin-bottom: 8px;
  font-weight: bold;
}

.p-sitemap-list__items {
  display: flex;
  flex-wrap: wrap;
}

.p-sitemap-list__item {
  margin: 0 16px 8px 0;

  &:last-child {
    margin-right: 0;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-sitemap-list {
    display: flex;
  }

  .p-sitemap-list__title {
    min-width: 120px;
    margin-bottom: 0;
  }
}
