@charset "utf-8";

// 口コミ総合評価
.c-jg-grid {
  border: solid 1px $honne-gray-300;
}

.c-jg-grid__row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-jg-grid__row + .c-jg-grid__row {
  border-top: solid 1px $honne-gray-300;
}

.c-jg-grid__cell {
  flex: 1;
  padding: 8px;
}

.c-jg-grid__cell + .c-jg-grid__cell {
  border-left: solid 1px $honne-gray-300;
}

.c-jg-grid__text-strong {
  margin-left: 8px;
  font-weight: bold;
  color: $honne-red-bright;
}
