@charset "utf-8";

// 特徴、写真、地図などの大枠
.c-jg-detail-block {
  padding: 32px 24px;
}

.c-jg-detail-block__h2 {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-500;
}

.c-jg-detail-block__h3 {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-blue;
}

.c-jg-detail-block__h3--small {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-700;
}

.c-jg-detail-block__h4 {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
}

/* 481px ~ 768px */
@include screen($breakpoint-tablet-min, $breakpoint-tablet-max) {
  .c-jg-detail-block {
    padding: 32px;
  }

  .c-jg-detail-block__h3 {
    font-family: $font-family-700bold;
    font-size: $fs-2xl;
    font-weight: $font-weight-700bold;
    color: $honne-blue;
  }
}

/* 769px ~ 990px */
@include screen($breakpoint-pc-small-min, 990px) {
  .c-jg-detail-block {
    padding: 32px 24px;
    margin: 0 32px 32px;
    border-radius: $radius-size-medium;
    box-shadow: $shadow-size-medium;
  }

  .c-jg-detail-block__h3 {
    font-family: $font-family-700bold;
    font-size: $fs-2xl;
    font-weight: $font-weight-700bold;
    color: $honne-blue;
  }
}

/* 990px ~ */
@include min-screen(990px) {
  .c-jg-detail-block {
    max-width: 800px;
    padding: 24px;
    margin-bottom: 32px;
    border-radius: $radius-size-medium;
    box-shadow: $shadow-size-medium;
  }

  .c-jg-detail-block__h3 {
    font-family: $font-family-700bold;
    font-size: $fs-2xl;
    font-weight: $font-weight-700bold;
    color: $honne-blue;
  }

  .c-jg-detail-block__h3--small {
    margin-bottom: 0;
  }
}
