@charset "utf-8";

.p-search-filter {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  overflow-x: scroll;
  font-size: $fs-xs;
  white-space: nowrap;
  border-top: solid 1px $honne-gray-200;
  @include scrollbar-none;
}

.p-search-filter--fixed {
  position: fixed;
  top: 0;
  z-index: $jigyosho-search-filter;
  width: 100%;
  background-color: $honne-white;
  border-bottom: solid 1px $honne-gray-200;
}

.p-search-filter__button {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  font-weight: bold;
  border: solid 1px $honne-gray-400;
  border-radius: 50px;
}

.p-search-filter__icon {
  @include icon(15px, 9px);
  margin-right: 8px;
  background-image: url("~images/user_inline/icon/filter.svg");
}

.p-search-filter__label {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 8px;
  font-size: $fs-xs;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-filter {
    display: none;
  }
}
