@charset "utf-8";

.p-cash-form-bank {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 40px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.p-cash-form-bank__title {
  margin-bottom: 4px;
  font-size: $fs-2md;
  font-weight: bold;
  color: $honne-blue;
}

.p-cash-form-bank__desc {
  font-size: $fs-sm;
  line-height: $line-height-medium;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cash-form-bank {
    padding: 16px 24px;
  }

  .p-cash-form-bank__title {
    font-size: $fs-lg;
  }

  .p-cash-form-bank__desc {
    font-size: $fs-md;
  }
}
