@charset "utf-8";

.p-jigyosho-summary-wide {
  padding-bottom: 16px;
  background-color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-summary-wide {
    padding-bottom: 0;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-summary-wide {
    margin-top: -24px;
  }
}
