@charset "utf-8";

.p-special4-inquiry__box {
  padding: 24px;
  background-color: $honne-yellow-white;
  border-radius: 8px;
}

.p-special4-inquiry__no-shadow {
  background-color: $honne-yellow-white;
  box-shadow: none;
}

.p-special4-inquiry__button {
  padding: 12px 16px;
}

.p-special4-inquiry__header {
  padding: 0;
  margin-top: 0;
  margin-bottom: 24px;
  text-align: center;
}

.p-special4-inquiry__h2 {
  font-size: $fs-3xl;
}

.p-special4-inquiry__inner {
  padding: 0;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special4-inquiry__wrap {
    padding: 0 52px 0 72px;
  }

  .p-special4-inquiry__box {
    padding: 32px 72px 24px 100px;
  }

  .p-special4-inquiry__button {
    max-width: 240px;
    padding: 20px 16px;
    margin-right: auto;
    margin-left: auto;
  }

  .p-special4-inquiry__header {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .p-special4-inquiry__h2 {
    font-size: $font-size-big8;
  }

  .p-special4-inquiry__inner {
    padding: 0 40px;
  }
}
