@charset "utf-8";

.o-block__item--large + .o-block__item--large {
  margin-top: 32px;
}

.o-block__item + .o-block__item {
  margin-top: 24px;
}

.o-block__item--small + .o-block__item--small {
  margin-top: 16px;
}

.o-block__item--x-small + .o-block__item--x-small {
  margin-top: 8px;
}

.o-block__column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
