@charset "utf-8";

.c-daily-schedule-event__condensed {
  display: flex;
  align-items: flex-start;
  margin-left: 4px;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-left: solid 2px $honne-blue-lighten;
  }

  &:nth-last-of-type(2) {
    padding-bottom: 26px;
  }

  &:last-child {
    margin-top: -10px;
    margin-left: 6px;
  }
}

.c-daily-schedule__badge {
  position: relative;
  z-index: $z-index-1;
  width: 12px;
  height: 12px;
  margin-left: -7px;
  background: $honne-blue-lighten;
  border-radius: 50%;
}

.c-daily-schedule-event__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.c-daily-schedule-event__head {
  display: flex;
  align-items: center;
  margin-top: -6px;
  margin-bottom: 8px;
}

.c-daily-schedule-event__time {
  margin-left: 12px;
  font-size: $fs-lg;
  color: $honne-gray-500;
}

.c-daily-schedule-event__title {
  margin-left: 8px;
  font-size: $fs-lg;
}

.c-daily-schedule-event__description {
  margin-top: -4px;
  margin-right: 16px;
  margin-bottom: 8px;
  margin-left: 12px;
  font-size: $fs-md;
}

.c-daily-schedule-event__img {
  width: calc(100% - 12px);
  margin-left: 12px;
  overflow: hidden;
  border-radius: 4px;

  img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
    object-fit: cover;
  }
}

@include min-screen($breakpoint-pc-small-min) {
  .c-daily-schedule-event__condensed {
    &:not(:last-child) {
      padding-bottom: 40px;
    }

    &:nth-last-of-type(2) {
      padding-bottom: 50px;
    }
  }

  .c-daily-schedule-event__body {
    flex-direction: row;
  }

  .c-daily-schedule-event__head {
    width: 30%;
    margin-bottom: 0;
  }

  .c-daily-schedule-event__description {
    flex-grow: 4;
    width: 10%;
    margin-bottom: 0;
    margin-left: 0;
  }

  .c-daily-schedule-event__img {
    width: 20%;
    margin-left: 0;
  }
}
