@charset "utf-8";

.p-top-contents {
  padding: 16px 0;
  background-color: $honne-yellow-white;
}

.p-top-contents__inner {
  width: 100%;
  margin: 0 auto;
}

.p-top-contents__box,
.p-top-contents__news,
.p-top-contents__qa {
  padding: 24px 0;
  background-color: $honne-white;
}

.p-top-contents__news {
  margin-bottom: 12px;
}

.p-top-contents__column {
  margin: 16px 0;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-contents {
    display: block;
    padding: 24px 0;
  }

  .p-top-contents__inner {
    width: calc(100vw - 64px);
    max-width: 1080px;
    margin: 0 auto;
  }

  .p-top-contents__box,
  .p-top-contents__news,
  .p-top-contents__qa {
    height: 100%;
    padding: 40px;
    border-radius: 12px;
  }

  .p-top-contents__news {
    margin-bottom: 0;
  }

  .p-top-contents__news,
  .p-top-contents__qa {
    width: calc(50vw - 44px);
    max-width: 528px;
  }

  .p-top-contents__column {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
  }
}
