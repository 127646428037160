@charset "utf-8";

.p-questions-answer {
  position: relative;
  margin-bottom: 32px;
}

.p-questions-answer__box {
  padding: 16px;
  background-color: $honne-yellow-dark;
}

.p-questions-answer__title {
  padding-left: 36px;
  font-size: $fs-lg;
  line-height: $line-height-base;
}

.p-questions-answer__title__a {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 4px 8px;
  font-size: $fs-2md;
  font-weight: bold;
  line-height: initial;
  color: $honne-white;
  background-color: $honne-yellow;
  border-radius: 10px 10px 0 10px;
}

.p-questions-answer__responder {
  display: flex;
  align-items: center;
  padding-top: 12px;
  border-top: 1px dashed $honne-yellow;
}

.p-questions-answer__responder__image {
  width: 36px;
  height: 36px;
  margin-right: 8px;
  object-fit: cover;
  border-radius: 50%;
}

.p-questions-answer__responder__name {
  margin-bottom: 4px;
  font-size: $fs-md;
  font-weight: bold;
}

.p-questions-answer__responder__post {
  font-size: $fs-xs;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-questions-answer__box {
    padding: 16px 24px 12px 64px;
  }

  .p-questions-answer__title__a {
    padding: 6px 10px;
    font-size: $fs-xl;
    border-radius: 12px 12px 0 12px;
  }

  .p-questions-answer__title {
    padding-left: 0;
    font-size: $fs-2xl;
  }
}
