@charset "utf-8";

.p-cash-form-review__block {
  margin-top: 12px;
}

.p-cash-form-review__block + .p-cash-form-review__block {
  margin-top: 24px;
}

.p-cash-form-review__subtitle {
  margin-bottom: 8px;
  font-weight: bold;
}

.p-cash-form-review__desc {
  margin-bottom: 16px;
  line-height: $lh-large;
}

.p-cash-form-review__text {
  margin-top: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cash-form-review__desc {
    margin-bottom: 24px;
  }
}
