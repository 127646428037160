@charset "utf-8";

.p-consultant-feature {
  position: relative;
  margin-bottom: 48px;
  overflow: hidden;
  background: $honne-yellow-white;
}

.p-consultant-feature:before {
  position: absolute;
  top: 0;
  display: block;
  width: 0;
  content: "";
  border-top: solid 40px $honne-white;
  border-right: solid 50vw transparent;
  border-left: solid 50vw transparent;
}

.p-consultant-feature__inner {
  padding: 80px 0 40px;
}

.p-consultant-feature__lists {
  margin-top: 24px;
}

.p-consultant-feature__list {
  position: relative;
  padding-left: 40px;
  font-size: $fs-md;
  line-height: $line-height-x-large;
}

.p-consultant-feature__list + .p-consultant-feature__list {
  margin-top: 12px;
}

.p-consultant-feature__list:before {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 25px;
  height: 19px;
  content: "";
  background: url("~images/icon/check-yellow.svg");
}

.p-consultant-feature__list-blue {
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-blue;
}

.p-consultant-feature__title {
  font-size: $fs-2xl;
  text-align: center;
  letter-spacing: 1px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-consultant-feature {
    margin-bottom: 72px;
    background: $honne-yellow-white;
    background-image: url("~images/consultant/photo.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto 100%;
  }

  .p-consultant-feature:before {
    border-top: solid 80px $honne-white;
  }

  .p-consultant-feature__inner {
    width: 100%;
    min-height: 437px;
    padding: 140px 0 24px 30%;
  }

  .p-consultant-feature__title {
    font-size: $fs-5xl;
    text-align-last: left;
    letter-spacing: 2px;
  }

  .p-consultant-feature__lists {
    margin-top: 16px;
  }

  .p-consultant-feature__list {
    font-size: $fs-lg;
    line-height: $line-height-px3;
  }

  .p-consultant-feature__list-blue {
    font-size: $fs-2xl;
  }
}
