@charset "utf-8";

.p-jigyosho-tabs {
  top: 0;
  z-index: $jigyosho-nav-header;
  display: none;
  width: 100%;
  height: 42px;
  overflow: hidden;
  background-color: $honne-white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
}

.p-jigyosho-tabs.p-jigyosho-tabs--fixed {
  position: fixed;
  display: block;
}

.p-jigyosho-tabs__inner {
  display: flex;
  align-items: center;
  height: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  @include scrollbar-none;
}

.p-jigyosho-tabs__item {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 54px;
  height: 100%;
  padding: 0 4px;
  font-size: $fs-xs;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border-bottom: solid 1px transparent;
}

.p-jigyosho-tabs__item a {
  color: $honne-gray-700;
  opacity: 1;
  transition: 0s;
}

.p-jigyosho-tabs__item--current {
  box-sizing: border-box;
  border-bottom: 2px solid $honne-blue;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-tabs__inner {
    max-width: 1080px;
    margin: 0 auto;
  }

  .p-jigyosho-tabs__item {
    max-width: 100px;
    font-size: $fs-md;
  }
}
