@charset "utf-8";

.p-staff-contact {
  padding: 32px 24px 24px;
  background-color: $honne-white;
}

.p-staff-contact__title {
  margin-bottom: 8px;
  font-size: $fs-xl;
  font-weight: bold;
  text-align: center;
}

.p-staff-contact__desc {
  margin-bottom: 16px;
  line-height: $lh-x-large;

  & br {
    display: none;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-staff-contact__title {
    font-size: $fs-3xl;
  }

  .p-staff-contact__desc {
    text-align: center;

    & br {
      display: initial;
    }
  }
}
