@charset "utf-8";

.p-cash-form-content__block + .p-cash-form-content__block {
  margin-top: 16px;
}

.p-cash-form-content__title {
  margin-bottom: 8px;
  font-weight: bold;
}

.p-cash-form-content__score {
  font-weight: normal;
}
