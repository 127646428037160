/* ----------------------------------------------------------------------------
    RESET
---------------------------------------------------------------------------- */
/* HTML5 display definitions */
section,
nav,
article,
aside,
hgroup,
header,
footer,
figure,
figcaption,
details {
  display: block;
}

video,
audio,
canvas {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

/* reboot.css https://github.com/twbs/bootstrap/blob/v4-dev/scss/_reboot.scss */

// Document
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
//    we force a non-overlapping, non-auto-hiding scrollbar to counteract.
// 6. Change the default tap highlight to be completely transparent in iOS.

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html {
  line-height: $line-height1; // 3
  /* stylelint-disable plugin/no-unsupported-browser-features */
  -webkit-text-size-adjust: 100%; // 4
  -ms-text-size-adjust: 100%; // 4
  -ms-overflow-style: scrollbar; // 5
  -webkit-tap-highlight-color: rgba($honne-black, 0); // 6
}

// Body
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Set an explicit initial text-align value so that we can later use the
//    the `inherit` value on things like `<th>` elements.

body {
  margin: 0; // 1
  font-family: $font-family-base;
  font-size: $fs-md;
  font-feature-settings: "palt";
  line-height: $line-height-base;
  color: $honne-gray-700;
  text-align: left; // 3
  letter-spacing: $ls-small;
  background-color: $honne-white; // 2
  -webkit-font-smoothing: antialiased;
}

/* Sections */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: $fs-md;
  font-weight: bold;
}

/* Grouping content */
p,
blockquote,
dl,
dd,
figure {
  margin: 0;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: auto;
  color: inherit;
}

pre {
  margin: 0;
  font-family: monospace, sans-serif;
  word-wrap: break-word;
  white-space: pre-wrap;
}

ol,
ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

/* Text-level semantics */
a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

strong,
b {
  font-weight: bold;
}

small {
  font-size: $fs-xs;
}

q {
  quotes: none;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted;
}

acronym {
  cursor: help;
  /*indicating to users that more info is available */
  border-bottom: 1px dotted #000;
}

code,
samp,
kbd {
  font-family: monospace, sans-serif;
}

mark {
  color: $honne-black;
  background-color: $honne-yellow;
}

sub,
sup {
  position: relative;
  font-size: $fs-xs;
  line-height: $line-height0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

br {
  *letter-spacing: $letter-spacing0;
}

em {
  /*bringing italics back to the em element*/
  font-style: italic;
}

/* Embedded content */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data */
table {
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

caption {
  padding: 0;
  /*coordinated marking to match cell's padding*/
  margin-bottom: 0.5em;
  /*centered so it doesn't blend in to other content*/
  text-align: center;
}

th,
td {
  padding: 0;
  text-align: left;
  vertical-align: baseline;
}

th {
  /*distinguishing table headers from data cells*/
  font-weight: bold;
  text-align: center;
}

/* Forms */
form {
  margin: 0;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  *margin-left: -7px;
  border: 0;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: $fs-md;
  color: inherit;
  outline: none;
}

input,
button {
  line-height: normal;
  vertical-align: inherit;
}

input::-moz-focus-inner,
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="search"]:focus {
  outline-offset: -2px;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  padding: 0;
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

select {
  line-height: normal;
  background-color: inherit;
}

textarea {
  overflow: auto;
  vertical-align: top;
}
