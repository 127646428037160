@charset "utf-8";

.p-search-cost {
  padding: 0 24px;
}

.p-search-cost + .p-search-cost {
  margin-top: 16px;
}

.p-search-cost__title {
  margin-bottom: 4px;
  font-weight: bold;
}

.p-search-cost__main {
  display: flex;
  gap: 6px;
  align-items: center;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-search-cost {
    padding: 0;
  }
}
