@charset "utf-8";

.c-markup__article {
  .c-mp-contact {
    margin: 24px 0;
    @include reset-markup();

    .c-tel-box__tel__image {
      width: 32px;
      height: 23px;
      margin-right: 8px;
    }

    .c-search-tel__text {
      font-size: $fs-lg;
    }
  }

  /* 769px ~ */
  @include min-screen($breakpoint-pc-small-min) {
    .c-mp-contact {
      .c-search-tel__text {
        font-size: $fs-xl;
      }
    }
  }
}
