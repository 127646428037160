@charset "utf-8";

.c-recommend-block {
  overflow: hidden;
}

.c-recommend-block + .c-recommend-block {
  margin-top: 32px;
}

.c-recommend-block--visible {
  overflow: visible;
}

.c-recommend-block__inner {
  padding-bottom: 16px;
  margin: 0 24px;
}

.c-recommend-block__title {
  margin: 24px 0 16px;
  font-size: $fs-lg;
  font-weight: bold;
}

.c-recommend-block__text {
  margin: -12px 0 16px;
  font-size: $fs-md;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-recommend-block__inner {
    margin: 0 32px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-recommend-block__inner {
    margin: 0;
  }
}
