@charset "utf-8";

.p-jigyosho-surroungings__items {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 24px;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-jigyosho-surroungings__items {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-surroungings__items {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* 990px ~ */
@include min-screen(990px) {
  .p-jigyosho-surroungings__img {
    height: 150px;
  }
}
