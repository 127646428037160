@charset "utf-8";

.p-my-user {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background-color: $honne-white;
  border-bottom: 1px solid $honne-gray-300;
}

.p-my-user__icon {
  @include icon(30px, 30px);
  margin-right: 8px;
  object-fit: cover;
  border-radius: 50%;
}

.p-my-user__title {
  font-size: $fs-lg;
  font-weight: bold;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-my-user {
    padding: 16px 24px;
    border-radius: 8px 8px 0 0;
  }

  .p-my-user__icon {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }

  .p-my-user__title {
    font-size: $fs-xl;
  }
}
