@charset "utf-8";

.c-social {
  display: flex;
}

.c-social__facebook {
  margin-right: 12px;
}

.c-social__icon {
  width: 28px;
  height: 28px;
}
