@charset "utf-8";

.p-brochures-complete {
  margin: 0 auto 32px;
  text-align: center;
}

.p-brochures-complete__img {
  width: 180px;
}

.p-brochures-complete__title {
  margin-bottom: 16px;
  font-size: $fs-2xl;
  font-weight: bold;
}

.p-brochures-complete__text {
  margin-bottom: 24px;
  font-size: $fs-md;
  line-height: $lh-x-large;
  text-align: left;
}

.p-brochures-complete__note {
  display: block;
  margin-top: 12px;
  font-size: $fs-xs;
  line-height: $lh-medium;
  text-align: left;
}

.p-brochures-complete__note__title {
  font-weight: bold;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochures-complete {
    max-width: 960px;
    padding: 32px 40px;
    margin-top: 32px;
    border-radius: $radius-size-medium;
    box-shadow: $shadow-size-medium;
  }

  .p-brochures-complete__img {
    width: 240px;
  }

  .p-brochures-complete__title {
    font-size: $fs-4xl;
  }

  .p-brochures-complete__text {
    font-size: $fs-lg;
    text-align: center;
  }

  .p-brochures-complete__note {
    font-size: $fs-sm;
    line-height: $lh-x-large;
    text-align: center;
  }

  .p-brochures-complete__button {
    max-width: 400px;
    margin: 0 auto;
  }
}
