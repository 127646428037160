@charset "utf-8";

/*doc
---
title: star
name: star
category: components
---
```html_example
<div class="c-star">
  <div class="c-star__list">
    <i class="c-icon-star c-star__item c-icon--small"></i>
    <i class="c-icon-star c-star__item c-icon--small"></i>
    <i class="c-icon-star c-star__item c-icon--small"></i>
    <i class="c-icon-star-dark c-star__item c-icon--small"></i>
    <i class="c-icon-star-dark c-star__item c-icon--small"></i>
  </div>
  <span class="c-star__score">3.0</span>
</div>
```
*/

.c-star {
  display: flex;
  align-items: center;
}

.c-star__list {
  display: flex;
}

.c-star__item + .c-star__item {
  margin-left: 5px;
}

.c-star__score {
  margin-left: 4px;
  font-size: $fs-md;
  font-weight: bold;
  color: $honne-red-bright;
}
