@charset "utf-8";

.c-jigyosho-tel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  border: 2px solid $honne-blue;
  border-radius: $radius-size-medium;
}

@include link-color("c-jigyosho-tel", $honne-gray-700);

.c-jigyosho-tel__title {
  font-size: $fs-2md;
  font-weight: bold;
}

.c-jigyosho-tel__icon {
  margin-right: 8px;
}

.c-jigyosho-tel__tel {
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: initial;
}

.c-jigyosho-tel__for {
  font-size: $fs-5xl;
}

.c-jigyosho-tel__text {
  font-size: $fs-2md;
}

.c-jigyosho-tel__desc {
  padding-right: 8px;
  padding-left: 8px;
  font-size: $fs-3xs;
  text-align: center;
}

.c-jigyosho-tel__subtext {
  margin-left: 4px;
  font-size: $fs-2xs;
  font-weight: normal;
}

.c-jigyosho-tel__number {
  display: none;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-jigyosho-tel__number {
    display: block;
    font-size: $fs-3xl;
    font-weight: bold;
    line-height: initial;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .c-jigyosho-tel {
    background-color: $honne-yellow-white;
    border: 0;
  }
}
