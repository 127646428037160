@charset "utf-8";

$breakpoint-pc-max: 1080px;
$breakpoint-pc-min: 900px;

$breakpoint-pc-small-max: 899px;
$breakpoint-pc-small-min: 769px;

$breakpoint-tablet-max: 768px;
$breakpoint-tablet-min: 481px;

$breakpoint-sp: 480px;
$breakpoint-sp-min: 320px;
