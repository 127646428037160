@charset "utf-8";

.p-staff-kv {
  margin-bottom: 16px;
  background-color: $honne-white;
}

.p-staff-kv__inner {
  padding: 24px;
}

.p-staff-kv__title {
  margin-bottom: 4px;
  font-size: $fs-2xl;
  font-weight: bold;
}

.p-staff-kv__img-sp {
  width: 100%;
  object-fit: cover;
}

.p-staff-kv__img-pc {
  display: none;
}

.p-staff-kv__desc {
  font-size: $fs-sm;
  line-height: $lh-x-large;
  color: $honne-gray-700;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-staff-kv {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 24px;
    border-radius: 12px;
  }

  .p-staff-kv__inner {
    padding: 48px 24px 48px 48px;
  }

  .p-staff-kv__img-sp {
    display: none;
  }

  .p-staff-kv__img-pc {
    display: block;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  .p-staff-kv__title {
    font-size: $fs-5xl;
  }

  .p-staff-kv__desc {
    font-size: $fs-2md;
  }
}
