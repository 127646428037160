@charset "utf-8";

.p-top-honne-news__title {
  margin-bottom: 16px;
  text-align: center;

  img {
    width: 230px;
    height: 23px;
  }
}

.p-guide-related.p-top-related {
  margin: 0;
}

.p-top-news__desc {
  padding: 0 24px;
  margin-bottom: 16px;
}

/* 481px ~ 768px */
@include screen($breakpoint-tablet-min, $breakpoint-tablet-max) {
  .p-top-news__desc {
    text-align: center;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-honne-news__title {
    img {
      width: 280px;
      height: 28px;
    }
  }

  .p-top-news__desc {
    padding: 0;
  }
}
