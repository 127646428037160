@charset "utf-8";

.c-tag-table {
  width: 100%;
  margin: 24px 0;
  overflow: auto;

  &__inner {
    width: auto;
    min-width: 100%;
    white-space: nowrap;
    table-layout: fixed;
  }

  & th {
    padding: 16px 0;
    font-size: $fs-md;
    font-weight: bold;
    color: $honne-gray-700;
    text-align: center;
    background-color: $honne-gray-100;
    border: solid 1px $honne-gray-300;
  }

  & td {
    padding: 16px;
    font-size: $fs-md;
    color: $honne-gray-700;
    background-color: $honne-white;
    border: solid 1px $honne-gray-300;
  }
}
