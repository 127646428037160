@charset "utf-8";

.p-jigyosho-box__inner {
  padding-top: 16px;
  padding-bottom: 16px;
}

.p-jigyosho-box__wrap {
  max-width: 1080px;
  margin: 0 auto;
}

.p-jigyosho-box__jm {
  margin-right: 24px;
  margin-left: 24px;
}

.p-jigyosho-box__column {
  margin-top: 16px;
}

.p-jigyosho-box__cashback {
  margin: 24px 24px 0;
}

/* 769px ~ 1080px*/
@include screen($breakpoint-pc-small-min, $breakpoint-pc-max) {
  .p-jigyosho-box__column {
    margin-right: 32px;
    margin-left: 32px;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-box__column {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  .p-jigyosho-box__jm {
    margin-right: 32px;
    margin-left: 32px;
  }
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-box__jm {
    display: none;
  }

  .p-jigyosho-box__cashback {
    display: none;
  }

  .p-jigyosho-box__wrap {
    margin-top: 24px;
  }
}
