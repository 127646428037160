.c-inquiry-tel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  color: $honne-gray-700;
  border: 2px solid $honne-blue;
  border-radius: $radius-size-medium;
}

.c-inquiry-tel:visited {
  color: $honne-gray-700;
}

.c-inquiry-tel--white {
  padding: 0;
  border: none;
}

.c-inquiry-tel__top {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-4xl;
  font-weight: bold;
}

.c-inquiry-tel__icon {
  margin-right: 8px;
}

.c-inquiry-tel__desc {
  font-size: $fs-2xs;
  text-align: center;
}

.c-inquiry-tel__desc--bold {
  font-weight: bold;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-inquiry-tel__top {
    margin-bottom: 0;
    font-size: $fs-5xl;
  }

  .c-inquiry-tel__icon {
    width: 24px;
    height: 24px;
  }
}
