@charset "utf-8";

.c-event + .c-event {
  margin-top: 24px;
}

.c-event__head {
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
}

.c-event__head__icon {
  min-width: 26px;
  height: 26px;
}

.c-event__head__title {
  font-size: $fs-xl;
  font-weight: bold;
}

.c-event__head__time {
  font-size: $fs-xs;
  font-weight: normal;
  color: $honne-gray-600;
}

.c-event__row {
  display: flex;
  line-height: $lh-large;
}

.c-event__row + .c-event__row {
  margin-top: 4px;
}

.c-event__row__title {
  min-width: 48px;
  font-weight: bold;
}

.c-event__link {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 4px;
  font-size: $fs-sm;
  color: $honne-blue-lighten;
}

.c-event__plan {
  display: flex;
  gap: 4px;
  margin-top: 16px;
}

.c-event__plan__title {
  font-size: $fs-xl;
  font-weight: bold;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-event__head__title {
    font-size: $fs-2xl;
  }
}
