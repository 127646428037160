@charset "utf-8";

.p-favorite-modal {
  z-index: $favorite-modal-frame;
  padding-bottom: 32px;
}

.p-favorite-modal__modal {
  z-index: $favorite-modal;
}

.p-favorite-modal__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.p-favorite-modal__title {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  color: $honne-gray-700;
}

.p-favorite-modal__desc {
  padding: 0 24px;
  font-size: $fs-sm;
  line-height: $line-height9;
  color: $honne-black;
}

.p-favorite-modal__signup__button {
  width: calc(100% - 48px);
  max-width: 270px;
  padding: 16px 40px;
  margin: 16px auto;
  border-color: $honne-red-bright;
}

.p-favorite-modal__signin__link {
  margin-bottom: 12px;
}

.p-favorite-modal__footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-favorite-modal__title {
    font-size: $fs-xl;
  }

  .p-favorite-modal__desc {
    font-size: $fs-md;
  }
}
