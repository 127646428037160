@charset "utf-8";

.p-top-hero {
  height: 600px;
}

.p-top-hero__inner {
  padding: 0 24px;
}

.p-top-hero__desc {
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $line-height9;
  color: $honne-white;
  text-align: center;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  letter-spacing: $letter-spacing-px9;
}

.p-top-hero__title {
  margin-bottom: 16px;
  font-size: $font-size-big2;
  font-weight: bold;
  color: $honne-white;
  text-align: center;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  letter-spacing: $letter-spacing-px10;
}

/* 481px ~ 768px */
@include screen($breakpoint-tablet-min, $breakpoint-tablet-max) {
  .p-top-hero__inner {
    padding-top: 32px;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-hero {
    height: 600px;
  }

  .p-top-hero__inner {
    padding: 80px 32px 0;
  }

  .p-top-hero__desc {
    margin-bottom: 12px;
    font-size: $fs-2xl;
    line-height: initial;
    letter-spacing: $letter-spacing-px11;
  }

  .p-top-hero__title {
    margin-bottom: 24px;
    font-size: $font-size-big5;
    line-height: initial;
    letter-spacing: $letter-spacing-px13;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-hero {
    height: 660px;
  }

  .p-top-hero__inner {
    padding: 80px 0 0;
  }

  .p-top-hero__br {
    display: none;
  }

  .p-top-hero__desc {
    font-size: $fs-4xl;
  }

  .p-top-hero__title {
    font-size: $font-size-big6;
  }
}
