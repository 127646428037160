@charset "utf-8";

.p-brochure {
  margin-bottom: 32px;
}

.p-brochure__sub-desc {
  margin-top: 12px;
  font-size: $fs-xs;
  color: $honne-gray-500;
}

.p-brochure__h1 {
  font-family: $font-family-700bold;
  font-size: $fs-2xl;
  font-weight: $font-weight-700bold;
  text-align: center;
}

.p-brochure__jigyosho {
  padding: 24px 16px;
  background-color: $honne-yellow-bright;
  border-radius: 8px;
}

.p-brochure__jigyosho__title {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: bold;
}

.p-brochure__links {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.p-brochure__link {
  margin-left: 16px;
}

.p-brochure__modal {
  padding: 0;
  line-height: $line-height3;
}

.p-brochure-form__field {
  overflow-y: scroll;
  @include scrollbar-none;
}

.p-brochure-inner__header {
  max-width: 960px;
  padding: 24px 0 8px;
}

.p-brochure-inner__header__text {
  font-size: $fs-xl;
  color: $honne-blue;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .p-brochure__button {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brochure__h1 {
    margin-bottom: 0;
    font-size: $fs-5xl;
  }

  .p-brochure__button {
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
  }

  .p-brochure-inner__header {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 24px auto;
  }

  .p-brochure-inner__header__right {
    display: flex;
  }
}
