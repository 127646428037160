@charset "utf-8";

.c-form-time {
  display: flex;
  align-items: center;

  & .hour {
    margin-right: 4px;
  }

  & .hour,
  .minute {
    width: 100%;
    padding: 12px;
    font-size: $fs-2md;
    font-weight: bold;
    appearance: none;
    background-color: $honne-white;
    background-image: url("~images/user_inline/icon/arrow-down-blue.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) 50%;
    background-size: 10px 10px;
    border: $honne-gray-400 solid 2px;
    border-radius: $radius-size-medium;
    outline: none;
    transition: all;

    &:disabled {
      background-color: $honne-gray-400;
    }
  }

  & .hour:focus,
  .minute:focus {
    border: $honne-blue 2px solid;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-form-time {
    & .hour {
      margin-right: 12px;
    }
  }
}
