@charset "utf-8";

/*doc
---
title: news-card
name: news-card
category: components
---
```html_example
<a class="c-news-card" href="#" style="width:317px;">
  <div class="c-news-card__img-wrap">
    <img src="http://placehold.it/317x190">
  </div>
  <div class="c-news-card__main">
    <h4 class="c-news-card__title">セカンドキャリアは介護士  元ライオンズ投手の蒲谷さん</h4>
    <p class="c-news-card__desc">　１８１cmの長身に剛速球を繰り出した大きな手が野球選手だった過去を物語る。長沢にある介護付き有料老人ホーム「プラージュ・シエル湘南長沢」で介護福祉士として勤務する蒲谷和茂さん（51）は、元プロ野球選手（投手）だ。...</p>
  </div>
</a>
```
*/

.c-news-card {
  display: block;
  margin: 16px;
  color: $honne-gray-700;
  background-color: $honne-white;
  border-radius: $radius-size-medium;
  box-shadow: $shadow-size-medium;
}

.c-news-card__img {
  position: relative;
  height: 190px;
  overflow: hidden;
  text-align: center;
  background-color: $honne-gray-100;
  border-radius: 8px 8px 0 0;

  & img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin: auto;
  }
}

.c-news-card__main {
  padding: 12px 16px;
}

.c-news-card__title {
  margin-bottom: 4px;
  font-size: $fs-xl;
  font-weight: $font-family-700bold;
  color: $honne-gray-700;
}

.c-news-card__desc {
  margin-bottom: 4px;
  font-size: $fs-sm;
  font-weight: bold;
  color: $honne-gray-500;
}
