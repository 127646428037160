@charset "utf-8";

.p-jigyosho-contact__inquiry {
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 40px;
  background-color: $honne-yellow-white;
  border: 0;
  border-radius: 8px;
}

.p-jigyosho-box__wrap {
  .p-jigyosho-contact__inquiry--nursing {
    margin-top: 0;
    margin-bottom: 16px;
    background-color: $honne-white;
  }
}

/* ~ 769px */
@include max-screen($breakpoint-pc-small-min) {
  .p-jigyosho-contact__inquiry--nursing {
    margin-top: 0;
    margin-bottom: 0;
    background-color: $honne-white;
    border-radius: 0;

    .c-inquiry__top {
      margin-bottom: 12px;
    }

    .c-inquiry__title {
      font-size: $fs-2md;
    }

    .c-inquiry__desc {
      display: none;
    }

    .c-inquiry__contact__tel {
      margin-bottom: 12px;
    }

    .c-inquiry__contact__web {
      padding: 8px 0;
      font-size: $fs-md;
    }
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-contact__inquiry--nursing {
    width: inherit;
    margin: 0 32px 40px;
  }
}

/* 900px ~  */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-contact__inquiry {
    width: auto;
  }
}

/* 1080px ~  */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-contact__inquiry--nursing {
    margin: 0 0 40px;
  }
}
