@charset "utf-8";

@mixin icon($width: 16px, $height: 16px) {
  display: inline-block;
  width: $width;
  height: $height;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
