@charset "utf-8";

.p-jigyosho-recommend {
  background-color: $honne-white;

  .c-recommend-block--visible {
    padding-bottom: 24px;
    padding-left: 24px;
    overflow: hidden;
  }

  .p-jigyosho__subtitle {
    padding: 0 24px;
  }
}

/* ~ 768px */
@include max-screen($breakpoint-tablet-max) {
  .p-jigyosho-recommend {
    padding-top: 16px;
    background-color: $honne-yellow-white;

    .c-recommend-block--visible {
      padding-top: 24px;
      background-color: $honne-white;
    }
  }

  .p-jigyosho-recommend::after {
    display: block;
    width: 100%;
    height: 16px;
    content: "";
    background-color: $honne-yellow-white;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-recommend {
    margin-top: 8px;

    .c-recommend-block--visible {
      padding-bottom: 40px;
      padding-left: 32px;
    }
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-jigyosho-recommend {
    margin-top: 16px;

    .c-recommend-block--visible {
      width: 100%;
      padding-left: 0;
      overflow: hidden;
    }
  }
}
