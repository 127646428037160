.p-jigyosho-availability-request__modal-closer {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.p-jigyosho-availability-request__modal-body {
  display: flex;
  justify-content: center;
  padding: 0;
  overflow-y: scroll;
  border-bottom: none;
  @include scrollbar-none;
}

/* form */
.p-jigyosho__availability_modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 24px 24px;
}

.p-jigyosho__availability_modal-form__hero {
  width: 100px;
  margin-bottom: 16px;
}

.p-jigyosho__availability_modal-form__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-jigyosho__availability_modal-form__title {
  margin-bottom: 4px;
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: $line-height-px3;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-px1;
}

.p-jigyosho__availability_modal-form__desc {
  font-size: $fs-sm;
  line-height: $lh-medium;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-small;
}

.p-jigyosho__availability_modal-form__form {
  width: 100%;
  box-shadow: none;

  span {
    font-size: $fs-xs;
    font-weight: normal;
  }
}

.p-jigyosho__availability_modal-form__tel {
  align-self: flex-start;
  font-size: $fs-2md;
  font-weight: bold;
  color: $honne-gray-700;
}

.p-jigyosho__availability_modal-form__tel:visited {
  color: $honne-gray-700;
}

.p-jigyosho__availability_modal-form__tel__desc {
  font-size: $fs-xs;
  font-weight: normal;
}

.p-jigyosho__availability_modal-form__field__content {
  margin-top: 4px;
}

.p-jigyosho__availability_modal-form__field
  + .p-jigyosho__availability_modal-form__field {
  margin-top: 8px;
  margin-bottom: 16px;
}

.p-jigyosho__availability_modal-form__check {
  align-items: center;
  margin-bottom: 8px;
  font-size: $fs-xs;
  line-height: $line-height-x-small;
  color: $honne-gray-700;
  letter-spacing: $letter-spacing-px1;
}

.p-jigyosho__availability_modal-form__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  margin: 16px auto 0;
  font-size: $fs-2md;
  font-weight: bold;
  line-height: $line-height-medium;
  color: $honne-white;
  letter-spacing: $letter-spacing-px1;
  cursor: pointer;
  background: $honne-blue-lighten;
  border-radius: 8px;
}

.p-jigyosho__availability_modal-form__button--arrow {
  margin-left: 8px;
}

/* complete */
.p-jigyosho__availability_modal-complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 40px 24px;
}

.p-jigyosho__availability_modal-complete__hero {
  width: 160px;
  height: 160px;
}

.p-jigyosho__availability_modal-complete__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $fs-2xl;
  font-weight: bold;
  line-height: $line-height-px3;
  color: $honne-gray-700;
  text-align: center;
  letter-spacing: $letter-spacing-small;
}

.p-jigyosho__availability_modal-complete__desc {
  margin-top: 12px;
  font-size: $fs-md;
  line-height: $line-height3;
  color: $honne-gray-700;
  text-align: justify;
  letter-spacing: $letter-spacing-px2;
}

.p-jigyosho__availability_modal-complete__button {
  width: 100%;
  padding: 12px;
  margin-top: 12px;
  font-size: $fs-2md;

  font-weight: bold;
  line-height: $line-height-medium;
  color: $honne-blue;
  text-align: center;
  letter-spacing: $letter-spacing-px1;

  cursor: pointer;

  background: $honne-yellow-white;
  border-radius: 8px;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-jigyosho-availability-request__modal-closer {
    top: 24px;
    right: 24px;
  }

  .p-jigyosho-availability-request__modal {
    max-width: 880px;
  }

  .p-jigyosho__availability_modal-form {
    flex-direction: row;
    justify-content: center;
    margin: 64px 0;
  }

  .p-jigyosho__availability_modal-form__hero {
    width: 178px;
    height: 203px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .p-jigyosho__availability_modal-form__content {
    align-items: start;
    width: 480px;
    padding-bottom: 0;
  }

  .p-jigyosho__availability_modal-form__title {
    margin-top: auto;
    margin-bottom: 12px;
    font-size: $fs-4xl;
    letter-spacing: $letter-spacing-medium;
  }

  .p-jigyosho__availability_modal-form__desc {
    font-size: $fs-2md;
    letter-spacing: $letter-spacing-px2;
  }

  .p-jigyosho__availability_modal-form__tel {
    font-size: $fs-2xl;
    font-weight: bold;
    letter-spacing: $letter-spacing-px1;
  }

  .p-jigyosho__availability_modal-form__tel__desc {
    font-size: $fs-sm;
    letter-spacing: $letter-spacing-px1;
  }

  .p-jigyosho__availability_modal-form__form {
    width: 100%;
    height: 100%;
    margin: 8px 0 0 0;
  }

  .p-jigyosho__availability_modal-form__field {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .p-jigyosho__availability_modal-form__field
    + .p-jigyosho__availability_modal-form__field {
    margin-top: 12px;
  }

  .p-jigyosho__availability_modal-form__field__content {
    flex-grow: 1;
    width: auto;
    margin-top: 0;
  }

  .p-jigyosho__availability_modal-form__check {
    font-size: $fs-sm;
    line-height: $line-height-px1;
  }

  .p-jigyosho__availability_modal-form__button {
    width: auto;
    padding: 12px 90px;
    margin: 16px auto 0 0;
  }

  /* complete */
  .p-jigyosho__availability_modal-complete {
    margin: 48px 0;
  }

  .p-jigyosho__availability_modal-complete__title {
    display: inline;
    font-size: $fs-4xl;
  }

  .p-jigyosho__availability_modal-complete__desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $fs-2md;
  }

  .p-jigyosho__availability_modal-complete__button {
    width: 240px;
    margin-top: 24px;
  }
}
