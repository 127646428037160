@charset "utf-8";

$col-min-width: 120px;

.p-jigyosho-service-table__title {
  margin-bottom: 8px;
  font-size: $fs-md;
  font-weight: bold;
}

.p-jigyosho-service-table__service {
  display: block;
  width: 100%;
  margin-bottom: 16px;
  table-layout: fixed;
}

.p-jigyosho-service-table__service__head {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 8px;
  font-size: $fs-sm;
  font-weight: bold;
  line-height: $line-height6;
  background-color: $honne-gray-100;
  border: solid 1px $honne-gray-300;
}

.p-jigyosho-service-table__service__col {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: $col-min-width;
  max-width: 200px;
  padding: 8px;
  font-size: $fs-sm;
  font-weight: bold;
  line-height: $line-height6;
  text-align: center;
  background-color: $honne-white;
  border: solid 1px $honne-gray-300;
}

.p-jigyosho-service-table__service__row {
  display: inline-block;
}

.p-jigyosho-service-table__service__name {
  height: 64px;
}

.p-jigyosho-service-table__service thead {
  display: block;
  float: left;
}

.p-jigyosho-service-table__service thead tr th {
  margin-bottom: -1px;

  &:last-child {
    margin-bottom: 0;
  }
}

.p-jigyosho-service-table__service tbody {
  display: flex;
  width: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.p-jigyosho-service-table__service tbody tr {
  margin-left: -1px;

  &:first-child {
    margin-left: -1px;
  }
}

.p-jigyosho-service-table__service tbody tr td {
  margin-bottom: -1px;

  &:last-child {
    margin-bottom: 0;
  }
}

.p-jigyosho-service-table + .p-jigyosho-service-table {
  margin-top: 8px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-jigyosho-service-table__title {
    font-size: $fs-lg;
  }

  .p-jigyosho-service-table__service__head {
    width: 20%;
    padding: 8px;
  }

  .p-jigyosho-service-table__service__col {
    width: 20%;
    padding: 8px;
  }

  .p-jigyosho-service-table__service__row {
    display: flex;
  }

  .p-jigyosho-service-table__service__name {
    flex: 0 1 $col-min-width;
    height: auto;
    background-color: $honne-gray-100;
  }

  .p-jigyosho-service-table__service__plan {
    flex: 0 1 $col-min-width;
  }

  .p-jigyosho-service-table__service__price {
    flex: 0 1 $col-min-width;
  }

  .p-jigyosho-service-table__service__pricemore {
    flex: 0 1 $col-min-width;
  }

  .p-jigyosho-service-table__service__note {
    flex: 3 1 auto;
    min-width: calc(100% - 480px);
    max-width: calc(100% - 480px);
    height: auto;
    line-height: $line-height-base;
  }

  .p-jigyosho-service-table__service thead {
    float: none;
    width: 100%;
    margin-bottom: -1px;
  }

  .p-jigyosho-service-table__service thead tr th {
    margin-bottom: 0;
    margin-left: -1px;

    &:first-child {
      margin-left: 0;
    }
  }

  .p-jigyosho-service-table__service tbody {
    display: block;
    overflow-x: auto;
    white-space: normal;
  }

  .p-jigyosho-service-table__service tbody tr {
    margin-bottom: -1px;
    margin-left: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .p-jigyosho-service-table__service tbody tr td {
    margin-bottom: 0;
    margin-left: -1px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
