@charset "utf-8";

.p-news-slider {
  width: 100%;
  margin-bottom: 24px;
}

.p-news-slider__slide {
  position: relative;
}

.p-news-slider__slide:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 45.31%,
    rgba(0, 0, 0, 0.5) 68.75%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.p-news-slider__pickup {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 90px;
}

.p-news-slider__image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.p-news-slider__text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 16px 16px;
}

.p-news-slider__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 8px;
}

.p-news-slider__date {
  font-size: $fs-xs;
  color: $honne-white;
}

.p-news-slider__category {
  color: $honne-white;
  border-color: $honne-white;
}

.p-news-slider__title {
  font-size: $fs-lg;
  font-weight: bold;
  line-height: $lh-small;
  color: $honne-white;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-news-slider {
    position: relative;
    margin-bottom: 0;
  }

  .p-news-slider__slide:before {
    display: none;
  }

  .p-news-slider__image {
    height: 400px;
    border-radius: 8px;
  }

  .p-news-slider__text {
    position: static;
    padding: 0;
  }

  .p-news-slider__meta {
    margin-bottom: 12px;
  }

  .p-news-slider__date {
    font-size: $fs-sm;
    color: $honne-gray-400;
  }

  .p-news-slider__category {
    color: $honne-blue;
    border-color: $honne-blue;
  }

  .p-news-slider__title {
    font-size: $fs-4xl;
    color: $honne-gray-700;
  }
}
