@charset "utf-8";

/*doc
---
title: dropdown-menu
name: dropdown-menu
category: components
---
```html_example
<div class="js-drop-down">
  <div class="c-block-nav c-dropdown-menu js-drop-down-trigger">
    <i class="c-icon-qa c-block-nav__icon"></i>
    <span class="c-block-nav__title">介護のQ＆A</span>
    <i class="c-dropdown-menu__icon c-slide-nav__drop-down-icon js-drop-down-icon"></i>
  </div>
  <div class="js-drop-down-target">
    drop content here
  </div>
</div>
```
*/

.c-dropdown-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.c-dropdown-menu__icon {
  position: absolute;
  top: 50%;
  right: 24px;
  display: block;
  width: 11px;
  height: 11px;
  border-right: 3px solid #1298b3;
  border-bottom: 3px solid #1298b3;
  transition: all 0.5s;
  -webkit-transform: translateY(-70%) rotate(45deg);
  transform: translateY(-70%) rotate(45deg);
}

.c-dropdown-menu__icon--up {
  background-position-y: bottom;
  transform: rotate(180deg);
}
