@charset "utf-8";

.p-special4-card {
  padding-bottom: 32px;
  background-color: $honne-white;
}

.p-special4-subcard {
  padding: 24px;
  background-color: $honne-white;
  box-shadow: 0 0 20px rgba($honne-gray-600, 0.1);

  &.p-special4-subcard__full {
    padding: 24px 0;
  }

  .p-special4-action__box {
    margin-bottom: 12px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-special4-card {
    width: 1080px;
    padding-bottom: 98px;
    margin: -80px auto 0;
    border-radius: 60px;
    box-shadow: 0 0 20px rgba($honne-gray-600, 0.1);

    .p-special4-action {
      margin-bottom: 0;
    }
  }

  .p-special4-subcard {
    width: $breakpoint-pc-max;
    padding: 32px 24px;
    margin: 0 auto;
    border-radius: 60px;

    &.p-special4-subcard__full {
      padding: 32px 0;
    }

    .p-special4-action__box {
      margin-bottom: 0;
    }
  }
}
