@charset "utf-8";

.p-jys-banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-jys-banner__sp {
  width: 100%;
  margin-top: 16px;
  object-fit: cover;
}

@include min-screen($breakpoint-pc-min) {
  .p-jys-banner__sp {
    display: none;
  }
}
