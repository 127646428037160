@charset "utf-8";

.c-cashback {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: $fs-xs;
  font-weight: bold;
  color: $honne-gray-700;
  text-align: center;
  background-color: rgba(242, 184, 48, 0.2);
  border-radius: 4px;
}

.c-cashback__icon {
  width: 12px;
  height: 12px;
}

/* 480px ~ */
@include min-screen($breakpoint-sp) {
  .c-cashback br {
    display: none;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-cashback {
    font-size: $fs-md;
    border-radius: 8px;
  }

  .c-cashback__icon {
    width: 16px;
    height: 16px;
  }
}
