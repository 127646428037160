@charset "utf-8";

/*doc
---
title: list2
name: list2
category: components
---
```html_example
<div style="margin-bottom: 24px;">
  <h2>decimal list</h2>
  <ol class="d-list">
    <li class="d-list__item d-list__item--decimal">個人情報保護について</li>
    <li class="d-list__item d-list__item--decimal">情報セキュリティ対策</li>
  </ol>
</div>

<h2>dot list</h2>
<ul class="d-list d-list--nest">
  <li class="d-list__item d-list__item--dot d-list__item--margin-small">ログイン時における本人確認のため</li>
  <li class="d-list__item d-list__item--dot d-list__item--margin-small">本サービスが提供する機能のご案内や、介護サービス・介護施設資料、謝礼などの送付のため</li>
  <li class="d-list__item d-list__item--dot d-list__item--margin-small">本サービスの利用状況の調査、分析のため</li>
</ul>
```
*/

.d-list__item {
  margin-bottom: 12px;
}

.d-list--nest {
  margin-left: 1em;
}

.d-list__item--decimal {
  list-style: decimal inside;
}

.d-list__item--dot {
  list-style: disc inside;
}

.d-list__item--margin-small + .d-list__item--margin-small {
  margin-top: 0.5em;
}
