.p-lp-online {
  min-width: $breakpoint-sp-min;
}

.p-lp-online__button {
  font-size: $fs-2md;
  font-weight: bold;
  text-align: center;
  letter-spacing: $letter-spacing-px4;
  border: 0;
  border-radius: 100px;
  box-shadow: 0 0 20px rgba($honne-gray-500, 0.1);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
}

.p-lp-online__button--blue {
  background-color: color-lighten($honne-blue, 4%);

  a {
    color: $honne-white;
  }
}

.p-lp-online__button--white {
  background-color: $honne-white;

  a {
    color: color-lighten($honne-blue, 4%);
  }
}

.p-lp-online__container {
  padding-top: 24px;
  padding-bottom: 56px;
  background-color: color-lighten($honne-gray-200, 3%);
}

.p-lp-online__box {
  box-sizing: border-box;
  width: calc(100vw - 48px);
  padding: 32px 24px;
  margin: 0 auto 24px;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba($honne-gray-500, 0.1);
}

.p-lp-online__box--white {
  background-color: $honne-white;
}

.p-lp-online__box--blue {
  color: $honne-white;
  background-color: color-lighten($honne-blue, 4%);
}

.p-lp-online__inner {
  order: 3;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-lp-online__inner {
    order: initial;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-lp-online {
    letter-spacing: $letter-spacing-px4;
  }

  .p-lp-online__box {
    width: 1080px;
    padding: 56px;
    border-radius: 8px;
  }

  .p-lp-online__button {
    display: inline-block;
  }
}
