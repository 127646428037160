@charset "utf-8";

/*doc
---
title: button
name: button
category: component-parts
---
```html_example
<a class="c-button c-button--primary" href="#" style="margin-bottom:16px;">
  <i class="c-icon-apply c-icon--large c-button__icon-left"></i>
  <span>primary</span>
</a>
<a class="c-button c-button--secondary" href="#" style="margin-bottom:16px;">
  <span>secondary</span>
</a>
<a class="c-button c-button--yellow" href="#" style="margin-bottom:16px;">
  <span>黄色(LP用)</span>
</a>
<a class="c-button c-button--twitter" href="#" style="margin-bottom:16px;">
  <span>twitter</span>
</a>
<a class="c-button c-button--facebook" href="#" style="margin-bottom:16px;">
  <span>facebook</span>
</a>
<a class="c-button c-button--primary c-button--medium" href="#" style="margin-bottom:16px;">
  <span>mediumサイズ</span>
</a>
<a class="c-button c-button--primary c-button--large" href="#" style="margin-bottom:16px;">
  <span>largeサイズ</span>
</a>
<a class="c-button c-button--primary c-button--disabled" href="#" style="margin-bottom:16px;">
  <span>disabled</span>
</a>
```
*/

.c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px;
  font-size: $fs-md;
  font-weight: bold;
  line-height: $line-height2;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: 0.25s;
}

%c-button--primary {
  color: $honne-white;
  background-color: $honne-red-bright;
}

.c-button--primary {
  @extend %c-button--primary;
}

.c-button--primary:visited {
  @extend %c-button--primary;
}

.c-button--primary:hover {
  background-color: color-lighten($honne-red-bright, 7%);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

%c-button--secondary {
  color: $honne-white;
  background-color: $honne-blue-lighten;
}

.c-button--secondary {
  @extend %c-button--secondary;
}

.c-button--secondary:visited {
  @extend %c-button--secondary;
}

.c-button--secondary:hover {
  color: $honne-white;
  background-color: color-lighten($honne-blue-lighten, 7%);
}

%c-button--yellow {
  color: $honne-white;
  background-color: $honne-yellow;
}

.c-button--yellow {
  @extend %c-button--yellow;
}

.c-button--yellow:visited {
  @extend %c-button--yellow;
}

.c-button--yellow:hover {
  background-color: color-lighten($honne-yellow, 7%);
}

%c-button--twitter {
  color: $honne-white;
  background-color: $twitter-brand-color;
}

.c-button--twitter {
  @extend %c-button--twitter;
}

.c-button--twitter:visited {
  @extend %c-button--twitter;
}

.c-button--twitter:hover {
  background-color: color-lighten($twitter-brand-color, 7%);
}

%c-button--facebook {
  color: $honne-white;
  background-color: $facebook-brand-color;
}

.c-button--facebook {
  @extend %c-button--facebook;
}

.c-button--facebook:visited {
  @extend %c-button--facebook;
}

.c-button--facebook:hover {
  background-color: color-lighten($twitter-brand-color, 7%);
}

%c-button--yellow-white {
  color: $honne-blue;
  background-color: $honne-yellow-white;
}

.c-button--yellow-white {
  @extend %c-button--yellow-white;
}

.c-button--yellow-white:visited {
  @extend %c-button--yellow-white;
}

.c-button--yellow-white:hover {
  background-color: color-lighten($honne-yellow-white, 7%);
}

// whiteは消す方向
%c-button--white {
  color: $honne-gray-800;
  background-color: $honne-white;
  border: solid 1px $honne-gray-400;
}

.c-button--white {
  @extend %c-button--white;
}

.c-button--white:visited {
  @extend %c-button--white;
}

.c-button--white:hover {
  @extend %c-button--white;
}

// size
.c-button--medium {
  padding: 12px;
}

.c-button--large {
  padding: 16px;
  font-size: $fs-lg;
}

%c-button--disabled {
  color: $honne-white;
  pointer-events: none;
  background-color: $honne-gray-400;
}

.c-button--disabled {
  @extend %c-button--disabled;
}

.c-button--disabled:visited {
  @extend %c-button--disabled;
}

.c-button:disabled {
  @extend %c-button--disabled;
}

.c-button__icon-left {
  margin-right: 8px;
}
