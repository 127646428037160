@charset "utf-8";

.p-brochure-block {
  padding: 16px;
  margin-top: 16px;
  background-color: $honne-gray-100;
  border-radius: $radius-size-medium;
}

.p-brochure-block__h1 {
  font-weight: bold;
  color: $honne-blue;
}

.p-brochure-block__h2 {
  margin-top: 8px;
  font-weight: bold;
}
