@charset "utf-8";

.p-ranking-top {
  position: relative;
  height: auto;
  padding: 48px 0 8px 0;
  text-align: center;
  border-bottom: 1px solid $honne-gray-300;
}

.p-ranking-top__inner {
  font-weight: bold;
}

.p-ranking-top__title-primary {
  font-size: $fs-xl;
  color: $honne-blue;
}

.p-ranking-top__title-secondary {
  font-size: $fs-xl;
  color: $honne-blue;
}

.p-ranking-top__title-main {
  margin-bottom: 20px;
  font-size: $fs-5xl;
  color: $honne-blue;
}

.p-ranking-top__desc {
  padding: 8px 12px 8px 20px;
  margin: 0 auto 8px;
  font-size: $fs-xs;
  color: $honne-white;
  text-align: left;
  background: $honne-blue;
  border-radius: 30px;
}

.p-ranking-top__period {
  font-size: $fs-xs;
  font-weight: normal;
  color: $honne-gray-800;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-ranking-top {
    padding: 0;
  }

  .p-ranking-top__inner {
    padding: 56px 24px 12px;
  }

  .p-ranking-top__title-primary {
    font-size: $fs-4xl;
  }

  .p-ranking-top__title-secondary {
    font-size: $fs-4xl;
  }

  .p-ranking-top__title-main {
    margin: 0 0 8px 0;
    font-size: $font-size-big9;
  }

  .p-ranking-top__desc {
    padding: 4px 8px 4px 12px;
    font-size: $fs-md;
  }
}
