@charset "utf-8";

.p-offer-header {
  & .c-header {
    display: none;
  }

  & .c-breadcrumb {
    display: none;
  }
}

.p-offer-header__back {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px 16px;
  font-size: $fs-xs;
  font-weight: bold;
  background-color: $honne-white;
  border-bottom: 1px solid $honne-gray-300;
}

/* 900px ~ */
@include min-screen($breakpoint-pc-min) {
  .p-offer-header {
    & .c-header {
      display: block;
    }

    & .c-breadcrumb {
      display: block;
    }
  }

  .p-offer-header__back {
    display: none;
  }
}
