@charset "utf-8";

.p-top-guide {
  padding: 16px 0;
  background-color: $honne-yellow-white;
}

.p-top-guide__title {
  margin-bottom: 16px;
  font-size: $fs-xl;
  font-weight: bold;
  line-height: $line-height-xx-large;
  text-align: center;
  letter-spacing: $letter-spacing-px5;
}

.p-top-pickup-guide__desc {
  padding: 0 24px;
  margin-bottom: 16px;
}

.p-top-guide__title-image {
  display: inline-block;
  width: 225px;
  height: 22px;
  margin-top: 8px;
}

.p-top-pickup-guide {
  display: flex;
  overflow-x: auto;
  @include scrollbar-none;
}

.p-top-pickup-guide__wrap {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px 24px;
}

.p-top-pickup-guide__image {
  height: 160px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-top-pickup-guide__item {
  position: relative;
  width: 280px;
  border-radius: 8px;
  box-shadow: $shadow-size-medium;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &:before {
    position: absolute;
    top: -3px;
    left: -3px;
    display: inline-block;
    width: 80px;
    height: 60px;
    content: "";
    background: url("~images/top/recommend.png") no-repeat;
    background-size: contain;
  }
}

.p-top-pickup-guide__main {
  padding: 16px;
}

.p-top-pickup-guide__text {
  margin-bottom: 12px;
  font-size: $fs-2md;
  font-weight: bold;
  line-height: $line-height-large;
  letter-spacing: $letter-spacing-px2;
}

.p-top-more.p-top-pickup-guide-more {
  margin-top: 8px;
}

/* 481px ~ 768px */
@include screen($breakpoint-tablet-min, $breakpoint-tablet-max) {
  .p-top-pickup-guide__desc {
    text-align: center;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-top-guide {
    padding: 32px;
  }

  .p-top-guide__title {
    margin-bottom: 24px;
    font-size: $fs-3xl;
    letter-spacing: $letter-spacing-px4;
  }

  .p-top-pickup-guide__desc {
    padding: 0;
    margin-bottom: 32px;
    text-align: center;
  }

  .p-top-guide__title-image {
    width: 265px;
    height: 26px;
    margin-top: 16px;
  }

  .p-top-pickup-guide__wrap {
    padding: 0;
  }

  .p-top-pickup-guide {
    display: initial;
    overflow-x: initial;
  }

  .p-top-pickup-guide__item {
    width: calc((100vw - 192px) / 3);
    max-width: 317px;

    &:not(:last-child) {
      margin-right: initial;
    }
  }

  .p-top-more.p-top-pickup-guide-more {
    margin-top: 32px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-top-guide {
    padding: 24px 0;
  }
}
