@charset "utf-8";

.p-brand__head {
  margin: 24px 0 16px;
  font-size: $fs-xl;
}

.p-brand__head__count {
  color: $honne-red-bright;
}

.p-brand__pager {
  margin-top: 24px;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-brand__head {
    margin: 32px 0 16px;
    font-size: $fs-3xl;
  }
}
