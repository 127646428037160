@charset "utf-8";

.c-chart {
  &__text {
    margin-top: 16px;
    font-size: $fs-lg;
    font-weight: bold;
    text-align: center;
  }

  &__text--blue {
    color: $honne-blue;
  }

  &__text--red {
    color: $honne-red-bright;
  }

  &__text--gray {
    color: $honne-gray-600;
  }
}
