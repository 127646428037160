@charset "utf-8";

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-my-brochure {
    .c-brochure-footer {
      margin-right: calc(50vw - 540px);
      margin-left: auto;
    }
  }
}
