@charset "utf-8";

.p-cash-form-action {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.p-cash-form-action__button {
  padding: 16px;
  font-size: $fs-xl;
  border-radius: 40px;
  box-shadow: $shadow-size-medium;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-cash-form-action {
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
  }

  .p-cash-form-action__button {
    max-width: 300px;
  }
}
