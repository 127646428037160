@charset "utf-8";

.p-question-request-form-block {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  margin: 16px 0;
  background-color: $honne-blue-bright;
  border-radius: $radius-size-medium;
}

.p-question-request-form-block__h1 {
  margin-right: 16px;
  font-size: $fs-md;
  font-weight: bold;
  line-height: $lh-large;
  color: $honne-blue;
}

.p-question-request-form-block__text {
  font-size: $fs-sm;
  line-height: $lh-medium;
  color: $honne-blue;
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .p-question-request-form-block {
    flex-direction: row;
  }
}
