@charset "utf-8";

.p-cash-top-staff {
  display: flex;
  padding: 16px;
  background: $honne-yellow-white;
  border-radius: $radius-size-medium;
}

.p-cash-top-staff__logo {
  margin-right: 8px;
}

.p-cash-top-staff__title {
  @include typo-2md-xl-md;
  font-weight: bold;
  color: $honne-gray-700;
}

.p-cash-top-staff__desc {
  @include typo-md-xl-md;
  color: $honne-gray-600;
}
