@charset "utf-8";

.p-line-intro {
  min-width: $breakpoint-sp-min;
  margin-bottom: 40px;
  background-color: $honne-yellow-white;
}

.p-line-intro__title {
  margin-bottom: 8px;
  font-size: $fs-xl;
  font-weight: bold;
  text-align: center;
  letter-spacing: $letter-spacing-px2;
}

.p-line-intro__desc {
  font-size: $fs-2md;
  line-height: $line-height3;
  letter-spacing: $letter-spacing-px2;

  br {
    display: none;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-line-intro {
    max-width: $breakpoint-pc-max;
    padding: 0 0 80px;
    margin: 0 auto;
    color: $honne-gray-700;
    text-align: center;
  }

  .p-line-intro__title {
    margin-bottom: 16px;
    font-size: $font-size-big1;
    font-weight: bold;
    letter-spacing: $letter-spacing-px2;
  }

  .p-line-intro__desc {
    font-size: $fs-2xl;
    line-height: $line-height3;
    letter-spacing: $letter-spacing-px2;

    br {
      display: block;
    }
  }
}
