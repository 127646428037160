@charset "utf-8";

/*doc
---
title: box
name: box
category: components
---
```html_example
<div style="background-color: #f8f7f5;padding: 24px;">
  <div class="c-box">
    <div class="c-box__inner">box1</div>
  </div>
  <div class="c-box">
    <div class="c-box__inner">box2</div>
  </div>
  <div class="c-box c-box--pager">
    <nav class="c-pagination">
      <div class="c-pagination__prev"></div>
      <span class="c-pagination__content">ページ1 - 1〜20件</span>
      <a rel="next" class="c-pagination__next" aria-label="次のページへ" href="#">
        <span class="c-pagination__next__text">次のページへ</span>
        <i class="c-icon-arrow-right-blue"></i>
      </a>
    </nav>
  </div>
</div>

<h2 style="margin: 24px 0;">SPの場合で幅いっぱい表示したい</h2>
<div style="background-color: #f8f7f5;padding: 24px;">
  <div class="c-box">
    <div class="c-box__inner">
      <div class="c-box__inner__full">full box</div>
    </div>
  </div>
</div>
```
*/

// 白背景角丸の汎用的なボックス
.c-box {
  width: 100%;
  overflow: hidden;
  background-color: $honne-white;
}

.c-box + .c-box {
  margin-top: 16px;
}

.c-box--pager {
  width: calc(100% - (#{$sp-page-margin} * 2));
  margin: 0 auto;
  border-radius: $radius-size-medium;
}

.c-box__inner {
  padding: $sp-page-margin;
}

.c-box__inner:after {
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
  content: ".";
  opacity: 0;
}

.c-box__inner__full {
  margin-right: -24px;
  margin-left: -24px;
}

/* 481px ~ */
@include min-screen($breakpoint-tablet-min) {
  .c-box__inner__full--sp {
    margin-right: 0;
    margin-left: 0;
  }
}

/* 769px ~ */
@include min-screen($breakpoint-pc-small-min) {
  .c-box {
    border-radius: $radius-size-medium;
  }

  .c-box--pager {
    width: 100%;
  }

  .c-box--column {
    padding: 0 32px;
  }

  .c-box__inner__full--tablet {
    margin-right: 0;
    margin-left: 0;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .c-box--column {
    padding: 0;
  }
}
